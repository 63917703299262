import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";

function DocumentsGrid(props) {
  //function
  function handleViewFile(file) {
    let id = file._id;
    let apiUrl = process.env.REACT_APP_API_URL + "/api/user/file/" + id;
    let apiParams = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };
    fetch(apiUrl, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          let type = repos.data.fileType;
          let bytes = new Uint8Array(repos.data.meta_data.data.data);
          let newLink = saveByteArray(repos.data.fileName, bytes, type);
          newLink.link.click();
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  }
  function saveByteArray(reportName, byte, type) {
    let blob = new Blob([byte], { type: type });
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);

    let fileName = reportName;
    link.download = fileName;

    let newLink = {
      name: fileName,
      link: link,
    };
    return newLink;
  }
  return (
    <div>
      <TableContainer component={Paper}>
        <Table
          sx={{
            minWidth: 650,
          }}
        >
          <TableHead
            sx={{ backgroundColor: process.env.REACT_APP_PRIMARY_COLOR }}
          >
            <TableRow
              sx={{
                "& .MuiTableCell-root": {
                  textAlign: "center",
                  fontFamily: "sans-serif",
                  fontSize: "20px",
                  fontWeight: 700,
                  color: "white",
                },
              }}
            >
              <TableCell>File Name</TableCell>
              <TableCell>Uploaded By</TableCell>
              <TableCell>Upload Date</TableCell>
              <TableCell>File</TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              "& .MuiTableRow-root:hover": {
                backgroundColor: process.env.REACT_APP_SECONDARY_COLOR,
              },
            }}
          >
            {props.data.map((file) => (
              <TableRow
                key={file._id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "& .MuiTableCell-root": {
                    textAlign: "center",
                    fontFamily: "Helvetica",
                    fontSize: "18px",
                  },
                }}
              >
                <TableCell>{file.fileName}</TableCell>
                <TableCell>{file.uploadedby}</TableCell>
                <TableCell>{file.createdAt.slice(0, 10)}</TableCell>
                <TableCell>
                  <Button onClick={() => handleViewFile(file)}>Download</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default DocumentsGrid;
