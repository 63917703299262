import React from "react";
import "../../Styles/holidaysmob.css";
import { useState } from "react";
import { useContext } from "react";
import { UserContext } from "../../UserContext";
import { useEffect } from "react";

function HolidayGridMob(props) {
  const [users, setUsers] = useState([]);
  const [isChecked, setIsChecked] = useState(true);
  const [holidays, setHolidays] = useState([]);
  const { user } = useContext(UserContext);
  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    getUsers();
    getallHolidays();
    getReportingTo();
  }, []);

  const arrayUniqueByKey = [
    ...new Map(props.data.map((item) => [item.userId.name, item])).values(),
  ];

  const getUsers = () => {
    let apiURL = process.env.REACT_APP_API_URL + "/api/user/allusers";

    let apiParams = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setUsers(repos.data);
        } else alert(repos.message);
      });
  };
  const getReportingTo = () => {
    let empid = user._id;

    let apiUrl =
      process.env.REACT_APP_API_URL + "/api/user/register?empid=" + empid;
    const apiParam = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };

    fetch(apiUrl, apiParam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setCurrentUser(repos.data);
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };
  const getallHolidays = async () => {
    let apiURL = process.env.REACT_APP_API_URL + "/api/holiday/getallholidays";

    let apiParams = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };
    await fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setHolidays(repos.data);
        } else alert(repos.message);
      });
  };

  const updateHoliday = (isChecked, holiday, sh) => {
    if (sh != user._id) {
      return;
    }
    if (new Date(holiday.date).setHours(0, 0, 0, 0) < new Date()) {
      alert("Cannot update past holidays!!");
      return;
    }
    let apiURL =
      process.env.REACT_APP_API_URL + "/api/holiday/selectedholidays";

    let apiParam = {
      method: "POST",
      headers: {
        holidayId: holiday._id,
        userId: sh,
        isSelected: isChecked,
        dateofholiday: new Date(holiday.date).toISOString(),
        occation: holiday.occation,
        reportingtoid: currentUser.reportingToId,
        empname: user.name,
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };
    fetch(apiURL, apiParam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          props.refreshGrid();
        } else alert(repos.message);
      });
  };
  return (
    <div>
      {holidays.map((holiday) => {
        return (
          <div id="card_1_holi">
            <svg class="card_1_bdr">
              <rect
                id="card_1_bdr"
                rx="11"
                ry="11"
                x="0"
                y="0"
                width="266"
                height="44"
              ></rect>
            </svg>
            <div id="ChristmasSun_Dec_25_2022">
              <span>
                {holiday.occation}({new Date(holiday.date).toDateString()})
              </span>
            </div>
            {arrayUniqueByKey.length > 0 ? (
              arrayUniqueByKey.map((currentUser) =>
                props.data.filter(
                  (sh) =>
                    sh.userId._id == currentUser.userId._id &&
                    sh.holidayId == holiday._id
                ).length > 0 ? (
                  <input
                    class="chkbx_holi_mob"
                    type="checkbox"
                    checked={
                      props.data
                        .filter(
                          (sh) =>
                            sh.userId._id == currentUser.userId._id &&
                            sh.holidayId == holiday._id
                        )
                        .map((m) => m.isSelected)[0]
                    }
                    onChange={(e) =>
                      updateHoliday(
                        e.target.checked,
                        holiday,
                        props.selectedEmployee
                      )
                    }
                  />
                ) : (
                  <input
                    class="chkbx_holi_mob"
                    checked={false}
                    type="checkbox"
                    onChange={(e) =>
                      updateHoliday(
                        e.target.checked,
                        holiday,
                        props.selectedEmployee
                      )
                    }
                  />
                )
              )
            ) : (
              <input
                class="chkbx_holi_mob"
                checked={false}
                type="checkbox"
                onChange={(e) =>
                  updateHoliday(
                    e.target.checked,
                    holiday,
                    props.selectedEmployee
                  )
                }
              />
            )}
          </div>
        );
      })}
    </div>
  );
}

export default HolidayGridMob;
