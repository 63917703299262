import React from "react";
import "../../Styles/tasksmob.css";
import { useState } from "react";
import { useContext } from "react";
import { UserContext } from "../../UserContext";
import { useEffect } from "react";

function MyTasksPanelMob(props) {
  const { user, setUser } = useContext(UserContext);
  //useState
  const [task, setTask] = useState("");
  const [description, setDescription] = useState("");
  const [approver, setApprover] = useState("");
  const [important, setImportant] = useState(false);
  const [urgent, setUrgent] = useState(false);
  const [assignTo, setAssignTo] = useState("");
  const [deadline, setDeadline] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [data, setData] = useState([]);
  //useEffect
  useEffect(() => {
    let empid = user._id;
    setAssignTo(empid);

    const apiurl =
      process.env.REACT_APP_API_URL +
      "/api/user/employeesdropdown?empid=" +
      user._id;
    const apiparam = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };
    fetch(apiurl, apiparam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setData(repos.data);
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  }, []);
  //functions

  const handleCreate = () => {
    if (task.length < 1) {
      alert("Please enter task!");
      return;
    }
    if (description.length < 1) {
      alert("Please enter task!");
      return;
    }
    const apiurl = process.env.REACT_APP_API_URL + "/api/mytasks";
    const apiparam = {
      method: "POST",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
        task,
        description: encodeURIComponent(description),
        assigntoid: assignTo,
        assigntoname: data.filter((emp) => emp._id === assignTo)[0].name,
        important,
        urgent,
        deadline: new Date(deadline).toISOString().split("T")[0],
        creatorid: user._id,
        creatorname: user.name,
      },
    };
    fetch(apiurl, apiparam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          alert(repos.message);
          props.refreshGrid();
          handleReset();
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };
  const handleReset = () => {
    setTask("");
    setDescription("");
    setAssignTo(user._id);
    setImportant(false);
    setUrgent(false);
    setDeadline(new Date());
  };
  function addDays(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  return (
    <div>
      <textarea
        placeholder="Task Details"
        class="details_input_task_mob"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      ></textarea>
      <input
        placeholder="Task"
        class="comment_box_task_mob"
        value={task}
        onChange={(e) => setTask(e.target.value)}
      />

      <div id="assign_grp_mob">
        <select
          class="assign_input_mob"
          value={assignTo}
          onChange={(e) => {
            setAssignTo(e.target.value);
          }}
        >
          {data.map((employee) => {
            return <option value={employee._id}>{employee.name}</option>;
          })}
        </select>
        <div id="assign_lbl_mob">
          <span>Assign To</span>
        </div>
      </div>
      <div id="dead_grp_task_mob">
        <input
          type="date"
          class="deadline_input_mob"
          value={deadline}
          onChange={(e) => {
            const fromDateTimestamp = Date.parse(e.target.value);

            if (isNaN(fromDateTimestamp)) {
              // handle invalid date input
              return;
            }
            setDeadline(e.target.value);
          }}
        />

        <div id="deadline_lbl_task_mob">
          <span>Deadline</span>
        </div>
      </div>
      <div id="checkbox_overall_grp_mob">
        <div id="imp_checkbox_mob">
          <div id="Important_task_mob">
            <span>Important</span>
          </div>
          <input
            type="checkbox"
            class="chkbx_task_mob"
            checked={important}
            onChange={(e) => setImportant(e.target.checked)}
          />
        </div>
        <div id="urgent_chckbx_mob">
          <div id="Urgent_task_mob">
            <span>Urgent</span>
          </div>
          <input
            type="checkbox"
            class="chkbx_task_1_mob"
            checked={urgent}
            onChange={(e) => setUrgent(e.target.checked)}
          />
        </div>
      </div>
      <div id="mid_btn_grp_mob">
        <div id="create_task_btn_grp_mob">
          <svg class="create_bdr_task_mob">
            <ellipse
              id="create_bdr_task_mob"
              rx="17.5"
              ry="17.5"
              cx="17.5"
              cy="17.5"
            ></ellipse>
          </svg>
          <button
            onClick={() => {
              handleCreate();
            }}
          >
            <img
              id="play-butttontask_mob"
              src="play-butttontask.png"
              srcset="play-butttontask.png 1x"
              alt=""
            />
          </button>
        </div>
        <div id="reset_task_btn_grp_mob">
          <svg class="stop-button_bdrtask">
            <ellipse
              id="stop-button_bdrtask_mob"
              rx="17.5"
              ry="17.5"
              cx="17.5"
              cy="17.5"
            ></ellipse>
          </svg>
          <button
            onClick={(e) => {
              handleReset();
            }}
          >
            <img
              id="stop-button_imgtask_mob"
              src="stop-button_imgtask.png"
              srcset="stop-button_imgtask.png 1x"
              alt=""
            />
          </button>
        </div>
        <div id="task_Create_mob">
          <span>Create</span>
        </div>
        <div id="task_Reset_mob">
          <span>Reset</span>
        </div>
      </div>
    </div>
  );
}

export default MyTasksPanelMob;
