import React, { useState, useEffect, useContext } from "react";
import Image from "./logo.jpg";
import { useHistory } from "react-router-dom";
import Menu from "../Components/Menu";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import "../Styles/register.css";
import { UserContext } from "../UserContext";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { Button, Grid } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

function Register(props) {
  const { user } = useContext(UserContext);
  const history = useHistory();
  const [uniqueId, setUniqueId] = useState("");
  const [dateOfJoining, setDateOfJoining] = useState(new Date());
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [userdob, setUserdob] = useState(new Date().toISOString().slice(0, 10));
  const [userGender, setUserGender] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userAccesstype, setUserAccessType] = useState("");
  const [userDesignation, setUserDesignation] = useState("");
  const [userConfirmpassword, setUserConfirmPassword] = useState("");
  const [userPhoto, setUserPhoto] = useState();
  const [data, setData] = useState([]);
  const [reportingToId, setReportingToId] = useState("");
  const [img, setImg] = useState();
  const [shiftStartHours, setShiftStartHours] = useState(0);
  const [shiftStartMinutes, setShiftStartMinutes] = useState(0);
  const [shiftStopHours, setShiftStopHours] = useState(0);
  const [shiftStopMinutes, setShiftStopMinutes] = useState(0);
  const [userPAN, setUserPAN] = useState("");
  const [userAadhar, setUserAadhar] = useState("");
  const [userPassport, setUserPassport] = useState("");
    const [userPassportExpiry, setUserPassportExpiry] = useState(new Date().toISOString().slice(0, 10));
  const [userEmergencyContact, setUserEmergencyContact] = useState("");
  const [userEmergencyNo, setUserEmergencyNo] = useState("");
  const [userBankAccount, setUserBankAccount] = useState("");
  const [active, setActive] = useState(true);
  const [upiId, setUpiId] = useState();

  const handleImageUpload = (e) => {
    if (e.target.files[0].size > 71680) {
      alert("Please choose a file with size below 70kb!");
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (e) => {
      setImg(e.target.result);
    };
    let formData = new FormData();
    formData.append("image", e.target.files[0], e.target.value);
    setUserPhoto(formData);
  };
  const redirected = () => {
    if (!img) {
      alert("Please upload image!");
      return;
    } else if (userPassword === userConfirmpassword) {
      let shiftStart = shiftStartHours + shiftStartMinutes;
      let shiftEnd = shiftStopHours + shiftStopMinutes;
      const apiurl = process.env.REACT_APP_API_URL + "/api/user/register";
      var apiparam = {
        method: "POST",
        headers: {
          uid: uniqueId,
          doj: dateOfJoining,
          email: userEmail,
          name: userName,
          phone: userPhone,
          address: encodeURIComponent(userAddress),
          dob: userdob,
          gender: userGender,
          password: userPassword,
          accesstype: userAccesstype,
          designation: userDesignation,
          reportingtoid: reportingToId,
          reportingtoname: data.filter((item) => item._id === reportingToId)[0]
            .name,
          shiftStart: shiftStart,
          shiftEnd: shiftEnd,
          pannumber: userPAN,
          aadharnumber: userAadhar,
          passportnumber: userPassport,
            passportexpiry: userPassportExpiry,
          emergencycontactname: userEmergencyContact,
          emergencycontactnumber: userEmergencyNo,
          bankaccountdetails: encodeURIComponent(userBankAccount),
          active: active,
          upiid: upiId,
          authtoken: localStorage.getItem("loggedintoken"),
        },
        body: userPhoto,
      };
      fetch(apiurl, apiparam)
        .then((res) => (res.status === 200 ? res.json() : res.text()))
        .then((repos) => {
          if (repos.data) {
            console.log(repos);
            alert("Employee added!");
            window.location.href = "/";
          } else alert(repos.message || repos);
        })
        .catch((err) => alert(err));
    } else {
      alert("passwords do not match");
    }
  };
  useEffect(() => {
    setShiftStartHours(540);
    setShiftStartMinutes(0);
    setShiftStopHours(1080);
    setShiftStopMinutes(0);
    const apiurl =
      process.env.REACT_APP_API_URL +
      "/api/user/employeesdropdown?empid=" +
      user._id;
    fetch(apiurl, {
      headers: { authtoken: localStorage.getItem("loggedintoken") },
    })
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setData(repos.data);
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  }, []);

  return (
    <Grid container>
      <div id="sign_up">
        <img
          id="logo"
          src="logo.png"
          srcset="logo.png 1x, logo@2x.png 2x"
          alt=""
        />
        <img
          id="n_0944467"
          src="n_0944467.png"
          srcset="n_0944467.png 1x, n_0944467@2x.png 2x"
          alt="register page "
        />
        <div id="Group_11">
          <svg class="Rectangle_1_j">
            <linearGradient
              id="Rectangle_1_j"
              spreadMethod="pad"
              x1="0.5"
              x2="0.531"
              y1="0"
              y2="1.03"
            >
              <stop offset="0" stop-color="#b0f6d3" stop-opacity="1"></stop>
              <stop
                offset="0.8582"
                stop-color="#d6d3fe"
                stop-opacity="1"
              ></stop>
              <stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
            </linearGradient>
            <rect
              id="Rectangle_1_j"
              rx="32"
              ry="32"
              x="0"
              y="0"
              width="659"
              height="1247"
            ></rect>
          </svg>
          <div id="Group_10">
            <Tooltip title="Upload Profile Picture">
              <label for="image">
                <input
                  id="image"
                  type="file"
                  onChange={(e) => handleImageUpload(e)}
                  hidden
                  accept="image/*"
                  style={{ cursor: "pointer" }}
                />
                <img id="profile" src={img || "profile.png"} alt="" />
              </label>
            </Tooltip>
            <form method="post" encType="multipart/form-data">
              <input
                type="text"
                placeholder="Name"
                className="Rectangle_2 "
                value={userName}
                label="Name"
                onChange={(e) => setUserName(e.target.value)}
                required
              />
              <input
                type="text"
                placeholder="Email"
                className="Rectangle_3"
                label="Email"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                required
              />
              <input
                placeholder="Unique ID"
                id="Rectangle_4"
                class="Rectangle_4"
                value={uniqueId}
                onChange={(e) => setUniqueId(e.target.value)}
              />
              <div className="Rectangle_5">
                {/*<LocalizationProvider dateAdapter={AdapterDateFns}>*/}
                {/*  <DatePicker*/}
                {/*    label="Date of Joining"*/}
                {/*    value={new Date(dateOfJoining).toISOString().split("T")[0]}*/}
                {/*    onChange={(newValue) => {*/}
                {/*      setDateOfJoining(newValue);*/}
                {/*    }}*/}
                {/*    required*/}
                {/*    renderInput={(params) => (*/}
                {/*      <TextField {...params} sx={{ width: "283px" }} />*/}
                {/*    )}*/}
                {/*  />*/}
                {/*              </LocalizationProvider>*/}
                              <input
                                  type="date"
                                  style={{
                                      "width": "100%",
                                      "height": "100%",
                                      "borderRadius": "8px",
                                      "padding":"10px"
                                  }}
                                  placeholder="Date of Joining"
                                  value={new Date(dateOfJoining).toISOString().split("T")[0]}
                                  disabled={user.accesstype != 5}
                                  onChange={(e) => {
                                      setDateOfJoining(e.target.value);
                                  }}
                              />
              </div>
              <input
                id="Rectangle_6"
                placeholder="Mobile"
                class="Rectangle_6"
                value={userPhone}
                onChange={(e) => setUserPhone(e.target.value)}
                required
              />
              <select
                class="Rectangle_7"
                id="Rectangle_7"
                value={userGender}
                onChange={(e) => setUserGender(e.target.value)}
                required
                selected
              >
                <option value="" selected disabled>
                  Gender
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
              <div>
                <div class="Rectangle_8">
                  {" "}
                  {/*<LocalizationProvider dateAdapter={AdapterDateFns}>*/}
                  {/*  <DatePicker*/}
                  {/*    label="Date of Birth"*/}
                  {/*    value={userdob}*/}
                  {/*    onChange={(e) => {*/}
                  {/*      const fromDateTimestamp = Date.parse(e.target.value);*/}

                  {/*      if (isNaN(fromDateTimestamp)) {*/}
                  {/*        // handle invalid date input*/}
                  {/*        return;*/}
                  {/*      }*/}
                  {/*      setUserdob(e.target.value);*/}
                  {/*    }}*/}
                  {/*    required*/}
                  {/*    renderInput={(params) => (*/}
                  {/*      <TextField*/}
                  {/*        {...params}*/}
                  {/*        sx={{*/}
                  {/*          border: "2px solid darkcyan",*/}
                  {/*          textOverflow: "ellipsis",*/}
                  {/*          borderRadius: "10px",*/}
                  {/*          width: "283px",*/}
                  {/*        }}*/}
                  {/*      />*/}
                  {/*    )}*/}
                  {/*  />*/}
                                  {/*</LocalizationProvider>*/}
                                  <input
                                      type="date"
                                      style={{
                                          "width": "100%",
                                          "height": "100%",
                                          "borderRadius": "8px", "border": "2px solid darkcyan",
                                          "padding": "10px"}}
                                      placeholder="Date of Birth"
                                      disabled={user.accesstype != 5}
                                      value={new Date(userdob).toISOString().split("T")[0]}
                                      onChange={(e) => {
                                          const fromDateTimestamp = Date.parse(e.target.value);

                                          if (isNaN(fromDateTimestamp)) {
                                              // handle invalid date input
                                              return;
                                          }
                                          setUserdob(e.target.value);
                                      }}
                                  />
                </div>
              </div>
              <select
                id="Rectangle_9"
                placeholder="Designation"
                class="Rectangle_9"
                value={userDesignation}
                onChange={(e) => setUserDesignation(e.target.value)}
                required
              >
                <option value="" selected disabled>
                  Designation
                </option>

                <option value="Manager">Manager</option>
                <option value="employee">Employee</option>
                <option value="client">Client</option>
              </select>
              <select
                placeholder="Reporting to"
                class="Rectangle_10"
                value={reportingToId}
                onChange={(e) => setReportingToId(e.target.value)}
                required
              >
                <option value="" selected disabled>
                  Reporting to
                </option>
                {data.map((employee) => {
                  return <option value={employee._id}>{employee.name}</option>;
                })}
              </select>
              <select
                id="Rectangle_11"
                placeholder="Access Type"
                class="Rectangle_11"
                value={userAccesstype}
                onChange={(e) => setUserAccessType(e.target.value)}
                required
              >
                <option value="" selected disabled>
                  Access Type
                </option>

                <option value="1">Manager</option>
                <option value="0">Employee</option>
                <option value="5">Admin</option>
              </select>
              <input
                id="Rectangle_12"
                placeholder="PAN Number"
                class="Rectangle_12"
                value={userPAN}
                onChange={(e) => setUserPAN(e.target.value)}
              />
              <input
                placeholder="Aadhar Number"
                class="Rectangle_13"
                id="Rectangle_13"
                value={userAadhar}
                onChange={(e) => setUserAadhar(e.target.value)}
              />

              <input
                placeholder="Passport Number"
                class="Rectangle_14"
                value={userPassport}
                onChange={(e) => setUserPassport(e.target.value)}
              />

              {/* <input
              id="Rectangle_15"
              type="date"
              placeholder="Passport Expiry Date"
              class="Rectangle_15"
              value={new Date(userPassportExpiry).toISOString().split("T")[0]}
              onChange={(newValue) => {
                setUserPassportExpiry(newValue);
              }}
            /> */}
              <div class="Rectangle_15">
                {/*<LocalizationProvider dateAdapter={AdapterDateFns}>*/}
                {/*  <DatePicker*/}
                {/*    label="Passport Expiry Date"*/}
                {/*    value={*/}
                {/*      new Date(userPassportExpiry).toISOString().split("T")[0]*/}
                {/*    }*/}
                {/*    onChange={(newValue) => {*/}
                {/*      setUserPassportExpiry(newValue);*/}
                {/*    }}*/}
                {/*    renderInput={(params) => (*/}
                {/*      <TextField*/}
                {/*        {...params}*/}
                {/*        sx={{*/}
                {/*          border: "2px solid darkcyan",*/}
                {/*          textOverflow: "ellipsis",*/}
                {/*          borderRadius: "10px",*/}
                {/*          width: "283px",*/}
                {/*        }}*/}
                {/*      />*/}
                {/*    )}*/}
                {/*    style={{ width: "100%" }}*/}
                {/*    required*/}
                {/*  />*/}
                              {/*</LocalizationProvider>*/}

                              <input
                                  placeholder="Passport Expiry Date"
                                  type="date"
                                  style={{
                                      "width": "100%",
                                      "height": "100%",
                                      "borderRadius": "8px", "border": "2px solid darkcyan",
                                      "padding": "10px"
                                  }}
                                  disabled={user.accesstype != 5}
                                  value={new Date(userPassportExpiry).toISOString().split("T")[0] || ""}
                                  onChange={(e) => {
                                      const fromDateTimestamp = Date.parse(e.target.value);

                                      if (isNaN(fromDateTimestamp)) {
                                          // handle invalid date input
                                          return;
                                      }
                                      setUserPassportExpiry(e.target.value);
                                  }}
                              />
              </div>
              <input
                placeholder="Emergency Contact Person"
                class="Rectangle_16"
                value={userEmergencyContact}
                onChange={(e) => setUserEmergencyContact(e.target.value)}
                required
              />

              <input
                placeholder="Emergency Contact Number"
                class="Rectangle_17"
                value={userEmergencyNo}
                onChange={(e) => setUserEmergencyNo(e.target.value)}
                required
              />
              <textarea
                id="Rectangle_18"
                placeholder="Address"
                class="Rectangle_18"
                value={userAddress}
                minRows={3}
                onChange={(e) => setUserAddress(e.target.value)}
                required
              ></textarea>
              <textarea
                id="Rectangle_19"
                placeholder="Bank Account Details"
                class="Rectangle_19"
                minRows={3}
                value={userBankAccount}
                onChange={(e) => setUserBankAccount(e.target.value)}
              ></textarea>
              <select
                class="Rectangle_21"
                value={parseInt(shiftStartHours)}
                label="Shift Start hour"
                onChange={(e) => {
                  setShiftStartHours(e.target.value);
                }}
              >
                <option value={0}>00</option>
                <option value={60}>01</option>
                <option value={120}>02</option>
                <option value={180}>03</option>
                <option value={240}>04</option>
                <option value={300}>05</option>
                <option value={360}>06</option>
                <option value={420}>07</option>
                <option value={480}>08</option>
                <option value={540}>09</option>
                <option value={600}>10</option>
                <option value={660}>11</option>
                <option value={720}>12</option>
                <option value={780}>13</option>
                <option value={840}>14</option>
                <option value={900}>15</option>
                <option value={960}>16</option>
                <option value={1020}>17</option>
                <option value={1080}>18</option>
                <option value={1140}>19</option>
                <option value={1200}>20</option>
                <option value={1260}>21</option>
                <option value={1320}>22</option>
                <option value={1380}>23</option>
              </select>
              <select
                class="Rectangle_22"
                value={parseInt(shiftStartMinutes)}
                label="Shift Start minutes"
                onChange={(e) => setShiftStartMinutes(e.target.value)}
              >
                <option value={0}>00</option>
                <option value={15}>15</option>
                <option value={30}>30</option>
                <option value={45}>45</option>
              </select>
              <select
                class="Rectangle_23"
                value={shiftStopHours}
                label="Shift Stop hours"
                onChange={(e) => setShiftStopHours(e.target.value)}
              >
                <option value={0}>00</option>
                <option value={60}>01</option>
                <option value={120}>02</option>
                <option value={180}>03</option>
                <option value={240}>04</option>
                <option value={300}>05</option>
                <option value={360}>06</option>
                <option value={420}>07</option>
                <option value={480}>08</option>
                <option value={540}>09</option>
                <option value={600}>10</option>
                <option value={660}>11</option>
                <option value={720}>12</option>
                <option value={780}>13</option>
                <option value={840}>14</option>
                <option value={900}>15</option>
                <option value={960}>16</option>
                <option value={1020}>17</option>
                <option value={1080}>18</option>
                <option value={1140}>19</option>
                <option value={1200}>20</option>
                <option value={1260}>21</option>
                <option value={1320}>22</option>
                <option value={1380}>23</option>
              </select>
              <select
                class="Rectangle_24"
                value={shiftStopMinutes}
                label="Shift Stop minutes"
                onChange={(e) => setShiftStopMinutes(e.target.value)}
              >
                <option value={0}>00</option>
                <option value={15}>15</option>
                <option value={30}>30</option>
                <option value={45}>45</option>
              </select>
              <input
                type="password"
                placeholder="Password"
                className="Rectangle_Password"
                value={userPassword}
                onChange={(e) => setUserPassword(e.target.value)}
                required
              />
              <input
                type="password"
                placeholder="Confirm Password"
                class="Rectangle_cnfPassword"
                value={userConfirmpassword}
                onChange={(e) => setUserConfirmPassword(e.target.value)}
                required
              />
              <input
                type="text"
                id="register_upiId"
                placeholder="UPI ID(GPAY or PhonePe)"
                value={upiId}
                onChange={(e) => setUpiId(e.target.value)}
              />
              <button
                id="Group_12"
                onClick={() => {
                  redirected();
                }}
              >
                <svg class="Rectangle_27_bx">
                  <rect
                    id="Rectangle_27_bx"
                    rx="9"
                    ry="9"
                    x="0"
                    y="0"
                    width="162"
                    height="50"
                  ></rect>
                </svg>
                <div id="Register_by">
                  <span>Register</span>
                </div>
              </button>
            </form>
          </div>
          <div id="Group_9">
            <Menu />
          </div>
        </div>
      </div>
    </Grid>
  );
}

export default Register;
