import React, { useEffect, useState, useContext } from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Button, MenuItem } from "@mui/material";
import { areDayPropsEqual } from "@mui/lab/PickersDay/PickersDay";
import { UserContext } from "../UserContext";
import "../Styles/attendance.css";

function TasksFilter(props) {
  const { user, setUser } = useContext(UserContext);
  //variable
  var accesstypes = parseInt(user.accesstype);
  //useState
  const [fromValue, setFromValue] = useState(new Date());
  const [toValue, setToValue] = useState(new Date());
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [disabled, setDisabled] = useState(true);
  const [data, setData] = useState([]);
  const [empid, setEmpid] = useState(props.employee);
  // useEffect;
  useEffect(() => {
    GetEmployees();
  }, []);
  //functions
  function GetEmployees() {
    let empid = user._id;
    const apiurl =
      process.env.REACT_APP_API_URL +
      "/api/user/employeesdropdown?empid=" +
      empid;
    const apiparam = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };

    fetch(apiurl, apiparam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setData([{ _id: "all", name: "All Team Members" }, ...repos.data]);
          setDisabled(false);
        } else console.log(repos.message || repos);
      })
      .catch((err) => console.log(err));
  }

  return (
    // <div>
    //   <div
    //     style={{
    //       display: "flex",
    //       justifyContent: "center",
    //       marginTop: "20px",
    //       alignItems: "center",
    //     }}
    //   >
    //     {accesstypes > 0 ? (
    //       <div style={{ margin: "10px" }}>
    //         <TextField
    //           select
    //           style={{ width: "300px" }}
    //           value={props.employee || user._id}
    //           InputLabelProps={{ shrink: true }}
    //           label="Employee"
    //           onChange={(e) => {
    //             props.changeEmployee(e.target.value);
    //           }}
    //           disabled={disabled}
    //         >
    //           {data.map((employee) => {
    //             return (
    //               <MenuItem value={employee._id}>{employee.name}</MenuItem>
    //             );
    //           })}
    //         </TextField>
    //       </div>
    //     ) : (
    //       ""
    //     )}
    //     <div style={{ margin: "10px" }}>
    //       <TextField
    //         select
    //         style={{ width: "300px" }}
    //         value={props.taskStatus}
    //         InputLabelProps={{ shrink: true }}
    //         label="Task Status"
    //         onChange={(e) => {
    //           props.changeTaskStatus(e.target.value);
    //         }}
    //         disabled={disabled}
    //       >
    //         <MenuItem value="all">All Tasks</MenuItem>
    //         <MenuItem value="open">Open</MenuItem>
    //         <MenuItem value="breached">Breached Deadline</MenuItem>
    //         <MenuItem value="waiting">Waiting Closure Confirmation</MenuItem>
    //         <MenuItem value="closed">Closed</MenuItem>
    //       </TextField>
    //     </div>
    //     <div style={{ margin: "10px" }}>
    //       <Button
    //         variant="contained"
    //         color="success"
    //         name="Adminfilter"
    //         onClick={(e) => props.onFilterClick()}
    //       >
    //         View
    //       </Button>
    //     </div>
    //   </div>
    // </div>
    <div id="emp_tast_grp">
      {accesstypes > 0 ? (
        <select
          class="employee_bdr_input_task"
          value={props.employee || user._id}
          label="Employee"
          onChange={(e) => {
            props.changeEmployee(e.target.value);
          }}
        >
          {data.map((employee) => {
            return <option value={employee._id}>{employee.name}</option>;
          })}
        </select>
      ) : (
        ""
      )}
      {accesstypes > 0 ? (
        <div id="Employee_lbl_task">
          <span>Employee</span>
        </div>
      ) : (
        ""
      )}
      <select
        class="to_bdr_input_task"
        value={props.taskStatus}
        label="Task Status"
        onChange={(e) => {
          props.changeTaskStatus(e.target.value);
        }}
        disabled={disabled}
      >
        <option value="all">All Tasks</option>
        <option value="open">Open</option>
        <option value="breached">Breached Deadline</option>
        <option value="waiting">Waiting Closure Confirmation</option>
        <option value="closed">Closed</option>
      </select>
      <div id="To_lbl_task">
        <span>Task Status</span>
      </div>
    </div>
  );
}

export default TasksFilter;
