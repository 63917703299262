import { Box } from "@material-ui/core";
import React, { useContext } from "react";
import { UserContext } from "../../UserContext";
import SingleChat from "./SingleChat";

const ChatBox = ({ fetchAgain, setFetchAgain }) => {
  const { selectedChat } = useContext(UserContext);

  return (
    <Box
      sx={{
        display: { base: selectedChat ? "flex" : "none", md: "flex" },
        flexDirection: "column",
        alignItems: "center",
        padding: "3px",
        width: { base: "100%", md: "68%" },
        borderRadius: "25px",
        border: "1px",
        marginLeft: "5px",
      }}
    >
      <SingleChat fetchAgain={fetchAgain} setFetchAgain={setFetchAgain} />{" "}
    </Box>
  );
};

export default ChatBox;
