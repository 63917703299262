import React, { useContext, useEffect } from "react";
import "../../Styles/homeMob.css";
import Sidebar from "./Sidebar";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { UserContext } from "../../UserContext";
import { MenuItem, Tooltip } from "@mui/material";
import { default as Noti } from "@mui/material/Menu";
import NotificationBadge from "react-notification-badge";
import { Effect } from "react-notification-badge";
function TopNav(props) {
  const {
    user,
    setUser,
    inAppNotification,
    setInAppNotification,
    notification,
    setNotification,
  } = useContext(UserContext);
  const history = useHistory();
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedTab, setSelectedTab] = useState(props.tabIndex);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (id) => {
    setAnchorEl(null);
  };

  const location = useLocation();
  function logout() {
    localStorage.clear();
    setUser(null);
    window.location.href = "/";
  }
  const notiStatus = async (id) => {
    const apiURL =
      process.env.REACT_APP_API_URL + "/api/notification/status/" + id;
    const apiParams = {
      method: "POST",
      headers: {
        userid: user._id,
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };

    await fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setInAppNotification(repos.data);
        } else {
          alert("could not update notification Status");
        }
      })
      .catch((err) => alert(JSON.stringify(err)));
  };

  const handleMessageClick = () => {
    history.push("/chats");
  };

  useEffect(() => {
    if (!user) {
      let storedUser = user;
      setUser(storedUser);
    }
  }, []);

  useEffect(() => {}, [inAppNotification]);

  return (
    <div>
      {location.pathname == "/" ? (
        <div id="noti_grp_mob">
          <svg class="bdr_noti_mob">
            <rect
              id="bdr_noti_mob"
              rx="7"
              ry="7"
              x="0"
              y="0"
              width="40"
              height="40"
            ></rect>
          </svg>
          <button
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            style={{ float: "right" }}
          >
            <NotificationBadge
              count={
                inAppNotification.filter((item) => item.isread === false).length
              }
              effect={Effect.SCALE}
            />
            <img
              id="notification-bell_mob"
              src="notification-bell_mob.png"
              srcset="notification-bell_mob.png 1x"
              alt=""
            />
          </button>
          <Noti
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {!inAppNotification.length ? (
              <MenuItem style={{ padding: "0px 10px" }}>
                No new Notifications
              </MenuItem>
            ) : (
              inAppNotification.map((n) =>
                n.isread === false ? (
                  <Tooltip title="Mark as Read" placement="bottom-end" arrow>
                    <MenuItem onClick={() => notiStatus(n._id)}>
                      {n.sender.name} {n.message}
                    </MenuItem>
                  </Tooltip>
                ) : (
                  ""
                )
              )
            )}
          </Noti>
        </div>
      ) : (
        ""
      )}
      {user && parseInt(user.accesstype) != 0 ? (
        location.pathname !== "/register" &&
        location.pathname !== "/profile" &&
        location.pathname !== "/chats" &&
        location.pathname !== "/dashboard" ? (
          <>
            <Tooltip title="Add New Employee">
              <div
                id="add_member_grp_mob"
                onClick={() => history.push("/register")}
              >
                <svg class="bdr_btn_mob">
                  <rect
                    id="bdr_btn_mob"
                    rx="7"
                    ry="7"
                    x="0"
                    y="0"
                    width="40"
                    height="40"
                  ></rect>
                </svg>
                <img
                  id="add-user_btn_mob"
                  src="add-user_btn_mob.png"
                  srcset="add-user_btn_mob.png 1x"
                  alt=""
                />
              </div>
            </Tooltip>
          </>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
      {location.pathname == "/" ? (
        <div id="msg_member_grp_mob" onClick={() => handleMessageClick()}>
          <svg class="bdr_btn_mob">
            <rect
              id="bdr_btn_mob"
              rx="7"
              ry="7"
              x="0"
              y="0"
              width="40"
              height="40"
            ></rect>
          </svg>

          <img
            id="msg-user_btn_mob"
            src="msg_img_mob.png"
            srcset="msg_img_mob.png 1x"
            alt=""
          />
        </div>
      ) : (
        ""
      )}
      {location.pathname != "/" ? (
        <div id="home_btn_grp_mob" onClick={() => history.push("/")}>
          <svg class="bdr_btn_mob_cj">
            <rect
              id="bdr_btn_mob_cj"
              rx="6"
              ry="6"
              x="0"
              y="0"
              width="40"
              height="40"
            ></rect>
          </svg>
          <img
            id="home_btn_img_mob"
            src="home_btn_img_mob.png"
            srcset="home_btn_img_mob.png 1x"
            alt=""
          />
        </div>
      ) : (
        ""
      )}
      {location.pathname == "/" ? (
        <img
          id="top_nav_logout"
          src="logout_img.png"
          srcset="logout_img.png 1x, logout_img@2x.png 2x"
          alt=""
          onClick={() => logout()}
        />
      ) : (
        ""
      )}

      {location.pathname == "/" ? (
        <div id="filter_btn_grp_mob_cl">
          <svg class="bdr_menu_btn_mob">
            <rect
              id="bdr_menu_btn_mob"
              rx="7"
              ry="7"
              x="0"
              y="0"
              width="40"
              height="40"
            ></rect>
          </svg>
          <button onClick={() => setOpenMenu(!openMenu)}>
            <img
              id="menu_btn_img_mob"
              src="menu_btn_img_mob.png"
              srcset="menu_btn_img_mob.png 1x"
              alt=""
            />
          </button>
        </div>
      ) : (
        ""
      )}
      {openMenu ? (
        <Sidebar
          closeMenu={() => setOpenMenu(!openMenu)}
          changeTab={(tabIndex) => props.setTab(tabIndex)}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default TopNav;
