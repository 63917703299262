import React, { useContext, useEffect, useState } from "react";
import "../../Styles/leavesmob.css";
import { UserContext } from "../../UserContext";
import { Modal } from "@mui/material";

function LeavesGridMob(props) {
  const { user, setUser } = useContext(UserContext);
  //useState
  const [open, setOpen] = useState(false);
  const [opened, setOpened] = useState(false);
  const [approver, setApprover] = useState("");
  const [fromdate, setFromDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [todate, setToDate] = useState(new Date().toISOString().slice(0, 10));
  const [typeleave, setTypeLeave] = useState("");
  const [leavereason, setLeaveReason] = useState("");
  const [leaveDays, setLeaveDays] = useState(0);
  const [Checked, setChecked] = useState(false);
  const [disableChecks, setDisableChecks] = useState();
  const [approverRemarks, setApproverRemarks] = useState("");
  const [startFirstHalf, setStartFirstHalf] = useState(true);
  const [startSecondHalf, setStartSecondHalf] = useState(true);
  const [endFirstHalf, setEndFirstHalf] = useState(true);
  const [endSecondHalf, setEndSecondHalf] = useState(true);
  const [leaveStatus, setLeaveStatus] = useState();
  const [leaveId, setLeaveId] = useState();

  //useEffect
  useEffect(() => {
    const fromDateTimestamp = Date.parse(fromdate);
    const toDateTimestamp = Date.parse(todate);

    if (isNaN(fromDateTimestamp) || isNaN(toDateTimestamp)) {
      return;
    }

    const date1 = new Date(fromdate);
    const date2 = new Date(todate);
    const datediff = date2 - date1;

    if (
      date1.toISOString().split("T")[0] === date2.toISOString().split("T")[0]
    ) {
      setLeaveDays(
        date1.getDay() === 0
          ? 1 - (!startFirstHalf ? 0.5 : 0) - (!startSecondHalf ? 0.5 : 0)
          : 1 - (!startFirstHalf ? 0.5 : 0) - (!startSecondHalf ? 0.5 : 0)
      );
      setDisableChecks(true);
      setEndFirstHalf(true);
      setEndSecondHalf(true);
    } else {
      setDisableChecks(false);
      const datediff = date2 - date1;

      const diffdays = Math.ceil(datediff / (1000 * 60 * 60 * 24)) + 1;

      let sundays = 0;
      for (let i = 0; i < diffdays; i++) {
        let currentday = addDays(fromdate, i);
        if (currentday.getDay() === 0) {
          sundays++;
        }
      }
      setLeaveDays(
        diffdays -
          sundays -
          (!startFirstHalf ? 0.5 : 0) -
          (!startSecondHalf ? 0.5 : 0) -
          (!endFirstHalf ? 0.5 : 0) -
          (!endSecondHalf ? 0.5 : 0)
      );
    }
  }, [
    fromdate,
    todate,
    startFirstHalf,
    startSecondHalf,
    endFirstHalf,
    endSecondHalf,
  ]);
  //functions
  const handleClosed = () => {
    setOpened(false);
  };
  function addDays(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  const handleCancelLeaves = () => {
    const apiUrl = process.env.REACT_APP_API_URL + "/api/leaves/cancelleaves";
    const apiParam = {
      method: "POST",
      headers: {
        id: leaveId,
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };
    fetch(apiUrl, apiParam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos) {
          alert(repos.message);
          props.refreshGrid();
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
    setOpen(true);
  };
  const handleEditLeaves = () => {
    //var a = approver._id;

    const apiurl = process.env.REACT_APP_API_URL + "/api/leaves/editleaves";
    const apiparam = {
      method: "POST",
      headers: {
        id: leaveId,
        fromdate: fromdate,
        fromfirsthalfleave: startFirstHalf,
        fromsecondhalfleave: startSecondHalf,
        todate: todate,
        tofirsthalfleave: endFirstHalf,
        tosecondhalfleave: endSecondHalf,
        typeleave: typeleave,
        leavedays: leaveDays,
        leavereason: leavereason,
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };
    fetch(apiurl, apiparam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos) {
          alert(repos.message);
          props.refreshGrid();
        } else alert(repos.message || repos);
      });
    handleClose();
  };
  const handleApprovers = (e) => {
    setApproverRemarks(e.target.value);
  };

  function onFirstCheckClick(e) {
    setChecked(!Checked);
    const totaldays = e.target.checked ? leaveDays + 0.5 : leaveDays - 0.5;
    setLeaveDays(totaldays);
  }
  const handleClickOpen = (appliedById) => {
    let empid = appliedById;

    let apiUrl =
      process.env.REACT_APP_API_URL + "/api/user/register?empid=" + empid;
    const apiParam = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };

    fetch(apiUrl, apiParam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setApprover(repos.data);
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
    setOpen(true);
  };
  const handleClickOpened = () => {
    setOpened(true);
  };
  function handleCellClick(leavedetail) {
    setLeaveId(leavedetail._id);
    setFromDate(leavedetail.Fromdate.slice(0, 10));
    setStartFirstHalf(leavedetail.FromFirstHalfLeave);
    setStartSecondHalf(leavedetail.FromSecondHalfLeave);
    setToDate(leavedetail.Todate.slice(0, 10));
    setEndFirstHalf(leavedetail.ToFirstHalfLeave);
    setEndSecondHalf(leavedetail.ToSecondHalfLeave);
    setTypeLeave(leavedetail.TypeofLeave);
    setLeaveDays(leavedetail.LeaveDays);
    setLeaveReason(leavedetail.LeaveReason);
    setApproverRemarks(leavedetail.ApproverRemarks);
    setLeaveStatus(leavedetail.Status);
    handleClickOpen(leavedetail.EmpId);
  }
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      {props.data.map((leavedetail) => {
        return (
          <div
            id="grid_info_overall_grp"
            onClick={() => handleCellClick(leavedetail)}
          >
            <svg class="grid_bg_leave">
              <rect
                id="grid_bg_leave"
                rx="10"
                ry="10"
                x="0"
                y="0"
                width="282"
                height="97"
              ></rect>
            </svg>
            <img id="leave1" src="leave1.png" srcset="leave1.png 1x" alt="" />

            {/* <div id="date_grid">
              <span>2023-04-21</span>
            </div>
            <div id="n__lbl_grid">
              <span>1</span>
            </div> */}
            <div id="lbl_name_grid">
              <span>{leavedetail.name}</span>
            </div>
            {/* <div id="date_mob_grid">
              <span>01-07-2023</span>
            </div> */}
            <div id="n_023-04-21_date_grid">
              <span>{leavedetail.Fromdate.slice(0, 10)}</span>
            </div>
            <img
              id="clock_img_grid"
              src="clock_img_grid.png"
              srcset="clock_img_grid.png 1x"
              alt=""
            />

            <img
              id="comments1_grid"
              src="comments1_grid.png"
              srcset="comments1_grid.png 1x"
              alt=""
            />

            <div id="FROM_grid">
              <span>FROM</span>
            </div>
            <div id="Out_lbl__grid">
              <span>To</span>
            </div>
            <div id="applied_lbl_grid">
              <span>{leavedetail.Status}</span>
            </div>
            <img
              id="clock_img_mob_leave"
              src="clock_img_mob_leave.png"
              srcset="clock_img_mob_leave.png 1x"
              alt=""
            />

            <img
              id="clipboard_img_grid"
              src="clipboard_img_grid.png"
              srcset="clipboard_img_grid.png 1x"
              alt=""
            />

            <div id="Eid-Ul-Fitr_lbl_mob">
              <span>{leavedetail.LeaveReason}</span>
            </div>
            <svg class="Line_1_underline_leave" viewBox="0 0 266 1">
              <path id="Line_1_underline_leave" d="M 0 0 L 266 0"></path>
            </svg>
            <div id="date_lbl_grid_mob">
              <span>{leavedetail.Todate.slice(0, 10)}</span>
            </div>
            <img
              id="leave_img_mob"
              src="leave_img_mob.png"
              srcset="leave_img_mob.png 1x"
              alt=""
            />
            <div></div>
            <div id="n__lbl_mob">
              <span>{leavedetail.LeaveDays}</span>
            </div>
          </div>
        );
      })}
      <Modal open={open} onClose={handleClose}>
        <div id="pop_up_leave">
          <div id="over_all_pop_leave_grp">
            <img
              id="bg_design_mob_leave"
              src="bg_design_mob_leave.png"
              srcset="bg_design_mob_leave.png 1x"
              alt=""
            />

            <svg class="editleave_bdr_popleave_m">
              <linearGradient
                id="editleave_bdr_popleave_m"
                spreadMethod="pad"
                x1="0.5"
                x2="0.5"
                y1="0"
                y2="1"
              >
                <stop offset="0" stop-color="#b5ffb7" stop-opacity="1"></stop>
                <stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
              </linearGradient>
              <rect
                id="editleave_bdr_popleave_m"
                rx="8"
                ry="8"
                x="0"
                y="0"
                width="288"
                height="51"
              ></rect>
            </svg>
            <div id="Edit_Leave_lbl_pop_leave">
              <span>Edit Leave</span>
              <img
                id="close_popup_mob_leave"
                src="close.png"
                srcset="close.png 1x, close@2x.png 2x"
                alt=""
                onClick={handleClose}
              />
            </div>
            <div id="leave_days_grp_leave">
              <a class="bdr_leaves_mob">
                <rect
                  id="bdr_leaves_mob"
                  rx="8"
                  ry="8"
                  x="0"
                  y="0"
                  width="42"
                  height="42"
                ></rect>
              </a>
              <div id="no_of_days_lb_leave">
                <span>{leaveDays}</span>
              </div>
              <div id="No_of_Days_lbl_leave">
                <span>No. of Leave Days</span>
              </div>
            </div>
            <div id="btn_grp_input_grp">
              <div id="leave_type_grp_mob_popup">
                <select
                  placeholder="Leave Type"
                  class="leave_dropdown_inputpop_mob"
                  value={typeleave}
                  onChange={(e) => setTypeLeave(e.target.value)}
                >
                  <option key={"sick"} value={"sick"}>
                    Sick
                  </option>
                  <option key={"personal"} value={"personal"}>
                    Personal
                  </option>
                  <option key={"emergency"} value={"emergency"}>
                    Emergency
                  </option>
                </select>
                <div id="leave_type_leave">
                  <span>Leave Type</span>
                </div>
              </div>
              <div id="approver_name_grp_mob">
                <input
                  placeholder="Approver Name"
                  class="approver_input_leave"
                  value={approver.reportingToName}
                />

                <div id="approver_name_leave">
                  <span>Approver Name</span>
                </div>
              </div>
              <div id="from_date_grp_mob_popup">
                <div id="From_lbl_date_leave">
                  <span>From</span>
                </div>
                <input
                  placeholder="Leave Type"
                  type="date"
                  class="from_bdr_input_pop_mob"
                  value={fromdate}
                  onChange={(e) => {
                    setFromDate(e.target.value);
                  }}
                />
              </div>
              <div id="todate_grp">
                <div id="Group_3">
                  <div id="To_lbl_date_leave">
                    <span>To</span>
                  </div>
                  <input
                    placeholder="Leave Type"
                    type="date"
                    class="to_bdr_input_box_leave"
                    value={todate}
                    onChange={(e) => {
                      setToDate(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div id="check_box_4_grp_leave">
                <input
                  type="checkbox"
                  class="checkbox_4_leave"
                  onChange={(e) => setStartFirstHalf(e.target.checked)}
                  checked={startFirstHalf}
                />

                <div id="First_Half_4_lbl_leave">
                  <span>First Half</span>
                </div>
              </div>
              <div id="check_box_2_grp_leave">
                <input
                  type="checkbox"
                  class="bdr_check_box_2_leave"
                  onChange={(e) => setEndFirstHalf(e.target.checked)}
                  checked={endFirstHalf}
                />

                <div id="First_Half_2leave_">
                  <span>First Half</span>
                </div>
              </div>
              <div id="check_box_31_grp_leave">
                <input
                  type="checkbox"
                  class="checkbox_31_input_leave"
                  onChange={(e) => setStartSecondHalf(e.target.checked)}
                  checked={startSecondHalf}
                />

                <div id="Second_Half_31_lbl_leave">
                  <span>Second Half</span>
                </div>
              </div>
              <div id="check_box_17_grp_leave">
                <input
                  type="checkbox"
                  class="checkbox_button_leave"
                  onChange={(e) => setEndSecondHalf(e.target.checked)}
                  checked={endSecondHalf}
                />

                <div id="Second_Half_text_leave">
                  <span>Second Half</span>
                </div>
              </div>
              <div id="leave_reason_grp_">
                <textarea
                  placeholder="Leave Reason"
                  class="leave_reason_leave"
                  value={leavereason}
                  onChange={(e) => {
                    setLeaveReason(e.target.value);
                  }}
                ></textarea>
                <div id="leave_reason_lbl_pop_leave">
                  <span>Leave Reason</span>
                </div>
              </div>
              <div id="leave_comments_grp_">
                <textarea
                  placeholder="Approver Remarks"
                  class="leave_reason_leave"
                  value={approverRemarks}
                ></textarea>
                <div id="leave_reason_lbl_pop_leave">
                  <span>Approver Remarks</span>
                </div>
              </div>
              {leaveStatus === "Applied" ? (
                <button
                  id="btn_grp_first_leave"
                  onClick={() => {
                    handleEditLeaves();
                  }}
                >
                  <svg class="btn_edit_leave">
                    <rect
                      id="btn_edit_leave"
                      rx="9"
                      ry="9"
                      x="0"
                      y="0"
                      width="133"
                      height="50"
                    ></rect>
                  </svg>
                  <div id="Edit_Leave_pop_lbl_leave">
                    <span>Edit Leave</span>
                  </div>
                </button>
              ) : leaveStatus === "Cancelled" || leaveStatus === "Rejected" ? (
                ""
              ) : (
                <button
                  id="btn_grp_first_leave"
                  onClick={() => {
                    handleCancelLeaves();
                  }}
                >
                  <svg class="btn_edit_leave">
                    <rect
                      id="btn_edit_leave"
                      rx="9"
                      ry="9"
                      x="0"
                      y="0"
                      width="133"
                      height="50"
                    ></rect>
                  </svg>
                  <div id="Edit_Leave_pop_lbl_leave">
                    <span>Cancel Leave</span>
                  </div>
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default LeavesGridMob;
