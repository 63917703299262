import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import { Button } from "@mui/material";

function StoriesGrid(props) {
  //useState
  const [open, setOpen] = useState(false);
  const [opened, setOpened] = useState(false);
  const [approver, setApprover] = useState("");
  const [fromdate, setFromDate] = useState(new Date());
  const [todate, setToDate] = useState(new Date());
  const [typeleave, setTypeLeave] = useState("");
  const [leavereason, setLeaveReason] = useState("");
  const [leaveDays, setLeaveDays] = useState(0);
  const [Checked, setChecked] = useState(false);
  const [disableChecks, setDisableChecks] = useState();
  const [approveremarks, setApproverRemarks] = useState("");
  const [startFirstHalf, setStartFirstHalf] = useState(true);
  const [startSecondHalf, setStartSecondHalf] = useState(true);
  const [endFirstHalf, setEndFirstHalf] = useState(true);
  const [endSecondHalf, setEndSecondHalf] = useState(true);

  //useEffect
  useEffect(() => {
    const date1 = new Date(fromdate);
    const date2 = new Date(todate);
    if (date1.getTime() === date2.getTime()) {
      setLeaveDays(0);
      setDisableChecks(false);
    } else {
      setDisableChecks(true);
      const datediff = Math.abs(date2 - date1);

      const diffdays = Math.ceil(datediff / (1000 * 60 * 60 * 24)) + 1;

      let sundays = 0;
      for (let i = 0; i < diffdays; i++) {
        let currentday = addDays(fromdate, i);
        if (currentday.getDay() === 0) {
          sundays++;
        }
      }
      setLeaveDays(diffdays - sundays - 2);
    }
  }, [fromdate, todate]);

  //functions
  const handleClosed = () => {
    setOpened(false);
  };
  function addDays(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  const handlereset = () => {
    setFromDate(new Date());
    setToDate(new Date());
    setTypeLeave("");
    setLeaveDays(0);
    setLeaveReason("");
  };
  const redirect = () => {
    var a = approver._id;

    const apiurl = process.env.REACT_APP_API_URL + "/api/user/leaves";
    const apiparam = {
      method: "POST",
      headers: {
        empid: JSON.parse(localStorage.getItem("loggedInUser"))._id,
        fromdate: fromdate,
        todate: todate,
        typeleave: typeleave,
        leavedays: leaveDays,
        leavereason: leavereason,
        approver: a,
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };
    fetch(apiurl, apiparam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          alert("submitted successfully");
        } else alert(repos.message || repos);
      });
    handleClose();
  };
  const handleApprovers = (e) => {
    setApproverRemarks(e.target.value);
  };

  function onFirstCheckClick(e) {
    setChecked(!Checked);
    const totaldays = e.target.checked ? leaveDays + 0.5 : leaveDays - 0.5;
    setLeaveDays(totaldays);
  }
  const handleClickOpen = () => {
    let empid = JSON.parse(
      atob(localStorage.getItem("loggedintoken").split(".")[1])
    ).reportingto;

    let apiUrl =
      process.env.REACT_APP_API_URL + "/api/user/register?empid=" + empid;
    const apiParam = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };

    fetch(apiUrl, apiParam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          console.log(repos.data);
          setApprover(repos.data);
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
    setOpen(true);
  };
  const handleClickOpened = () => {
    setOpened(true);
  };
  function handleCellClick(leavedetail) {
    setFromDate(leavedetail.Fromdate.slice(0, 10));
    setStartFirstHalf(leavedetail.FromFirstHalfLeave);
    setStartSecondHalf(leavedetail.FromSecondHalfLeave);
    setToDate(leavedetail.Todate.slice(0, 10));
    setEndFirstHalf(leavedetail.ToFirstHalfLeave);
    setEndSecondHalf(leavedetail.ToSecondHalfLeave);
    setTypeLeave(leavedetail.TypeofLeave);
    setLeaveDays(leavedetail.LeaveDays);
    setLeaveReason(leavedetail.LeaveReason);
    setApproverRemarks(leavedetail.ApproverRemarks);
    handleClickOpen();
  }
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <div>
        <Dialog
          maxWidth={"md"}
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div>
            <DialogTitle
              id="alert-dialog-title"
              sx={{
                background: process.env.REACT_APP_PRIMARY_COLOR,
                height: "60px",
                textAlign: "center",
                color: "white",
              }}
            >
              {"Edit Leaves"}
              <Button sx={{ float: "right" }} onClick={handleClose}>
                X
              </Button>
            </DialogTitle>
          </div>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <form class="row g-3">
                <div class="col-md-6">
                  <label class="form-label">From</label>
                  <input
                    type="date"
                    class="form-control"
                    value={fromdate}
                    min={new Date(fromdate).toISOString().split("T")[0]}
                    max={todate}
                    disabled
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <div>
                      <span>
                        <input
                          type="checkbox"
                          onChange={onFirstCheckClick}
                          checked={startFirstHalf}
                        />
                        <label for="radio3">First Half</label>
                      </span>
                    </div>
                    <div>
                      <span>
                        <input
                          type="checkbox"
                          onChange={onFirstCheckClick}
                          checked={startSecondHalf}
                        />
                        <label>Second Half</label>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <label class="form-label">To</label>
                  <input
                    type="date"
                    class="form-control"
                    min={fromdate}
                    value={todate}
                    disabled
                  />
                  {disableChecks ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <div>
                        <span>
                          <input
                            type="checkbox"
                            onChange={onFirstCheckClick}
                            checked={endFirstHalf}
                          />
                          <label>First Half</label>
                        </span>
                      </div>
                      <div>
                        <span>
                          <input
                            type="checkbox"
                            onChange={onFirstCheckClick}
                            checked={endSecondHalf}
                          />
                          <label for="radio3">Second Half</label>
                        </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div class="col-md-6">
                  <label>Number of Leave Days</label>
                  <input
                    type="text"
                    class="form-control  "
                    placeholder="Number of leave days"
                    value={leaveDays}
                    disabled
                  ></input>
                </div>

                <div class="col-md-6">
                  <label>Type of Leave</label>
                  <select class="form-select" value={typeleave} disabled>
                    <option value="" selected disabled>
                      Type of leave
                    </option>
                    <option value="sick">Sick</option>
                    <option value="Personal">Personal</option>
                    <option value="Emergency">Emergency</option>
                  </select>
                </div>
                <div class="col-md-6">
                  <label>Leave Reason:</label>
                  <textarea
                    type="text"
                    class="form-control col"
                    value={leavereason}
                    required
                    disabled
                  />
                </div>
                {approveremarks ? (
                  <div class="col-md-6">
                    <label>Approver Remarks:</label>

                    <textarea
                      type="text"
                      class="form-control col"
                      style={{ width: "100%" }}
                      value={approveremarks}
                      disabled
                    />
                  </div>
                ) : (
                  " "
                )}
                <div class="col-md-6">
                  <label>Approver</label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    value={approver.name}
                  />
                </div>
                {approveremarks ? (
                  ""
                ) : (
                  <div style={{ textAlign: "center" }}>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => {
                        redirect();
                      }}
                      style={{ margin: "5px" }}
                    >
                      submit
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => {
                        handlereset();
                      }}
                      style={{ margin: "5px" }}
                    >
                      Reset
                    </Button>
                  </div>
                )}
              </form>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>

      <div>
        <TableContainer component={Paper}>
          <Table
            sx={{
              minWidth: 650,
            }}
          >
            <TableHead
              sx={{ backgroundColor: process.env.REACT_APP_PRIMARY_COLOR }}
            >
              <TableRow
                sx={{
                  "& .MuiTableCell-root": {
                    textAlign: "center",
                    fontFamily: "sans-serif",
                    fontSize: "20px",
                    fontWeight: 700,
                    color: "white",
                  },
                }}
              >
                <TableCell>Initiative</TableCell>
                <TableCell>Epic</TableCell>
                <TableCell>Story</TableCell>
                <TableCell>Sprint</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                "& .MuiTableRow-root:hover": {
                  backgroundColor: process.env.REACT_APP_SECONDARY_COLOR,
                },
              }}
            >
              {props.data.map((leavedetail) => (
                <TableRow
                  key={leavedetail._id}
                  onClick={() => handleCellClick(leavedetail)}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "& .MuiTableCell-root": {
                      textAlign: "center",
                      fontFamily: "Helvetica",
                      fontSize: "18px",
                    },
                  }}
                >
                  <TableCell>{leavedetail.Fromdate.slice(0, 10)}</TableCell>
                  <TableCell>{leavedetail.Todate.slice(0, 10)}</TableCell>
                  <TableCell>{leavedetail.LeaveReason}</TableCell>
                  <TableCell>{leavedetail.LeaveDays}</TableCell>
                  <TableCell>{leavedetail.Status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default StoriesGrid;
