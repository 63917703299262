import React, { useState, useContext } from "react";
import "../../Styles/homeMob.css";
import { UserContext } from "../../UserContext";
import { useEffect } from "react";
import FilterMob from "./FilterMob";
import { Modal } from "@mui/material";
import AttendancePanelMob from "./AttendancePanelMob";
import AttendancePanel from "../Panels/AttendancePanel";
import AttendanceGridMob from "./AttendanceGridMob";
import AttendanceGrid from "../Grids/AttendanceGrid";
import MyTasksPanel from "../Panels/MyTasksPanel";
import TasksPanelMob from "./TasksPanelMob";
import TopNav from "./TopNav";
import LeavesPanelMob from "./LeavesPanelMob";
import LeavesPanel from "../Panels/LeavesPanel";
import { useHistory } from "react-router-dom";
import LeavesGrid from "../Grids/LeavesGrid";
import LeavesGridMob from "./LeavesGridMob";
import MyTasksGrid from "../Grids/MyTasksGrid";
import MyTasksGridMob from "./MyTasksGridMob";
import MyTasksPanelMob from "./MyTasksPanelMob";
import TasksFilterMob from "./TasksFilterMob";
import HolidaysPanel from "../Panels/HolidaysPanel";
import HolidaysPanelMob from "./HolidaysPanelMob";
import HolidayGridMob from "./HolidayGridMob";
import HolidaysGrid from "../Grids/HolidaysGrid";
import LeavesApprovalGrid from "../Grids/LeavesApprovalGrid";
import LeavesApprovalGridMob from "./LeavesApprovalGridMob";
import {geoFindMe} from "../../Utils";

function HomeMob() {
  const [img, setimg] = useState();
  const { user } = useContext(UserContext);
  const [selectedEmployee, setSelectedEmployee] = useState("all");
  const [myAccessType, setMyAccessType] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedFromDate, setSelectedFromDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [selectedToDate, setSelectedToDate] = useState(
    new Date(Date.now() + 60 * 60 * 1000).toISOString().substring(0, 10)
  );
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedTaskStatus, setSelectedTaskStatus] = useState("open");
  const [attendanceData, setAttendanceData] = useState([]);
  const [leavesData, setLeavesData] = useState([]);
  const [storiesData, setStoriesData] = useState([]);
  const [myTasksData, setMyTasksData] = useState([]);
  const [storyTasksData, setStoryTasksData] = useState([]);
  const [documentsData, setDocumentsData] = useState([]);
  const [holidaysData, setHolidaysData] = useState([]);
  const [leavesApprovalData, setLeavesApprovalData] = useState([]);
  const [salaryComponents, setSalaryComponents] = useState();
  const [openPanel, setOpenPanel] = useState(false);
  var history = useHistory();

  function currentTime() {
    var display = new Date().toLocaleTimeString();
    if (document.getElementById("time_lbl_mob_b"))
      document.getElementById("time_lbl_mob_b").innerHTML = display;
    setTimeout(currentTime, 1000);
  }

  async function LoadImage() {
    let getname = user.name;
    setName(getname);
    let empid = user._id;
    var apiUrl =
      process.env.REACT_APP_API_URL + "/api/user/profilepic/" + empid;
    const apiParam = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };
    fetch(apiUrl, apiParam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          arrayBufferToBase64(repos.data.image.data.data);
        } else console.log(repos.message || repos);
      })
      .catch((err) => alert("Image: " + err));
  }

  function arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    var base64Flag = "data:image/jpeg;base64,";
    setimg(base64Flag + window.btoa(binary));
  }
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  function setEmployee(id) {
    setSelectedEmployee(id);
  }
  function setFromDate(date) {
    setSelectedFromDate(new Date(date).toISOString().substring(0, 10));
  }
  function setToDate(date) {
    setSelectedToDate(new Date(date).toISOString().substring(0, 10));
  }

  function setTab(tabIndex) {
    switch (tabIndex) {
      case 1:
        setSelectedFromDate(new Date().toISOString().substring(0, 10));
        setSelectedToDate(
          new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)
            .toISOString()
            .substring(0, 10)
        );
        break;
      case 3:
        history.push("/chats");
        break;
      case 4:
        setSelectedFromDate(new Date().toISOString().substring(0, 10));
        setSelectedToDate(
          new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)
            .toISOString()
            .substring(0, 10)
        );
        break;
      case 7:
        setSelectedFromDate(new Date().toISOString().substring(0, 10));
        setSelectedToDate(
          new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)
            .toISOString()
            .substring(0, 10)
        );
        break;
      case 8:
        setSelectedFromDate(new Date().toISOString().substring(0, 10));
        setSelectedToDate(
          new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)
            .toISOString()
            .substring(0, 10)
        );
        break;
      default:
        setSelectedFromDate(new Date().toISOString().substring(0, 10));
        setSelectedToDate(new Date().toISOString().substring(0, 10));
        break;
    }
    setSelectedTab(tabIndex);
  }

  function filterData() {
    let apiparam = {
      method: "POST",
      headers:
        selectedTab === 2
          ? {
              authtoken: localStorage.getItem("loggedintoken"),
              empid:
                (localStorage.getItem("filterEmployee") === "all"
                  ? user._id
                  : localStorage.getItem("filterEmployee")) ||
                (selectedEmployee === "all" ? user._id : selectedEmployee),
              taskstatus: selectedTaskStatus,
              access: myAccessType,
              team: selectedEmployee === "all",
            }
          : selectedTab == 7 || (myAccessType == 0 && selectedTab == 4)
          ? {
              authtoken: localStorage.getItem("loggedintoken"),
              empid:
                (localStorage.getItem("filterEmployee") === "all"
                  ? "all"
                  : localStorage.getItem("filterEmployee")) ||
                (selectedEmployee === "all" ? "all" : selectedEmployee),
              reportingtoid: user._id,
              access: user.accesstype,
            }
          : {
              authtoken: localStorage.getItem("loggedintoken"),
              empid:
                (localStorage.getItem("filterEmployee") === "all"
                  ? user._id
                  : localStorage.getItem("filterEmployee")) ||
                (selectedEmployee === "all" ? user._id : selectedEmployee),
              startdate: selectedFromDate,
              enddate: selectedToDate,
              access: myAccessType,
              team: selectedEmployee === "all",
            },
    };
    if (!apiparam.headers.empid) {
      return;
    }
    let apiUrl = process.env.REACT_APP_API_URL + "/api/";
    switch (selectedTab) {
      case 0:
        apiUrl += "attendance/filter";
        break;
      case 1:
        apiUrl += "leaves/filter";
        break;
      case 2:
        apiUrl += "mytasks/filter";
        break;
      case 4:
        myAccessType == 0
          ? (apiUrl += "holiday/getselectedholidays")
          : (apiUrl += "leavesapproval/filter");
        break;
      case 5:
        apiUrl += "stories/filter";
        break;
      case 6:
        apiUrl += "storytasks/filter";
        break;
      // case 7:
      //   apiUrl += "documents/filter";
      //   break;
      case 7:
        apiUrl += "holiday/getselectedholidays";
        break;
      case 9:
        apiUrl += "messages/filter";
        break;
      case 8:
        apiUrl += "payroll/getsalarycomponents";
        break;
      default:
        break;
    }
    fetch(apiUrl, apiparam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          switch (selectedTab) {
            case 0:
              setAttendanceData(repos.data);
              break;
            case 1:
              setLeavesData(repos.data);
              break;
            case 2:
              setMyTasksData(repos.data);
              break;
            case 4:
              myAccessType == 0
                ? setHolidaysData(repos.data)
                : setLeavesApprovalData(repos.data);
              break;
            case 5:
              setStoriesData(repos.data);
              break;
            case 6:
              setStoryTasksData(repos.data);
              break;
            case 7:
              setHolidaysData(repos.data);
              break;
            case 9:
              setDocumentsData(repos.data);
              break;
            case 8:
              setSalaryComponents(repos.data);
              break;
            default:
          }
        } else alert("Grid: " + (repos.message || repos));
      })
      .catch((err) => console.log(err));
  }

  function showPanels() {
    switch (selectedTab) {
      case 0:
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          window.navigator.userAgent
        ) ? (
          <AttendancePanelMob refreshGrid={() => filterData()} />
        ) : (
          <AttendancePanel refreshGrid={() => filterData()} />
        );
      case 1:
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          window.navigator.userAgent
        ) ? (
          <LeavesPanelMob refreshGrid={() => filterData()} />
        ) : (
          <LeavesPanel refreshGrid={() => filterData()} />
        );
      case 2:
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          window.navigator.userAgent
        ) ? (
          <MyTasksPanelMob refreshGrid={() => filterData()} />
        ) : (
          <MyTasksPanel refreshGrid={() => filterData()} />
        );
      case 4:
        return myAccessType == 0 ? (
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            window.navigator.userAgent
          ) ? (
            <HolidaysPanelMob refreshGrid={() => filterData()} />
          ) : (
            <HolidaysPanel refreshGrid={() => filterData()} />
          )
        ) : /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            window.navigator.userAgent
          ) ? (
          <LeavesPanelMob refreshGrid={() => filterData()} />
        ) : (
          <LeavesPanel refreshGrid={() => filterData()} />
        );
      case 7:
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          window.navigator.userAgent
        ) ? (
          <HolidaysPanelMob refreshGrid={() => filterData()} />
        ) : (
          <HolidaysPanel refreshGrid={() => filterData()} />
        );
      default:
        return " ";
    }
  }
  function showGrid() {
    switch (selectedTab) {
      case 0:
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          window.navigator.userAgent
        ) ? (
          <AttendanceGridMob
            data={attendanceData}
            selectedEmployee={selectedEmployee}
            selectedFromDate={selectedFromDate}
            selectedToDate={selectedToDate}
          />
        ) : (
          <AttendanceGrid
            data={attendanceData}
            selectedEmployee={selectedEmployee}
            selectedFromDate={selectedFromDate}
            selectedToDate={selectedToDate}
          />
        );
      case 1:
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          window.navigator.userAgent
        ) ? (
          <LeavesGridMob refreshGrid={() => filterData()} data={leavesData} />
        ) : (
          <LeavesGrid refreshGrid={() => filterData()} data={leavesData} />
        );
      case 2:
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          window.navigator.userAgent
        ) ? (
          <MyTasksGridMob refreshGrid={() => filterData()} data={myTasksData} />
        ) : (
          <MyTasksGrid refreshGrid={() => filterData()} data={myTasksData} />
        );
      case 4:
        return myAccessType == 0 ? (
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            window.navigator.userAgent
          ) ? (
            <HolidayGridMob
              data={holidaysData}
              username={user.name}
              refreshGrid={() => filterData()}
              selectedEmployee={selectedEmployee}
            />
          ) : (
            <HolidaysGrid
              data={holidaysData}
              username={user.name}
              refreshGrid={() => filterData()}
              selectedEmployee={selectedEmployee}
            />
          )
        ) : /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            window.navigator.userAgent
          ) ? (
          <LeavesApprovalGridMob
            refreshGrid={() => filterData()}
            data={leavesApprovalData}
          />
        ) : (
          <LeavesApprovalGrid
            refreshGrid={() => filterData()}
            data={leavesApprovalData}
          />
        );
      case 7:
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          window.navigator.userAgent
        ) ? (
          <HolidayGridMob
            data={holidaysData}
            username={user.name}
            refreshGrid={() => filterData()}
            selectedEmployee={selectedEmployee}
          />
        ) : (
          <HolidaysGrid
            data={holidaysData}
            username={user.name}
            refreshGrid={() => filterData()}
            selectedEmployee={selectedEmployee}
          />
        );

      default:
        return " ";
    }
  }
  useEffect(() => {
    filterData();
  }, [selectedEmployee, selectedTaskStatus, selectedTab]);

  useEffect(() => {
    if (user) {
      setSelectedEmployee(user._id);
      setMyAccessType(user.accesstype);
      LoadImage();
    }
  }, [user]);

  ///attendance
  const [showStop, setShowStop] = useState(false);
  const [startTime, setStartTime] = useState();
  const [endTime, setEndtime] = useState();
  const [comments, setComments] = useState("");
  const [startlocation, setStartLocation] = useState({});
  const [endLocation, setEndLocation] = useState({});
  const [myUserid, setMyUserId] = useState();
  const [name, setName] = useState();
  
  const startClick =  () => {
    setShowStop(false);
    setOpenPanel(!openPanel);
    geoFindMe().then(e => {
      setStartLocation({ startlatitude: e.latitude, startlongitude: e.longitude });
      RecordLogin()
    }).catch(err=>alert(err.message))
  };
  const endclick =  () => {
    setShowStop(true);
    setOpenPanel(!openPanel);
    geoFindMe().then(e => {
      setEndLocation({ stoplatitude: e.latitude, stoplongitude: e.longitude });
      RecordLogout()
    }).catch(err=>alert(err.message))
  };
  useEffect(() => {
    getLoginStatus();
  }, []);

  function getLoginStatus() {
    let myTokenData = localStorage.getItem("loggedintoken").split(".")[1];
    let userId = JSON.parse(atob(myTokenData))._id;
    const apiUrl =
      process.env.REACT_APP_API_URL + "/api/attendance/loginstatus/" + userId;
    let apiParams = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };
    fetch(apiUrl, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data[0] && repos.data[0].Starttime) {
          setStartTime(repos.data[0].Starttime);
          setMyUserId(repos.data[0]._id);
          if (
            repos.data[0].Starttime !== null &&
            repos.data[0].Starttime.length > 8
          ) {
            setEndtime(repos.data[0].Stoptime);
          }
          if (
            repos.data[0].Stoptime === null ||
            repos.data[0].Stoptime.length < 8
          ) {
            setShowStop(true);
          }
        }
      })
      .catch((err) => console.log(err));
  }

  const RecordLogin =() => {
    if (startlocation.startlatitude === undefined) {
      return;
    }
    let startTimeLocal = parseInt(
      JSON.parse(atob(localStorage.getItem("loggedintoken").split(".")[1]))
        .shiftStart
    );
    let getname = JSON.parse(
      atob(localStorage.getItem("loggedintoken").split(".")[1])
    ).name;

    let t = new Date().toLocaleTimeString();
    let h = new Date();
    let hrs = h.getHours();
    let mins = hrs * 60 + h.getMinutes();
    if (mins < startTimeLocal) {
      setStartTime(t);
      const apiUrl =
        process.env.REACT_APP_API_URL + "/api/attendance/starttimer";
      let apiParam = {
        method: "POST",
        headers: {
          empid: JSON.parse(localStorage.getItem("loggedInUser"))._id,
          fullname: getname,
          starttime: t,
          latitude: startlocation.startlatitude,
          longitude: startlocation.startlongitude,
          stoplatitude: "",
          stoplongitude: "",
          latereason: encodeURIComponent(comments),
          reasons: "",
          endtime: "",
          authtoken: localStorage.getItem("loggedintoken"),
        },
      };

      fetch(apiUrl, apiParam)
        .then((res) => (res.status === 200 ? res.json() : res.text()))
        .then((repos) => {
          if (repos.data) {
            // setMyUserId(repos.data._id);
            // setShowStart(true);
            filterData();
            alert("Your session start has been recorded");
            getLoginStatus();
            setComments("");
          } else alert(repos.message || repos);
        })
        .catch((err) => alert(err));
    } else {
      if (comments === null || comments.length === 0) {
        alert("Please enter comments!");
        return;
      } else {
        setStartTime(t);

        const apiUrlLog =
          process.env.REACT_APP_API_URL + "/api/attendance/starttimer";
        var apiParamLog = {
          method: "POST",
          headers: {
            empid: JSON.parse(localStorage.getItem("loggedInUser"))._id,
            fullname: getname,
            starttime: t,
            endtime: "",
            latitude: startlocation.startlatitude,
            longitude: startlocation.startlongitude,
            stoplatitude: "",
            stoplongitude: "",
            latereason: encodeURIComponent(comments),
            reasons: "",
            authtoken: localStorage.getItem("loggedintoken"),
          },
        };

        fetch(apiUrlLog, apiParamLog)
          .then((res) => (res.status === 200 ? res.json() : res.text()))
          .then((repos) => {
            if (repos.data) {
              setComments("");
              filterData();
              alert("Session started successfully!");
              getLoginStatus();
            } else alert(repos.message || repos);
          })
          .catch((err) => alert(err));
        // getAttendanceStatus();
      }
    }
  };

  const RecordLogout=() => {
    if (endLocation.stoplatitude === undefined) {
      return;
    }
    var stopTime = parseInt(
      JSON.parse(atob(localStorage.getItem("loggedintoken").split(".")[1]))
        .shiftEnd
    );

    var t = new Date().toLocaleTimeString();
    var h = new Date();
    var hrs = h.getHours();
    var mins = hrs * 60 + h.getMinutes();

    if (mins > stopTime) {
      setEndtime(t);
      const apiUrl =
        process.env.REACT_APP_API_URL + "/api/attendance/stoptimer";
      var apiParam = {
        method: "POST",
        headers: {
          id: myUserid,
          latitude: endLocation.stoplatitude,
          longitude: endLocation.stoplongitude,
          endtime: t,
          reasons: encodeURIComponent(comments),
          authtoken: localStorage.getItem("loggedintoken"),
        },
      };

      fetch(apiUrl, apiParam)
        .then((res) => (res.status === 200 ? res.json() : res.text()))
        .then((repos) => {
          if (repos.data) {
            // setMyUserId(repos.data._id);

            filterData();
            alert("Session stopped successfully!");
            setShowStop(false);
            // setShowStart(true);
            getLoginStatus();
            setComments("");
          } else alert(JSON.stringify(repos));
        })
        .catch((err) => alert(err));
    } else {
      if (comments === null || comments.length === 0) {
        alert("Please enter comments!");
        return;
      } else {
        setEndtime(t);
        const apiUrlz =
          process.env.REACT_APP_API_URL + "/api/attendance/stoptimer";
        var apiParamz = {
          method: "POST",
          headers: {
            id: myUserid,
            latitude: endLocation.stoplatitude,
            longitude: endLocation.stoplongitude,
            endtime: t,
            reasons: encodeURIComponent(comments),
            authtoken: localStorage.getItem("loggedintoken"),
          },
        };

        fetch(apiUrlz, apiParamz)
          .then((res) => (res.status === 200 ? res.json() : res.text()))
          .then((repos) => {
            if (repos.data) {
              // setMyUserId(repos.data._id);
              setShowStop(false);
              // setShowStart(true);
              setComments("");

              filterData();
              alert("Session stopped successfully!");
              getLoginStatus();
            } else alert(repos.message || repos);
          })
          .catch((err) => alert(err));
        // getAttendanceStatus();
      }
    }
  };
  function showNames() {
    switch (selectedTab) {
      case 0:
        return openPanel || showStop ? "Stop" : "Start";
      case 1:
        return openPanel ? "Cancel" : "Add Leave";
      case 2:
        return openPanel ? "Cancel" : "Add Task";
      case 4:
        return openPanel ? "Cancel" : "Approve Leave";
      case 7:
        return openPanel ? "Cancel" : "Add Holiday";
      default:
        return openPanel ? "Cancel" : "Open";
    }
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <div id="attendance_mob__1">
        <img
          id="full_bg_mob_1"
          src="full_bg_mob_1.png"
          srcset="full_bg_mob_1.png 1x"
          alt=""
        />

        {openPanel ? (
          <div id="card_grp">
            <svg class="below_card_mob_1_w">
              <linearGradient
                id="below_card_mob_1_w"
                spreadMethod="pad"
                x1="0.5"
                x2="0.5"
                y1="0"
                y2="1"
              >
                <stop offset="0" stop-color="#b5ffb7" stop-opacity="1"></stop>
                <stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
              </linearGradient>
              <rect
                id="below_card_mob_1_w"
                rx="16"
                ry="16"
                x="0"
                y="0"
                width="302"
                height="214"
              ></rect>
            </svg>
            {showGrid()}
          </div>
        ) : (
          <div id="card_grid_grp_new" style={{ overflow: "scroll" }}>
            <svg class="below_card_mob_1_w_new">
              <linearGradient
                id="below_card_mob_1_w_new"
                spreadMethod="pad"
                x1="0.5"
                x2="0.5"
                y1="0"
                y2="1"
              >
                <stop offset="0" stop-color="#b5ffb7" stop-opacity="1"></stop>
                <stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
              </linearGradient>
              <rect
                id="below_card_mob_1_w_new"
                rx="16"
                ry="16"
                x="0"
                y="0"
                width="299"
                height="541"
              ></rect>
            </svg>
            {showGrid()}
          </div>
        )}

        <div id="top_info_bar_mob_grp">
          <svg class="bg_mob_bz">
            <linearGradient
              id="bg_mob_bz"
              spreadMethod="pad"
              x1="0.5"
              x2="0.5"
              y1="0"
              y2="1"
            >
              <stop offset="0" stop-color="#b5ffb7" stop-opacity="1"></stop>
              <stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
            </linearGradient>
            <rect
              id="bg_mob_bz"
              rx="16"
              ry="16"
              x="0"
              y="0"
              width="300"
              height="155"
            ></rect>
          </svg>
          <div id="Affan_lbl_mob">
            <span>{name}</span>
          </div>
          <img
            id="profile_mob"
            src={img}
            alt=""
            onClick={(e) => history.push("/profile")}
          />

          <div id="time_lbl_mob_b">
            <span>{currentTime()}</span>
          </div>
          <div id="Start_lbl_mob">
            <span>{showNames()}</span>
          </div>
          <div id="startstop_grp">
            <svg class="bdr_ps_btn_mob">
              <ellipse
                id="bdr_ps_btn_mob"
                rx="17.5"
                ry="17.5"
                cx="17.5"
                cy="17.5"
              ></ellipse>
            </svg>
            {localStorage.getItem("tab") != 0 ? (
              !openPanel ? (
                <button onClick={() => setOpenPanel(!openPanel)}>
                  <img
                    id="play-buttton_img_mob"
                    src="play-buttton_img_task.png"
                    srcset="play-buttton_img_task.png"
                    alt=""
                  />
                </button>
              ) : (
                <button onClick={() => setOpenPanel(!openPanel)}>
                  <img
                    id="play-buttton_img_mob"
                    src="playbtn_img_task.png"
                    srcset="playbtn_img_task.png"
                    alt=""
                  />
                </button>
              )
            ) : !showStop ? (
              JSON.parse(localStorage.getItem("loggedInUser")).shiftStart <
              new Date().getHours() * 60 + new Date().getMinutes() ? (
                <button onClick={() => setOpenPanel(!openPanel)}>
                  {!openPanel ? (
                    <img
                      id="play-buttton_img_mob"
                      src="play-buttton_img_mob.png"
                      srcset="play-buttton_img_mob.png"
                      alt=""
                    />
                  ) : (
                    <img
                      id="play-buttton_img_mob"
                      src="playbtn_img_task.png"
                      srcset="playbtn_img_task.png"
                      alt=""
                    />
                  )}
                </button>
              ) : (
                <button onClick={() => startClick()}>
                  <img
                    id="play-buttton_img_mob"
                    src="play-buttton_img_mob.png"
                    srcset="play-buttton_img_mob.png"
                    alt=""
                  />
                </button>
              )
            ) : JSON.parse(localStorage.getItem("loggedInUser")).shiftEnd >
              new Date().getHours() * 60 + new Date().getMinutes() ? (
              <button onClick={() => setOpenPanel(!openPanel)}>
                {!openPanel ? (
                  <img
                    id="play-buttton_img_mob"
                    src="stop_time.png"
                    srcset="stop_time.png"
                    alt=""
                  />
                ) : (
                  <img
                    id="play-buttton_img_mob"
                    src="playbtn_img_task.png"
                    srcset="playbtn_img_task.png"
                    alt=""
                  />
                )}
              </button>
            ) : (
              <button onClick={() => endclick()}>
                <img
                  id="play-buttton_img_mob"
                  src="stop_time.png"
                  srcset="stop_time.png"
                  alt=""
                />
              </button>
            )}
          </div>
          <div id="filter_btn_grp_mob">
            <svg class="bdr_filter_mob">
              <rect
                id="bdr_filter_mob"
                rx="17.5"
                ry="17.5"
                x="0"
                y="0"
                width="35"
                height="35"
              ></rect>
            </svg>
            <button onClick={handleOpen}>
              <img
                id="filter_img_mob"
                src="filter_img_mob.png"
                srcset="filter_img_mob.png 1x"
                alt=""
              />
            </button>
          </div>
          <div id="Filter_lbl_mob">
            <span>Filter</span>
          </div>
        </div>
        <div id="top_bar_grp">
          <TopNav
            tabIndex={selectedTab}
            setTab={(tabIndex) => setTab(tabIndex)}
          />
        </div>
        <svg
          class="Line_1_underline_att"
          viewBox="0 0 266 1"
          style={{ display: openPanel ? "block" : "none" }}
        >
          <path id="Line_1_underline_att" d="M 0 0 L 266 0"></path>
        </svg>
        <div id="mid_overall" style={{ display: openPanel ? "block" : "none" }}>
          <div id="btn_bg_grp_mob">
            <div id="mig_bg_mob">
              <svg class="Rectangle_9_ct">
                <linearGradient
                  id="Rectangle_9_ct"
                  spreadMethod="pad"
                  x1="0.5"
                  x2="0.5"
                  y1="0"
                  y2="1"
                >
                  <stop offset="0" stop-color="#b5ffb7" stop-opacity="1"></stop>
                  <stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
                </linearGradient>
                <rect
                  id="Rectangle_9_ct"
                  rx="16"
                  ry="16"
                  x="0"
                  y="0"
                  width="302"
                  height="319"
                ></rect>
              </svg>
            </div>
            {showPanels()}
          </div>
        </div>
      </div>
      <Modal open={open} onClose={handleClose}>
        <div id="filter_grp_mob_overall">
          {selectedTab === 3 ? (
            ""
          ) : selectedTab === 2 ? (
            <TasksFilterMob
              employee={
                localStorage.getItem("filterEmployee") || selectedEmployee
              }
              changeEmployee={(id) => {
                setEmployee(id);
                localStorage.setItem("filterEmployee", id);
              }}
              taskStatus={selectedTaskStatus}
              changeTaskStatus={(status) => setSelectedTaskStatus(status)}
              onFilterClick={() => filterData()}
              closeModal={() => handleClose()}
            />
          ) : (
            <FilterMob
              employee={
                localStorage.getItem("filterEmployee") || selectedEmployee
              }
              fromDate={selectedFromDate}
              toDate={selectedToDate}
              changeEmployee={(id) => {
                setEmployee(id);
                localStorage.setItem("filterEmployee", id);
              }}
              changeFromDate={(date) => setFromDate(date)}
              changeToDate={(date) => setToDate(date)}
              onFilterClick={() => filterData()}
              selectedTab={selectedTab}
              closeModal={() => handleClose()}
            />
          )}
        </div>
      </Modal>
    </div>
  );
}

export default HomeMob;
