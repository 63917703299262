import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  Modal,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { UserContext } from "../../UserContext";
import UserBadgeItem from "./UserBadgeItem";
import { Alert } from "@mui/material";
import UserListItem from "./UserListItem";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const UpdateGroupChatModal = ({ fetchAgain, setFetchAgain, fetchMessages }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { user, selectedChat, setSelectedChat } = useContext(UserContext);
  const [groupChatName, setGroupChatName] = useState();
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [renameLoading, setRenameLoading] = useState(false);
  const [snackbarMessage, setSnackBarMessage] = useState("");
  const [openToast, setOpenToast] = useState(false);

  const handleRename = async () => {
    if (!groupChatName) {
      return;
    }
    const apiURL = process.env.REACT_APP_API_URL + "/api/chat/renamegroup";
    const apiParams = {
      method: "PUT",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
        chatid: selectedChat._id,
        chatname: groupChatName,
      },
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setSelectedChat(repos.data);
          setFetchAgain(!fetchAgain);
          setRenameLoading(false);
        } else {
          setSnackBarMessage("Error Renaming Chat");
          setOpenToast(true);
        }
      })
      .catch((err) => {
        setSnackBarMessage(err);
        setOpenToast(true);
      });
  };
  const handleSearch = async (query) => {
    setSearch(query);
    if (!query) {
      setOpenToast(true);
      setSnackBarMessage("Please Enter Something in the Search");
      return;
    }
    try {
      setLoading(true);
      const apiURL =
        process.env.REACT_APP_API_URL + "/api/user/allusers?search=" + search;
      const apiParams = {
        method: "GET",
        headers: {
          authtoken: localStorage.getItem("loggedintoken"),
          id: user._id,
        },
      };
      fetch(apiURL, apiParams)
        .then((res) => (res.status === 200 ? res.json() : res.text()))
        .then((repos) => {
          if (repos.data) {
            console.log(repos.data);
            setLoading(false);
            setSearchResult(repos.data);
          } else alert(repos.message || repos);
        })
        .catch((err) => alert(err));
    } catch (e) {
      setSnackBarMessage("Failed to fetch search results");
      setOpenToast(true);
    }
  };
  const handleAddUser = async (user1) => {
    if (selectedChat.users.find((u) => u._id === user1._id)) {
      setSnackBarMessage("User Already in Group");
      setOpenToast(true);
      return;
    }
    if (selectedChat.groupAdmin._id !== user._id) {
      setSnackBarMessage("Only Admins can add someone!");
      setOpenToast(true);
      return;
    }
    const apiURL = process.env.REACT_APP_API_URL + "/api/chat/addtogroup";
    const apiParams = {
      method: "PUT",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
        chatid: selectedChat._id,
        newuserid: user1._id,
      },
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setSelectedChat(repos.data);
          setFetchAgain(!fetchAgain);
          fetchMessages();
          setLoading(false);
        } else {
          setSnackBarMessage("Error Occured!");
          setOpenToast(true);
        }
      })
      .catch((err) => {
        setSnackBarMessage(err);
        setOpenToast(true);
      });
  };
  const handleRemove = async (user1) => {
    if (selectedChat.groupAdmin._id !== user._id) {
      setSnackBarMessage("Only Admin can Remove Someone");
      setOpenToast(true);
      return;
    }
    const apiURL = process.env.REACT_APP_API_URL + "/api/chat/removefromgroup";
    const apiParams = {
      method: "PUT",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
        chatid: selectedChat._id,
        removeid: user1._id,
      },
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          user1._id === user._id
            ? setSelectedChat()
            : setSelectedChat(repos.data);
          setFetchAgain(!fetchAgain);
          setLoading(false);
        } else {
          setSnackBarMessage("Error Occured!");
          setOpenToast(true);
        }
      })
      .catch((err) => {
        setSnackBarMessage(err);
        setOpenToast(true);
      });
  };
  return (
    <div>
      <IconButton style={{ display: { base: "flex" } }} onClick={handleOpen}>
        <VisibilityIcon />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {selectedChat.chatName}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Box
              style={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                paddingBottom: "10px",
              }}
            >
              {selectedChat.users.map((u) => (
                <UserBadgeItem
                  key={u._id}
                  user={u}
                  handleFunction={() => handleRemove(u)}
                />
              ))}
            </Box>
            <FormControl style={{ display: "flex" }}>
              <TextField
                label="Chat Name"
                value={groupChatName}
                onChange={(e) => {
                  setGroupChatName(e.target.value);
                }}
                variant="outlined"
                style={{ marginBottom: "10px" }}
              />
              <Button
                style={{ marginLeft: "5px" }}
                isLoading={renameLoading}
                onClick={handleRename}
              >
                Update
              </Button>
            </FormControl>
            <FormControl>
              <TextField
                label="Add User to Group"
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
                variant="outlined"
                style={{ marginBottom: "10px" }}
              />
            </FormControl>
            {loading ? (
              <CircularProgress />
            ) : (
              searchResult?.map((user) => (
                <UserListItem
                  key={user._id}
                  user={user}
                  handleFunction={() => handleAddUser(user)}
                />
              ))
            )}
            <Button
              variant="outlined"
              onClick={() => handleRemove(user)}
              disabled
            >
              Leave Group
            </Button>
          </Typography>
        </Box>
      </Modal>
      <Snackbar
        open={openToast}
        autoHideDuration={3000}
        onClose={() => setOpenToast(false)}
      >
        <Alert severity="error">{snackbarMessage}</Alert>
      </Snackbar>
    </div>
  );
};

export default UpdateGroupChatModal;
