import React, { useState, useEffect, useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField, Button, MenuItem } from "@mui/material";
import { UserContext } from "../../UserContext";
import "../../Styles/tasksPopup.css";

function MyTasksGrid(props) {
  const { user, setUser } = useContext(UserContext);
  //useState
  const [open, setOpen] = useState(false);
  const [taskId, setTaskId] = useState("");
  const [task, setTask] = useState("");
  const [description, setDescription] = useState("");
  const [deadline, setDeadline] = useState(new Date());
  const [assignToId, setAssignToId] = useState("");
  const [assignToName, setAssignToName] = useState("");
  const [important, setImportant] = useState(false);
  const [urgent, setUrgent] = useState(false);
  const [status, setStatus] = useState("Working");
  const [statusFilter, setStatusFilter] = useState("All");
  const [creatorId, setCreatorId] = useState("");
  const [creatorName, setCreatorName] = useState("");
  const [createdDate, setCreatedDate] = useState(new Date());
  const [data, setData] = useState([]);
  const [comments, setComments] = useState("");
  const [newComments, setNewComments] = useState("");

  useEffect(() => {
    let empid = user._id;
    setAssignToId(empid);

    const apiurl =
      process.env.REACT_APP_API_URL +
      "/api/user/employeesdropdown?empid=" +
      user._id;
    const apiparam = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };
    fetch(apiurl, apiparam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setData(repos.data);
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  }, []);

  function handleCellClick(task) {
    setTaskId(task._id);
    setTask(task.task);
    setDescription(task.description);
    setAssignToId(task.assignToId);
    setAssignToName(task.assignToName);
    setImportant(task.important);
    setUrgent(task.urgent);
    setDeadline(task.deadline.slice(0, 10));
    setStatus(task.status);
    setCreatorId(task.creatorId);
    setCreatorName(task.creatorName);
    setCreatedDate(task.createdAt);
    setComments(task.comments);
    setOpen(true);
    setStatus(task.status === "Created" ? "Working" : task.status);
  }
  const handleEdit = () => {
    if (task.length < 1) {
      alert("Please enter task!");
      return;
    }
    if (description.length < 1) {
      alert("Please enter task details!");
      return;
    }
    const apiurl = process.env.REACT_APP_API_URL + "/api/mytasks/edit";
    const apiparam = {
      method: "POST",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
        taskId,
        task,
        description: encodeURIComponent(description),
        assigntoid: assignToId,
        assigntoname: data.filter((emp) => emp._id === assignToId)[0].name,
        important,
        urgent,
        deadline: new Date(deadline).toISOString().split("T")[0],
        creatorid: user._id,
        creatorname: user.name,
        status,
        comments: encodeURIComponent(
          user.name +
            " (" +
            new Date().toISOString().split("T")[0] +
            " " +
            new Date().toLocaleTimeString() +
            "):\n" +
            newComments +
            "\n\n" +
            comments
        ),
      },
    };
    fetch(apiurl, apiparam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          alert(repos.message);
          props.refreshGrid();
          setOpen(false);
          setNewComments("");
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <div>
        {/* <Dialog
          maxWidth={"md"}
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div>
            <DialogTitle
              id="alert-dialog-title"
              sx={{
                background: process.env.REACT_APP_PRIMARY_COLOR,
                height: "60px",
                textAlign: "center",
                color: "white",
              }}
            >
              {"Update Task"}
              <Button sx={{ float: "right" }} onClick={handleClose}>
                X
              </Button>
            </DialogTitle>
          </div>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <form class="row">
                <div class="col-md-4">
                  <TextField
                    id="creator"
                    label="Assigned By"
                    value={creatorName}
                    readOnly
                    style={{ width: "100%" }}
                    sx={{
                      backgroundColor: "white",
                    }}
                  />
                </div>
                <div class="col-md-4">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Assigned On"
                      value={new Date(createdDate).toISOString().split("T")[0]}
                      readOnly
                      renderInput={(params) => <TextField {...params} />}
                      style={{ width: "100%" }}
                    />
                  </LocalizationProvider>
                </div>{" "}
                <div class="col-md-4">
                  <TextField
                    select
                    style={{ width: "100%" }}
                    value={assignToId}
                    label="Assign To"
                    disabled={user._id != creatorId && user.accesstype != 5}
                    onChange={(e) => {
                      setAssignToId(e.target.value);
                    }}
                    readOnly
                  >
                    {data.map((employee) => {
                      return (
                        <MenuItem value={employee._id}>
                          {employee.name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
                <div class="col-md-12">
                  <TextField
                    id="task"
                    label="Task"
                    value={task}
                    onChange={(e) => setTask(e.target.value)}
                    required
                    disabled={user._id != creatorId && user.accesstype != 5}
                    style={{ width: "100%", marginTop: "30px" }}
                    sx={{
                      backgroundColor: "white",
                    }}
                  />
                </div>
                <div class="col-md-12">
                  <TextField
                    id="description"
                    label="Details"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                    disabled={user._id != creatorId && user.accesstype != 5}
                    multiline
                    rows={2}
                    style={{
                      width: "100%",
                      marginBottom: "30px",
                      marginTop: "30px",
                    }}
                    sx={{
                      backgroundColor: "white",
                    }}
                  />
                </div>
                <div class="col-md-2">
                  <div style={{ textAlign: "center", marginTop: "15px" }}>
                    <input
                      type="checkbox"
                      checked={important}
                      disabled={user._id != creatorId && user.accesstype != 5}
                      onChange={(e) => setImportant(e.target.checked)}
                    />
                    &nbsp;&nbsp;
                    <label>Important</label>
                  </div>
                </div>
                <div class="col-md-2">
                  <div style={{ textAlign: "center", marginTop: "15px" }}>
                    <input
                      type="checkbox"
                      checked={urgent}
                      disabled={user._id != creatorId && user.accesstype != 5}
                      onChange={(e) => setUrgent(e.target.checked)}
                    />
                    &nbsp;&nbsp;
                    <label>Urgent</label>
                  </div>
                </div>
                <div class="col-md-4">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Deadline"
                      value={new Date(deadline).toISOString().split("T")[0]}
                      minDate={new Date()}
                      disabled={user._id != creatorId && user.accesstype != 5}
                      onChange={(newValue) => {
                        setDeadline(newValue);
                      }}
                      required
                      renderInput={(params) => <TextField {...params} />}
                      style={{ width: "100%" }}
                    />
                  </LocalizationProvider>
                </div>
                <div class="col-md-4">
                  <TextField
                    select
                    style={{ width: "100%" }}
                    value={status}
                    label="Status"
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                    required
                  >
                    <MenuItem selected value="Working">
                      Working
                    </MenuItem>
                    <MenuItem value="On-Hold">On-Hold</MenuItem>
                    <MenuItem value="Completed">Completed</MenuItem>
                    <MenuItem value="Cancelled">Cancelled</MenuItem>
                    <MenuItem value="Closed">Closed</MenuItem>
                  </TextField>
                </div>
                <div class="col-md-12">
                  <TextField
                    id="comments"
                    label="Comments"
                    value={comments}
                    readOnly
                    multiline
                    rows={4}
                    style={{
                      width: "100%",
                      marginBottom: "30px",
                      marginTop: "30px",
                    }}
                    sx={{
                      backgroundColor: "white",
                    }}
                  />
                </div>
                <div class="col-md-12">
                  <TextField
                    id="comments"
                    label="Add Comments"
                    value={newComments}
                    multiline
                    onChange={(e) => setNewComments(e.target.value)}
                    rows={2}
                    style={{ width: "100%", marginBottom: "30px" }}
                    sx={{
                      backgroundColor: "white",
                    }}
                  />
                </div>
                <div class="col-md-12">
                  <div style={{ textAlign: "center", marginTop: "5px" }}>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={(e) => {
                        handleEdit();
                      }}
                      style={{ margin: "25px" }}
                    >
                      Update Task
                    </Button>
                  </div>
                </div>
              </form>
            </DialogContentText>
          </DialogContent>
        </Dialog> */}
        <div id="task_pop-up" style={{ display: open ? "block" : "none" }}>
          <img
            id="update_task_bg_design"
            src="update_task_bg_design.png"
            srcset="update_task_bg_design.png 1x"
            alt=""
          />

          <button
            id="btn_grp"
            onClick={(e) => {
              handleEdit();
            }}
          >
            <svg class="update_task_leave">
              <rect
                id="update_task_leave"
                rx="9"
                ry="9"
                x="0"
                y="0"
                width="162"
                height="50"
              ></rect>
            </svg>
            <div id="update_task_lbl">
              <span>Update Task </span>
            </div>
          </button>

          <div id="Top_bdr_grp">
            <svg class="update_task_top_p">
              <linearGradient
                id="update_task_top_p"
                spreadMethod="pad"
                x1="0.5"
                x2="0.5"
                y1="0"
                y2="1.252"
              >
                <stop offset="0" stop-color="#b5ffb7" stop-opacity="1"></stop>
                <stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
              </linearGradient>
              <rect
                id="update_task_top_p"
                rx="12"
                ry="12"
                x="0"
                y="0"
                width="618"
                height="50"
              ></rect>
            </svg>
            <div id="Update_Task_top_lbl">
              <span>Update Task</span>
            </div>
            <img
              id="close_task"
              src="close.png"
              srcset="close.png 1x, close@2x.png 2x"
              alt=""
              onClick={handleClose}
            />
          </div>
          <div id="pop_task_scroll_">
            <input
              placeholder="Assigned By"
              class="assigned_by_input"
              value={creatorName}
              readOnly
            />

            <input
              placeholder="Task"
              class="Task_input"
              onChange={(e) => setTask(e.target.value)}
              required
              disabled={user._id != creatorId && user.accesstype != 5}
              value={task}
            />

            <input
              placeholder="Details"
              class="details_input"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
              disabled={user._id != creatorId && user.accesstype != 5}
            />

            <input
              placeholder="Approver Name"
              type="date"
              class="assigned_on"
              value={new Date(createdDate).toISOString().split("T")[0]}
              disabled
            />

            <select
              class="assigned_to_"
              value={assignToId}
              label="Assign To"
              disabled={user._id != creatorId && user.accesstype != 5}
              onChange={(e) => {
                setAssignToId(e.target.value);
              }}
              readOnly
            >
              {data.map((employee) => {
                return <option value={employee._id}>{employee.name}</option>;
              })}
            </select>
            <input
              type="date"
              placeholder="Deadline"
              class="deadline_date"
              value={deadline}
              minDate={new Date()}
              disabled={user._id != creatorId && user.accesstype != 5}
              onChange={(e) => {
                const fromDateTimestamp = Date.parse(e.target.value);

                if (isNaN(fromDateTimestamp)) {
                  // handle invalid date input
                  return;
                }
                setDeadline(e.target.value);
              }}
            />

            <select
              placeholder="Status"
              class="status_select"
              value={status}
              label="Status"
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            >
              <option selected value="Working">
                Working
              </option>
              <option value="On-Hold">On-Hold</option>
              <option value="Completed">Completed</option>
              <option value="Cancelled">Cancelled</option>
              <option value="Closed">Closed</option>
            </select>
            <div id="Important_grp">
              <input
                type="checkbox"
                class="imp_checkbox_4"
                checked={important}
                disabled={user._id != creatorId && user.accesstype != 5}
                onChange={(e) => setImportant(e.target.checked)}
              />

              <div id="Imp_4_lbl">
                <span>Important</span>
              </div>
            </div>
            <div id="urgent_grp_">
              <input
                type="checkbox"
                class="urgent_input"
                checked={urgent}
                disabled={user._id != creatorId && user.accesstype != 5}
                onChange={(e) => setUrgent(e.target.checked)}
              />

              <div id="urgent_3lbl">
                <span>Urgent</span>
              </div>
            </div>
            <textarea
              placeholder="Comment"
              class="add_com_bdr_input"
              value={comments}
              readOnly
            ></textarea>
            <textarea
              placeholder="Add Comment"
              class="com_bdr_input"
              value={newComments}
              multiline
              onChange={(e) => setNewComments(e.target.value)}
            ></textarea>
            <div id="Assigned_by_taskpop">
              <span>Assigned by</span>
            </div>
            <div id="Assigned_on_taskpop">
              <span>Assigned on</span>
            </div>
            <div id="Assigned_To_taskpop">
              <span>Assigned To</span>
            </div>
            <div id="Task_taskpop">
              <span>Task</span>
            </div>
            <div id="Details_taskpop">
              <span>Details</span>
            </div>
            <div id="Deadline_taskpop">
              <span>Deadline</span>
            </div>
            <div id="Status_taskpop">
              <span>Status</span>
            </div>
            <div id="Comments_taskpop">
              <span>Comments</span>
            </div>
            <div id="Add_Comments_taskpop">
              <span>Add Comments</span>
            </div>
          </div>
        </div>
      </div>

      <div>
        <TableContainer component={Paper}>
          <Table
            sx={{
              minWidth: 650,
            }}
          >
            <TableHead
              sx={{ backgroundColor: process.env.REACT_APP_PRIMARY_COLOR }}
            >
              <TableRow
                sx={{
                  "& .MuiTableCell-root": {
                    textAlign: "center",
                    fontFamily: "sans-serif",
                    fontSize: "20px",
                    fontWeight: 700,
                    color: "white",
                  },
                }}
              >
                <TableCell>Employee</TableCell>
                <TableCell>Task</TableCell>
                <TableCell>Priority</TableCell>
                <TableCell>Deadline</TableCell>
                <TableCell>
                  <TextField
                    select
                    style={{ width: "150px" }}
                    InputProps={{
                      style: { color: "white", fontWeight: "bold" },
                    }}
                    value={statusFilter}
                    label="Status"
                    onChange={(e) => {
                      setStatusFilter(e.target.value);
                    }}
                  >
                    <MenuItem selected value="All">
                      All
                    </MenuItem>
                    <MenuItem selected value="Created">
                      Created
                    </MenuItem>
                    <MenuItem selected value="Working">
                      Working
                    </MenuItem>
                    <MenuItem value="On-Hold">On-Hold</MenuItem>
                    <MenuItem value="Completed">Completed</MenuItem>
                    <MenuItem value="Cancelled">Cancelled</MenuItem>
                    <MenuItem value="Closed">Closed</MenuItem>
                  </TextField>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                "& .MuiTableRow-root:hover": {
                  backgroundColor: process.env.REACT_APP_SECONDARY_COLOR,
                },
              }}
            >
              {props.data
                .filter((item) =>
                  statusFilter === "All" ? item : item.status === statusFilter
                )
                .map((task) => (
                  <TableRow
                    key={task._id}
                    onClick={() => handleCellClick(task)}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "& .MuiTableCell-root": {
                        textAlign: "center",
                        fontFamily: "Helvetica",
                        fontSize: "18px",
                      },
                    }}
                  >
                    <TableCell>{task.assignToName}</TableCell>
                    <TableCell>{task.task}</TableCell>
                    <TableCell>
                      {task.important ? "Important " : ""}
                      {task.important && task.urgent ? "+" : ""}
                      {task.urgent ? " Urgent" : ""}
                    </TableCell>
                    <TableCell>
                      {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Deadline"
                          value={
                            new Date(task.deadline).toISOString().split("T")[0]
                          }
                          minDate={
                            task.status === "Completed" ||
                            task.status === "Cancelled" ||
                            task.status === "Closed"
                              ? null
                              : new Date()
                          }
                          readOnly
                          required
                          renderInput={(params) => <TextField {...params} />}
                          style={{ width: "100%" }}
                        />
                      </LocalizationProvider> */}
                      {new Date(task.deadline).toISOString().split("T")[0]}
                    </TableCell>
                    <TableCell>{task.status}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default MyTasksGrid;
