import React, { useState, useMemo, useEffect } from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Login from "./Layouts/Login";
import Register from "./Layouts/Register";
import ForgotPassword from "./Layouts/ForgotPassword";
import ResetPassword from "./Layouts/ResetPassword";
import Home from "./Layouts/Home";
import Chats from "./Layouts/Chats";
import "./App.css";
import { UserContext } from "./UserContext";
import Profile from "./Layouts/Profile";
import Dashboard from "./Layouts/Dashboard";
import ProfileDetails from "./Layouts/ProfileDetails";
import PayrollDetails from "./Layouts/PayrollDetails";
import NewHome from "./Layouts/NewHome";
import HomeMob from "./Components/MobileUI/HomeMob";
import RegisterMob from "./Components/MobileUI/RegisterMob";
import ProfileMob from "./Components/MobileUI/ProfileMob";
import OldPayroll from "./Layouts/OldPayroll";
function App() {
  const [user, setUser] = useState(null);
  const [selectedChat, setSelectedChat] = useState();
  const [chats, setChats] = useState([]);
  const [notification, setNotification] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [inAppNotification, setInAppNotification] = useState([]);
  const value = useMemo(
    () => ({
      user,
      setUser,
      selectedChat,
      setSelectedChat,
      chats,
      setChats,
      notification,
      setNotification,
      onlineUsers,
      setOnlineUsers,
      inAppNotification,
      setInAppNotification,
    }),
    [
      user,
      setUser,
      selectedChat,
      setSelectedChat,
      chats,
      setChats,
      notification,
      setNotification,
      onlineUsers,
      setOnlineUsers,
      inAppNotification,
      setInAppNotification,
    ]
  );

  useEffect(() => {
    let storedUser = localStorage.getItem("loggedInUser");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
    user && getOnlineUsers();
  }, []);

  useEffect(() => {
    user && getOnlineUsers();
  }, [user]);

  useEffect(() => {
    user && getNotification();
  }, [user]);
  async function getOnlineUsers() {
    const apiURL =
      process.env.REACT_APP_API_URL + "/api/user/onlineusers/" + user._id;
    const apiParams = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };

    await fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setOnlineUsers(repos.data);
        } else alert(JSON.stringify(repos));
      })
      .catch((err) => alert(JSON.stringify(err)));

    setTimeout(getOnlineUsers, 30000);
  }

  async function getNotification() {
    const apiURL =
      process.env.REACT_APP_API_URL + "/api/notification/" + user._id;
    const apiParams = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };

    await fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setInAppNotification(repos.data);
        } else alert(JSON.stringify(repos));
      })
      .catch((err) => alert(JSON.stringify(err)));
  }
  return (
    <div>
      <UserContext.Provider value={value}>
        <Router>
          <Switch>
            <Route
              exact
              path="/"
              component={
                user
                  ? /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                      window.navigator.userAgent
                    )
                    ? HomeMob
                    : NewHome
                  : Login
              }
            />
            <Route path="/forgotPassword" component={ForgotPassword} />
            <Route path="/resetpassword" component={ResetPassword} />
            <Route
              path="/profile"
              component={
                user
                  ? /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                      window.navigator.userAgent
                    )
                    ? ProfileMob
                    : Profile
                  : Login
              }
            />
            <Route
              path="/register"
              component={
                user
                  ? /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                      window.navigator.userAgent
                    )
                    ? RegisterMob
                    : Profile
                  : Login
              }
            />
            {/* <Route path="/home" component={user ? Home : Login} /> */}
            {/*  */}
            <Route path="/chats" component={user ? Chats : Login} />
            <Route path="/payroll" component={user ? PayrollDetails : Login} />
            {/* <Route path="/dashboard" component={user ? Dashboard : Login} /> */}
            {/* <Route path="/homeMob" component={user ? HomeMob : Login} /> */}
            <Route path="/oldpayroll" component={user ? OldPayroll : Login} />
            <Redirect to="/" />
          </Switch>
        </Router>
      </UserContext.Provider>
    </div>
  );
}
export default App;
