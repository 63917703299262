import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Menu from "../Components/Menu";
import TextField from "@mui/material/TextField";
import { Select, MenuItem, Input, Tooltip } from "@mui/material";
import { UserContext } from "../UserContext";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import MuiPhoneNumber from "material-ui-phone-number";
import "../Styles/profile.css";
import { pointer } from "caniuse-lite/data/features";
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: process.env.REACT_APP_SECONDARY_COLOR,
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Profile(props) {
  const location = useLocation();
  const { user, setUser } = useContext(UserContext);
  const history = useHistory();
  const [uniqueId, setUniqueId] = useState("");
  const [dateOfJoining, setDateOfJoining] = useState(new Date());
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [userdob, setUserdob] = useState(new Date().toISOString().slice(0, 10));
  const [userGender, setUserGender] = useState("");
  const [userAccesstype, setUserAccessType] = useState("");
  const [userDesignation, setUserDesignation] = useState("");
  const [userConfirmpassword, setUserConfirmPassword] = useState("");
  const [userPAN, setUserPAN] = useState("");
  const [userAadhar, setUserAadhar] = useState("");
  const [userPassport, setUserPassport] = useState("");
  const [userPassportExpiry, setUserPassportExpiry] = useState(new Date());
  const [userEmergencyContact, setUserEmergencyContact] = useState("");
  const [userEmergencyNo, setUserEmergencyNo] = useState("");
  const [userBankAccount, setUserBankAccount] = useState("");
  // const [userPhoto, setUserPhoto] = useState();
  const [data, setData] = useState([]);
  const [profileDetails, setProfileDetails] = useState();
  const [employeeId, setEmployeeId] = useState();
  const [reportingToId, setReportingToId] = useState("");
  const [reportingToName, setReportingToName] = useState();
  const [open, setOpen] = React.useState(false);
  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setnewPassword] = useState();
  const [img, setImg] = useState();
  const [shiftStartHours, setShiftStartHours] = useState(0);
  const [shiftStartMinutes, setShiftStartMinutes] = useState(0);
  const [shiftStopHours, setShiftStopHours] = useState(0);
  const [shiftStopMinutes, setShiftStopMinutes] = useState(0);
  const [profileId, setProfileId] = useState("");
  const [active, setActive] = useState(false);
  const [showActive, setShowActive] = useState(true);
  const [upiId, setUpiId] = useState("");
  const [userPhoto, setUserPhoto] = useState();
  const [userPassword, setUserPassword] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function handleFileSelect(f) {
    var reader = new FileReader();
    // Closure to capture the file information.
    reader.onload = (function (theFile) {
      return function (e) {
        var binaryData = e.target.result;
        //Converting Binary Data to base 64
        var base64String = window.btoa(binaryData);
        //showing file converted to base64
        setImg("data:image/jpeg;base64," + base64String);
      };
    })(f);
    // Read in the image file as a data URL.
    reader.readAsBinaryString(f);
  }

  const handleImageUpload = async (e) => {
    if (e.target.files[0].size > 71680) {
      alert("Please choose a file with size below 70kb!");
      return;
    }
    handleFileSelect(e.target.files[0]);
    let formdata = new FormData();
    formdata.append("image", e.target.files[0], e.target.value);
    location.pathname === "/profile"?updateProfilePic(formdata):setUserPhoto(formdata);
  };
  const updateProfilePic = (formdata) => {
    const apiurl = process.env.REACT_APP_API_URL + "/api/user/updateprofilepic";
    var apiparam = {
      method: "POST",
      headers: {
        id: employeeId,
        authtoken: localStorage.getItem("loggedintoken"),
      },
      body: formdata,
    };

    fetch(apiurl, apiparam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };

  const updateProfile = () => {
    let shiftStart = Number(shiftStartHours) + Number(shiftStartMinutes);
    let shiftEnd = Number(shiftStopHours) + Number(shiftStopMinutes);
    const apiurl = process.env.REACT_APP_API_URL + "/api/user/" + profileId;

    var apiparam = {
      method: "POST",
      headers: {
        uid: uniqueId,
        doj: dateOfJoining,
        name: userName,
        email: userEmail,
        phone: userPhone,
        address: encodeURIComponent(userAddress),
        shiftStart: shiftStart,
        shiftEnd: shiftEnd,
        dob: userdob,
        gender: userGender,
        reportingtoid: reportingToId,
        reportingtoname: reportingToName,
        accesstype: userAccesstype,
        designation: userDesignation,
        pannumber: userPAN,
        aadharnumber: userAadhar,
        passportnumber: userPassport,
        passportexpiry: userPassportExpiry,
        emergencycontactname: userEmergencyContact,
        emergencycontactnumber: userEmergencyNo,
        bankaccountedtails: encodeURIComponent(userBankAccount),
        upiid: upiId,
        active: active,
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };
    fetch(apiurl, apiparam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          alert("Profile Updated");
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };

  const getProfileDetails = (id) => {
    const apiUrl = process.env.REACT_APP_API_URL + "/api/user/" + id;
    fetch(apiUrl, {
      headers: { authtoken: localStorage.getItem("loggedintoken") },
    })
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          console.log(repos.data);
          function getTime(num) {
            var hours = Math.floor(num / 60);
            var minutes = (num / 60 - hours) * 60;
            return { hours, minutes };
          }

          let start = getTime(repos.data[0].shiftStart);
          let startHourInMins = start.hours * 60;
          let stop = getTime(repos.data[0].shiftEnd);
          let stopHourInMins = stop.hours * 60;
          setUniqueId(repos.data[0].uid || "");
          setDateOfJoining(repos.data[0].doj || new Date());
          setShiftStartHours(startHourInMins);
          setShiftStartMinutes(start.minutes);
          setShiftStopHours(stopHourInMins);
          setShiftStopMinutes(stop.minutes);
          setProfileDetails(repos.data);
          setUserName(repos.data[0].name);
          setUserGender(repos.data[0].gender);
          setUserPhone(repos.data[0].phone);
          setUserAddress(repos.data[0].address);
          setUserdob(repos.data[0].dob);
          setUserEmail(repos.data[0].email);
          setUserDesignation(repos.data[0].designation);

          setUserPAN(repos.data[0].panNumber || "");
          setUserAadhar(repos.data[0].aadharNumber || "");
          setUserPassport(repos.data[0].passportNumber || "");
          setUserPassportExpiry(repos.data[0].passportExpiry || new Date());
          setUserEmergencyContact(repos.data[0].emergencyContactName || "");
          setUserEmergencyNo(repos.data[0].emergencyContactNumber || "");
          setUserBankAccount(repos.data[0].bankAccountDetails || "");
          setImg(arrayBufferToBase64(repos.data[0].image.data.data));
          setReportingToId(repos.data[0].reportingToId);
          setReportingToName(repos.data[0].reportingToName);
          setActive(repos.data[0].active);
          setProfileId(id);
          setUpiId(repos.data[0].upiId);

          setUserAccessType(repos.data[0].accesstype);
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };

  const redirected = () => {
    if (!img) {
      alert("Please upload image!");
      return;
    } else if (userPassword === userConfirmpassword) {
      let shiftStart = shiftStartHours + shiftStartMinutes;
      let shiftEnd = shiftStopHours + shiftStopMinutes;
      const apiurl = process.env.REACT_APP_API_URL + "/api/user/register";
      var apiparam = {
        method: "POST",
        headers: {
          uid: uniqueId,
          doj: dateOfJoining,
          email: userEmail,
          name: userName,
          phone: userPhone,
          address: encodeURIComponent(userAddress),
          dob: userdob,
          gender: userGender,
          password: userPassword,
          accesstype: userAccesstype,
          designation: userDesignation,
          reportingtoid: reportingToId,
          reportingtoname: data.filter((item) => item._id === reportingToId)[0]
            .name,
          shiftStart: shiftStart,
          shiftEnd: shiftEnd,
          pannumber: userPAN,
          aadharnumber: userAadhar,
          passportnumber: userPassport,
            passportexpiry: userPassportExpiry,
          emergencycontactname: userEmergencyContact,
          emergencycontactnumber: userEmergencyNo,
          bankaccountdetails: encodeURIComponent(userBankAccount),
          active: active,
          upiid: upiId,
          authtoken: localStorage.getItem("loggedintoken"),
        },
        body: userPhoto,
      };
      fetch(apiurl, apiparam)
        .then((res) => (res.status === 200 ? res.json() : res.text()))
        .then((repos) => {
          if (repos.data) {
            console.log(repos);
            alert("Employee added!");
            window.location.href = "/";
          } else alert(repos.message || repos);
        })
        .catch((err) => alert(err));
    } else {
      alert("passwords do not match");
    }
  };

  function arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    var base64Flag = "data:image/jpeg;base64,";
    return base64Flag + window.btoa(binary);
  }

  function logout() {
    localStorage.clear();
    setUser(null);
    window.location.href = "/";
  }
  const updatePassword = () => {
    if (newPassword === userConfirmpassword) {
      let apiUrl = process.env.REACT_APP_API_URL + "/api/user/updatepassword";
      let apiParams = {
        method: "POST",
        headers: {
          id: employeeId,
          oldpassword: oldPassword,
          newpassword: newPassword,
          authtoken: localStorage.getItem("loggedintoken"),
        },
      };
      fetch(apiUrl, apiParams)
        .then((res) => (res.status == 200 ? res.json() : res.text()))
        .then((repos) => {
          if (repos.data) {
            alert(repos.message);
            setOpen(false);
            logout();
          } else alert(repos.message || repos);
        })
        .catch((err) => alert(err));
    } else {
      alert("Passwords do not match");
    }
  };

  useEffect(() => {

    let empid = user._id;
    if (location.pathname === "/profile") {
      setEmployeeId(empid);
      getProfileDetails(empid);
    }
    else {
      setShiftStartHours(540);
      setShiftStartMinutes(0);
      setShiftStopHours(1080);
      setShiftStopMinutes(0);
    }

    const apiurl =
      process.env.REACT_APP_API_URL +
      "/api/user/employeesdropdown?empid=" +
      user._id;
    const apiparam = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };
    fetch(apiurl, apiparam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setData(repos.data);
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  }, []);

  return (
    <div id="profile_sign_up" style={{height:(location.pathname === "/profile"?"1600px":"1430px")}}>
      <img
        id="profile_n_0944467"
        src="n_0944467.png"
        srcset="n_0944467.png 1x"
        alt=""
      />

      <div id="profile_full_bg_grp">
        <svg class="profile_Rectangle_1_j_profile">
          <linearGradient
            id="profile_Rectangle_1_j"
            spreadMethod="pad"
            x1="0.5"
            x2="0.531"
            y1="0"
            y2="1.03"
          >
            <stop offset="0" stop-color="#b0f6d3" stop-opacity="1"></stop>
            <stop offset="0.8582" stop-color="#d6d3fe" stop-opacity="1"></stop>
            <stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
          </linearGradient>
          <rect
            id="profile_Rectangle_1_j"
            rx="32"
            ry="32"
            x="0"
            y="0"
            width="659"
            height="1313"
          ></rect>
        </svg>

        {location.pathname === "/profile" ?
          <div id="profile_employee_grp">
            <select
              placeholder="Employees"
              class="profile_Rectangle_2"
              value={profileId}
              onChange={(e) => {
                getProfileDetails(e.target.value);
              }}
            >
              {(showActive ? data.filter((emp) => emp.active) : data).sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1).map((employee) => {
                return <option value={employee._id}>{employee.name}</option>;
              })}
            </select>
            <div id="profile_Employees_profile">
              <span>Employees</span>   ( <input
                type="checkbox"
                onChange={(e) => setShowActive(e.target.checked)}
                checked={showActive}
              /> Active Only)

            </div>
          </div> : <div id="profile_employee_grp">
            <input
              type="text"
              placeholder="Name"
              className="profile_Rectangle_2 "
              value={userName}
              label="Name"
              onChange={(e) => setUserName(e.target.value)}
              required
            />
            <div id="profile_Employees_profile">
              <span>Employee Name</span> </div>

          </div>}
        <div id="profile_Email_grp">
          <input
            placeholder="Email"
            class="profile_Rectangle_3"
            disabled={user.accesstype != 5}
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
          />

          <div id="profile_Email_profile">
            <span>Email</span>
          </div>
        </div>
        <div id="profile_UID_grp">
          <input
            placeholder="Unique ID"
            class="profile_Rectangle_4"
            disabled={user.accesstype != 5}
            value={uniqueId}
            onChange={(e) => setUniqueId(e.target.value)}
          />

          <div id="profile_Unique_ID_profile">
            <span>Unique ID</span>
          </div>
        </div>
        <div id="profile_DOJ_grp">
          <input
            type="date"
            placeholder="Date of Joining"
            class="profile_Rectangle_5"
            value={new Date(dateOfJoining).toISOString().split("T")[0]}
            disabled={user.accesstype != 5}
            onChange={(e) => {
              setDateOfJoining(e.target.value);
            }}
          />

          <div id="profile_Date_of_Joining_profile">
            <span>Date of Joining</span>
          </div>
        </div>
        <div id="profile_Mob_grp">
          <input
            placeholder="Mobile"
            class="profile_Rectangle_6"
            value={userPhone}
            onChange={(e) => setUserPhone(e.target.value)}
            required
            disabled={user._id != profileId && user.accesstype != 5}
          />

          <div id="profile_Mobile_profile">
            <span>Mobile</span>
          </div>
        </div>
        <div id="profile_gender_grp">
          <select
            class="profile_Rectangle_7"
            value={userGender}
            disabled={user.accesstype != 5}
            onChange={(e) => setUserGender(e.target.value)}
          >
            <option value="" selected disabled>
              Gender
            </option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
          <div id="profile_Gender_profile">
            <span>Gender</span>
          </div>
        </div>
        <div id="profile_DOB_GRP">

          <input
            type="date"
            placeholder="DOB"
            class="profile_Rectangle_8"
            disabled={user.accesstype != 5}
            value={new Date(userdob).toISOString().split("T")[0]}
            onChange={(e) => {
              const fromDateTimestamp = Date.parse(e.target.value);

              if (isNaN(fromDateTimestamp)) {
                // handle invalid date input
                return;
              }
              setUserdob(e.target.value);
            }}
          />

          <div id="profile_DOB_profile">
            <span>DOB</span>
          </div>
        </div>
        <div id="profile_Destination">
          <select
            placeholder="Designation"
            class="profile_Rectangle_9"
            disabled={user.accesstype != 5}
            label="Designation"
            value={userDesignation}
            onChange={(e) => setUserDesignation(e.target.value)}
            required
          >
            <option value="" selected>
              Designation
            </option>
            <option value="manager">Manager</option>
            <option value="employee">Employee</option>
            <option value="client">client</option>
          </select>
          <div id="profile_Designation_profile">
            <span>Designation</span>
          </div>
        </div>
        <div id="profile_reprt_grp">
          {user.accesstype != 5 ? (
            <input
              placeholder="Reporting to"
              class="profile_Rectangle_10"
              value={reportingToName}
              InputLabelProps={{ shrink: true }}
              disabled
            />
          ) : (
            <select
              value={reportingToId}
              class="profile_Rectangle_10_profile_select"
              disabled={user.accesstype != 5}
              label="Reporting to"
              onChange={(e) => {
                setReportingToId(e.target.value);
                setReportingToName(
                  data.filter((item) => item._id === e.target.value)[0].name
                );
              }}
              required
            >
              {data.map((employee) => {
                return <option value={employee._id}>{employee.name}</option>;
              })}
            </select>
          )}

          <div id="profile_Reporting_To_profile">
            <span>Reporting To</span>
          </div>
        </div>
        <div id="profile_access_grp">
          <select
            placeholder="Access Type"
            class="profile_Rectangle_11"
            disabled={user.accesstype != 5}
            label="Access Type"
            value={userAccesstype}
            onChange={(e) => setUserAccessType(e.target.value)}
            required
          >
            <option value="1">Manager</option>
            <option value="0">Employee</option>
            <option value="5">Admin</option>
          </select>
          <div id="profile_Access_Type_profile">
            <span>Access Type</span>
          </div>
        </div>
        <div id="profile_pan_grp">
          <input
            placeholder="Passport Number"
            class="profile_Rectangle_12"
            value={userPassport}
            onChange={(e) => setUserPassport(e.target.value)}
            disabled={user._id != profileId && user.accesstype != 5}
          />

          <div id="profile_PAN_Number_profile">
            <span>Passport Number</span>
          </div>
        </div>
        <div id="profile_passport_grp">
          <input
            placeholder="Passport Expiry Date"
            type="date"
            class="profile_Rectangle_13"
            disabled={user._id != profileId && user.accesstype != 5}
            value={new Date(userPassportExpiry).toISOString().split("T")[0]}
            onChange={(newValue) => {
              setUserPassportExpiry(newValue);
            }}
          />

          <div id="profile_Passport_Expiry_profile">
            <span>Passport Expiry Date</span>
          </div>
        </div>
        <div id="profile_emergency_grp">
          <input
            placeholder="Pan Number"
            class="profile_Rectangle_14"
            value={userPAN}
            onChange={(e) => setUserPAN(e.target.value)}
            InputLabelProps={{ shrink: true }}
            required
            disabled={user.accesstype != 5}
          />

          <div id="profile_Emergency_Contact_profile">
            <span>Pan Number</span>
          </div>
        </div>
        <div id="profile_emer_number_grp">
          <input
            type="text"
            placeholder="Aadhar Number "
            class="profile_Rectangle_15"
            value={userAadhar}
            onChange={(e) => setUserAadhar(e.target.value)}
            required
            disabled={user.accesstype != 5}
          />

          <div id="profile_Emergency_Contact_profile_ct">
            <span>Aadhar Number</span>
          </div>
        </div>
        <div id="profile_emer_person_grp">
          <input
            placeholder="Emergency Contact Person"
            class="profile_Rectangle_16"
            value={userEmergencyContact}
            onChange={(e) => setUserEmergencyContact(e.target.value)}
            required
            disabled={user._id != profileId && user.accesstype != 5}
          />

          <div id="profile_Emergency_Contact_profile_cw">
            <span>Emergency Contact Person</span>
          </div>
        </div>
        <div id="profile_emer_grp">
          <input
            placeholder="Emergency Contact Number"
            class="profile_Rectangle_17"
            value={userEmergencyNo}
            onChange={(e) => setUserEmergencyNo(e.target.value)}
            required
            disabled={user._id != profileId && user.accesstype != 5}
          />

          <div id="profile_Emergency_Contact_profile_cz">
            <span>Emergency Contact Number</span>
          </div>
        </div>
        <div id="profile_address_grp">
          <textarea
            placeholder="Address"
            class="profile_Rectangle_18"
            rows={3}
            value={userAddress}
            onChange={(e) => setUserAddress(e.target.value)}
            required
            disabled={user._id != profileId && user.accesstype != 5}
          ></textarea>
          <div id="profile_Address_profile">
            <span>Address</span>
          </div>
        </div>
        {user._id != profileId && user.accesstype != 5 ? (
          ""
        ) : (
          <div id="profile_bank_grp">
            <textarea
              placeholder="Bank Account Details"
              class="profile_Rectangle_19"
              value={userBankAccount}
              onChange={(e) => setUserBankAccount(e.target.value)}
            ></textarea>

            <div id="profile_Bank_Accountprofile">
              <span>Bank Account Details</span>
            </div>
          </div>
        )}
        <div id="profile_SSH_grp_1">
          <select
            class="profile_Rectangle_21"
            disabled={user._id == profileId && user.accesstype != 5}
            value={parseInt(shiftStartHours)}
            label="Shift Start hours"
            onChange={(e) => {
              setShiftStartHours(e.target.value);
            }}
          >
            <option value={0}>00</option>
            <option value={60}>01</option>
            <option value={120}>02</option>
            <option value={180}>03</option>
            <option value={240}>04</option>
            <option value={300}>05</option>
            <option value={360}>06</option>
            <option value={420}>07</option>
            <option value={480}>08</option>
            <option value={540}>09</option>
            <option value={600}>10</option>
            <option value={660}>11</option>
            <option value={720}>12</option>
            <option value={780}>13</option>
            <option value={840}>14</option>
            <option value={900}>15</option>
            <option value={960}>16</option>
            <option value={1020}>17</option>
            <option value={1080}>18</option>
            <option value={1140}>19</option>
            <option value={1200}>20</option>
            <option value={1260}>21</option>
            <option value={1320}>22</option>
            <option value={1380}>23</option>
          </select>
          <div id="profile_Shift_Start_profile">
            <span>Shift Start Hours</span>
          </div>
        </div>
        <div id="profile_SSM_grp1">
          <select
            class="profile_Rectangle_22_profile"
            disabled={user._id == profileId && user.accesstype != 5}
            value={parseInt(shiftStartMinutes)}
            label="Shift Start minutes"
            onChange={(e) => setShiftStartMinutes(e.target.value)}
          >
            <option value={0}>00</option>
            <option value={15}>15</option>
            <option value={30}>30</option>
            <option value={45}>45</option>
          </select>
          <div id="profile_Shift_Startprofile">
            <span>Shift Start Minutes</span>
          </div>
        </div>
        <div id="profile_SSH_grp_2">
          <select
            class="profile_Rectangle_23_profile"
            disabled={user._id == profileId && user.accesstype != 5}
            value={shiftStopHours}
            label="Shift Stop hours"
            onChange={(e) => setShiftStopHours(e.target.value)}
          >
            <option value={0}>00</option>
            <option value={60}>01</option>
            <option value={120}>02</option>
            <option value={180}>03</option>
            <option value={240}>04</option>
            <option value={300}>05</option>
            <option value={360}>06</option>
            <option value={420}>07</option>
            <option value={480}>08</option>
            <option value={540}>09</option>
            <option value={600}>10</option>
            <option value={660}>11</option>
            <option value={720}>12</option>
            <option value={780}>13</option>
            <option value={840}>14</option>
            <option value={900}>15</option>
            <option value={960}>16</option>
            <option value={1020}>17</option>
            <option value={1080}>18</option>
            <option value={1140}>19</option>
            <option value={1200}>20</option>
            <option value={1260}>21</option>
            <option value={1320}>22</option>
            <option value={1380}>23</option>
          </select>
          <div id="profile_Shift_Stop_profile">
            <span>Shift Stop Hour</span>
          </div>
        </div>
        <div id="profile_SSM_grp_2">
          <select
            class="profile_Rectangle_24"
            disabled={user._id == profileId && user.accesstype != 5}
            value={shiftStopMinutes}
            label="Shift Stop minutes"
            onChange={(e) => setShiftStopMinutes(e.target.value)}
          >
            <option value={0}>00</option>
            <option value={15}>15</option>
            <option value={30}>30</option>
            <option value={45}>45</option>
          </select>
          <div id="profile_Shift_Stop_Minutes">
            <span>Shift Stop Minutes</span>
          </div>
        </div>
        <div id="profile_upi_grp">
          <div id="profile_Emergency_Contact_profile_cw">
            <span>UPI ID</span>
          </div>
          <input
            type="text"
            className="profile_Rectangle_upi_16"
            placeholder="UPI ID"
            value={upiId}
            onChange={(e) => setUpiId(e.target.value)}
          />
        </div>
        <Tooltip title="update profile picture">
          <label id="profile_profile" style={{ cursor: "pointer" }}>
            <input
              type="file"
              onChange={(e) => handleImageUpload(e)}
              hidden
              disabled={user._id != profileId && user.accesstype != 5}
              accept="image/*"
            />
            <img src={img} alt="" width="100" height="100" />
          </label>
        </Tooltip>
        <input
          type="checkbox"
          class="profile_Rectangle_25"
          disabled={user._id == profileId && user.accesstype != 5}
          onChange={(e) => setActive(e.target.checked)}
          checked={active}
        />

        <div id="profile_Active">
          <span>Active</span>
        </div>
        
       
              {location.pathname === "/profile" ?
        <div id="profile_Group_6">
          <button
            id="profile_Group_2"
            onClick={() => {
              updateProfile();
            }}
          >
            <svg class="profile_Rectangle_26_s">
              <rect
                id="profile_Rectangle_26_s"
                rx="9"
                ry="9"
                x="0"
                y="0"
                width="162"
                height="50"
              ></rect>
            </svg>
            <div id="profile_Update_t">
              <span>Update Profile</span>
            </div>
          </button>
          <button id="profile_Group_3_profile" onClick={handleOpen}>
            <svg class="profile_Rectangle_27_y">
              <rect
                id="profile_Rectangle_27_y"
                rx="9"
                ry="9"
                x="0"
                y="0"
                width="162"
                height="50"
              ></rect>
            </svg>
            <div id="profile_Change_Password_z">
              <span>Change Password</span>
            </div>
          </button>
        </div>:
          <>
        <div id="profile_pass_grp">
        <input
                type="password"
                placeholder="Password"
                className="profile_Rectangle_16"
                value={userPassword}
                onChange={(e) => setUserPassword(e.target.value)}
                required
              />

          <div id="profile_Emergency_Contact_profile_cw">
            <span>Password</span>
          </div>
        </div>
        
        <div id="profile_conf_pass_grp">
         
        <input
                type="password"
                placeholder="Confirm Password"
                class="profile_Rectangle_17"
                value={userConfirmpassword}
                onChange={(e) => setUserConfirmPassword(e.target.value)}
                required
              />

          <div id="profile_Emergency_Contact_profile_cz">
            <span>Confirm Password</span>
          </div>
        </div>
        <button
                id="Group_12"
                onClick={() => {
                  redirected();
                }}
              >
                <svg class="Rectangle_27_bx">
                  <rect
                    id="Rectangle_27_bx"
                    rx="9"
                    ry="9"
                    x="0"
                    y="0"
                    width="162"
                    height="50"
                  ></rect>
                </svg>
                <div id="Register_by">
                  <span>Register</span>
                </div>
              </button></>
    
          // </div>
          }
        <div id="profile_Group_9">
          <Menu />
        </div>
      </div>
      <img id="profile_logo" src="logo.png" srcset="logo.png 1x" alt="" />


      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: "center" }}
          >
            Change Password
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div class="row g-3">
              <div class="col">
                <input
                  type="password"
                  class="form-control"
                  placeholder="Old Password"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  required
                  autocomplete="new-password"
                />
              </div>
            </div>
            <div class="row g-3">
              <div class="col">
                <input
                  type="password"
                  class="form-control"
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setnewPassword(e.target.value)}
                  required
                  autocomplete="new-password"
                />
              </div>
            </div>
            <div class="row g-3">
              <div class="col">
                <input
                  type="password"
                  class="form-control"
                  placeholder="Confirm Password"
                  value={userConfirmpassword}
                  onChange={(e) => setUserConfirmPassword(e.target.value)}
                  required
                  autocomplete="new-password"
                />
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  updatePassword();
                }}
              >
                Update
              </Button>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}

export default Profile;
