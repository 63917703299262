import {
  Box,
  Button,
  Snackbar,
  TextField,
  Tooltip,
  Avatar,
} from "@material-ui/core";
import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../../UserContext";
import AddIcon from "@mui/icons-material/Add";
import {
  Stack,
  Skeleton,
  Typography,
  Alert,
  Modal,
  FormControl,
  CircularProgress,
} from "@mui/material";
import { getSender, getSenderFull } from "./ChatLogic";
import GroupChatModal from "./GroupChatModal";
import NotificationBadge from "react-notification-badge";
import { Effect } from "react-notification-badge";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import UserListItem from "./UserListItem";
import ProfileModal from "./ProfileModal";
import CircleIcon from "@mui/icons-material/Circle";
import UpdateGroupChatModal from "./UpdateGroupChatModal";
import UserBadgeItem from "./UserBadgeItem";
const MyChats = ({ fetchAgain, setFetchAgain }) => {
  const [profile, setProfile] = useState({});
  const [loggedUser, setLoggedUser] = useState();
  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [search, setSearch] = useState("");

  const [searchGroupResult, setSearchGroupResult] = useState([]);
  const [searchGroup, setSearchGroup] = useState("");
  const {
    user,
    setUser,
    selectedChat,
    chats,
    setChats,
    setSelectedChat,
    notification,
    setNotification,
    onlineUsers,
  } = useContext(UserContext);
  const [snackbarMessage, setSnackBarMessage] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const [groupChatName, setGroupChatName] = useState();
  const [renameLoading, setRenameLoading] = useState(false);

  const [loadingChat, setLoadingChat] = useState(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [open, setOpen] = React.useState(false);
  const [groupOpen, setGroupOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleGroupClose = () => setGroupOpen(false);
  var accesstypes = parseInt(user.accesstype);
  const accessChat = async (userId) => {
    try {
      setLoadingChat(true);
      const apiURL = process.env.REACT_APP_API_URL + "/api/chat";

      const apiParams = {
        method: "POST",
        headers: {
          authtoken: localStorage.getItem("loggedintoken"),
          currentid: user._id,
          selectedid: userId,
        },
      };
      await fetch(apiURL, apiParams)
        .then((res) => (res.status === 200 ? res.json() : res.text()))
        .then((repos) => {
          if (repos.data) {
            // console.log(repos);
            const { data } = repos;
            if (!chats.find((c) => c._id === data._id)) {
              setChats([data, ...chats]);
              setSelectedChat(repos.data);
              setLoadingChat(false);
              setSearchResult([]);
              setSearch("");
            }
          } else {
            setSnackBarMessage("Error accessing the Chat");
            setOpenToast(true);
          }
        })
        .catch((err) => {
          setSnackBarMessage(err);
          setOpenToast(true);
        });
    } catch (error) {}
  };
  const fetchChats = async () => {
    const apiURL = process.env.REACT_APP_API_URL + "/api/chat";
    const apiParams = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
        userid: user._id,
      },
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setChats(repos.data);
        } else alert(JSON.stringify(repos));
      })
      .catch((err) => alert(JSON.stringify(err)));
  };

  //
  const handleAvatarClick = async (chat) => {
    let prof = getSenderFull(user, chat.users);
    setProfile(prof);
    !chat.isGroupChat ? handleOpen() : handleGroupOpen();
  };
  const handleGroupOpen = () => {
    setGroupOpen(true);
  };
  const handleRename = async () => {
    if (!groupChatName) {
      return;
    }
    const apiURL = process.env.REACT_APP_API_URL + "/api/chat/renamegroup";
    const apiParams = {
      method: "PUT",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
        chatid: selectedChat._id,
        chatname: groupChatName,
      },
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          // setSelectedChat(repos.data);
          setFetchAgain(!fetchAgain);
          setRenameLoading(false);
          setGroupOpen(false);
        } else {
          setSnackBarMessage("Error Renaming Chat");
          setOpenToast(true);
        }
      })
      .catch((err) => {
        setSnackBarMessage(err);
        setOpenToast(true);
      });
  };
  const handleSearch = async () => {
    // setSearch(query);
    if (!search) {
      setOpenToast(true);
      setSnackBarMessage("Please Enter Something in the Search");
      return;
    }
    try {
      setLoading(true);
      const apiURL =
        process.env.REACT_APP_API_URL + "/api/user/allusers?search=" + search;
      const apiParams = {
        method: "GET",
        headers: {
          authtoken: localStorage.getItem("loggedintoken"),
          id: user._id,
        },
      };
      fetch(apiURL, apiParams)
        .then((res) => (res.status === 200 ? res.json() : res.text()))
        .then((repos) => {
          if (repos.data) {
            setLoading(false);
            setSearchResult(repos.data);
          } else alert(repos.message || repos);
        })
        .catch((err) => alert(err));
    } catch (e) {
      setSnackBarMessage("Failed to fetch search results");
      setOpenToast(true);
    }
  };

  const handleGroupSearch = async (query) => {
    setSearchGroup(query);
    if (!query) {
      setOpenToast(true);
      setSnackBarMessage("Please Enter Something in the Search");
      return;
    }
    try {
      // setLoading(true);
      const apiURL =
        process.env.REACT_APP_API_URL +
        "/api/user/allusers?search=" +
        searchGroup;
      const apiParams = {
        method: "GET",
        headers: {
          authtoken: localStorage.getItem("loggedintoken"),
          id: user._id,
        },
      };
      fetch(apiURL, apiParams)
        .then((res) => (res.status === 200 ? res.json() : res.text()))
        .then((repos) => {
          if (repos.data) {
            // setLoading(false);
            setSearchGroupResult(repos.data);
          } else alert(repos.message || repos);
        })
        .catch((err) => alert(err));
    } catch (e) {
      setSnackBarMessage("Failed to fetch search results");
      setOpenToast(true);
    }
  };
  const handleAddUser = async (user1) => {
    if (selectedChat.users.find((u) => u._id === user1._id)) {
      setSnackBarMessage("User Already in Group");
      setOpenToast(true);
      return;
    }
    if (selectedChat.groupAdmin._id !== user._id) {
      setSnackBarMessage("Only Admins can add someone!");
      setOpenToast(true);
      return;
    }
    const apiURL = process.env.REACT_APP_API_URL + "/api/chat/addtogroup";
    const apiParams = {
      method: "PUT",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
        chatid: selectedChat._id,
        newuserid: user1._id,
      },
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setSelectedChat(repos.data);
          setFetchAgain(!fetchAgain);
          // fetchMessages();
          setLoading(false);
        } else {
          setSnackBarMessage("Error Occured!");
          setOpenToast(true);
        }
      })
      .catch((err) => {
        setSnackBarMessage(err);
        setOpenToast(true);
      });
  };
  const handleRemove = async (user1) => {
    if (selectedChat.groupAdmin._id !== user._id) {
      setSnackBarMessage("Only Admin can Remove Someone");
      setOpenToast(true);
      return;
    }
    const apiURL = process.env.REACT_APP_API_URL + "/api/chat/removefromgroup";
    const apiParams = {
      method: "PUT",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
        chatid: selectedChat._id,
        removeid: user1._id,
      },
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          user1._id === user._id
            ? setSelectedChat()
            : setSelectedChat(repos.data);
          setFetchAgain(!fetchAgain);
          setLoading(false);
        } else {
          setSnackBarMessage("Error Occured!");
          setOpenToast(true);
        }
      })
      .catch((err) => {
        setSnackBarMessage(err);
        setOpenToast(true);
      });
  };
  useEffect(() => {
    setLoggedUser(user);
    fetchChats();
  }, []);
  useEffect(() => {
    setLoggedUser(user);
    fetchChats();
  }, [fetchAgain]);

  return (
    <Box
      sx={{
        flexDirection: "column",
        alignItems: "center",
        padding: "10px",
        bgcolor: process.env.REACT_APP_PRIMARY_COLOR,
        width: { base: "100%", md: "31%" },
        borderRadius: "25px",
        border: "1px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <TextField
            label="Search for Users"
            color="success"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Button
            style={{
              display: "flex",
              borderRadius: "50px",
            }}
            variant="contained"
            size="small"
            onClick={handleSearch}
          >
            Go
          </Button>
          <Snackbar
            open={openToast}
            autoHideDuration={3000}
            onClose={() => setOpenToast(false)}
          >
            <Alert severity="error">{snackbarMessage}</Alert>
          </Snackbar>
        </Box>

        <GroupChatModal>
          {accesstypes === 0 ? (
            ""
          ) : (
            <Tooltip title={"Create Group chat"} placement="bottom-start">
              <Button
                style={{
                  display: "flex",
                  borderRadius: "50px",
                }}
                variant="contained"
              >
                <GroupAddIcon color="success" />
              </Button>
            </Tooltip>
          )}
        </GroupChatModal>
      </Box>
      {loading ? (
        <Stack>
          <Skeleton variant="text" sx={{ height: "45px" }} />
          <Skeleton variant="text" sx={{ height: "45px" }} />
          <Skeleton variant="text" sx={{ height: "45px" }} />
          <Skeleton variant="text" sx={{ height: "45px" }} />
          <Skeleton variant="text" sx={{ height: "45px" }} />
          <Skeleton variant="text" sx={{ height: "45px" }} />
        </Stack>
      ) : (
        search.length > 0 &&
        searchResult?.map((u) => (
          <UserListItem
            key={user._id}
            user={u}
            handleFunction={() => accessChat(u._id)}
          ></UserListItem>
        ))
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "3px",
          bgcolor: process.env.REACT_APP_TERTIARY_COLOR,
          width: "100%",
          borderRadius: "lg",
        }}
      >
        {chats ? (
          <Stack>
            {chats.map((chat) => (
              <Box
                onClick={() => {
                  setSelectedChat(chat);
                  setNotification(
                    notification.filter((item) => chat._id !== item.chat._id)
                  );
                }}
                style={{
                  width: "100%",
                  cursor: "pointer",
                  background:
                    selectedChat === chat
                      ? process.env.REACT_APP_SECONDARY_COLOR
                      : "#E8E8E8",
                  color: selectedChat === chat ? "black" : "black",
                }}
                key={chat._id}
              >
                <>
                  <NotificationBadge
                    count={
                      notification.filter((item) => chat._id === item.chat._id)
                        .length
                    }
                    effect={Effect.SCALE}
                  />
                  <Typography>
                    {loggedUser ? (
                      <Avatar
                        onClick={() => handleAvatarClick(chat)}
                        style={{
                          display: "inline-grid",
                          margin: "5px",
                          color: "white",
                          backgroundColor: !chat.isGroupChat
                            ? onlineUsers.filter(
                                (item) =>
                                  item._id ===
                                  getSenderFull(loggedUser, chat.users)._id
                              ).length === 1
                              ? "green"
                              : ""
                            : "",
                        }}
                      >
                        {chat && !chat.isGroupChat
                          ? getSenderFull(loggedUser, chat.users)
                              .name.split(" ")[0]
                              .slice(0, 1) +
                            (
                              getSenderFull(loggedUser, chat.users).name.split(
                                " "
                              )[1] || " "
                            ).slice(0, 1)
                          : chat.chatName || "  ".slice(0, 2).toUpperCase()}
                      </Avatar>
                    ) : (
                      ""
                    )}
                    &nbsp;&nbsp;
                    {!chat.isGroupChat
                      ? getSender(loggedUser, chat.users)
                      : chat.chatName}
                    &nbsp;&nbsp;
                  </Typography>
                </>
              </Box>
            ))}
          </Stack>
        ) : (
          <Stack>
            <Skeleton variant="text" sx={{ height: "45px" }} />
            <Skeleton variant="text" sx={{ height: "45px" }} />
            <Skeleton variant="text" sx={{ height: "45px" }} />
            <Skeleton variant="text" sx={{ height: "45px" }} />
            <Skeleton variant="text" sx={{ height: "45px" }} />
            <Skeleton variant="text" sx={{ height: "45px" }} />
          </Stack>
        )}
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Avatar> {profile.name && profile.name.slice(0, 2)} </Avatar>
          <Typography
            style={{
              fontSize: { base: "28px", md: "30px" },
              fontFamily: "Work sans",
            }}
          >
            Email: {profile.email}
          </Typography>
        </Box>
      </Modal>

      {/* groupmodal */}
      <Modal
        open={groupOpen}
        onClose={handleGroupClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {selectedChat && selectedChat.chatName}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Box
              style={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                paddingBottom: "10px",
              }}
            >
              {selectedChat &&
                selectedChat.users.map((u) => (
                  <UserBadgeItem
                    key={u._id}
                    user={u}
                    handleFunction={() => handleRemove(u)}
                  />
                ))}
            </Box>
            <div style={{ textAlign: "center" }}>
              <FormControl>
                <TextField
                  label="Chat Name"
                  value={groupChatName}
                  onChange={(e) => {
                    setGroupChatName(e.target.value);
                  }}
                  variant="outlined"
                />
              </FormControl>

              <Button
                variant="contained"
                color="success"
                style={{ margin: "10px 5px" }}
                isLoading={renameLoading}
                onClick={handleRename}
              >
                Update
              </Button>
            </div>
            <br />
            <FormControl>
              <TextField
                label="Add User to Group"
                onChange={(e) => {
                  handleGroupSearch(e.target.value);
                }}
                variant="outlined"
              />
            </FormControl>

            {loading ? (
              <CircularProgress />
            ) : (
              searchGroupResult?.map((user) => (
                <UserListItem
                  key={user._id}
                  user={user}
                  handleFunction={() => handleAddUser(user)}
                />
              ))
            )}
            {/* <Button
              variant="outlined"
              onClick={() => handleRemove(user)}
              disabled
            >
              Leave Group
            </Button> */}
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
};

export default MyChats;
