import React, { useEffect, useState, useContext } from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Button, MenuItem } from "@mui/material";
import { areDayPropsEqual } from "@mui/lab/PickersDay/PickersDay";
import { UserContext } from "../UserContext";
import "../Styles/attendance.css";

function Filter(props) {
  const { user, setUser } = useContext(UserContext);
  //variable
  var accesstypes = parseInt(user.accesstype);
  //useState
  const [fromValue, setFromValue] = useState(new Date());
  const [toValue, setToValue] = useState(new Date());
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [disabled, setDisabled] = useState(true);
  const [data, setData] = useState([]);
  const [empid, setEmpid] = useState(props.employee);
  const [showActive, setShowActive] = useState(true);
  // useEffect;
  useEffect(() => {
    GetEmployees();
  }, []);
  //functions
  function GetEmployees() {
    let empid = user._id;
    const apiurl =
      process.env.REACT_APP_API_URL +
      "/api/user/employeesdropdown?empid=" +
      empid;
    const apiparam = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };

    fetch(apiurl, apiparam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setData([{ _id: "all", name: "All Team Members", active: true }, ...repos.data]);
          setDisabled(false);
        } else console.log(repos.message || repos);
      })
      .catch((err) => console.log(err));
  }

  return (
    // <div>
    //   <div
    //     style={{
    //       display: "flex",
    //       justifyContent: "center",
    //       marginTop: "20px",
    //       alignItems: "center",
    //     }}
    //   >
    //     {accesstypes > 0 ? (
    //       <div style={{ margin: "10px" }}>
    //         <TextField
    //           select
    //           style={{ width: "300px" }}
    //           value={props.employee || user._id}
    //           InputLabelProps={{ shrink: true }}
    //           label="Employee"
    //           onChange={(e) => {
    //             props.changeEmployee(e.target.value);
    //           }}
    //           disabled={disabled}
    //         >
    //           {data.map((employee) => {
    //             return (
    //               <MenuItem value={employee._id}>{employee.name}</MenuItem>
    //             );
    //           })}
    //         </TextField>
    //       </div>
    //     ) : (
    //       ""
    //     )}
    //     <div style={{ margin: "10px" }}>
    //       <LocalizationProvider dateAdapter={AdapterDateFns}>
    //         <DatePicker
    //           label="From"
    //           value={props.fromDate}
    //           InputLabelProps={{ shrink: true }}
    //           onChange={(newValue) => props.changeFromDate(newValue)}
    //           renderInput={(params) => (
    //             <TextField {...params} variant="filled" color="success" />
    //           )}
    //         />
    //       </LocalizationProvider>
    //     </div>
    //     <div style={{ margin: "10px" }}>
    //       <LocalizationProvider dateAdapter={AdapterDateFns}>
    //         <DatePicker
    //           label="To"
    //           value={props.toDate}
    //           InputLabelProps={{ shrink: true }}
    //           onChange={(newValue) => props.changeToDate(newValue)}
    //           renderInput={(params) => (
    //             <TextField {...params} variant="filled" color="success" />
    //           )}
    //           format="DD-MM-YYYY"
    //         />
    //       </LocalizationProvider>
    //     </div>
    //     <div style={{ margin: "10px" }}>
    //       <Button
    //         variant="contained"
    //         color="success"
    //         name="Adminfilter"
    //         onClick={(e) => props.onFilterClick()}
    //       >
    //         View
    //       </Button>
    //     </div>
    //   </div>
    // </div>
    <>
      <div id="filter_option_grp">
        {accesstypes > 0 ? (
          <select
            class="employee_bdr_input"
            value={props.employee || user._id}
            onChange={(e) => {
              props.changeEmployee(e.target.value);
            }}
            disabled={disabled}
          >
            {data.filter((emp) => emp._id === "all").map((employee) => {
              return <option value={employee._id}>{employee.name}</option>;
            })}
            {(showActive?data.filter((emp) => emp.active):data).filter((emp) => emp._id !== "all").sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1:-1).map((employee) => {
              return <option value={employee._id}>{employee.name}</option>;
            })}
          </select>
        ) : (
          ""
        )}
        {accesstypes > 0 ? (
          <div id="Employee_lbl">
            <span>Employee</span> ( <input
          type="checkbox"
          onChange={(e) => setShowActive(e.target.checked)}
          checked={showActive}
        /> Active Only)
          </div>
        ) : (
          ""
        )}
        <input
          type="date"
          class="from_bdr_input"
          value={props.fromDate}
          onChange={(e) => props.changeFromDate(e.target.value)}
        />

        <div id="From_lbl">
          <span>From</span>
        </div>
        <input
          type="date"
          class="to_bdr_input"
          value={props.toDate}
          onChange={(e) => props.changeToDate(e.target.value)}
        />

        <div id="To_lbl">
          <span>To</span>
        </div>
      </div>
    </>
  );
}

export default Filter;
