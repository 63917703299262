import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Tooltip } from "@material-ui/core";
import MapSharpIcon from "@mui/icons-material/MapSharp";
import { Button } from "@mui/material";

function AttendanceGrid(props) {
  const [gridData,setGridData]=useState([]);
  //useState
  //useEffect
  useEffect(() => {
    setGridData(props.data);
  }, [props.data]);
  //functions
  const mapLink = (latitude, longitude) => {
    const mapUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    window.open(mapUrl, "_blank");
  };
  function convertMinutesToTime(minutes) {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    const hoursStr = String(hours).padStart(2, "0");
    const minsStr = String(mins).padStart(2, "0");

    return hoursStr + ":" + minsStr;
  }

  function calculateDistance(lat1, lon1) {
    const R = 6371; // radius of the earth in km
    const lat2 = 13.0154496;
    const lon2 = 77.6634368;
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // distance in km
    if (Math.round(distance) == 0) {
      return "At office";
    } else {
      return Math.round(distance) + " Km";
    }
  }

  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }

  return (
    <div>
      <div>
        <TableContainer component={Paper} className="tableContainer">
          <Table>
            <TableHead
              sx={{
                backgroundColor: process.env.REACT_APP_PRIMARY_COLOR,
              }}
            >
              <TableRow
                sx={{
                  "& .MuiTableCell-root": {
                    textAlign: "center",
                    fontFamily: "sans-serif",
                    fontSize: "20px",
                    fontWeight: 700,
                    color: "white",
                  },
                }}
              >
                <TableCell>Date</TableCell>
                <TableCell>Employee</TableCell>
                <TableCell>In Time</TableCell>
                <TableCell>In Comments</TableCell>
                <TableCell>In Location</TableCell>
                <TableCell>Out Time</TableCell>
                <TableCell>Out Comments</TableCell>
                <TableCell>Out Location</TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                "& .MuiTableRow-root:hover": {
                  backgroundColor: process.env.REACT_APP_SECONDARY_COLOR,
                },
              }}
            >
              {gridData.map((attendance) => (
                <TableRow
                  key={attendance._id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "& .MuiTableCell-root": {
                      textAlign: "center",
                      fontFamily: "Helvetica",
                      fontSize: "18px",
                    },
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ textAlign: "center" }}
                  >
                    {attendance.date.slice(0, 10)}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ textAlign: "center" }}
                  >
                    {attendance.Fullname}
                  </TableCell>
                  <Tooltip
                    arrow
                    title={
                      "Shift start @ " +
                      convertMinutesToTime(attendance.ShiftStartTime || 540)
                    }
                  >
                    <TableCell
                      sx={{
                        color:
                          attendance.Starttime &&
                          new Date(attendance.Starttime).getHours() * 60 +
                            new Date(attendance.Starttime).getMinutes() >
                            JSON.parse(localStorage.getItem("loggedInUser"))
                              .shiftStart
                            ? "red"
                            : "green",
                      }}
                    >
                      {" "}
                      {new Date(attendance.Starttime).toLocaleTimeString()}
                    </TableCell>
                  </Tooltip>
                  <TableCell>{attendance.Latereason}</TableCell>
                  <TableCell
                    onClick={() =>
                      mapLink(
                        attendance.Startlatitude,
                        attendance.Startlongitude
                      )
                    }
                  >
                    <Button>
                      <MapSharpIcon />
                      {attendance.Starttime &&
                        calculateDistance(
                          attendance.Startlatitude,
                          attendance.Startlongitude
                        )}{" "}
                    </Button>
                  </TableCell>
                  <Tooltip
                    arrow
                    title={
                      "Shift End @ " +
                      convertMinutesToTime(attendance.ShiftEndTime || 1080)
                    }
                  >
                    <TableCell
                      sx={{
                        color:
                          (attendance &&
                            attendance.Reason === "Auto logout by System") ||
                          new Date(attendance.Stoptime).getHours() * 60 +
                            new Date(attendance.Stoptime).getMinutes() <=
                            JSON.parse(localStorage.getItem("loggedInUser"))
                              .shiftEnd
                            ? "red"
                            : "green",
                      }}
                    >
                      {attendance.Stoptime
                        ? new Date(attendance.Stoptime).toLocaleTimeString()
                        : ""}
                    </TableCell>
                  </Tooltip>
                  <TableCell>{attendance.Reason}</TableCell>
                  <TableCell
                    onClick={() =>
                      mapLink(attendance.Stoplatitude, attendance.Stoplongitude)
                    }
                  >
                    {attendance.Stoptime ? (
                      <Button>
                        <MapSharpIcon />
                        {attendance.Stoptime &&
                          calculateDistance(
                            attendance.Stoplatitude,
                            attendance.Stoplongitude
                          )}
                      </Button>
                    ) : (
                      ""
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default AttendanceGrid;
