import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import "../Styles/widget.css";
import { Tooltip } from "@mui/material";

function Widget(props) {
  var history = useHistory();
  function currentTime() {
    var display = new Date().toLocaleTimeString();
    if (document.getElementById("time_lbl"))
      document.getElementById("time_lbl").innerHTML = display;
    setTimeout(currentTime, 1000);
  }
  return (
    // <div>
    //   <div
    //     className="namecard"
    //     style={{
    //       textAlign: "center",
    //       height: "270.237px",
    //       verticalAlign: "middle",
    //       borderTopLeftRadius: "15px",
    //       borderBottomLeftRadius: "15px",
    //     }}
    //   >
    //     <div>
    //       <img
    //         src={props.img}
    //         style={{
    //           borderRadius: "50%",
    //           width: "80px",
    //           marginTop: "10px",
    //         }}
    //         alt="profile pic"
    //         onClick={(e) => history.push("/profile", props.name)}
    //       />
    //     </div>
    //     <div
    //       style={{
    //         color: "white",
    //         fontWeight: 700,
    //         fontSize: "22px",
    //       }}
    //       onClick={(e) => history.push("/profile")}
    //     >
    //       {props.name}
    //     </div>
    //     <div
    //       style={{
    //         marginTop: "20px",
    //       }}
    //     >
    //       <div style={{ textAlign: "center" }} id="clock" className="heading">
    //         {currentTime()}
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div id="Profile_grp_with_time">
      <svg class="profile_bdr_2_t">
        <linearGradient
          id="profile_bdr_2_t"
          spreadMethod="pad"
          x1="0.5"
          x2="0.5"
          y1="0"
          y2="1"
        >
          <stop offset="0" stop-color="#b5ffb7" stop-opacity="1"></stop>
          <stop offset="1" stop-color="#fbf9f4" stop-opacity="1"></stop>
        </linearGradient>
        <rect
          id="profile_bdr_2_t"
          rx="13"
          ry="13"
          x="0"
          y="0"
          width="141"
          height="147"
        ></rect>
      </svg>
      <svg class="profile_bdr_in">
        <rect
          id="profile_bdr_in"
          rx="8"
          ry="8"
          x="0"
          y="0"
          width="118"
          height="25"
        ></rect>
      </svg>
      <div id="Ronnie_lb">
        <marquee>{props.name}</marquee>
      </div>
      <svg class="time_bde_out_x">
        <linearGradient
          id="time_bde_out_x"
          spreadMethod="pad"
          x1="0.03"
          x2="1"
          y1="1"
          y2="0.132"
        >
          <stop offset="0" stop-color="#b5ffb7" stop-opacity="1"></stop>
          <stop offset="1" stop-color="#f8f8f8" stop-opacity="1"></stop>
        </linearGradient>
        <rect
          id="time_bde_out_x"
          rx="13"
          ry="13"
          x="0"
          y="0"
          width="141"
          height="33"
        ></rect>
      </svg>
      <svg class="time_bdr_in">
        <rect
          id="time_bdr_in"
          rx="8"
          ry="8"
          x="0"
          y="0"
          width="100"
          height="18"
        ></rect>
      </svg>
      <div id="time_lbl">
        <span> {currentTime()}</span>
      </div>
      <Tooltip title="profile">
        <img
          id="profile_img"
          src={props.img}
          alt=""
          onClick={(e) => history.push("/profile", props.name)}
        />
      </Tooltip>
    </div>
  );
}

export default Widget;
