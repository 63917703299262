import React from "react";
import "../../Styles/taskfiltermob.css";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { UserContext } from "../../UserContext";

function TasksFilterMob(props) {
  const { user, setUser } = useContext(UserContext);
  //variable
  var accesstypes = parseInt(user.accesstype);
  //useState
  const [fromValue, setFromValue] = useState(new Date());
  const [toValue, setToValue] = useState(new Date());
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [disabled, setDisabled] = useState(true);
  const [data, setData] = useState([]);
  const [empid, setEmpid] = useState(props.employee);
  // useEffect;
  useEffect(() => {
    GetEmployees();
  }, []);
  //functions
  function GetEmployees() {
    let empid = user._id;
    const apiurl =
      process.env.REACT_APP_API_URL +
      "/api/user/employeesdropdown?empid=" +
      empid;
    const apiparam = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };

    fetch(apiurl, apiparam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setData([{ _id: "all", name: "All Team Members" }, ...repos.data]);
          setDisabled(false);
        } else console.log(repos.message || repos);
      })
      .catch((err) => console.log(err));
  }

  return (
    <div>
      <div id="Group_38">
        <svg class="full_bg_design_holi_u">
          <linearGradient
            id="full_bg_design_holi_u"
            spreadMethod="pad"
            x1="0.5"
            x2="0.5"
            y1="0"
            y2="1"
          >
            <stop offset="0" stop-color="#b5ffb7" stop-opacity="1"></stop>
            <stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
          </linearGradient>
          <rect
            id="full_bg_design_holi_u"
            rx="11"
            ry="11"
            x="0"
            y="0"
            width="299"
            height="541"
          ></rect>
        </svg>
        <div id="Group_37">
          {accesstypes > 0 ? (
            <select
              value={props.employee || user._id}
              class="employee_bdr_input_holi"
              onChange={(e) => {
                props.changeEmployee(e.target.value);
              }}
            >
              {data.map((employee) => {
                return <option value={employee._id}>{employee.name}</option>;
              })}
            </select>
          ) : (
            ""
          )}
          <div id="Employee_lbl_holi">
            <span>Employee</span>
          </div>
          <select
            type="date"
            class="to_bdr_input_holi"
            value={props.taskStatus}
            label="Task Status"
            onChange={(e) => {
              props.changeTaskStatus(e.target.value);
            }}
            disabled={disabled}
          >
            <option value="all">All Tasks</option>
            <option value="open">Open</option>
            <option value="breached">Breached Deadline</option>
            <option value="waiting">Waiting Closure Confirmation</option>
            <option value="closed">Closed</option>
          </select>
          <div id="To_lbl_holi">
            <span>Task Status</span>
          </div>
        </div>
        <div id="Group_22_mob">
          <svg class="Ellipse_4">
            <ellipse
              id="Ellipse_4"
              rx="22.5"
              ry="22.5"
              cx="22.5"
              cy="22.5"
            ></ellipse>
          </svg>
          <button
            onClick={() => {
              props.onFilterClick();
              props.closeModal();
            }}
          >
            <img
              id="search1"
              src="search1.png"
              srcset="search1.png 1x"
              alt=""
            />
          </button>
        </div>
      </div>
    </div>
  );
}

export default TasksFilterMob;
