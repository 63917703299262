import React, { useContext, useState } from "react";
import "../../Styles/holidaysmob.css";
import { UserContext } from "../../UserContext";
function HolidaysPanelMob() {
  const [holiday, setHoliday] = useState(new Date());
  const [occation, setOccation] = useState("");
  const { user, setUser } = useContext(UserContext);

  const addHoliday = () => {
    if (user.accesstype != "5") {
      alert("Only Admins can add an holiday");
      return;
    }
    const apiURL = process.env.REACT_APP_API_URL + "/api/holiday";
    const apiParams = {
      method: "POST",
      headers: {
        od: encodeURIComponent(holiday),
        occation: occation,
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };

    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          alert("Holiday Added Successfully");
          setHoliday(new Date());
          setOccation("");
        } else alert("Holiday Could Not be Added");
      });
  };
  return (
    <div>
      <div id="btn_lbl_grp_mob">
        <div id="btn_grp_holi_mob">
          <svg class="bdr_holi_btn_mob">
            <ellipse
              id="bdr_holi_btn_mob"
              rx="17.5"
              ry="17.5"
              cx="17.5"
              cy="17.5"
            ></ellipse>
          </svg>
          <button
            onClick={() => {
              addHoliday();
            }}
          >
            <img
              id="play-buttton_img_holi_mob"
              src="play-buttton_img_holi.png"
              srcset="play-buttton_img_holi.png 1x"
              alt=""
            />
          </button>
        </div>
        <div id="Add_Holiday_holi_mob">
          <span>Add Holiday</span>
        </div>
        <input
          type="date"
          class="from_date_holi_mob"
          value={new Date(holiday).toISOString().split("T")[0]}
          onChange={(e) => setHoliday(e.target.value)}
        />
      </div>
      <textarea
        placeholder="Occation"
        class="occasion_input_holi_mob"
        value={occation}
        onChange={(e) => setOccation(e.target.value)}
      ></textarea>
      <div id="From_holi_lbl">
        <span>Holiday Date</span>
      </div>
      <div id="Occation_holi_lbl">
        <span>Holiday Occation</span>
      </div>
    </div>
  );
}

export default HolidaysPanelMob;
