import React, { useEffect, useContext } from "react";
import { Button } from "@mui/material";
import { UserContext } from "../UserContext";
import { Link, useLocation, useHistory } from "react-router-dom";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Tooltip } from "@material-ui/core";
import DashboardIcon from "@mui/icons-material/Dashboard";

import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { default as Noti } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import NotificationBadge from "react-notification-badge";

import { Effect } from "react-notification-badge";
import "../Styles/attendance.css";
function Menu() {
  const {
    user,
    setUser,
    inAppNotification,
    setInAppNotification,
    notification,
    setNotification,
  } = useContext(UserContext);
  const history = useHistory();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (id) => {
    setAnchorEl(null);
  };

  const notiStatus = async (id) => {
    const apiURL =
      process.env.REACT_APP_API_URL + "/api/notification/status/" + id;
    const apiParams = {
      method: "POST",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
        userid: user._id,
      },
    };

    await fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setInAppNotification(repos.data);
        } else {
          alert("could not update notification Status");
        }
      })
      .catch((err) => alert(JSON.stringify(err)));
  };

  useEffect(() => {
    if (!user) {
      let storedUser = user;
      setUser(storedUser);
    }
  }, []);

  useEffect(() => {}, [inAppNotification]);

  //function
  function logout() {
    localStorage.clear();
    setUser(null);
    window.location.href = "/";
  }
  const handleMessageClick = () => {
    history.push("/chats");
  };
  return (
    <div>
      {location.pathname == "/home" && (
        <h1
          style={{
            float: "left",
            fontSize: "32px",
            margin: 0,
            padding: "8px",
            lineHeight: 1,
            fontWeight: 700,
            letterSpacing: "0.5px",
            left: "-56px",
            position: "absolute",
            border: "1px solid white",
            backgroundColor: "white",
            borderRadius: "50px",
            top: "-5px",
          }}
        >
          <img
            src="home_logo.jpg"
            alt=""
            style={{ width: "36px", height: "36px", marginRight: "8px" }}
          />
          <font style={{ fontFamily: "Cooper Black", color: "#14c871" }}>
            Operate
          </font>
          &nbsp;
          <font
            color="red"
            style={{ fontFamily: "Cooper Black", color: "red" }}
          >
            Live
          </font>
        </h1>
      )}
      <Tooltip title="logout">
        {/* <Button
          variant="outlined"
          color="error"
          size="small"
          onClick={() => logout()}
          style={{ float: "right" }}
        >
          <LogoutIcon color="error" />
        </Button> */}
        <button onClick={() => logout()} style={{ float: "right" }}>
          <img
            id="logout_img"
            src="logout_img.png"
            srcset="logout_img.png 1x, logout_img@2x.png 2x"
            alt=""
          />
        </button>
      </Tooltip>

      <button
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{ float: "right" }}
      >
        <NotificationBadge
          count={
            inAppNotification.filter((item) => item.isread === false).length
          }
          effect={Effect.SCALE}
        />
        <img
          id="notification_bell_img"
          src="notification_bell_img.png"
          srcset="notification_bell_img.png 1x, notification_bell_img@2x.png 2x"
          alt=""
        />
      </button>
      <Noti
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {!inAppNotification.length ? (
          <MenuItem style={{ padding: "0px 10px" }}>
            No new Notifications
          </MenuItem>
        ) : (
          inAppNotification.map((n) =>
            n.isread === false ? (
              <Tooltip title="Mark as Read" placement="bottom-end" arrow>
                <MenuItem onClick={() => notiStatus(n._id)}>
                  {n.sender.name} {n.message}
                </MenuItem>
              </Tooltip>
            ) : (
              ""
            )
          )
        )}
      </Noti>
      {location.pathname !== "/chats" && (
        <button onClick={() => handleMessageClick()} style={{ float: "right" }}>
          <NotificationBadge
            count={notification.length}
            effect={Effect.SCALE}
          />
          <img
            id="msg_menu"
            src="msg_img.png"
            srcset="msg_img.png 1x, msg_img@2x.png 2x"
            alt=""
          />
        </button>
      )}
      {"  "}
      {user && parseInt(user.accesstype) != 0 ? (
        location.pathname !== "/register" &&
        location.pathname !== "/profile" &&
        location.pathname !== "/chats" &&
        location.pathname !== "/dashboard" ? (
          <>
            <Tooltip title="Add New Employee">
              <button
                onClick={() => history.push("/register")}
                style={{ float: "right" }}
              >
                <img
                  id="addemployee_img"
                  src="home_img.png"
                  srcset="home_img.png 1x, home_img@2x.png 2x"
                  alt=""
                />
              </button>
            </Tooltip>
            {/* <Tooltip title="Dashboard">
              <Button
                variant="outlined"
                color="success"
                size="small"
                style={{ float: "right" }}
                onClick={() => history.push("/dashboard")}
              >
                <DashboardIcon color="success" />
              </Button>
            </Tooltip> */}
          </>
        ) : (
          <button
            onClick={() => {
              localStorage.setItem("tab", 0);
              history.push("/");
            }}
          >
            <img
              id="home1"
              src="home1.png"
              srcset="home1.png 1x, home1@2x.png 2x"
              alt=""
            />
          </button>
        )
      ) : location.pathname === "/profile" || location.pathname === "/chats" ? (
        <button onClick={() => history.push("/")}>
          <img
            id="home1"
            src="home1.png"
            srcset="home1.png 1x, home1@2x.png 2x"
            alt=""
          />
        </button>
      ) : (
        ""
      )}
    </div>
  );
}

export default Menu;
