import React from "react";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../UserContext";
import { useState } from "react";
import { useEffect } from "react";
import TopNav from "./TopNav";
import { Box, Button, Modal, Tooltip, Typography } from "@mui/material";
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: process.env.REACT_APP_SECONDARY_COLOR,
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
function ProfileMob() {
  const { user, setUser } = useContext(UserContext);
  const history = useHistory();
  const [uniqueId, setUniqueId] = useState("");
  const [dateOfJoining, setDateOfJoining] = useState(new Date());
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [userdob, setUserdob] = useState(new Date().toISOString().slice(0, 10));
  const [userGender, setUserGender] = useState("");
  const [userAccesstype, setUserAccessType] = useState("");
  const [userDesignation, setUserDesignation] = useState("");
  const [userConfirmpassword, setUserConfirmPassword] = useState("");
  const [userPAN, setUserPAN] = useState("");
  const [userAadhar, setUserAadhar] = useState("");
  const [userPassport, setUserPassport] = useState("");
  const [userPassportExpiry, setUserPassportExpiry] = useState(new Date());
  const [userEmergencyContact, setUserEmergencyContact] = useState("");
  const [userEmergencyNo, setUserEmergencyNo] = useState("");
  const [userBankAccount, setUserBankAccount] = useState("");
  // const [userPhoto, setUserPhoto] = useState();
  const [data, setData] = useState([]);
  const [profileDetails, setProfileDetails] = useState();
  const [employeeId, setEmployeeId] = useState();
  const [reportingToId, setReportingToId] = useState("");
  const [reportingToName, setReportingToName] = useState();
  const [open, setOpen] = React.useState(false);
  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setnewPassword] = useState();
  const [img, setImg] = useState();
  const [shiftStartHours, setShiftStartHours] = useState(0);
  const [shiftStartMinutes, setShiftStartMinutes] = useState(0);
  const [shiftStopHours, setShiftStopHours] = useState(0);
  const [shiftStopMinutes, setShiftStopMinutes] = useState(0);
  const [profileId, setProfileId] = useState("");
  const [active, setActive] = useState(false);
  const [upiId, setUpiId] = useState("");
  const [tab, setTab] = useState(0);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function handleFileSelect(f) {
    var reader = new FileReader();
    // Closure to capture the file information.
    reader.onload = (function (theFile) {
      return function (e) {
        var binaryData = e.target.result;
        //Converting Binary Data to base 64
        var base64String = window.btoa(binaryData);
        //showing file converted to base64
        setImg("data:image/jpeg;base64," + base64String);
      };
    })(f);
    // Read in the image file as a data URL.
    reader.readAsBinaryString(f);
  }

  const handleImageUpload = async (e) => {
    if (e.target.files[0].size > 71680) {
      alert("Please choose a file with size below 70kb!");
      return;
    }
    handleFileSelect(e.target.files[0]);
    let formdata = new FormData();
    formdata.append("image", e.target.files[0], e.target.value);
    updateProfilePic(formdata);
  };
  const updateProfilePic = (formdata) => {
    const apiurl = process.env.REACT_APP_API_URL + "/api/user/updateprofilepic";
    var apiparam = {
      method: "POST",
      headers: {
        id: employeeId,
        authtoken: localStorage.getItem("loggedintoken"),
      },
      body: formdata,
    };

    fetch(apiurl, apiparam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };

  const updateProfile = () => {
    let shiftStart = Number(shiftStartHours) + Number(shiftStartMinutes);
    let shiftEnd = Number(shiftStopHours) + Number(shiftStopMinutes);
    const apiurl = process.env.REACT_APP_API_URL + "/api/user/" + profileId;

    var apiparam = {
      method: "POST",
      headers: {
        uid: uniqueId,
        doj: dateOfJoining,
        name: userName,
        email: userEmail,
        phone: userPhone,
        address: encodeURIComponent(userAddress),
        shiftStart: shiftStart,
        shiftEnd: shiftEnd,
        dob: userdob,
        gender: userGender,
        reportingtoid: reportingToId,
        reportingtoname: reportingToName,
        accesstype: userAccesstype,
        designation: userDesignation,
        pannumber: userPAN,
        aadharnumber: userAadhar,
        passportnumber: userPassport,
        passportexpiry: userPassportExpiry,
        emergencycontactname: userEmergencyContact,
        emergencycontactnumber: userEmergencyNo,
        bankaccountedtails: encodeURIComponent(userBankAccount),
        upiid: upiId,
        active: active,
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };
    fetch(apiurl, apiparam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          alert("Profile Updated");
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };

  const getProfileDetails = (id) => {
    const apiUrl = process.env.REACT_APP_API_URL + "/api/user/" + id;
    fetch(apiUrl, {
      headers: { authtoken: localStorage.getItem("loggedintoken") },
    })
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          console.log(repos.data);
          function getTime(num) {
            var hours = Math.floor(num / 60);
            var minutes = (num / 60 - hours) * 60;
            return { hours, minutes };
          }

          let start = getTime(repos.data[0].shiftStart);
          let startHourInMins = start.hours * 60;
          let stop = getTime(repos.data[0].shiftEnd);
          let stopHourInMins = stop.hours * 60;
          setUniqueId(repos.data[0].uid || "");
          setDateOfJoining(repos.data[0].doj || new Date());
          setShiftStartHours(startHourInMins);
          setShiftStartMinutes(start.minutes);
          setShiftStopHours(stopHourInMins);
          setShiftStopMinutes(stop.minutes);
          setProfileDetails(repos.data);
          setUserName(repos.data[0].name);
          setUserGender(repos.data[0].gender);
          setUserPhone(repos.data[0].phone);
          setUserAddress(repos.data[0].address);
          setUserdob(repos.data[0].dob);
          setUserEmail(repos.data[0].email);
          setUserDesignation(repos.data[0].designation);

          setUserPAN(repos.data[0].panNumber || "");
          setUserAadhar(repos.data[0].aadharNumber || "");
          setUserPassport(repos.data[0].passportNumber || "");
          setUserPassportExpiry(repos.data[0].passportExpiry || new Date());
          setUserEmergencyContact(repos.data[0].emergencyContactName || "");
          setUserEmergencyNo(repos.data[0].emergencyContactNumber || "");
          setUserBankAccount(repos.data[0].bankAccountDetails || "");
          setImg(arrayBufferToBase64(repos.data[0].image.data.data));
          setReportingToId(repos.data[0].reportingToId);
          setReportingToName(repos.data[0].reportingToName);
          setActive(repos.data[0].active);
          setProfileId(id);
          setUpiId(repos.data[0].upiId);

          setUserAccessType(repos.data[0].accesstype);
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };

  function arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    var base64Flag = "data:image/jpeg;base64,";
    return base64Flag + window.btoa(binary);
  }

  function logout() {
    localStorage.clear();
    setUser(null);
    window.location.href = "/";
  }
  const updatePassword = () => {
    if (newPassword === userConfirmpassword) {
      let apiUrl = process.env.REACT_APP_API_URL + "/api/user/updatepassword";
      let apiParams = {
        method: "POST",
        headers: {
          id: employeeId,
          oldpassword: oldPassword,
          newpassword: newPassword,
          authtoken: localStorage.getItem("loggedintoken"),
        },
      };
      fetch(apiUrl, apiParams)
        .then((res) => (res.status == 200 ? res.json() : res.text()))
        .then((repos) => {
          if (repos.data) {
            alert(repos.message);
            setOpen(false);
            logout();
          } else alert(repos.message || repos);
        })
        .catch((err) => alert(err));
    } else {
      alert("Passwords do not match");
    }
  };
  useEffect(() => {
    let empid = user._id;
    setEmployeeId(empid);
    getProfileDetails(empid);

    const apiurl =
      process.env.REACT_APP_API_URL +
      "/api/user/employeesdropdown?empid=" +
      user._id;
    const apiparam = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };
    fetch(apiurl, apiparam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setData(repos.data);
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  }, []);
  return (
    <div id="add_new_emplyee_off">
      <img id="bg_pic" src="bg_pic.png" srcset="bg_pic.png 1x" alt="" />

      <svg class="bg_deisgn_emp_off">
        <ellipse
          id="bg_deisgn_emp_off"
          rx="231"
          ry="229.5"
          cx="231"
          cy="229.5"
        ></ellipse>
      </svg>
      <TopNav />
      {/* <img
    id="profile_pic_emp_off"
    src="profile_pic_emp_off.png"
    srcset="profile_pic_emp_off.png 1x"
    alt=""
  /> */}
      <Tooltip title="Upload Profile Picture">
        <label for="image">
          <input
            id="image"
            type="file"
            onChange={(e) => handleImageUpload(e)}
            hidden
            accept="image/*"
            style={{ cursor: "pointer" }}
          />
          <img id="profile_pic_emp_off" src={img} alt="" />
        </label>
      </Tooltip>

      <div id="btn_and_labal_all_grp">
        {tab == 1 ? (
          <>
            {" "}
            <svg
              class="tab_design_emp_off"
              viewBox="2201.084 2821.74 378.875 1210.756"
            >
              <path
                id="tab_design_emp_off"
                d="M 2201.1064453125 2890.255126953125 L 2201.1064453125 4032.495849609375 L 2579.203369140625 4032.495849609375 L 2579.203369140625 2913.458740234375 C 2579.203369140625 2913.458740234375 2585.93701171875 2870.940673828125 2559.198486328125 2869.08349609375 C 2547.88818359375 2868.2978515625 2555.28564453125 2868.94873046875 2551.681884765625 2869.08349609375 C 2547.50439453125 2869.237060546875 2547.756103515625 2869.08349609375 2547.756103515625 2869.08349609375 C 2547.756103515625 2869.08349609375 2528.421142578125 2872.5107421875 2527.392822265625 2852.510986328125 C 2526.364501953125 2832.511474609375 2527.392822265625 2836.467041015625 2527.392822265625 2836.467041015625 C 2527.392822265625 2836.467041015625 2527.846435546875 2822.462158203125 2514.8837890625 2821.9619140625 C 2501.921142578125 2821.4619140625 2395.720703125 2821.9619140625 2395.720703125 2821.9619140625 C 2395.720703125 2821.9619140625 2379.29052734375 2819.931396484375 2379.29052734375 2836.467041015625 C 2379.29052734375 2853.00244140625 2379.29052734375 2852.510986328125 2379.29052734375 2852.510986328125 C 2379.29052734375 2852.510986328125 2379.519287109375 2858.73046875 2377.438232421875 2863.923828125 C 2376.3779296875 2866.591552734375 2374.3037109375 2869.075439453125 2370.771240234375 2869.08349609375 C 2368.84521484375 2869.087890625 2212.341552734375 2869.603759765625 2210.901611328125 2870.60791015625 C 2200.134521484375 2867.96240234375 2201.1064453125 2890.255126953125 2201.1064453125 2890.255126953125 Z"
              ></path>
            </svg>
            <div id="Scroll_Group_emo_off">
              <div id="all_input_grp">
                <div id="unique_id_grp_emp_off">
                  <input
                    placeholder="Unique ID"
                    class="unique_input_emp_off"
                    disabled={user.accesstype != 5}
                    value={uniqueId}
                    onChange={(e) => setUniqueId(e.target.value)}
                  />

                  <div id="unque_id_emp_off">
                    <span>Unique ID</span>
                  </div>
                </div>
                <div id="designation_emp_off_grp">
                  <select
                    placeholder="Designation"
                    class="designation_input_emp_off"
                    disabled={user.accesstype != 5}
                    label="Designation"
                    value={userDesignation}
                    onChange={(e) => setUserDesignation(e.target.value)}
                  >
                    <option value="" selected disabled>
                      Designation
                    </option>

                    <option value="Manager">Manager</option>
                    <option value="employee">Employee</option>
                    <option value="client">Client</option>
                  </select>
                  <div id="designation_emp_off">
                    <span>Designation</span>
                  </div>
                </div>
                {user.accesstype == 5 ? (
                  <div id="report_emp_off_grp">
                    <div id="reporting_to_lvl_emp_off">
                      <span>Reporting To</span>
                    </div>
                    <select
                      placeholder="Reporting To"
                      class="reporting_to_input_emp_off"
                      value={reportingToId}
                      onChange={(e) => setReportingToId(e.target.value)}
                      required
                    >
                      <option value="" selected disabled>
                        Reporting to
                      </option>
                      {data.map((employee) => {
                        return (
                          <option value={employee._id}>{employee.name}</option>
                        );
                      })}
                    </select>
                  </div>
                ) : (
                  <div id="report_emp_off_grp">
                    <div id="reporting_to_lvl_emp_off">
                      <span>Reporting To</span>
                    </div>{" "}
                    <input
                      placeholder="Reporting to"
                      class="reporting_to_input_emp_off"
                      value={reportingToName}
                      InputLabelProps={{ shrink: true }}
                      disabled
                    />
                  </div>
                )}
                <div id="access_type_emp_off">
                  <select
                    placeholder="Access Type"
                    class="access_type_input"
                    disabled={user.accesstype != 5}
                    value={userAccesstype}
                    onChange={(e) => setUserAccessType(e.target.value)}
                  >
                    <option value="" selected disabled>
                      Access Type
                    </option>

                    <option value="1">Manager</option>
                    <option value="0">Employee</option>
                    <option value="5">Admin</option>
                  </select>
                  <div id="DOB_profile_emp_off">
                    <span>Access Type</span>
                  </div>
                </div>
                {user._id != profileId && user.accesstype != 5 ? (
                  ""
                ) : (
                  <div id="BAD_grp_emp_off">
                    <textarea
                      placeholder="Bank Account Detail"
                      class="BAD_input_emp_off"
                      minRows={3}
                      value={userBankAccount}
                      onChange={(e) => setUserBankAccount(e.target.value)}
                    ></textarea>
                    <div id="BAD_emp_off">
                      <span>Bank Account Details</span>
                    </div>
                  </div>
                )}
                <div id="UPI_ID_grp_emp_off">
                  <input
                    placeholder="UPI ID(GPAY or PhonePe)"
                    value={upiId}
                    onChange={(e) => setUpiId(e.target.value)}
                    class="aadhar_bdr_emp_off"
                  />

                  <div id="UPI_id_lbl_emp_off">
                    <span>UPI ID</span>
                  </div>
                </div>
                <div id="DOJ_grp_emp_off">
                  <div id="DOJ_lbl_emp_off">
                    <span>Date of Joining</span>
                  </div>
                  <input
                    type="date"
                    class="DOJ_input"
                    value={new Date(dateOfJoining).toISOString().split("T")[0]}
                    disabled={user.accesstype != 5}
                    onChange={(e) => {
                      setDateOfJoining(e.target.value);
                    }}
                  />
                </div>
                <div id="stop_ht_emp_off">
                  <div id="stop_hr_lbl">
                    <span>Stop Hr</span>
                  </div>

                  <select
                    class="stop_ht_input"
                    value={shiftStopHours}
                    label="Shift Stop hours"
                    onChange={(e) => setShiftStopHours(e.target.value)}
                  >
                    <option value={0}>00</option>
                    <option value={60}>01</option>
                    <option value={120}>02</option>
                    <option value={180}>03</option>
                    <option value={240}>04</option>
                    <option value={300}>05</option>
                    <option value={360}>06</option>
                    <option value={420}>07</option>
                    <option value={480}>08</option>
                    <option value={540}>09</option>
                    <option value={600}>10</option>
                    <option value={660}>11</option>
                    <option value={720}>12</option>
                    <option value={780}>13</option>
                    <option value={840}>14</option>
                    <option value={900}>15</option>
                    <option value={960}>16</option>
                    <option value={1020}>17</option>
                    <option value={1080}>18</option>
                    <option value={1140}>19</option>
                    <option value={1200}>20</option>
                    <option value={1260}>21</option>
                    <option value={1320}>22</option>
                    <option value={1380}>23</option>
                  </select>
                </div>
                <div id="Passport_no_emp_off">
                  <div id="pass_number_lbl">
                    <span>Passport Number</span>
                  </div>
                  <input
                    placeholder="Passport Number"
                    class="passport_no_emp_off"
                    value={userPassport}
                    onChange={(e) => setUserPassport(e.target.value)}
                    disabled={user._id != profileId && user.accesstype != 5}
                  />
                </div>
                <div id="start_hr_emp_off_grp">
                  <div id="start_hr_lbl">
                    <span>Start Hr</span>
                  </div>
                  <select
                    class="start_hr_emp_off_bdr"
                    label="Shift Start hour"
                    disabled={user._id == profileId && user.accesstype != 5}
                    value={parseInt(shiftStartHours)}
                    onChange={(e) => {
                      setShiftStartHours(e.target.value);
                    }}
                  >
                    <option value={0}>00</option>
                    <option value={60}>01</option>
                    <option value={120}>02</option>
                    <option value={180}>03</option>
                    <option value={240}>04</option>
                    <option value={300}>05</option>
                    <option value={360}>06</option>
                    <option value={420}>07</option>
                    <option value={480}>08</option>
                    <option value={540}>09</option>
                    <option value={600}>10</option>
                    <option value={660}>11</option>
                    <option value={720}>12</option>
                    <option value={780}>13</option>
                    <option value={840}>14</option>
                    <option value={900}>15</option>
                    <option value={960}>16</option>
                    <option value={1020}>17</option>
                    <option value={1080}>18</option>
                    <option value={1140}>19</option>
                    <option value={1200}>20</option>
                    <option value={1260}>21</option>
                    <option value={1320}>22</option>
                    <option value={1380}>23</option>
                  </select>
                </div>
                <div id="Start_min_emp_off">
                  <select
                    class="start_min_input_emp_off"
                    disabled={user._id == profileId && user.accesstype != 5}
                    value={parseInt(shiftStartMinutes)}
                    label="Shift Start minutes"
                    onChange={(e) => setShiftStartMinutes(e.target.value)}
                  >
                    <option value={0}>00</option>
                    <option value={15}>15</option>
                    <option value={30}>30</option>
                    <option value={45}>45</option>
                  </select>

                  <div id="start_min_emp_off">
                    <span>Start Min</span>
                  </div>
                </div>
                <div id="Stop_min_emp_off">
                  <select
                    class="stop_min_input_emp_off"
                    disabled={user._id == profileId && user.accesstype != 5}
                    value={shiftStopMinutes}
                    label="Shift Stop minutes"
                    onChange={(e) => setShiftStopMinutes(e.target.value)}
                  >
                    <option value={0}>00</option>
                    <option value={15}>15</option>
                    <option value={30}>30</option>
                    <option value={45}>45</option>
                  </select>
                  <div id="stop_min_emp_off">
                    <span>Stop Min</span>
                  </div>
                </div>
                <div id="PED_grp_emp_off">
                  <div id="PED_emp_off">
                    <span>Passport Expiry Date</span>
                  </div>
                  <input
                    placeholder="Passport Expiry Date"
                    class="dob_bdr_emp_off"
                    disabled={user._id != profileId && user.accesstype != 5}
                    value={
                      new Date(userPassportExpiry).toISOString().split("T")[0]
                    }
                    onChange={(newValue) => {
                      setUserPassportExpiry(newValue);
                    }}
                  />
                </div>
                {/* <svg class="swtch_emp_off">
              <rect
                id="swtch_emp_off"
                rx="0"
                ry="0"
                x="0"
                y="0"
                width="47"
                height="20"
              ></rect>
            </svg> */}
                <input
                  class="swtch_emp_off"
                  type="checkbox"
                  disabled={user._id == profileId && user.accesstype != 5}
                  onChange={(e) => setActive(e.target.checked)}
                  checked={active}
                />
                <div id="active_emp_off">
                  <span>Active</span>
                </div>
                <div id="aadhar_lvl_emp_off">
                  <span>Shift Timings</span>
                </div>
                <div id="pan_number_grp">
                  <input
                    placeholder="PAN No"
                    class="pan_bdr"
                    value={userPAN}
                    onChange={(e) => setUserPAN(e.target.value)}
                    required
                    disabled={user.accesstype != 5}
                  />

                  <div id="Pan_lbl">
                    <span>PAN No</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="update_btn_rgrp"
              onClick={() => {
                updateProfile();
              }}
            >
              <svg class="update_btn_emp_off">
                <rect
                  id="update_btn_emp_off"
                  rx="9"
                  ry="9"
                  x="0"
                  y="0"
                  width="132"
                  height="50"
                ></rect>
              </svg>
              <div id="Update_emp_off">
                <span>Update</span>
              </div>
            </div>
            <div id="change_pass_btn_grp">
              <svg class="chang_pass_emp_off">
                <rect
                  id="chang_pass_emp_off"
                  rx="9"
                  ry="9"
                  x="0"
                  y="0"
                  width="132"
                  height="50"
                ></rect>
              </svg>
              <div id="Change_Password_emp_off" onClick={handleOpen}>
                <span>Change Password</span>
              </div>
            </div>
            {/* <div
              id="register_btn_rgrp"
              onClick={() => {
                redirected();
              }}
            >
              <svg class="update_btn_emp_off">
                <rect
                  id="update_btn_emp_off"
                  rx="9"
                  ry="9"
                  x="0"
                  y="0"
                  width="132"
                  height="50"
                ></rect>
              </svg>
              <div id="Update_emp_off">
                <span>Register</span>
              </div>
            </div> */}
          </>
        ) : (
          <>
            <svg
              class="design_tabs"
              viewBox="2201.998 2821.74 377.205 1035.085"
            >
              <path
                id="design_tabs"
                d="M 2202 2889.790771484375 L 2202 3856.82470703125 L 2579.203369140625 3856.82470703125 L 2579.203369140625 2912.56884765625 C 2579.203369140625 2912.56884765625 2578.93701171875 2879.73681640625 2552.198486328125 2877.805419921875 C 2525.4599609375 2875.874267578125 2387.756103515625 2877.805419921875 2387.756103515625 2877.805419921875 C 2387.756103515625 2877.805419921875 2368.421142578125 2881.36962890625 2367.392822265625 2860.57080078125 C 2366.364501953125 2839.7724609375 2367.392822265625 2837.05517578125 2367.392822265625 2837.05517578125 C 2367.392822265625 2837.05517578125 2367.846435546875 2822.490966796875 2354.8837890625 2821.970703125 C 2341.921142578125 2821.45068359375 2235.720703125 2821.970703125 2235.720703125 2821.970703125 C 2235.720703125 2821.970703125 2219.29052734375 2819.859130859375 2219.29052734375 2837.05517578125 C 2219.29052734375 2854.251220703125 2219.29052734375 2860.57080078125 2219.29052734375 2860.57080078125 C 2219.29052734375 2860.57080078125 2219.899169921875 2877.782958984375 2210.771240234375 2877.805419921875 C 2201.643310546875 2877.82763671875 2202 2889.790771484375 2202 2889.790771484375 Z"
              ></path>
            </svg>
            <div id="Scroll_Group_emp_per">
              <div id="all_input_grp_with_btn">
                <div id="name_grp_input">
                  <select
                    placeholder="Employees"
                    class="name_bd"
                    value={profileId}
                    onChange={(e) => {
                      getProfileDetails(e.target.value);
                    }}
                  >
                    {data.map((employee) => {
                      return (
                        <option value={employee._id}>{employee.name}</option>
                      );
                    })}
                  </select>

                  <div id="name_lbl_input">
                    <span>Name</span>
                  </div>
                </div>
                <div id="mobile_number_grp">
                  <input
                    placeholder="Mobile Number"
                    class="mob_no_bdr"
                    value={userPhone}
                    onChange={(e) => setUserPhone(e.target.value)}
                    required
                    disabled={user._id != profileId && user.accesstype != 5}
                  />

                  <div id="mob_number_grp">
                    <span>Mobile Number</span>
                  </div>
                </div>
                <div id="email_id_grp">
                  <input
                    placeholder="Email ID"
                    class="email_input_"
                    disabled={user.accesstype != 5}
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                  />

                  <div id="Email_profile_lbl">
                    <span>Email ID</span>
                  </div>
                </div>
                <div id="Gender_grp_input">
                  <div id="Gender_profile_name">
                    <span>Gender</span>
                  </div>
                  <select
                    class="gen_bdr_input"
                    value={userGender}
                    disabled={user.accesstype != 5}
                    onChange={(e) => setUserGender(e.target.value)}
                    required
                    selected
                  >
                    <option value="" selected disabled>
                      Gender
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                <div id="DOB_grp_">
                  <input
                    type="date"
                    placeholder="DOB"
                    class="dob_bdr_input"
                    disabled={user.accesstype != 5}
                    value={userdob}
                    onChange={(e) => {
                      const fromDateTimestamp = Date.parse(e.target.value);

                      if (isNaN(fromDateTimestamp)) {
                        // handle invalid date input
                        return;
                      }
                      setUserdob(e.target.value);
                    }}
                  />

                  <div id="DOB_profile_lbl">
                    <span>DOB</span>
                  </div>
                </div>
                <div id="Aadhar_number_grp">
                  <input
                    placeholder="Aadhaar no"
                    class="aadhar_bdr"
                    value={userAadhar}
                    onChange={(e) => setUserAadhar(e.target.value)}
                    required
                    disabled={user.accesstype != 5}
                  />

                  <div id="aadhar_lvl_lbl">
                    <span>Aadhaar no</span>
                  </div>
                </div>
                <div id="Address_grp">
                  <textarea
                    placeholder="Address"
                    class="address_bdr_"
                    value={userAddress}
                    onChange={(e) => setUserAddress(e.target.value)}
                    required
                    disabled={user._id != profileId && user.accesstype != 5}
                  ></textarea>
                  <div id="address_lbl_port">
                    <span>Address</span>
                  </div>
                </div>
                <div id="ECP_Grp">
                  <input
                    placeholder="Emergency Contact Person"
                    class="ECG_bdr"
                    value={userEmergencyContact}
                    onChange={(e) => setUserEmergencyContact(e.target.value)}
                    required
                    disabled={user._id != profileId && user.accesstype != 5}
                  />

                  <div id="ECG_lbl">
                    <span>Emergency Contact Person</span>
                  </div>
                </div>
                <div id="ECN_grp">
                  <input
                    placeholder="Emergency Contact Number"
                    class="ECN_bdr"
                    value={userEmergencyNo}
                    onChange={(e) => setUserEmergencyNo(e.target.value)}
                    required
                    disabled={user._id != profileId && user.accesstype != 5}
                  />

                  <div id="ECN_lbl">
                    <span>Emergency Contact Number</span>
                  </div>
                </div>
              </div>
            </div>
            <svg class="btn_next_page">
              <rect
                id="btn_next_page"
                rx="9"
                ry="9"
                x="0"
                y="0"
                width="132"
                height="50"
              ></rect>
            </svg>
            <div id="Next_Page_lbl_btn" onClick={() => setTab(1)}>
              <span>Next</span>
            </div>
          </>
        )}
      </div>
      <div id="top_tab_names_">
        <div id="personal_lbl" onClick={() => setTab(0)}>
          <span>Personal</span>
        </div>
        <div id="Office_info_lvl" onClick={() => setTab(1)}>
          <span>Office Info</span>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: "center" }}
          >
            Change Password
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div class="row g-3">
              <div class="col">
                <input
                  type="password"
                  class="form-control"
                  placeholder="Old Password"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  required
                  autocomplete="new-password"
                />
              </div>
            </div>
            <div class="row g-3">
              <div class="col">
                <input
                  type="password"
                  class="form-control"
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setnewPassword(e.target.value)}
                  required
                  autocomplete="new-password"
                />
              </div>
            </div>
            <div class="row g-3">
              <div class="col">
                <input
                  type="password"
                  class="form-control"
                  placeholder="Confirm Password"
                  value={userConfirmpassword}
                  onChange={(e) => setUserConfirmPassword(e.target.value)}
                  required
                  autocomplete="new-password"
                />
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  updatePassword();
                }}
              >
                Update
              </Button>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}

export default ProfileMob;
