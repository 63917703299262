import React, { useState, useEffect, useContext } from "react";
import TextField from "@mui/material/TextField";
import { UserContext } from "../UserContext";
import { nanoid } from "nanoid";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import "../Styles/salary.css";
import PDFGenerator from "./PDFGenerator";

function PayrollDetails() {
  const [salaryComponents, setSalaryComponents] = useState([]);
  const [formDatas, setFormDatas] = useState([]);
  const [grossSalary, setGrossSalary] = useState(0);
  const [totalDeduction, setTotalDeduction] = useState(0);
  const [netSalary, setNetSalary] = useState(0);
  const [editedRowId, setEditedRowId] = useState(null);
  const [oldFormData, setOldFormData] = useState([]);
  const { user, setUser } = useContext(UserContext);
  const [selectedEmp, setSelectedEmp] = useState(user._id);
  const [data, setData] = useState([]);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const getSalaryComponents = () => {
    const apiURL =
      process.env.REACT_APP_API_URL + "/api/payroll/getsalarycomponents";
    const apiParams = {
      method: "POST",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        setSalaryComponents(repos.data);
      });
  };

  useEffect(() => {
    getSalaryComponents();
    getEmployees();
  }, []);

  const handleAddRow = () => {
    let newRow = {
      _id: nanoid(),
      component: "",
      isPercentage: false,
      amount: 0,
      parentComponent: "",
      frequency: "",
      isDeduction: false,
      isCompulsory: false,
      finalAmount: 0,
    };
    setFormDatas([...formDatas, newRow]);
    setEditedRowId(newRow._id);
  };

  const handleDeleteRow = (_id) => {
    setFormDatas((prevFormDatas) =>
      prevFormDatas.filter((formData) => formData._id !== _id)
    );
  };

  const handleInputChange = (_id, field, value) => {
    const componentExists = formDatas.some(
      (obj) => obj.component == value && field != "parentComponent"
    );
    if (componentExists) {
      alert("This Salary component already exists");
      return;
    }

    setFormDatas((prevFormDatas) => {
      const updatedFormDatas = prevFormDatas.map((formData) => {
        if (formData._id === _id) {
          if (field == "component") {
            let newcomponent = salaryComponents.find(
              (obj) => obj.componentName == value
            );
            if (
              !prevFormDatas.some(
                (obj) => obj.component == newcomponent.subComponent
              )
            ) {
              alert("This Parent component does not exist");
              return {
                ...formData,
              };
            }
            return {
              ...formData,
              [field]: value,
              parentComponent: newcomponent.subComponent,
              isPercentage: newcomponent.isPercentage,
              isDeduction: newcomponent.isDeduction,
            };
          }
          return {
            ...formData,
            [field]: value,
          };
        }

        return formData;
      });

      return updatedFormDatas;
    });

    setFormDatas((prevFormDatas) => {
      let newFormDatas = prevFormDatas.map((formdata) => {
        if (
          formdata.isPercentage == true &&
          formdata.parentComponent != "grossSalary" &&
          formdata.parentComponent != "netSalary"
        ) {
          let finalAmount =
            (Number(formdata.amount) / 100) *
            formDatas.find((obj) => obj.component == formdata.parentComponent)
              .finalAmount;
          return {
            ...formdata,
            finalAmount: Number(finalAmount),
          };
        } else {
          return {
            ...formdata,
            finalAmount: Number(formdata.amount),
          };
        }
      });
      return newFormDatas;
    });
  };

  const handleCheckboxChange = (_id, field, checked) => {
    setFormDatas((prevData) => {
      const updatedFormDatas = prevData.map((formData) => {
        if (formData._id === _id) {
          return {
            ...formData,
            [field]: checked,
          };
        }
        return formData;
      });

      return updatedFormDatas;
    });
  };
  function getEmployees() {
    let empid = user._id;
    const apiurl =
      process.env.REACT_APP_API_URL +
      "/api/user/employeesdropdown?empid=" +
      empid;
    const apiparam = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };

    fetch(apiurl, apiparam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          if (user.accesstype == "5") {
            setData(repos.data);
          } else {
            // setData(repos.data.filter((employee) => employee._id == user._id));
            setData(repos.data);
          }
        } else console.log(repos.message || repos);
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    let grossSal = 0;
    let deductions = 0;
    let netSalary = 0;
    formDatas.map((formdata) => {
      if (formdata.isDeduction == false) {
        grossSal += Number(formdata.finalAmount);
        setGrossSalary(grossSal);
      } else {
        deductions += Number(formdata.finalAmount);
        setTotalDeduction(deductions);
      }
      setNetSalary(grossSal - deductions);
    });
  }, [formDatas]);

  const handleEditRow = (_id) => {
    console.log(_id);
    setOldFormData([...formDatas]);
    setEditedRowId(_id); // Set the currently edited row
  };
  const handleEditedRowSave = () => {
    setOldFormData([...formDatas]);
    setEditedRowId(null);
    setFormDatas((prevFormDatas) => {
      let newFormDatas = prevFormDatas.map((formdata) => {
        if (
          formdata.isPercentage == true &&
          formdata.parentComponent != "grossSalary" &&
          formdata.parentComponent != "netSalary"
        ) {
          let finalAmount =
            (Number(formdata.amount) / 100) *
            formDatas.find((obj) => obj.component == formdata.parentComponent)
              .finalAmount;
          return {
            ...formdata,
            finalAmount: Number(finalAmount),
          };
        } else {
          return {
            ...formdata,
            finalAmount: Number(formdata.amount),
          };
        }
      });
      return newFormDatas;
    });
  };
  const handleCancelEdit = (_id) => {
    setEditedRowId(null);
    setFormDatas(oldFormData);
  };
  const savePayslip = () => {
    let apiURL = process.env.REACT_APP_API_URL + "/api/payroll/addpayroll";
    let apiParams = {
      method: "POST",
      headers: {
        userid: selectedEmp,
        salarycomponents: JSON.stringify(formDatas),
        lastupdatedby: user._id,
      },
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          console.log(repos.data);
        }
      });
  };
  useEffect(() => {
    let apiURL =
      process.env.REACT_APP_API_URL + "/api/payroll/getpayrollcomponents";
    let apiParams = {
      method: "GET",
      headers: {
        userid: selectedEmp,
      },
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data.length > 0) {
          setFormDatas([...repos.data[0].payroll]);
        } else {
          setFormDatas([]);
        }
      });
  }, [selectedEmp]);

  return (
    <div
      style={{
        background: `url("/over_all_salary_design.png")`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100vh",
      }}
    >
      <h3 style={{ textAlign: "center" }}>Payroll</h3>
      <div style={{ overflowX: "auto", height: "100%", width: "100%" }}>
        <table style={{ border: "2px solid black" }}>
          <thead>
            <tr>
              <th colspan="7">
                {" "}
                <FormControl>
                  <InputLabel id="demo-simple-select-label">
                    Employee
                  </InputLabel>
                  <Select
                    sx={{ minWidth: 160 }}
                    label="Employee"
                    value={Number(user.accesstype) < 1 ? user._id : selectedEmp}
                    onChange={(e) => setSelectedEmp(e.target.value)}
                  >
                    {data.map((employee) => {
                      return (
                        <MenuItem value={employee._id}>
                          {employee.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </th>
              <th colspan="6" style={{ textAlign: "left" }}>
                <Button variant="text" color="success" onClick={handleAddRow}>
                  <AddCircleIcon />
                </Button>
              </th>
            </tr>
            <tr>
              <th>Component</th>
              <th>Is Percentage</th>
              <th>Amount</th>
              <th>Parent-component</th>
              <th>frequency</th>
              <th>Total Amount</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {formDatas &&
              formDatas.map((formData) => (
                <tr key={formData._id}>
                  <td>
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">
                        Salary Component
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        sx={{ minWidth: 180 }}
                        disabled={editedRowId !== formData._id}
                        value={formData.component}
                        onChange={(e) =>
                          handleInputChange(
                            formData._id,
                            "component",
                            e.target.value
                          )
                        }
                      >
                        {salaryComponents &&
                          salaryComponents.map((m) => {
                            return (
                              <MenuItem value={m.componentName}>
                                {m.componentName}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </td>
                  <td>
                    <Checkbox
                      checked={formData.isPercentage}
                      disabled={editedRowId !== formData._id}
                      onChange={(e) =>
                        handleCheckboxChange(
                          formData._id,
                          "isPercentage",
                          e.target.checked
                        )
                      }
                    />
                  </td>
                  <td>
                    <TextField
                      type={"number"}
                      value={formData.amount}
                      onChange={(e) =>
                        handleInputChange(
                          formData._id,
                          "amount",
                          e.target.value
                        )
                      }
                      sx={{ maxWidth: 180, minWidth: 150 }}
                      disabled={editedRowId !== formData._id}
                      label="Amount"
                      id="outlined-start-adornment"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end">
                            {formData.isPercentage ? "%" : "₹"}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </td>
                  <td>
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">
                        Parent-component
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Parent-component"
                        sx={{ minWidth: 180 }}
                        value={formData.parentComponent}
                        onChange={(e) =>
                          handleInputChange(
                            formData._id,
                            "parentComponent",
                            e.target.value
                          )
                        }
                        disabled={
                          !formData.isPercentage || editedRowId !== formData._id
                        }
                      >
                        {salaryComponents &&
                          salaryComponents.map((m) => {
                            return (
                              <MenuItem value={m.componentName}>
                                {m.componentName}
                              </MenuItem>
                            );
                          })}

                        <MenuItem value="grossSalary">Gross Salary</MenuItem>
                        <MenuItem value="netSalary">Net Salary</MenuItem>
                      </Select>
                    </FormControl>
                  </td>
                  <td>
                    <TextField
                      select
                      InputLabelProps={{ shrink: true }}
                      sx={{ width: "15ch" }}
                      label="frequency"
                      value={formData.frequency}
                      disabled={editedRowId !== formData._id}
                      onChange={(e) =>
                        handleInputChange(
                          formData._id,
                          "frequency",
                          e.target.value
                        )
                      }
                    >
                      <MenuItem value="">select</MenuItem>
                      <MenuItem value="monthly">Per Month</MenuItem>
                    </TextField>
                  </td>
                  <td>₹{formData.finalAmount}</td>
                  <td colspan="">
                    {editedRowId !== formData._id ? ( // Render appropriate buttons based on editing status
                      <Button
                        variant="text"
                        color="success"
                        onClick={() => handleEditRow(formData._id)}
                      >
                        <EditIcon />
                      </Button>
                    ) : (
                      <>
                        <Button
                          variant="text"
                          color="success"
                          onClick={() => handleEditedRowSave()}
                        >
                          Save
                        </Button>
                        <Button
                          variant="text"
                          color="success"
                          onClick={() => handleCancelEdit(formData._id)}
                        >
                          cancel
                        </Button>
                      </>
                    )}
                    <Button
                      variant="text"
                      color="error"
                      onClick={() => handleDeleteRow(formData._id)}
                    >
                      <DeleteIcon />
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
          <tr>
            <th colspan="">
              <Grid item style={{ fontWeight: "bolder" }}>
                <Typography variant="div">
                  Gross Salary:₹{grossSalary}
                </Typography>
              </Grid>
            </th>
            <th>
              <Grid item style={{ fontWeight: "bolder" }}>
                <Typography variant="div">
                  Deductions:₹{totalDeduction}
                </Typography>
              </Grid>
            </th>
            <th>
              <Grid item style={{ fontWeight: "bolder" }}>
                <Typography variant="div">Net Salary:₹{netSalary}</Typography>
              </Grid>
            </th>

            <th colspan="" style={{ textAlign: "left" }}>
              <Button
                variant="contained"
                color="success"
                onClick={() => savePayslip()}
              >
                save
              </Button>
            </th>
            <th>
              {formDatas.length > 0 && (
                <PDFGenerator
                  data={formDatas}
                  month={monthNames[new Date().getMonth()]}
                />
              )}
            </th>
          </tr>
        </table>
      </div>
    </div>
  );
}

export default PayrollDetails;
