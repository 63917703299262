import React, { useState, useEffect, useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import { Button } from "@mui/material";
import { UserContext } from "../../UserContext";

function LeavesApprovalGrid(props) {
  //useState
  const { user, setUser } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [opened, setOpened] = useState(false);
  const [approver, setApprover] = useState("");
  const [fromdate, setFromDate] = useState(new Date());
  const [todate, setToDate] = useState(new Date());
  const [typeleave, setTypeLeave] = useState("");
  const [leavereason, setLeaveReason] = useState("");
  const [leaveDays, setLeaveDays] = useState(0);
  const [Checked, setChecked] = useState(false);
  const [disableChecks, setDisableChecks] = useState();
  const [approverRemarks, setApproverRemarks] = useState("");
  const [startFirstHalf, setStartFirstHalf] = useState(true);
  const [startSecondHalf, setStartSecondHalf] = useState(true);
  const [endFirstHalf, setEndFirstHalf] = useState(true);
  const [endSecondHalf, setEndSecondHalf] = useState(true);
  const [leaveId, setLeaveId] = useState();

  //useEffect
  useEffect(() => {
    const date1 = new Date(fromdate);
    const date2 = new Date(todate);
    const datediff = date2 - date1;
    if (datediff < 0) {
      alert("leave days cannot be negative value");
      return;
    }

    if (
      date1.toISOString().split("T")[0] === date2.toISOString().split("T")[0]
    ) {
      setLeaveDays(
        date1.getDay() === 0
          ? 1 - (!startFirstHalf ? 0.5 : 0) - (!startSecondHalf ? 0.5 : 0)
          : 1 - (!startFirstHalf ? 0.5 : 0) - (!startSecondHalf ? 0.5 : 0)
      );
      setDisableChecks(true);
      setEndFirstHalf(true);
      setEndSecondHalf(true);
    } else {
      setDisableChecks(false);
      const datediff = date2 - date1;

      const diffdays = Math.ceil(datediff / (1000 * 60 * 60 * 24)) + 1;

      let sundays = 0;
      for (let i = 0; i < diffdays; i++) {
        let currentday = addDays(fromdate, i);
        if (currentday.getDay() === 0) {
          sundays++;
        }
      }
      setLeaveDays(
        diffdays -
          sundays -
          (!startFirstHalf ? 0.5 : 0) -
          (!startSecondHalf ? 0.5 : 0) -
          (!endFirstHalf ? 0.5 : 0) -
          (!endSecondHalf ? 0.5 : 0)
      );
    }
  }, [
    fromdate,
    todate,
    startFirstHalf,
    startSecondHalf,
    endFirstHalf,
    endSecondHalf,
  ]);

  //functions
  const handleClosed = () => {
    setOpened(false);
  };
  function addDays(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  const handlereset = () => {
    setFromDate(new Date());
    setToDate(new Date());
    setTypeLeave("");
    setLeaveDays(0);
    setLeaveReason("");
  };
  const updateLeavesStatus = (status) => {
    const apiurl =
      process.env.REACT_APP_API_URL + "/api/leavesapproval/updateleavesstatus";
    const apiparam = {
      method: "POST",
      headers: {
        id: leaveId,
        approverRemarks: approverRemarks,
        status: status,
        userid: user._id,
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };
    fetch(apiurl, apiparam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          alert("Submitted successfully");
          props.refreshGrid();
        } else alert(repos.message || repos);
      });
    handleClose();
  };
  const handleApprovers = (e) => {
    setApproverRemarks(e.target.value);
  };

  function onFirstCheckClick(e) {
    setChecked(!Checked);
    const totaldays = e.target.checked ? leaveDays + 0.5 : leaveDays - 0.5;
    setLeaveDays(totaldays);
  }
  const handleClickOpen = (appliedById) => {
    let empid = appliedById;

    let apiUrl =
      process.env.REACT_APP_API_URL + "/api/user/register?empid=" + empid;
    const apiParam = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };

    fetch(apiUrl, apiParam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setApprover(repos.data);
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
    setOpen(true);
  };
  const handleClickOpened = () => {
    setOpened(true);
  };
  function handleCellClick(leavedetail) {
    setFromDate(leavedetail.Fromdate.slice(0, 10));
    setStartFirstHalf(leavedetail.FromFirstHalfLeave);
    setStartSecondHalf(leavedetail.FromSecondHalfLeave);
    setToDate(leavedetail.Todate.slice(0, 10));
    setEndFirstHalf(leavedetail.ToFirstHalfLeave);
    setEndSecondHalf(leavedetail.ToSecondHalfLeave);
    setTypeLeave(leavedetail.TypeofLeave);
    setLeaveDays(leavedetail.LeaveDays);
    setLeaveReason(leavedetail.LeaveReason);
    setLeaveId(leavedetail._id);
    handleClickOpen(leavedetail.EmpId);
  }
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      {/* <div>
        <Dialog
          maxWidth={"md"}
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div>
            <DialogTitle
              id="alert-dialog-title"
              sx={{
                background: process.env.REACT_APP_PRIMARY_COLOR,
                height: "60px",
                textAlign: "center",
                color: "white",
              }}
            >
              {"Approve Leaves"}
              <Button sx={{ float: "right" }} onClick={handleClose}>
                X
              </Button>
            </DialogTitle>
          </div>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <form class="row g-3">
                <div class="col-md-6">
                  <label class="form-label">From</label>
                  <input
                    type="date"
                    class="form-control"
                    value={fromdate}
                    min={new Date(fromdate).toISOString().split("T")[0]}
                    max={todate}
                    disabled
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <div>
                      <span>
                        <input
                          type="checkbox"
                          onChange={(e) => setStartFirstHalf(e.target.checked)}
                          checked={startFirstHalf}
                        />
                        <label for="radio3">First Half</label>
                      </span>
                    </div>
                    <div>
                      <span>
                        <input
                          type="checkbox"
                          onChange={(e) => setStartSecondHalf(e.target.checked)}
                          checked={startSecondHalf}
                        />
                        <label>Second Half</label>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <label class="form-label">To</label>
                  <input
                    type="date"
                    class="form-control"
                    min={fromdate}
                    value={todate}
                    disabled
                  />
                  {disableChecks ? (
                    ""
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <div>
                        <span>
                          <input
                            type="checkbox"
                            onChange={(e) => setEndFirstHalf(e.target.checked)}
                            checked={endFirstHalf}
                          />
                          <label>First Half</label>
                        </span>
                      </div>
                      <div>
                        <span>
                          <input
                            type="checkbox"
                            onChange={(e) => setEndSecondHalf(e.target.checked)}
                            checked={endSecondHalf}
                          />
                          <label for="radio3">Second Half</label>
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div class="col-md-6">
                  <label>Number of Leave Days</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Number of leave days"
                    value={leaveDays}
                    disabled
                  ></input>
                </div>

                <div class="col-md-6">
                  <label>Type of Leave</label>
                  <select class="form-select" value={typeleave} disabled>
                    <option value="" selected disabled>
                      Type of leave
                    </option>
                    <option value="sick">Sick</option>
                    <option value="personal">Personal</option>
                    <option value="emergency">Emergency</option>
                  </select>
                </div>
                <div class="col-md-6">
                  <label>Leave Reason:</label>
                  <textarea
                    type="text"
                    class="form-control col"
                    value={leavereason}
                    required
                    disabled
                  />
                </div>

                <div class="col-md-6">
                  <label>Approver Remarks({approver.reportingToName})</label>
                  <textarea
                    type="text"
                    class="form-control col"
                    style={{ width: "100%" }}
                    value={approverRemarks}
                    onChange={(e) => {
                      setApproverRemarks(e.target.value);
                    }}
                  />
                </div>

                <div style={{ textAlign: "center" }}>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => {
                      updateLeavesStatus("Approved");
                    }}
                    disabled={approverRemarks.length < 8}
                    style={{
                      margin: "5px",
                    }}
                  >
                    Accept
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      updateLeavesStatus("Rejected");
                    }}
                    disabled={approverRemarks.length < 8}
                    style={{ margin: "5px" }}
                  >
                    Reject
                  </Button>
                </div>
              </form>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div> */}
      <div id="popup_leave" style={{ display: open ? "block" : " none" }}>
        <div id="overall_grp_leave_pop">
          <div id="grp_bg_desgin_grp_pop">
            <img
              id="bg_desgin_full_pop"
              src="bg_desgin_full_pop.png"
              srcset="bg_desgin_full_pop.png 1x"
              alt=""
            />

            <svg class="editleave_bdr_pop_l">
              <linearGradient
                id="editleave_bdr_pop_l"
                spreadMethod="pad"
                x1="0.5"
                x2="0.5"
                y1="0"
                y2="1"
              >
                <stop offset="0" stop-color="#b5ffb7" stop-opacity="1"></stop>
                <stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
              </linearGradient>
              <rect
                id="editleave_bdr_pop_l"
                rx="8"
                ry="8"
                x="0"
                y="0"
                width="425"
                height="51"
              ></rect>
            </svg>
            <div id="Edit_Leave_lbl_pop">
              <span>Edit Leave</span>
            </div>
            <img
              id="close_"
              src="close.png"
              srcset="close.png 1x, close@2x.png 2x"
              alt=""
              onClick={handleClose}
            />
          </div>
          <div id="Edit_leave_inner_grp">
            <div id="From_lbl_date">
              <span>From</span>
            </div>
            <div id="To_lbl_date">
              <span>To</span>
            </div>
            <select
              placeholder="Leave Type"
              class="leave_dropdown_inputpop"
              value={typeleave}
              onChange={(e) => setTypeLeave(e.target.value)}
            >
              <option key={"sick"} value={"sick"}>
                Sick
              </option>
              <option key={"personal"} value={"personal"}>
                Personal
              </option>
              <option key={"emergency"} value={"emergency"}>
                Emergency
              </option>
              {/* <option key={"holiday"} value={"holiday"}>
                Holiday
              </option> */}
            </select>
            <input
              type="date"
              class="from_bdr_input_pop"
              value={new Date(fromdate).toISOString().split("T")[0]}
              onChange={(e) => {
                setFromDate(e.target.value);
              }}
            />

            <input
              type="date"
              class="to_bdr_input_box_pop"
              value={new Date(todate).toISOString().split("T")[0]}
              minDate={fromdate}
              onChange={(e) => {
                setToDate(e.target.value);
              }}
            />

            <div id="check_box_4_grp_pop">
              <input
                type="checkbox"
                class="checkbox_4_pop_btn"
                onChange={(e) => setStartFirstHalf(e.target.checked)}
                checked={startFirstHalf}
              />

              <div id="First_Half_4_lbl_pop">
                <span>First Half</span>
              </div>
            </div>
            <div id="check_box_31_grp">
              <input
                type="checkbox"
                class="checkbox_31_input"
                onChange={(e) => setStartSecondHalf(e.target.checked)}
                checked={startSecondHalf}
              />

              <div id="Second_Half_31_lbl">
                <span>Second Half</span>
              </div>
            </div>
            <div id="leave_days_grp_pop">
              <a class="bdr_leaves">
                <rect
                  id="bdr_leaves"
                  rx="8"
                  ry="8"
                  x="0"
                  y="0"
                  width="42"
                  height="42"
                ></rect>
              </a>
              <div id="no_of_days_lb_pop">
                <span>{leaveDays}</span>
              </div>
              <div id="No_of_Days_lbl_pop">
                <span>No. of Leave Days</span>
              </div>
            </div>
            <textarea
              placeholder="Leave Reason"
              class="leave_reason_input_box"
              value={leavereason}
              onChange={(e) => {
                setLeaveReason(e.target.value);
              }}
            ></textarea>
            {disableChecks ? (
              ""
            ) : (
              <>
                <div id="check_box_2_grp_pop">
                  <input
                    type="checkbox"
                    class="bdr_check_box_2_pop"
                    onChange={(e) => setEndFirstHalf(e.target.checked)}
                    checked={endFirstHalf}
                  />

                  <div id="First_Half_2_pop">
                    <span>First Half</span>
                  </div>
                </div>

                <div id="check_box_17_grp">
                  <input
                    type="checkbox"
                    class="checkbox_button"
                    onChange={(e) => setEndSecondHalf(e.target.checked)}
                    checked={endSecondHalf}
                  />

                  <div id="Second_Half_text">
                    <span>Second Half</span>
                  </div>
                </div>
              </>
            )}
            <input
              placeholder="Approver Name"
              class="approver_input_box"
              value={approver.reportingToName}
            />

            {/* <button id="btn_grp_first">
              <svg class="btn_edit_pop_leave">
                <rect
                  id="btn_edit_pop_leave"
                  rx="9"
                  ry="9"
                  x="0"
                  y="0"
                  width="162"
                  height="50"
                ></rect>
              </svg>
              <div id="Edit_Leave_pop_lbl">
                <span>Edit Leave</span>
              </div>
            </button> */}
            <div>
              {/* {leaveStatus === "Applied" ? (
                <button
                  id="btn_grp_first"
                  onClick={() => {
                    handleEditLeaves();
                  }}
                >
                  Edit Leave
                </button>
              ) : leaveStatus === "Cancelled" || leaveStatus === "Rejected" ? (
                ""
              ) : (
                <button
                  id="btn_grp_first"
                  onClick={() => {
                    handleCancelLeaves();
                  }}
                >
                  Cancel Leave
                </button>
              )} */}

              <button
                id="btn_grp_first_approval"
                onClick={() => {
                  updateLeavesStatus("Approved");
                }}
                disabled={approverRemarks.length < 8}
              >
                Accept
              </button>
              <button
                id="btn_grp_second_approval"
                onClick={() => {
                  updateLeavesStatus("Rejected");
                }}
                disabled={approverRemarks.length < 8}
              >
                Reject
              </button>
            </div>
            <div id="leave_type_lbl">
              <span>Leave Type</span>
            </div>
            <div id="approver_name_lbl">
              <span>Approver Name</span>
            </div>
            <div id="leave_reason_lbl_pop">
              <span>Leave Reason</span>
            </div>
            <div id="approver_remarks">
              <span>Approver remarks</span>
            </div>

            <textarea
              type="text"
              className="leave_approver_remarks"
              value={approverRemarks}
              onChange={(e) => {
                setApproverRemarks(e.target.value);
              }}
            />
          </div>
        </div>
      </div>

      <div>
        <TableContainer component={Paper}>
          <Table
            sx={{
              minWidth: 650,
            }}
          >
            <TableHead
              sx={{ backgroundColor: process.env.REACT_APP_PRIMARY_COLOR }}
            >
              <TableRow
                sx={{
                  "& .MuiTableCell-root": {
                    textAlign: "center",
                    fontFamily: "sans-serif",
                    fontSize: "20px",
                    fontWeight: 700,
                    color: "white",
                  },
                }}
              >
                <TableCell>Employee</TableCell>
                <TableCell>From</TableCell>
                <TableCell>To</TableCell>
                <TableCell>Comments</TableCell>
                <TableCell>LeaveDays</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                "& .MuiTableRow-root:hover": {
                  backgroundColor: process.env.REACT_APP_SECONDARY_COLOR,
                },
              }}
            >
              {props.data.map((leavedetail) => (
                <TableRow
                  key={leavedetail._id}
                  onClick={() => handleCellClick(leavedetail)}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "& .MuiTableCell-root": {
                      textAlign: "center",
                      fontFamily: "Helvetica",
                      fontSize: "18px",
                    },
                  }}
                >
                  <TableCell>{leavedetail.name}</TableCell>
                  <TableCell>{leavedetail.Fromdate.slice(0, 10)}</TableCell>
                  <TableCell>{leavedetail.Todate.slice(0, 10)}</TableCell>
                  <TableCell>{leavedetail.LeaveReason}</TableCell>
                  <TableCell>{leavedetail.LeaveDays}</TableCell>
                  <TableCell>{leavedetail.Status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default LeavesApprovalGrid;
