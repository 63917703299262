import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../UserContext";
import Box from "@mui/material/Box";
import SideDrawer from "../Components/chatComponents/SideDrawer";
import MyChats from "../Components/chatComponents/MyChats";
import ChatBox from "../Components/chatComponents/ChatBox";
import { Container } from "@material-ui/core";
import Menu from "../Components/Menu";
const Chats = () => {
  const { user } = useContext(UserContext);
  const [fetchAgain, setFetchAgain] = useState(false);
  useEffect(() => {
    // let myTokenData = localStorage.getItem("loggedintoken").split(".")[1];
    if (user) {
    }
  }, [user]);
  return (
    <div style={{ width: "100%" }}>
      {/* {user && <SideDrawer />} */}

      <Container>
        <div style={{ paddingTop: "15px" }}>
          <Menu />
        </div>
        <Box
          style={{
            display: "flex",
            width: "100%",
            height: "91.5vh",
            padding: "10px",
          }}
        >
          {user && (
            <MyChats fetchAgain={fetchAgain} setFetchAgain={setFetchAgain} />
          )}
          {user && (
            <ChatBox fetchAgain={fetchAgain} setFetchAgain={setFetchAgain} />
          )}
        </Box>
      </Container>
    </div>
  );
};

export default Chats;
