import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Tab } from "bootstrap";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { UserContext } from "../../UserContext";

function HolidaysGrid(props) {
  const [users, setUsers] = useState([]);
  const [isChecked, setIsChecked] = useState(true);
  const [holidays, setHolidays] = useState([]);
  const { user } = useContext(UserContext);
  const [currentUser, setCurrentUser] = useState();
  const [totalUsers, setTotalUsers] = useState(0);

  let arrayUniqueByKey =
    props && props.data && props.data.length > 0
      ? Object.values(
          props.data
            .filter((i) => i.userId && i.userId.active === true)
            .reduce((acc, item) => {
              acc[item.userId.name] = item;
              return acc;
            }, {})
        )
      : [];

  const getUsers = () => {
    let apiURL = process.env.REACT_APP_API_URL + "/api/user/allusers";

    let apiParams = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setUsers(repos.data);
        } else alert(repos.message);
      });
  };

  const getReportingTo = () => {
    let empid = user._id;

    let apiUrl =
      process.env.REACT_APP_API_URL + "/api/user/register?empid=" + empid;
    const apiParam = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };

    fetch(apiUrl, apiParam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setCurrentUser(repos.data);
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };

  const getallHolidays = async () => {
    let apiURL = process.env.REACT_APP_API_URL + "/api/holiday/getallholidays";

    let apiParams = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };
    await fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setHolidays(repos.data);
        } else alert(repos.message);
      });
  };

  const updateHoliday = (isChecked, holiday, sh) => {
    if (sh != user._id) {
      return;
    }
    if (new Date(holiday.date).setHours(0, 0, 0, 0) < new Date()) {
      alert("Cannot update past holidays!!");
      return;
    }
    let apiURL =
      process.env.REACT_APP_API_URL + "/api/holiday/selectedholidays";

    let apiParam = {
      method: "POST",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
        holidayId: holiday._id,
        userId: sh,
        isSelected: isChecked,
        dateofholiday: new Date(holiday.date).toISOString(),
        occation: holiday.occation,
        reportingtoid: currentUser.reportingToId,
        empname: user.name,
      },
    };
    fetch(apiURL, apiParam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          props.refreshGrid();
        } else alert(repos.message);
      });
  };
  useEffect(() => {
    let total = 0;
    let t = users.filter((user) => user.active == true);
    users.forEach((user) => {
      if (user.active == true) {
        total += 1;
        setTotalUsers(total);
      }
    });
  }, [arrayUniqueByKey.length > 0 && arrayUniqueByKey]);

  useEffect(() => {
    getUsers();
    getallHolidays();
    getReportingTo();
  }, []);
  return (
    <div>
      <TableContainer component={Paper}>
        <Table
          sx={{
            minWidth: 650,
          }}
        >
          <TableHead
            sx={{ backgroundColor: process.env.REACT_APP_PRIMARY_COLOR }}
          >
            <TableRow
              sx={{
                "& .MuiTableCell-root": {
                  textAlign: "center",
                  fontFamily: "sans-serif",
                  fontSize: "20px",
                  fontWeight: 700,
                  color: "white",
                },
              }}
            >
              {}
              <TableCell>Holiday</TableCell>

              {localStorage.getItem("filterEmployee") == "all"
                ? arrayUniqueByKey.length > 0 &&
                  arrayUniqueByKey.map((currentUser) => (
                    <TableCell>{currentUser.userId.name}</TableCell>
                  ))
                : arrayUniqueByKey.length > 0
                ? arrayUniqueByKey.map((a) => (
                    <TableCell>{a.userId.name}</TableCell>
                  ))
                : localStorage.getItem("loggedInUser") && (
                    <TableCell>
                      {JSON.parse(localStorage.getItem("loggedInUser")).name}
                    </TableCell>
                  )}
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              "& .MuiTableRow-root:hover": {
                backgroundColor: process.env.REACT_APP_SECONDARY_COLOR,
              },
            }}
          >
            {holidays.map((holiday) => (
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "& .MuiTableCell-root": {
                    textAlign: "center",
                    fontFamily: "Helvetica",
                    fontSize: "18px",
                  },
                }}
              >
                <TableCell>
                  {holiday.occation}({new Date(holiday.date).toDateString()}){" "}
                  {totalUsers}
                  {
                    users.filter((user) =>
                      props.data.some(
                        (s) =>
                          s.userId._id === user._id &&
                          s.isSelected == true &&
                          s.holidayId == holiday._id
                      )
                    ).length
                  }
                </TableCell>

                {arrayUniqueByKey.length > 0 ? (
                  arrayUniqueByKey.map((currentUser) => (
                    <TableCell>
                      {props.data.filter(
                        (sh) =>
                          sh.userId._id == currentUser.userId._id &&
                          sh.holidayId == holiday._id
                      ).length > 0 ? (
                        <input
                          type="checkbox"
                          checked={
                            props.data
                              .filter(
                                (sh) =>
                                  sh.userId._id == currentUser.userId._id &&
                                  sh.holidayId == holiday._id
                              )
                              .map((m) => m.isSelected)[0]
                          }
                          onChange={(e) =>
                            updateHoliday(
                              e.target.checked,
                              holiday,
                              props.selectedEmployee
                            )
                          }
                        />
                      ) : (
                        <input
                          checked={false}
                          type="checkbox"
                          onChange={(e) =>
                            updateHoliday(
                              e.target.checked,
                              holiday,
                              props.selectedEmployee
                            )
                          }
                        />
                      )}
                    </TableCell>
                  ))
                ) : (
                  <input
                    checked={false}
                    type="checkbox"
                    onChange={(e) =>
                      updateHoliday(
                        e.target.checked,
                        holiday,
                        props.selectedEmployee
                      )
                    }
                  />
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default HolidaysGrid;
