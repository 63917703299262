import React, { useContext, useEffect, useState } from "react";
import "../../Styles/leavesmob.css";
import { UserContext } from "../../UserContext";
import { Modal } from "@mui/material";

function LeavesPanelMob(props) {
  //variable
  const { user, setUser } = useContext(UserContext);
  //useState
  const [open, setOpen] = useState(false);
  const [openLR, setOpenLR] = useState(false);
  const [fromdate, setFromDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [todate, setToDate] = useState(new Date().toISOString().slice(0, 10));
  const [disableChecks, setDisableChecks] = useState(true);
  const [leaveDays, setLeaveDays] = useState(1);
  const [typeleave, setTypeLeave] = useState("sick");
  const [leavereason, setLeaveReason] = useState("");
  const [approver, setApprover] = useState("");
  const [Checked, setChecked] = useState(false);
  const [startFirstHalf, setStartFirstHalf] = useState(true);
  const [startSecondHalf, setStartSecondHalf] = useState(true);
  const [endFirstHalf, setEndFirstHalf] = useState(true);
  const [endSecondHalf, setEndSecondHalf] = useState(true);
  const [leavesData, setLeavesData] = useState([]);

  let totalLeaves = 24;
  let totalHolidays = 10;
  //useEffect
  useEffect(() => {
    const fromDateTimestamp = Date.parse(fromdate);
    const toDateTimestamp = Date.parse(todate);

    if (isNaN(fromDateTimestamp) || isNaN(toDateTimestamp)) {
      return;
    }

    const date1 = new Date(fromdate);
    const date2 = new Date(todate);
    const datediff = date2 - date1;

    if (
      date1.toISOString().split("T")[0] === date2.toISOString().split("T")[0]
    ) {
      setLeaveDays(
        date1.getDay() === 0
          ? 1 - (!startFirstHalf ? 0.5 : 0) - (!startSecondHalf ? 0.5 : 0)
          : 1 - (!startFirstHalf ? 0.5 : 0) - (!startSecondHalf ? 0.5 : 0)
      );
      setDisableChecks(true);
      setEndFirstHalf(true);
      setEndSecondHalf(true);
    } else {
      setDisableChecks(false);
      const datediff = date2 - date1;

      const diffdays = Math.ceil(datediff / (1000 * 60 * 60 * 24)) + 1;

      let sundays = 0;
      for (let i = 0; i < diffdays; i++) {
        let currentday = addDays(fromdate, i);
        if (currentday.getDay() === 0) {
          sundays++;
        }
      }
      setLeaveDays(
        diffdays -
          sundays -
          (!startFirstHalf ? 0.5 : 0) -
          (!startSecondHalf ? 0.5 : 0) -
          (!endFirstHalf ? 0.5 : 0) -
          (!endSecondHalf ? 0.5 : 0)
      );
    }
  }, [
    fromdate,
    todate,
    startFirstHalf,
    startSecondHalf,
    endFirstHalf,
    endSecondHalf,
  ]);
  useEffect(() => {
    handleClickOpen();
  }, []);
  //functions
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    let empid = user._id;

    let apiUrl =
      process.env.REACT_APP_API_URL + "/api/user/register?empid=" + empid;
    const apiParam = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };

    fetch(apiUrl, apiParam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setApprover(repos.data);
          props.refreshGrid();
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
    setOpen(true);
  };
  const redirect = () => {
    const date1 = new Date(fromdate);
    const date2 = new Date(todate);
    const datediff = date2 - date1;
    if (datediff < 0) {
      alert("leave days cannot be negative value");
      return;
    }
    if (leaveDays < 0) {
      alert("leave days cannot be negative");
      return;
    }
    if (typeleave === null || typeleave.length === 0) {
      alert("Please enter select leave type!");
      return;
    }
    if (leavereason === null || leavereason.length === 0) {
      alert("Please enter leave reason!");
      return;
    }
    if (leaveDays === 0) {
      alert("Please choose first half or second half");
      return;
    }
    var approve = approver.reportingToId;
    let empName = user.name;
    const apiurl = process.env.REACT_APP_API_URL + "/api/leaves";
    const apiparam = {
      method: "POST",
      headers: {
        empid: user._id,
        fromdate: fromdate,
        fromfirsthalfleave: startFirstHalf,
        fromsecondhalfleave: startSecondHalf,
        todate: todate,
        tofirsthalfleave: endFirstHalf,
        tosecondhalfleave: endSecondHalf,
        typeleave: typeleave,
        leavedays: leaveDays,
        leavereason: leavereason,
        approver: approve,
        name: empName,
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };
    fetch(apiurl, apiparam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setOpenLR(false);
          alert(repos.message);
          handlereset();
          props.refreshGrid();
        } else alert(JSON.parse(repos).message);
      });
    handleClose();
  };

  const handlereset = () => {
    setFromDate(new Date());
    setToDate(new Date());
    setTypeLeave("");
    setLeaveDays(
      1 - (!startFirstHalf ? 0.5 : 0) - (!startSecondHalf ? 0.5 : 0)
    );
    setLeaveReason("");
  };
  function addDays(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  const getLeavesData = () => {
    let apiURL = process.env.REACT_APP_API_URL + "/api/leaves/leavesdata";
    let apiParam = {
      method: "GET",
      headers: {
        userid: user._id,
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };
    fetch(apiURL, apiParam)
      .then((res) => (res.status == 200 ? res.json() : res.text()))
      .then((repos) => {
        setLeavesData(repos.data);
      });
  };
  const handleCloseLR = () => {
    setOpenLR(false);
  };
  useEffect(() => {
    getLeavesData();
  }, []);

  return (
    <div>
      <div id="btn_full_grp">
        <div id="Group_8">
          <svg class="Ellipse_2">
            <ellipse
              id="Ellipse_2"
              rx="17.5"
              ry="17.5"
              cx="17.5"
              cy="17.5"
            ></ellipse>
          </svg>
          <button
            onClick={() => {
              setOpenLR(true);
            }}
            style={{ margin: "5px" }}
            disabled={!(fromdate && todate)}
          >
            <img
              id="play-buttton_img_task_mob"
              src="play-buttton_img_task.png"
              srcset="play-buttton_img_task.png 1x"
              alt=""
            />
          </button>
        </div>

        <div id="reset_btn_leave">
          <svg class="bdr_reset_leave">
            <ellipse
              id="bdr_reset_leave"
              rx="17.5"
              ry="17.5"
              cx="17.5"
              cy="17.5"
            ></ellipse>
          </svg>
          <button
            onClick={() => {
              handlereset();
            }}
          >
            <img
              id="stop-button_img_leave"
              src="stop-button_img_leave.png"
              srcset="stop-button_img_leave.png 1x"
              alt=""
            />
          </button>
        </div>
        <div id="Create_lbl_">
          <span>Create</span>
        </div>
        <div id="Reset_lbl">
          <span>Reset</span>
        </div>
      </div>
      <Modal open={openLR} onClose={handleCloseLR}>
        <>
          <textarea
            placeholder="Enter leave reson"
            id="leave_reason"
            value={leavereason}
            required
            onChange={(e) => {
              setLeaveReason(e.target.value);
            }}
          ></textarea>
          <button id="add_leave" onClick={() => redirect()}>
            Apply Leave
          </button>
        </>
      </Modal>
      <div id="approved_full_grp">
        <svg class="bg_design_app">
          <rect
            id="bg_design_app"
            rx="6"
            ry="6"
            x="0"
            y="0"
            width="94"
            height="47"
          ></rect>
        </svg>
        <div id="Approved_lbl_app">
          <span>Approved</span>
        </div>
        <img
          id="leave_rec_app"
          src="leave_rec_app.png"
          srcset="leave_rec_app.png 1x"
          alt=""
        />

        <div id="lbl_leav_app">
          <span>{leavesData && leavesData.leavestilltoday}</span>
        </div>
        <img
          id="holiday_rec_app"
          src="holiday_rec_app.png"
          srcset="holiday_rec_app.png 1x"
          alt=""
        />

        <div id="holi_lbl_app">
          <span>{leavesData && leavesData.totalHolidaysSelected}</span>
        </div>
      </div>
      <div id="from_full_gro_leave">
        <input
          type="date"
          class="from_bdr_input_1_eave"
          value={fromdate}
          onChange={(e) => {
            setFromDate(e.target.value);
          }}
        />

        <div id="From_lbl_1_leave">
          <span>From</span>
        </div>
        <div id="halfs_grp_leave">
          <div id="First_Half_mob">
            <span>First Half</span>
          </div>
          <div id="Second_Half_mob">
            <span>Second Half</span>
          </div>
          <input
            type="checkbox"
            class="chkbx_11_mob"
            onChange={(e) => setStartFirstHalf(e.target.checked)}
            checked={startFirstHalf}
          />

          <input
            type="checkbox"
            class="chkbx_12_mob"
            onChange={(e) => setStartSecondHalf(e.target.checked)}
            checked={startSecondHalf}
          />
        </div>
      </div>
      <div id="to_full_grp_leave">
        <input
          type="date"
          class="to_bdr_input_2_leave"
          value={todate}
          onChange={(e) => {
            setToDate(e.target.value);
          }}
        />

        <div id="To_lbl_2_leav">
          <span>To</span>
        </div>

        {disableChecks ? (
          ""
        ) : (
          <>
            <div id="n_half_full_grp">
              <div id="First_Half_leave">
                <span>First Half</span>
              </div>
              <div id="Second_Half_leave">
                <span>Second Half</span>
              </div>
              <input
                type="checkbox"
                class="chkbx_11_to"
                onChange={(e) => setEndFirstHalf(e.target.checked)}
                checked={endFirstHalf}
              />

              <input
                type="checkbox"
                class="chkbx_12_to"
                onChange={(e) => setEndSecondHalf(e.target.checked)}
                checked={endSecondHalf}
              />
            </div>
          </>
        )}
      </div>
      <div id="eligible_full_grp">
        <svg class="eli_bg_">
          <rect
            id="eli_bg_"
            rx="6"
            ry="6"
            x="0"
            y="0"
            width="94"
            height="47"
          ></rect>
        </svg>
        <div id="Approved_lbl_eli">
          <span>Eligible</span>
        </div>
        <img
          id="leave_rec_eli"
          src="leave_rec_eli.png"
          srcset="leave_rec_eli.png 1x"
          alt=""
        />

        <div id="lbl_leav_eli">
          <span>24</span>
        </div>
        <img
          id="holiday_rec_eli"
          src="holiday_rec_eli.png"
          srcset="holiday_rec_eli.png 1x"
          alt=""
        />

        <div id="holi_lbl_eli">
          <span>10</span>
        </div>
      </div>
      <div id="availed_full_grp">
        <svg class="availed_bg_leave">
          <rect
            id="availed_bg_leave"
            rx="6"
            ry="6"
            x="0"
            y="0"
            width="94"
            height="47"
          ></rect>
        </svg>
        <div id="availed_lbl_leave">
          <span>Availed</span>
        </div>
        <img
          id="leave_rec_img"
          src="leave_rec_img.png"
          srcset="leave_rec_img.png 1x"
          alt=""
        />

        <div id="lbl_leave">
          {" "}
          <span> {leavesData && leavesData.appliedleaves}</span>
        </div>
        <img
          id="holiday_rec_leave"
          src="holiday_rec_leave.png"
          srcset="holiday_rec_leave.png 1x"
          alt=""
        />

        <div id="holi_lbl_leave">
          <span>{leavesData && leavesData.selectedHolidaysbyuser}</span>
        </div>
      </div>
      <div id="leave_type_grp_mob">
        <select
          class="leave_reason"
          onChange={(e) => setTypeLeave(e.target.value)}
        >
          <option key={"sick"} value={"sick"} selected>
            Sick
          </option>
          <option key={"personal"} value={"personal"}>
            Personal
          </option>

          <option key={"emergency"} value={"emergency"}>
            Emergency
          </option>
        </select>
        <div id="leave_type_lbl">
          <span>Leave Type</span>
        </div>
        <input
          placeholder="Approver"
          class="approver_input_mob"
          value={approver.reportingToName}
        />
      </div>
      <div id="leave_days_grp_mob">
        <div id="Leave_Days_lbl_mob">
          <span>Leave Days</span>
        </div>
        <div id="no_grp">
          <svg class="rec_bdr_leave">
            <ellipse
              id="rec_bdr_leave"
              rx="17.5"
              ry="17.5"
              cx="17.5"
              cy="17.5"
            ></ellipse>
          </svg>
          <div id="no_of_days_leave">
            <span>1</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeavesPanelMob;
