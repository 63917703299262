import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faStop } from "@fortawesome/free-solid-svg-icons";
import { TextField, Button } from "@mui/material";
import "../../Styles/attendance.css";
import { useContext } from "react";
import { UserContext } from "../../UserContext";
import {geoFindMe} from "../../Utils";
import { Recommend } from "@mui/icons-material";

function AttendancePanel(props) {
  //variables
  const element1 = (
    <FontAwesomeIcon
      icon={faPlay}
      size="2x"
      color={process.env.REACT_APP_PRIMARY_COLOR}
    />
  );
  const element2 = <FontAwesomeIcon icon={faStop} size="2x" color="red" />;

  //useState
  const { user } = useContext(UserContext);
  const [showStop, setShowStop] = useState(false);
  const [startTime, setStartTime] = useState();
  const [endTime, setEndtime] = useState();
  const [comments, setComments] = useState("");
  const [startlocation, setStartLocation] = useState({});
  const [endLocation, setEndLocation] = useState({});
  const [myUserid, setMyUserId] = useState();
  const [name, setName] = useState();

  const [loggedinTime, setLoggedinTime] = useState("");

  useEffect(() => {
    const calculateLoggedinTime = () => {
      let totalMilliseconds = 0;
      props.attendanceData.length > 0 &&
        props.attendanceData.forEach((attendance) => {
          const startTime = new Date(attendance.Starttime);
          const stopTime =
            attendance.Stoptime != null &&
            attendance.Reason != "Auto logout by System"
              ? new Date(attendance.Stoptime)
              : new Date();

          const timeDifference = Math.abs(
            stopTime.getTime() - startTime.getTime()
          );
          totalMilliseconds += timeDifference;
        });

      const totalHours = Math.floor(totalMilliseconds / (1000 * 60 * 60));
      const totalMinutes = Math.floor((totalMilliseconds / (1000 * 60)) % 60);

      const formattedHours = totalHours.toString().padStart(2, "0");
      const formattedMinutes = totalMinutes.toString().padStart(2, "0");

      const formattedTime = `${formattedHours} Hours ${formattedMinutes} Minutes`;

      setLoggedinTime(formattedTime);
    };

    props.attendanceData.length > 0 && calculateLoggedinTime();
    const interval = setInterval(() => {
      props.attendanceData.length > 0 && calculateLoggedinTime();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [props.attendanceData]);
  //useEffect
  useEffect(() => {
    getLoginStatus();
  }, []);

  function getLoginStatus() {
    let myTokenData = localStorage.getItem("loggedintoken").split(".")[1];
    let userId = JSON.parse(atob(myTokenData))._id;
    const apiUrl =
      process.env.REACT_APP_API_URL + "/api/attendance/loginstatus/" + userId;
    let apiParams = {
      method: "GET",
      headers: {
        shiftstart: JSON.parse(localStorage.getItem("loggedInUser")).shiftStart,
        shiftend: JSON.parse(localStorage.getItem("loggedInUser")).shiftEnd,
      },
    };
    fetch(apiUrl, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        props.refreshGrid();
        if (repos.data[0] && repos.data[0].Starttime) {
          setStartTime(repos.data[0].Starttime);
          setMyUserId(repos.data[0].EmpId);
          if (
            repos.data[0].Starttime !== null &&
            repos.data[0].Starttime.length > 8
          ) {
            setEndtime(repos.data[0].Stoptime);
          }
          if (
            repos.data[0].Stoptime === null ||
            repos.data[0].Stoptime.length < 8
          ) {
            setShowStop(true);
          }
        }
      })
      .catch((err) => console.log(err));
  }

  const RecordLogin = () => {
    if (startlocation.startlatitude === undefined) {
      return;
    }
    let startTimeLocal = parseInt(
      JSON.parse(atob(localStorage.getItem("loggedintoken").split(".")[1]))
        .shiftStart
    );
    let getname = JSON.parse(
      atob(localStorage.getItem("loggedintoken").split(".")[1])
    ).name;

    let t = new Date().toLocaleTimeString();
    let h = new Date();
    let hrs = h.getHours();
    let mins = hrs * 60 + h.getMinutes();
    if (mins < startTimeLocal) {
      setStartTime(t);
      const apiUrl =
        process.env.REACT_APP_API_URL + "/api/attendance/starttimer";
      let apiParam = {
        method: "POST",
        headers: {
          empid: JSON.parse(localStorage.getItem("loggedInUser"))._id,
          fullname: getname,
          starttime: t,
          latitude: startlocation.startlatitude,
          longitude: startlocation.startlongitude,
          stoplatitude: "",
          stoplongitude: "",
          latereason: encodeURIComponent(comments),
          reasons: "",
          endtime: "",
          authtoken: localStorage.getItem("loggedintoken"),
          shiftstarttime: JSON.parse(localStorage.getItem("loggedInUser"))
            .shiftStart,
          shiftendtime: JSON.parse(localStorage.getItem("loggedInUser"))
            .shiftEnd,
        },
      };

      fetch(apiUrl, apiParam)
        .then((res) => (res.status === 200 ? res.json() : res.text()))
        .then((repos) => {
          if (repos) {
            // setMyUserId(repos.data._id);
            // setShowStart(true);
            setShowStop(false);
            props.refreshGrid();
            alert("Your session start has been recorded");
            getLoginStatus();
            setComments("");
          } else alert(repos.message || repos);
        })
        .catch((err) => alert(err));
    } else {
      if (comments === null || comments.length === 0) {
        alert("Please enter comments!");
        return;
      } else {
        setStartTime(t);

        const apiUrlLog =
          process.env.REACT_APP_API_URL + "/api/attendance/starttimer";
        var apiParamLog = {
          method: "POST",
          headers: {
            empid: JSON.parse(localStorage.getItem("loggedInUser"))._id,
            fullname: getname,
            starttime: t,
            endtime: "",
            latitude: startlocation.startlatitude,
            longitude: startlocation.startlongitude,
            stoplatitude: "",
            stoplongitude: "",
            latereason: encodeURIComponent(comments),
            reasons: "",
            authtoken: localStorage.getItem("loggedintoken"),
            shiftstarttime: JSON.parse(localStorage.getItem("loggedInUser"))
              .shiftStart,
            shiftendtime: JSON.parse(localStorage.getItem("loggedInUser"))
              .shiftEnd,
          },
        };

        fetch(apiUrlLog, apiParamLog)
          .then((res) => (res.status === 200 ? res.json() : res.text()))
          .then((repos) => {
            if (repos) {
              props.refreshGrid();
              setShowStop(true);
              setComments("");
              alert("Session started successfully!");
              getLoginStatus();
            } else alert(repos.message || repos);
          })
          .catch((err) => alert(err));
        // getAttendanceStatus();
      }
    }
  };

 const RecordLogout=() => {
    if (endLocation.stoplatitude === undefined) {
      return;
    }
    var stopTime = parseInt(
      JSON.parse(atob(localStorage.getItem("loggedintoken").split(".")[1]))
        .shiftEnd
    );

    var t = new Date().toLocaleTimeString();
    var h = new Date();
    var hrs = h.getHours();
    var mins = hrs * 60 + h.getMinutes();

    if (mins > stopTime) {
      setEndtime(t);
      const apiUrl =
        process.env.REACT_APP_API_URL + "/api/attendance/stoptimer";
      var apiParam = {
        method: "POST",
        headers: {
          empid: myUserid,
          latitude: endLocation.stoplatitude,
          longitude: endLocation.stoplongitude,
          endtime: t,
          reasons: encodeURIComponent(comments),
          authtoken: localStorage.getItem("loggedintoken"),
        },
      };

      fetch(apiUrl, apiParam)
        .then((res) => (res.status === 200 ? res.json() : res.text()))
        .then((repos) => {
          if (repos) {
            // setMyUserId(repos.data._id);

            props.refreshGrid();
            alert("Session stopped successfully!");
            setShowStop(false);
            // setShowStart(true);
            getLoginStatus();
            setComments("");
          } else alert(JSON.stringify(repos));
        })
        .catch((err) => alert(err));
    } else {
      if (comments === null || comments.length === 0) {
        alert("Please enter comments!");
        return;
      } else {
        setEndtime(t);
        const apiUrlz =
          process.env.REACT_APP_API_URL + "/api/attendance/stoptimer";
        var apiParamz = {
          method: "POST",
          headers: {
            empid: myUserid,
            latitude: endLocation.stoplatitude,
            longitude: endLocation.stoplongitude,
            endtime: t,
            reasons: encodeURIComponent(comments),
            authtoken: localStorage.getItem("loggedintoken"),
          },
        };

        fetch(apiUrlz, apiParamz)
          .then((res) => (res.status === 200 ? res.json() : res.text()))
          .then((repos) => {
            if (repos) {
              // setMyUserId(repos.data._id);
              setShowStop(false);
              // setShowStart(true);
              setComments("");
              props.refreshGrid();

              alert("Session stopped successfully!");
              getLoginStatus();
            } else alert(repos.message || repos);
          })
          .catch((err) => alert(err));
        // getAttendanceStatus();
      }
    }
  }

  //functions
  const startClick =  () => {
    geoFindMe().then(e => {
      setStartLocation({ startlatitude: e.latitude, startlongitude: e.longitude });
      RecordLogin();
    }).catch(err=>alert(err.message))
  };

  const endclick =  () => {
    geoFindMe().then(e => {
      setEndLocation({ stoplatitude: e.latitude, stoplongitude: e.longitude });
      RecordLogout();
    }).catch(err=>alert(err.message))
  };

  function convertMinutesToHoursMinutes(minutes) {
    var hours = Math.floor(minutes / 60);
    var remainingMinutes = minutes % 60;

    // Add leading zero if necessary
    var hoursStr = hours < 10 ? "0" + hours : hours.toString();
    var minutesStr =
      remainingMinutes < 10
        ? "0" + remainingMinutes
        : remainingMinutes.toString();

    return hoursStr + ":" + minutesStr;
  }
  //renderMethod
  return (
    // <div style={{ textAlign: "center" }}>
    //   <div
    //     class="row time"
    //     style={{
    //       textAlign: "center",
    //     }}
    //   >
    //     <div class="col-6">
    //       <div class="row">
    //         <div class="col">
    //           <label>
    //             <div class="col">
    //               {!showStop && (
    //                 <Button
    //                   style={{ fontSize: "15px" }}
    //                   onClick={(e) => {
    //                     startClick(e);
    //                   }}
    //                   size="small"
    //                 >
    //                   {element1}
    //                 </Button>
    //               )}

    //               {showStop && Date.parse(new Date(startTime))
    //                 ? new Date(startTime).toLocaleTimeString()
    //                 : ""}
    //             </div>
    //             <h6>Session Start</h6>
    //           </label>
    //         </div>
    //       </div>
    //     </div>

    //     <div class="col-6">
    //       <div class="row">
    //         <div class="col">
    //           <label>
    //             <div class="col">
    //               {showStop ? (
    //                 <Button
    //                   onClick={(e) => {
    //                     endclick(e);
    //                   }}
    //                   size="small"
    //                 >
    //                   {element2}
    //                 </Button>
    //               ) : (
    //                 " "
    //               )}
    //               {Date.parse(new Date(endTime))
    //                 ? new Date(endTime).toLocaleTimeString()
    //                 : ""}
    //             </div>
    //             <h6>Session End</h6>
    //           </label>
    //         </div>
    //       </div>
    //     </div>
    //     <br />
    //     <br />
    //     <div>
    //       <div>
    //         {/* <textarea
    //           rows="3"
    //           cols="50"
    //           placeholder="Comments..."
    //           value={comments}
    //           onChange={(e) => setComments(e.target.value)}
    //         ></textarea> */}
    //         <TextField
    //           id="leaveReason"
    //           style={{ width: "100%" }}
    //           label="Comments"
    //           multiline
    //           rows={3}
    //           value={comments}
    //           required
    //           onChange={(e) => {
    //             setComments(e.target.value);
    //           }}
    //           sx={{
    //             backgroundColor: "white",
    //           }}
    //         />
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div id="mid_changes_">
      <div id="shift_start_time">
        Shift Start:
        {convertMinutesToHoursMinutes(
          parseInt(
            JSON.parse(
              atob(localStorage.getItem("loggedintoken").split(".")[1])
            ).shiftStart
          )
        )}
      </div>
      <div id="shift_end_time">
        Shift End:
        {convertMinutesToHoursMinutes(
          parseInt(
            JSON.parse(
              atob(localStorage.getItem("loggedintoken").split(".")[1])
            ).shiftEnd
          )
        )}
      </div>
      <div id="total_logintime">
        Total hours worked:{props.attendanceData.length > 0 && loggedinTime}
      </div>
      <div id="timer_login">
        {!showStop && <marquee behavior="alternate">Start Session</marquee>}
        {showStop && Date.parse(new Date(startTime))
          ? new Date(startTime).toLocaleTimeString()
          : ""}
      </div>
      <img
        id="img_timer"
        src="img_timer.png"
        srcset="img_timer.png 1x, img_timer@2x.png 2x"
        alt=""
      />

      <div id="timer_login_stop">
        {showStop ? "End Session" : " "}
        {Date.parse(new Date(endTime))
          ? new Date(endTime).toLocaleTimeString()
          : ""}
      </div>
      <img
        id="img_timer_stop"
        src="img_timer_stop.png"
        srcset="img_timer_stop.png 1x, img_timer_stop@2x.png 2x"
        alt=""
      />

      <textarea
        class="comment_box"
        placeholder="Comments..."
        value={comments}
        onChange={(e) => setComments(e.target.value)}
      ></textarea>
      <svg class="bdr_2">
        <ellipse id="bdr_2" rx="21.5" ry="21.5" cx="21.5" cy="21.5"></ellipse>
      </svg>
      <svg class="bdr_1">
        <ellipse id="bdr_1" rx="17.5" ry="17.5" cx="17.5" cy="17.5"></ellipse>
      </svg>
      <button
        onClick={(e) => {
          startClick();
        }}
        disabled={!showStop ? false : true}
      >
        <img
          id="play-buttton_img"
          src="play-buttton_img.png"
          srcset="play-buttton_img.png 1x, play-buttton_img@2x.png 2x"
          alt=""
        />
      </button>

      <img
        id="link_up"
        src="link_up.png"
        srcset="link_up.png 1x, link_up@2x.png 2x"
        alt=""
      />

      <img
        id="link_down"
        src="link_down.png"
        srcset="link_down.png 1x, link_down@2x.png 2x"
        alt=""
      />

      <div id="mid_grp_">
        <svg class="bdr_back">
          <ellipse
            id="bdr_back"
            rx="21.5"
            ry="21.5"
            cx="21.5"
            cy="21.5"
          ></ellipse>
        </svg>
        <svg class="bdr">
          <ellipse id="bdr" rx="17.5" ry="17.5" cx="17.5" cy="17.5"></ellipse>
        </svg>
        <button
          onClick={(e) => {
            endclick();
          }}
          disabled={!showStop ? true : false}
        >
          <img
            id="stop-button_img"
            src="stop-button_img.png"
            srcset="stop-button_img.png 1x, stop-button_img@2x.png 2x"
            alt=""
          />
        </button>
      </div>
      <img
        id="link1_mid_top"
        src="link1_mid_top.png"
        srcset="link1_mid_top.png 1x, link1_mid_top@2x.png 2x"
        alt=""
      />

      <img
        id="link1_mid_down"
        src="link1_mid_down.png"
        srcset="link1_mid_down.png 1x, link1_mid_down@2x.png 2x"
        alt=""
      />

      <div id="Start_Session_lbl">
        <span>Start Session</span>
      </div>
      <div id="Session_End_lbl">
        <span>Session End</span>
      </div>
    </div>
  );
}

export default AttendancePanel;
