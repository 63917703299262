import { Box } from "@material-ui/core";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
const UserBadgeItem = ({ user, handleFunction }) => {
  return (
    <Box
      style={{
        padding: "2px",
        borderRadius: "lg",
        margin: "1px",
        marginBottom: "2px",
        fontSize: "12px",
        cursor: "pointer",
        background: process.env.REACT_APP_PRIMARY_COLOR,
        color: "black",
      }}
      onClick={handleFunction}
    >
      {user.name}
      <CloseIcon />
    </Box>
  );
};

export default UserBadgeItem;
