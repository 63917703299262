import {
  Box,
  Button,
  FormControl,
  Input,
  InputLabel,
  Modal,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert } from "@mui/material";
import React, { useContext, useState } from "react";
import { UserContext } from "../../UserContext";
import UserBadgeItem from "./UserBadgeItem";
import UserListItem from "./UserListItem";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const GroupChatModal = ({ children }) => {
  const { user, chats, setChats } = useContext(UserContext);
  const [open, setOpen] = React.useState(false);
  const [groupChatName, setGroupChatName] = useState();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [loading, setLoading] = useState(false);

  const [snackbarMessage, setSnackBarMessage] = useState("");
  const [openToast, setOpenToast] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSearch = async (query) => {
    setSearch(query);
    if (!query) {
      setSnackBarMessage("Please Enter Something in the Search");
      setOpenToast(true);
      return;
    }
    try {
      setLoading(true);
      const apiURL =
        process.env.REACT_APP_API_URL + "/api/user/allusers?search=" + search;
      const apiParams = {
        method: "GET",
        headers: {
          authtoken: localStorage.getItem("loggedintoken"),
          id: user._id,
        },
      };
      fetch(apiURL, apiParams)
        .then((res) => (res.status === 200 ? res.json() : res.text()))
        .then((repos) => {
          if (repos.data) {
            console.log(repos.data);
            setLoading(false);
            setSearchResult(repos.data);
          } else alert(repos.message || repos);
        })
        .catch((err) => alert(err));
    } catch (e) {
      setSnackBarMessage("Failed to fetch search results");
      setOpenToast(true);
    }
  };
  const handleSubmit = () => {
    if (!groupChatName || !selectedUsers) {
      setSnackBarMessage("Please fill all the fields");
      setOpenToast(true);
      return;
    }

    const apiURL = process.env.REACT_APP_API_URL + "/api/chat/group";
    const apiParams = {
      method: "POST",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
        chatname: groupChatName,
        users: JSON.stringify(selectedUsers.map((u) => u._id)),
        currentuser: user._id,
      },
    };

    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          const { data } = repos;
          setOpen(false);
          setChats([...chats, data]);
          setSnackBarMessage("Successfully created new chat");
          setOpenToast(true);
        } else {
          setSnackBarMessage("Error creating new Chat");
          setOpenToast(true);
        }
      })
      .catch((err) => {
        setSnackBarMessage(err);
        setOpenToast(true);
      });
  };
  const handleGroup = (userToAdd) => {
    if (selectedUsers.includes(userToAdd)) {
      setSnackBarMessage("User Already Added");
      setOpenToast(true);
      return;
    }
    setSelectedUsers([...selectedUsers, userToAdd]);
  };
  const handleDelete = (userToDelete) => {
    setSelectedUsers(
      selectedUsers.filter((sel) => sel._id !== userToDelete._id)
    );
  };
  return (
    <div>
      <span onClick={handleOpen}>{children}</span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            style={{
              fontSize: "35px",
              fontFamily: "comic sans",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Create Group Chat
          </Typography>
          <FormControl>
            <TextField
              label="Chat Name"
              onChange={(e) => {
                setGroupChatName(e.target.value);
              }}
              variant="outlined"
              margin="normal"
            />
          </FormControl>
          <FormControl>
            <TextField
              placeholder="Add Users"
              label="Add Users"
              margin="normal"
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
              variant="outlined"
            />
          </FormControl>
          <Box>
            {selectedUsers.map((u) => (
              <UserBadgeItem
                key={user._id}
                user={u}
                handleFunction={() => handleDelete(u)}
              />
            ))}
          </Box>
          {loading ? (
            <div>loading...</div>
          ) : (
            searchResult
              ?.slice(0, 4)
              .map((user) => (
                <UserListItem
                  key={user._id}
                  user={user}
                  handleFunction={() => handleGroup(user)}
                />
              ))
          )}
          <Button variant="contained" color="success" onClick={handleSubmit}>
            Create Chat
          </Button>
        </Box>
      </Modal>
      <Snackbar
        open={openToast}
        autoHideDuration={3000}
        onClose={() => setOpenToast(false)}
      >
        <Alert severity="error">{snackbarMessage}</Alert>
      </Snackbar>
    </div>
  );
};

export default GroupChatModal;
