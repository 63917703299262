import React from "react";
import "../../Styles/menubar.css";
import { useContext } from "react";
import { UserContext } from "../../UserContext";
import { useEffect } from "react";
import { useState } from "react";
function Sidebar(props) {
  const { user, setUser, notification, setNotification } =
    useContext(UserContext);
  const [value, setValue] = useState(0);
  //useEffect

  const handleTabChange = async (tab, newValue) => {
    props.changeTab(newValue);
    props.closeMenu();
    setValue(newValue);
    localStorage.setItem("tab", newValue);
    let localid;
    let access = user.accesstype;
    if (access === "0") {
      localid = user._id;
      // setSelectedEmployee(localid);
    } else {
      // localid = selectedEmployee;
    }

    LoadTab();
  };

  function LoadTab(index) {
    let selectedTab = parseInt(localStorage.getItem("tab"))
      ? parseInt(localStorage.getItem("tab"))
      : 0;
  }
  return (
    <div id="menu">
      <img
        id="bg_design_mob"
        src="bg_design_mob.png"
        srcset="bg_design_mob.png 1x"
        alt=""
      />

      <div class="slideInLeft1" id="menu_grp_">
        <svg class="full_bg_menu_mob">
          <rect
            id="full_bg_menu_mob"
            rx="15"
            ry="15"
            x="0"
            y="0"
            width="138"
            height="462"
          ></rect>
        </svg>
        <div id="Attendance_lbl_mob" onClick={() => handleTabChange(this, 0)}>
          <span>Attendance</span>
        </div>
        <img
          id="immigration_img_mob"
          src="immigration_img_mob.png"
          srcset="immigration_img_mob.png 1x"
          alt=""
        />

        <div id="Messages_lbl_mob" onClick={() => handleTabChange(this, 7)}>
          <span>Holidays</span>
        </div>
        <img
          id="msg_img_mob"
          src="holiday_img_mob.png"
          srcset="holiday_img_mob.png 1x"
          alt=""
        />

        <div id="Leaves_lbl_mob" onClick={() => handleTabChange(this, 1)}>
          <span>Leaves</span>
        </div>
        <img
          id="sick_img_mob"
          src="sick_img_mob.png"
          srcset="sick_img_mob.png 1x"
          alt=""
        />

        {/* <div id="Messages_lbl_mob">
          <span>Messages</span>
        </div>
        <img
          id="msg_img_mob"
          src="msg_img_mob.png"
          srcset="msg_img_mob.png 1x"
          alt=""
        /> */}

        {user.accesstype < 1 ? (
          <></>
        ) : (
          <>
            {" "}
            <div id="Approval_lbl_mob" onClick={() => handleTabChange(this, 4)}>
              <span>Approval</span>
            </div>
            <img
              id="sick_img_mob_"
              src="sick_img_mob_.png"
              srcset="sick_img_mob_.png 1x"
              alt=""
            />
          </>
        )}

        <div id="Tasks_lbl_mob" onClick={() => handleTabChange(this, 2)}>
          <span>Tasks</span>
        </div>
        <img
          id="task_img_mob"
          src="task_img_mob.png"
          srcset="task_img_mob.png 1x"
          alt=""
        />
      </div>
      <svg class="back_card2_mob slideInLeft1">
        <rect
          id="back_card2_mob"
          rx="11"
          ry="11"
          x="0"
          y="0"
          width="48"
          height="655"
        ></rect>
      </svg>
      <img
        class="slideInLeft1"
        id="front_card_mob_1"
        src="front_card_mob_1.png"
        srcset="front_card_mob_1.png 1x"
        alt=""
      />
    </div>
  );
}

export default Sidebar;
