import { Avatar, Tooltip } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import ScrollableFeed from "react-scrollable-feed";
import { UserContext } from "../../UserContext";
import {
  isLastMessage,
  isSameSender,
  isSameSenderMargin,
  isSameUser,
  isSameUserTrue,
} from "./ChatLogic";
import DoneIcon from "@mui/icons-material/Done";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";

const ScrollableChat = ({ messages }) => {
  const { user } = useContext(UserContext);
  useEffect(() => {}, [messages]);

  const changeDetectionFilter = (previousProps, newProps) => {
    const prevChildren = previousProps.children;
    const newChildren = newProps.children;

    const output =
      prevChildren !== newChildren &&
      prevChildren[prevChildren.length - 1] !==
        newChildren[newChildren.length - 1];
    return output;
  };
  return (
    <ScrollableFeed changeDetectionFilter={changeDetectionFilter}>
      {messages &&
        messages.map((m, i) => (
          <div style={{ display: "flex" }} key={m._id}>
            {(isSameSender(messages, m, i, user._id) ||
              isLastMessage(messages, i, user._id)) && (
              <Tooltip title={m.sender.name} placement="bottom-start">
                <Avatar
                  style={{
                    marginTop: "7px",
                    marginRight: "1px",
                    cursor: "pointer",
                  }}
                  sx={{ width: 24, height: 24 }}
                >
                  {m.sender.name &&
                    m.sender.name.split(" ")[0].slice(0, 1) +
                      m.sender.name.split(" ")[1].slice(0, 1)}
                </Avatar>
              </Tooltip>
            )}
            <span
              style={{
                backgroundColor: `${
                  m.sender._id === user._id ? "#BEE3F8" : "#B9F5D0"
                }`,
                borderRadius: "20px",
                padding: "5px 15px",
                maxWidth: "75%",
                marginLeft: isSameSenderMargin(messages, m, i, user._id),
                marginTop: isSameUser(messages, m, i, user._id) ? 3 : 10,
              }}
            >
              {m.content}
              <br />
              {m.createdAt && new Date(m.createdAt).toLocaleString()}
              {isSameUserTrue(m, user._id) ? (
                m.status && m.status === "sent" ? (
                  <Tooltip
                    title={
                      m.createdAt && new Date(m.createdAt).toLocaleTimeString()
                    }
                  >
                    <DoneIcon />
                  </Tooltip>
                ) : m.status === "seen" ? (
                  <Tooltip
                    title={
                      m.seenTime && new Date(m.seenTime).toLocaleTimeString()
                    }
                  >
                    <VisibilityIcon />
                  </Tooltip>
                ) : m.status === "received" ? (
                  <Tooltip
                    title={
                      m.receivedTime &&
                      new Date(m.receivedTime).toLocaleTimeString()
                    }
                  >
                    <LocalPostOfficeIcon />
                  </Tooltip>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </span>
          </div>
        ))}
    </ScrollableFeed>
  );
};

export default ScrollableChat;
