import { Box, Typography } from "@material-ui/core";
import { Avatar } from "@mui/material";
import { padding } from "@mui/system";
import React from "react";

const UserListItem = ({ user, handleFunction }) => {
  return (
    <div>
      <Box
        onClick={handleFunction}
        style={{
          cursor: "pointer",
          background: process.env.REACT_APP_SECONDARY_COLOR,
          width: "100%",
          display: "flex",
          alignItems: "center",
          color: "black",
          marginBottom: "2px",
        }}
      >
        <Avatar style={{ marginRight: "2", cursor: "pointer" }}>
          {" "}
          {user.name.slice(0, 2)}{" "}
        </Avatar>
        <Box>
          <Typography>{user.name}</Typography>
          <Typography>
            <b>Email:</b>
            {user.email}
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default UserListItem;
