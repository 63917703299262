import React, { useState, useEffect, useContext } from "react";
// import Dialog from "@mui/material/Dialog";
// import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";
// import Autocomplete from "@mui/material/Autocomplete";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DatePicker from "@mui/lab/DatePicker";
// import { TextField, MenuItem, Button } from "@mui/material";
import { UserContext } from "../../UserContext";
import "../../Styles/tasksPanel.css";
function MyTasksPanel(props) {
  //variable
  const { user, setUser } = useContext(UserContext);
  //useState
  const [task, setTask] = useState("");
  const [description, setDescription] = useState("");
  const [approver, setApprover] = useState("");
  const [important, setImportant] = useState(false);
  const [urgent, setUrgent] = useState(false);
  const [assignTo, setAssignTo] = useState("");
  const [deadline, setDeadline] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [data, setData] = useState([]);
  //useEffect
  useEffect(() => {
    let empid = user._id;
    setAssignTo(empid);

    const apiurl =
      process.env.REACT_APP_API_URL +
      "/api/user/employeesdropdown?empid=" +
      user._id;
    const apiparam = {
      method: "GET",
      headers: { authtoken: localStorage.getItem("loggedintoken") },
    };
    fetch(apiurl, apiparam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setData(repos.data);
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  }, []);
  //functions

  const handleCreate = () => {
    if (task.length < 1) {
      alert("Please enter task!");
      return;
    }
    if (description.length < 1) {
      alert("Please enter task!");
      return;
    }
    const apiurl = process.env.REACT_APP_API_URL + "/api/mytasks";
    const apiparam = {
      method: "POST",
      headers: {
        task,
        description: encodeURIComponent(description),
        assigntoid: assignTo,
        assigntoname: data.filter((emp) => emp._id === assignTo)[0].name,
        important,
        urgent,
        deadline: new Date(deadline).toISOString().split("T")[0],
        creatorid: user._id,
        creatorname: user.name,
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };
    fetch(apiurl, apiparam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          alert(repos.message);
          props.refreshGrid();
          handleReset();
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };
  const handleReset = () => {
    setTask("");
    setDescription("");
    setAssignTo(user._id);
    setImportant(false);
    setUrgent(false);
    setDeadline(new Date());
  };
  function addDays(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  //renderMethod
  return (
    // <div style={{ padding: "0px 10px 0px 10px" }}>
    //   <form class="row">
    //     <div class="col-md-10">
    //       <TextField
    //         id="task"
    //         label="Task"
    //         value={task}
    //         onChange={(e) => setTask(e.target.value)}
    //         required
    //         style={{ width: "100%" }}
    //         sx={{
    //           backgroundColor: "white",
    //         }}
    //       />
    //     </div>
    //     <div class="col-md-2">
    //       <span>
    //         <input
    //           type="checkbox"
    //           checked={important}
    //           onChange={(e) => setImportant(e.target.checked)}
    //         />
    //         &nbsp;&nbsp;
    //         <label>Important</label>
    //       </span>
    //       <br />
    //       <span>
    //         <input
    //           type="checkbox"
    //           checked={urgent}
    //           onChange={(e) => setUrgent(e.target.checked)}
    //         />
    //         &nbsp;&nbsp;
    //         <label>Urgent</label>
    //       </span>
    //     </div>
    //     <div class="col-md-12">
    //       <TextField
    //         id="description"
    //         label="Details"
    //         value={description}
    //         onChange={(e) => setDescription(e.target.value)}
    //         required
    //         multiline
    //         rows={2}
    //         style={{ width: "100%", marginBottom: "10px" }}
    //         sx={{
    //           backgroundColor: "white",
    //         }}
    //       />
    //     </div>
    //     <div class="col-md-4">
    //       <TextField
    //         select
    //         style={{ width: "100%" }}
    //         value={assignTo}
    //         label="Assign To"
    //         onChange={(e) => {
    //           setAssignTo(e.target.value);
    //         }}
    //         required
    //       >
    //         {data.map((employee) => {
    //           return <MenuItem value={employee._id}>{employee.name}</MenuItem>;
    //         })}
    //       </TextField>
    //     </div>
    //     <div class="col-md-4">
    //       <LocalizationProvider dateAdapter={AdapterDateFns}>
    //         <DatePicker
    //           label="Deadline"
    //           value={new Date(deadline).toISOString().split("T")[0]}
    //           minDate={new Date()}
    //           onChange={(newValue) => {
    //             setDeadline(newValue);
    //           }}
    //           required
    //           renderInput={(params) => <TextField {...params} />}
    //           style={{ width: "100%" }}
    //         />
    //       </LocalizationProvider>
    //     </div>
    //     <div class="col-md-2">
    //       <div style={{ textAlign: "center", marginTop: "5px" }}>
    //         <Button
    //           variant="contained"
    //           color="success"
    //           onClick={() => {
    //             handleCreate();
    //           }}
    //           style={{ margin: "5px" }}
    //         >
    //           Create
    //         </Button>
    //       </div>
    //     </div>{" "}
    //     <div class="col-md-2">
    //       <div style={{ textAlign: "center", marginTop: "5px" }}>
    //         <Button
    //           variant="contained"
    //           color="error"
    //           onClick={(e) => {
    //             handleReset();
    //           }}
    //           style={{ margin: "5px" }}
    //         >
    //           Reset
    //         </Button>
    //       </div>
    //     </div>
    //   </form>
    // </div>
    <div id="Group_3">
      <input
        placeholder="Task"
        class="comment_box_task"
        value={task}
        onChange={(e) => setTask(e.target.value)}
      />

      <textarea
        placeholder="Details"
        class="comment_box_task_b"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      ></textarea>
      <div id="app_res_grp_task">
        <div id="Start_Session_lbl_task">
          <span>Create</span>
        </div>
        <div id="Session_End_lbl_task">
          <span>Reset</span>
        </div>
        <svg class="bdr_task">
          <ellipse
            id="bdr_task"
            rx="21.5"
            ry="21.5"
            cx="21.5"
            cy="21.5"
          ></ellipse>
        </svg>
        <svg class="bdr_task_b">
          <ellipse
            id="bdr_task_b"
            rx="17.5"
            ry="17.5"
            cx="17.5"
            cy="17.5"
          ></ellipse>
        </svg>
        <button
          onClick={() => {
            handleCreate();
          }}
        >
          <img
            id="play-buttton_img_task"
            src="play-buttton_img_task.png"
            srcset="play-buttton_img_task.png 1x"
            alt=""
          />
        </button>

        <img
          id="link_up_task"
          src="link_up_task.png"
          srcset="link_up_task.png 1x"
          alt=""
        />

        <img
          id="link_down_task"
          src="link_down_task.png"
          srcset="link_down_task.png 1x"
          alt=""
        />

        <svg
          class="left_link_up_task_ca"
          viewBox="2247.021 277.652 8.117 2.127"
        >
          <path
            id="left_link_up_task_ca"
            d="M 2247.021240234375 277.6515502929688 C 2247.52294921875 277.7061767578125 2248.099853515625 277.7145080566406 2248.880126953125 277.7712097167969 C 2249.152587890625 277.7895202636719 2249.626708984375 277.859130859375 2250.271240234375 277.9796752929688 C 2254.037841796875 278.6775817871094 2255.13818359375 279.778076171875 2255.13818359375 279.778076171875"
          ></path>
        </svg>
        <svg
          class="left_link_down_task_cb"
          viewBox="2242.392 307.828 4.219 0.672"
        >
          <path
            id="left_link_down_task_cb"
            d="M 2246.6103515625 308.5001220703125 C 2246.6103515625 308.5001220703125 2244.0478515625 308.3907470703125 2242.3916015625 307.8282470703125"
          ></path>
        </svg>
        <svg class="bdr_back_task">
          <ellipse
            id="bdr_back_task"
            rx="21.5"
            ry="21.5"
            cx="21.5"
            cy="21.5"
          ></ellipse>
        </svg>
        <svg class="bdr_task_cd">
          <ellipse
            id="bdr_task_cd"
            rx="17.5"
            ry="17.5"
            cx="17.5"
            cy="17.5"
          ></ellipse>
        </svg>
        <button
          onClick={(e) => {
            handleReset();
          }}
        >
          <img
            id="stop-button_img_task"
            src="stop-button_img_task.png"
            srcset="stop-button_img_task.png 1x"
            alt=""
          />
        </button>

        <img
          id="link1_mid_top_task"
          src="link1_mid_top_task.png"
          srcset="link1_mid_top_task.png 1x"
          alt=""
        />

        <img
          id="link1_mid_down_task"
          src="link1_mid_down_task.png"
          srcset="link1_mid_down_task.png 1x"
          alt=""
        />

        <svg
          class="left_link_up_task_ch"
          viewBox="2247.021 277.652 8.117 2.127"
        >
          <path
            id="left_link_up_task_ch"
            d="M 2247.021240234375 277.6515502929688 C 2247.52294921875 277.7061767578125 2248.099853515625 277.7145080566406 2248.880126953125 277.7712097167969 C 2249.152587890625 277.7895202636719 2249.626708984375 277.859130859375 2250.271240234375 277.9796752929688 C 2254.037841796875 278.6775817871094 2255.13818359375 279.778076171875 2255.13818359375 279.778076171875"
          ></path>
        </svg>
        <svg
          class="left_link_down_task_ci"
          viewBox="2242.392 307.828 4.219 0.672"
        >
          <path
            id="left_link_down_task_ci"
            d="M 2246.6103515625 308.5001220703125 C 2246.6103515625 308.5001220703125 2244.0478515625 308.3907470703125 2242.3916015625 307.8282470703125"
          ></path>
        </svg>
      </div>
      <div id="assign_grp">
        <select
          class="assign_input_"
          value={assignTo}
          onChange={(e) => {
            setAssignTo(e.target.value);
          }}
        >
          {data.map((employee) => {
            return <option value={employee._id}>{employee.name}</option>;
          })}
        </select>
        <div id="assign_lbl">
          <span>Assign To</span>
        </div>
      </div>
      <div id="dead_grp">
        <input
          type="date"
          class="deadline_input_"
          value={deadline}
          onChange={(e) => {
            const fromDateTimestamp = Date.parse(e.target.value);

            if (isNaN(fromDateTimestamp)) {
              // handle invalid date input
              return;
            }
            setDeadline(e.target.value);
          }}
        />

        <div id="deadline_lbl_task">
          <span>Deadline</span>
        </div>
      </div>
      <div id="Important_task">
        <span>Important</span>
      </div>
      <div id="Urgent_task">
        <span>Urgent</span>
      </div>
      <input
        type="checkbox"
        class="chkbx_task"
        checked={important}
        onChange={(e) => setImportant(e.target.checked)}
      />

      <input
        type="checkbox"
        class="chkbx_task_cs"
        checked={urgent}
        onChange={(e) => setUrgent(e.target.checked)}
      />
    </div>
  );
}

export default MyTasksPanel;
