import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import Filter from "../Components/Filter";
import AttendanceGrid from "../Components/Grids/AttendanceGrid";
import DocumentsGrid from "../Components/Grids/DocumentsGrid";
import HolidaysGrid from "../Components/Grids/HolidaysGrid";
import LeavesApprovalGrid from "../Components/Grids/LeavesApprovalGrid";
import LeavesGrid from "../Components/Grids/LeavesGrid";
import MyTasksGrid from "../Components/Grids/MyTasksGrid";
import SalaryComponentsGrid from "../Components/Grids/SalaryComponentsGrid";
import StoriesGrid from "../Components/Grids/StoriesGrid";
import StoryTasksGrid from "../Components/Grids/StoryTasksGrid";
import Menu from "../Components/Menu";
import AttendancePanel from "../Components/Panels/AttendancePanel";
import HolidaysPanel from "../Components/Panels/HolidaysPanel";
import LeavesPanel from "../Components/Panels/LeavesPanel";
import MyTasksPanel from "../Components/Panels/MyTasksPanel";
import SalaryComponentPanel from "../Components/Panels/SalaryComponentPanel";
import StoriesPanel from "../Components/Panels/StoriesPanel";
import StoryTasksPanel from "../Components/Panels/StoryTasksPanel";
import TabsComponent from "../Components/TabsComponent";
import TasksFilter from "../Components/TasksFilter";
import Widget from "../Components/Widget";
import "../Styles/home.css";
import { UserContext } from "../UserContext";
import AttendancePanelMob from "../Components/MobileUI/AttendancePanelMob";
import TasksPanelMob from "../Components/MobileUI/TasksPanelMob";

function NewHome() {
  const [img, setimg] = useState();
  const [name, setName] = useState([]);
  const { user } = useContext(UserContext);
  const [selectedEmployee, setSelectedEmployee] = useState("all");
  const [myAccessType, setMyAccessType] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedFromDate, setSelectedFromDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [selectedToDate, setSelectedToDate] = useState(
    new Date(Date.now() + 60 * 60 * 1000).toISOString().substring(0, 10)
  );
  const [attendanceData, setAttendanceData] = useState([]);
  const [leavesData, setLeavesData] = useState([]);
  const [storiesData, setStoriesData] = useState([]);
  const [myTasksData, setMyTasksData] = useState([]);
  const [storyTasksData, setStoryTasksData] = useState([]);
  const [documentsData, setDocumentsData] = useState([]);
  const [holidaysData, setHolidaysData] = useState([]);
  const [leavesApprovalData, setLeavesApprovalData] = useState([]);
  const [selectedTaskStatus, setSelectedTaskStatus] = useState("open");
  const [salaryComponents, setSalaryComponents] = useState();
  var history = useHistory();

  async function LoadImage() {
    let getname = user.name;
    setName(getname);
    let empid = user._id;
    var apiUrl =
      process.env.REACT_APP_API_URL + "/api/user/profilepic/" + empid;
    const apiParam = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };
    fetch(apiUrl, apiParam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          arrayBufferToBase64(repos.data.image.data.data);
        } else console.log(repos.message || repos);
      })
      .catch((err) => alert("Image: " + err));
  }
  function arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    var base64Flag = "data:image/jpeg;base64,";
    setimg(base64Flag + window.btoa(binary));
  }

  function setTab(tabIndex) {
    switch (tabIndex) {
      case 1:
        setSelectedFromDate(new Date().toISOString().substring(0, 10));
        setSelectedToDate(
          new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)
            .toISOString()
            .substring(0, 10)
        );
        break;
      case 3:
        history.push("/chats");
        break;
      case 4:
        setSelectedFromDate(new Date().toISOString().substring(0, 10));
        setSelectedToDate(
          new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)
            .toISOString()
            .substring(0, 10)
        );
        break;
      case 7:
        setSelectedFromDate(new Date().toISOString().substring(0, 10));
        setSelectedToDate(
          new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)
            .toISOString()
            .substring(0, 10)
        );
        break;
      case 8:
        setSelectedFromDate(new Date().toISOString().substring(0, 10));
        setSelectedToDate(
          new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)
            .toISOString()
            .substring(0, 10)
        );
        break;
      default:
        setSelectedFromDate(new Date().toISOString().substring(0, 10));
        setSelectedToDate(new Date().toISOString().substring(0, 10));
        break;
    }
    setSelectedTab(tabIndex);
  }

  function filterData() {
    let apiparam = {
      method: "POST",
      headers:
        selectedTab === 2
          ? {
              authtoken: localStorage.getItem("loggedintoken"),
              empid:
                (localStorage.getItem("filterEmployee") === "all"
                  ? user._id
                  : localStorage.getItem("filterEmployee")) ||
                (selectedEmployee === "all" ? user._id : selectedEmployee),
              taskstatus: selectedTaskStatus,
              access: myAccessType,
              team: selectedEmployee === "all",
            }
          : selectedTab == 7 || (myAccessType == 0 && selectedTab == 4)
          ? {
              authtoken: localStorage.getItem("loggedintoken"),
              empid:
                (localStorage.getItem("filterEmployee") === "all"
                  ? "all"
                  : localStorage.getItem("filterEmployee")) ||
                (selectedEmployee === "all" ? "all" : selectedEmployee),
              reportingtoid: user._id,
              access: user.accesstype,
            }
          : {
              authtoken: localStorage.getItem("loggedintoken"),
              empid:
                (localStorage.getItem("filterEmployee") === "all"
                  ? user._id
                  : localStorage.getItem("filterEmployee")) ||
                (selectedEmployee === "all" ? user._id : selectedEmployee),
              startdate: selectedFromDate,
              enddate: selectedToDate,
              access: myAccessType,
              team: selectedEmployee === "all",
            },
    };
    if (!apiparam.headers.empid) {
      return;
    }
    let apiUrl = process.env.REACT_APP_API_URL + "/api/";
    switch (selectedTab) {
      case 0:
        apiUrl += "attendance/filter";
        break;
      case 1:
        apiUrl += "leaves/filter";
        break;
      case 2:
        apiUrl += "mytasks/filter";
        break;
      case 4:
        myAccessType == 0
          ? (apiUrl += "holiday/getselectedholidays")
          : (apiUrl += "leavesapproval/filter");
        break;
      case 5:
        apiUrl += "stories/filter";
        break;
      case 6:
        apiUrl += "storytasks/filter";
        break;
      // case 7:
      //   apiUrl += "documents/filter";
      //   break;
      case 7:
        apiUrl += "holiday/getselectedholidays";
        break;
      case 9:
        apiUrl += "messages/filter";
        break;
      case 8:
        apiUrl += "payroll/getsalarycomponents";
        break;
      default:
        break;
    }
    fetch(apiUrl, apiparam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          switch (selectedTab) {
            case 0:
              setAttendanceData(repos.data);
              break;
            case 1:
              setLeavesData(repos.data);
              break;
            case 2:
              setMyTasksData(repos.data);
              break;
            case 4:
              myAccessType == 0
                ? setHolidaysData(repos.data)
                : setLeavesApprovalData(repos.data);
              break;
            case 5:
              setStoriesData(repos.data);
              break;
            case 6:
              setStoryTasksData(repos.data);
              break;
            case 7:
              setHolidaysData(repos.data);

              break;
            case 9:
              setDocumentsData(repos.data);
              break;
            case 8:
              setSalaryComponents(repos.data);
              break;
            default:
          }
        } else alert("Grid: " + (repos.message || repos));
      })
      .catch((err) => console.log(err));
  }

  function showPanels() {
    switch (selectedTab) {
      case 0:
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          window.navigator.userAgent
        ) ? (
          <AttendancePanelMob refreshGrid={() => filterData()} />
        ) : (
          <AttendancePanel
            refreshGrid={() => filterData()}
            attendanceData={attendanceData}
          />
        );
      case 1:
        return <LeavesPanel refreshGrid={() => filterData()} />;
      case 2:
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          window.navigator.userAgent
        ) ? (
          <TasksPanelMob refreshGrid={() => filterData()} />
        ) : (
          <MyTasksPanel refreshGrid={() => filterData()} />
        );
      case 4:
        return myAccessType == 0 ? (
          <HolidaysPanel refreshGrid={() => filterData()} />
        ) : (
          <LeavesPanel refreshGrid={() => filterData()} />
        );
      case 5:
        return <StoriesPanel refreshGrid={() => filterData()} />;
      case 6:
        return <StoryTasksPanel refreshGrid={() => filterData()} />;
      case 7:
        return <HolidaysPanel refreshGrid={() => filterData()} />;
      // case 8:
      //   return <DocumentsPanel />;
      case 8:
        return <SalaryComponentPanel />;
      default:
        return " ";
    }
  }

  function showGrid() {
    switch (selectedTab) {
      case 0:
        return (
          <AttendanceGrid
            data={attendanceData}
            selectedEmployee={selectedEmployee}
            selectedFromDate={selectedFromDate}
            selectedToDate={selectedToDate}
          />
        );
      case 1:
        return (
          <LeavesGrid refreshGrid={() => filterData()} data={leavesData} />
        );
      case 2:
        return (
          <MyTasksGrid refreshGrid={() => filterData()} data={myTasksData} />
        );
      case 4:
        return myAccessType == 0 ? (
          <HolidaysGrid
            data={holidaysData}
            username={user.name}
            refreshGrid={() => filterData()}
            selectedEmployee={selectedEmployee}
          />
        ) : (
          <LeavesApprovalGrid
            refreshGrid={() => filterData()}
            data={leavesApprovalData}
          />
        );
      case 5:
        return (
          <StoriesGrid refreshGrid={() => filterData()} data={storiesData} />
        );
      case 6:
        return (
          <StoryTasksGrid
            refreshGrid={() => filterData()}
            data={storyTasksData}
          />
        );
      case 7:
        return (
          holidaysData.length > 0 && (
            <HolidaysGrid
              data={holidaysData}
              username={user.name}
              refreshGrid={() => filterData()}
              selectedEmployee={selectedEmployee}
            />
          )
        );
      case 9:
        return <DocumentsGrid data={documentsData} />;
      case 8:
        return <SalaryComponentsGrid data={salaryComponents} />;
      default:
        return " ";
    }
  }
  function setEmployee(id) {
    setSelectedEmployee(id);
  }
  function setFromDate(date) {
    setSelectedFromDate(new Date(date).toISOString().substring(0, 10));
  }
  function setToDate(date) {
    setSelectedToDate(new Date(date).toISOString().substring(0, 10));
  }
  useEffect(() => {
    filterData();
  }, [selectedEmployee, selectedTaskStatus, selectedTab]);

  useEffect(() => {
    if (user) {
      setSelectedEmployee(user._id);
      setMyAccessType(user.accesstype);
      LoadImage();
    }
  }, [user]);

  useEffect(() => {}, [
    attendanceData,
    leavesData,
    documentsData,
    leavesApprovalData,
    holidaysData,
    selectedEmployee,
  ]);

  useEffect(() => {
    filterData();
  }, []);
  return (
    <div
      id="attendance_page"
      // style={{ transformOrigin: " 0px 0px", transform: "scale(0.934)" }}
    >
      <img
        id="overall_bg_design"
        src="overall_bg_design.png"
        srcset="overall_bg_design.png 1x, overall_bg_design@2x.png 2x"
        alt=""
      />
      <Widget img={img} name={name} />
      <div id="Group_27">
        <div id="mid_bg_">
          <svg class="comment_bg_box_bk">
            <linearGradient
              id="comment_bg_box_bk"
              spreadMethod="pad"
              x1="0.5"
              x2="0.5"
              y1="0"
              y2="1"
            >
              <stop offset="0" stop-color="#b5ffb7" stop-opacity="1"></stop>
              <stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
            </linearGradient>
            <rect
              id="comment_bg_box_bk"
              rx="16"
              ry="16"
              lx="16"
              ly="16"
              x="0"
              y="0"
              width="calc(100vw - 220px)"
              height="215"
            ></rect>
          </svg>
        </div>
        {showPanels()}
      </div>
      <div id="filter_grp">
        <svg class="full_bg_design_bo">
          <linearGradient
            id="full_bg_design_bo"
            spreadMethod="pad"
            x1="0.5"
            x2="0.5"
            y1="0"
            y2="1"
          >
            <stop offset="0" stop-color="#b5ffb7" stop-opacity="1"></stop>
            <stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
          </linearGradient>
          <rect
            id="full_bg_design_bo"
            rx="0"
            ry="0"
            x="0"
            y="0"
            width="218"
            height="214"
          ></rect>
        </svg>
      </div>
      <div id="Group_22">
        <Menu />
      </div>
      <TabsComponent
        tabIndex={selectedTab}
        changeTab={(tabIndex) => setTab(tabIndex)}
      />
      <div className="Rectangle_28" style={{ overflow: "scroll" }}>
        {showGrid()}
      </div>
      <div id="filter_full_grp">
        <svg class="full_bg_design_b">
          <linearGradient
            id="full_bg_design_b"
            spreadMethod="pad"
            x1="0.5"
            x2="0.5"
            y1="0"
            y2="1"
          >
            <stop offset="0" stop-color="#b5ffb7" stop-opacity="1"></stop>
            <stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
          </linearGradient>
          <rect
            id="full_bg_design_b"
            rx="0"
            ry="0"
            x="0"
            y="0"
            width="218"
            height="214"
          ></rect>
        </svg>
        <div id="unchangable_grp">
          <div id="Search_lbl_">
            <span>View</span>
          </div>
          <div id="right_chain_grp">
            <div id="Group_17">
              <svg class="bdr_2_cb">
                <ellipse
                  id="bdr_2_cb"
                  rx="21.5"
                  ry="21.5"
                  cx="21.5"
                  cy="21.5"
                ></ellipse>
              </svg>
              <svg class="bdr_1_cc">
                <ellipse
                  id="bdr_1_cc"
                  rx="17.5"
                  ry="17.5"
                  cx="17.5"
                  cy="17.5"
                ></ellipse>
              </svg>
              <button onClick={(e) => filterData()}>
                <img
                  id="search_img_"
                  src="search_img_.png"
                  srcset="search_img_.png 1x, search_img_@2x.png 2x"
                  alt=""
                />
              </button>
            </div>
            <img
              id="link1_up"
              src="link1_up.png"
              srcset="link1_up.png 1x, link1_up@2x.png 2x"
              alt=""
            />

            <img
              id="link1_down"
              src="link1_down.png"
              srcset="link1_down.png 1x, link1_down@2x.png 2x"
              alt=""
            />

            <svg class="left_link_up_cg" viewBox="2247.021 277.652 8.117 2.127">
              <path
                id="left_link_up_cg"
                d="M 2247.021240234375 277.6515502929688 C 2247.52294921875 277.7061767578125 2248.099853515625 277.7145080566406 2248.880126953125 277.7712097167969 C 2249.152587890625 277.7895202636719 2249.626708984375 277.859130859375 2250.271240234375 277.9796752929688 C 2254.037841796875 278.6775817871094 2255.13818359375 279.778076171875 2255.13818359375 279.778076171875"
              ></path>
            </svg>
            <svg
              class="left_link_down_ch"
              viewBox="2242.392 307.828 4.219 0.672"
            >
              <path
                id="left_link_down_ch"
                d="M 2246.6103515625 308.5001220703125 C 2246.6103515625 308.5001220703125 2244.0478515625 308.3907470703125 2242.3916015625 307.8282470703125"
              ></path>
            </svg>
          </div>
        </div>
        {selectedTab === 3 ? (
          ""
        ) : selectedTab === 2 ? (
          <TasksFilter
            employee={
              localStorage.getItem("filterEmployee") || selectedEmployee
            }
            changeEmployee={(id) => {
              setEmployee(id);
              localStorage.setItem("filterEmployee", id);
            }}
            taskStatus={selectedTaskStatus}
            changeTaskStatus={(status) => setSelectedTaskStatus(status)}
            onFilterClick={() => filterData()}
          />
        ) : (
          <Filter
            employee={
              localStorage.getItem("filterEmployee") || selectedEmployee
            }
            fromDate={selectedFromDate}
            toDate={selectedToDate}
            changeEmployee={(id) => {
              setEmployee(id);
              localStorage.setItem("filterEmployee", id);
            }}
            changeFromDate={(date) => setFromDate(date)}
            changeToDate={(date) => setToDate(date)}
            onFilterClick={() => filterData()}
            selectedTab={selectedTab}
          />
        )}
      </div>
    </div>
  );
}

export default NewHome;
