import { Paper } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

function SalaryComponentsGrid() {
  const [data, setData] = useState();
  useEffect(() => {
    const apiURL =
      process.env.REACT_APP_API_URL + "/api/payroll/getsalarycomponents";
    const apiParams = {
      method: "POST",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        setData(repos.data);
      });
  }, []);
  return (
    <div>
      <TableContainer component={Paper}>
        <Table
          sx={{
            minWidth: 650,
          }}
        >
          <TableHead
            sx={{ backgroundColor: process.env.REACT_APP_PRIMARY_COLOR }}
          >
            <TableRow
              sx={{
                "& .MuiTableCell-root": {
                  textAlign: "center",
                  fontFamily: "sans-serif",
                  fontSize: "20px",
                  fontWeight: 700,
                  color: "white",
                },
              }}
            >
              <TableCell>Component Name</TableCell>
              <TableCell>Is Compulsory</TableCell>
              <TableCell>Is Deduction</TableCell>
              <TableCell>Is Percentage</TableCell>

              <TableCell>SubComponent</TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              "& .MuiTableRow-root:hover": {
                backgroundColor: process.env.REACT_APP_SECONDARY_COLOR,
              },
            }}
          >
            {data &&
              data.map((component) => {
                return (
                  <TableRow
                    key={component._id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "& .MuiTableCell-root": {
                        textAlign: "center",
                        fontFamily: "Helvetica",
                        fontSize: "18px",
                      },
                    }}
                  >
                    <TableCell>{component.componentName}</TableCell>
                    <TableCell>{component.isCompulsory.toString()}</TableCell>
                    <TableCell>{component.isDeduction.toString()}</TableCell>
                    <TableCell>{component.isPercentage.toString()}</TableCell>
                    <TableCell>
                      {component.isPercentage ? component.subComponent : "NA"}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default SalaryComponentsGrid;
