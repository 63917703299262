import React, { useContext, useState } from "react";
import { Button, TextField } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { UserContext } from "../../UserContext";
import "../../Styles/holidaysPanel.css";
function HolidaysPanel() {
  //var
  //usestate
  const [holiday, setHoliday] = useState(new Date());
  const [occation, setOccation] = useState("");
  const { user, setUser } = useContext(UserContext);
  //useeffect
  //function
  const addHoliday = (props) => {
    if (user.accesstype != "5") {
      alert("Only Admins can add an holiday");
      return;
    }
    const apiURL = process.env.REACT_APP_API_URL + "/api/holiday";
    const apiParams = {
      method: "POST",
      headers: {
        od: encodeURIComponent(holiday),
        occation: occation,
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };

    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          alert("Holiday Added Successfully");
          setHoliday(new Date());
          setOccation("");
          props.refreshGrid();
        } else alert("Holiday Could Not be Added");
      });
  };
  return (
    // <div>
    //   <div
    //     style={{
    //       display: "grid",
    //       gridTemplateColumns: "50% 50%",
    //       gridColumnGap: "10px",
    //     }}
    //   >
    //     <LocalizationProvider dateAdapter={AdapterDateFns}>
    //       <DatePicker
    //         inputFormat="dd/MM/yyyy"
    //         label="From"
    //         value={new Date(holiday).toISOString().split("T")[0]}
    //         onChange={(newValue) => setHoliday(newValue)}
    //         required
    //         renderInput={(params) => <TextField {...params} />}
    //         style={{ width: "100%" }}
    //       />
    //     </LocalizationProvider>

    //     <TextField
    //       id="occation"
    //       label="Occation"
    //       value={occation}
    //       onChange={(e) => setOccation(e.target.value)}
    //       sx={{
    //         backgroundColor: "lightgray",
    //       }}
    //     />
    //   </div>
    //   <div style={{ marginTop: "10px" }}>
    //     <Button
    //       variant="contained"
    //       color="success"
    //       onClick={() => {
    //         addHoliday();
    //       }}
    //     >
    //       Add Holiday
    //     </Button>
    //   </div>
    // </div>
    <div id="mid_change_section">
      <div id="app_res_grp_holiday">
        <div id="Start_Session_lbl_holi">
          <span>Create</span>
        </div>
        <svg class="bdr_holi">
          <ellipse
            id="bdr_holi"
            rx="21.5"
            ry="21.5"
            cx="21.5"
            cy="21.5"
          ></ellipse>
        </svg>
        <svg class="bdr_holi_1">
          <ellipse
            id="bdr_holi_1"
            rx="17.5"
            ry="17.5"
            cx="17.5"
            cy="17.5"
          ></ellipse>
        </svg>
        <button
          onClick={() => {
            addHoliday();
          }}
        >
          <img
            id="add_buttton_img_holi"
            src="add_buttton_img_holi.png"
            srcset="add_buttton_img_holi.png 1x, add_buttton_img_holi@2x.png 2x"
            alt=""
          />
        </button>

        <img
          id="link_up_holi"
          src="link_up_holi.png"
          srcset="link_up_holi.png 1x, link_up_holi@2x.png 2x"
          alt=""
        />

        <img
          id="link_down_holi"
          src="link_down_holi.png"
          srcset="link_down_holi.png 1x, link_down_holi@2x.png 2x"
          alt=""
        />

        <svg class="left_link_up_holi_b" viewBox="2247.021 277.652 8.117 2.127">
          <path
            id="left_link_up_holi_b"
            d="M 2247.021240234375 277.6515502929688 C 2247.52294921875 277.7061767578125 2248.099853515625 277.7145080566406 2248.880126953125 277.7712097167969 C 2249.152587890625 277.7895202636719 2249.626708984375 277.859130859375 2250.271240234375 277.9796752929688 C 2254.037841796875 278.6775817871094 2255.13818359375 279.778076171875 2255.13818359375 279.778076171875"
          ></path>
        </svg>
        <svg
          class="left_link_down_holi_b"
          viewBox="2242.392 307.828 4.219 0.672"
        >
          <path
            id="left_link_down_holi_b"
            d="M 2246.6103515625 308.5001220703125 C 2246.6103515625 308.5001220703125 2244.0478515625 308.3907470703125 2242.3916015625 307.8282470703125"
          ></path>
        </svg>
        <svg class="bdr_back_holi">
          <ellipse
            id="bdr_back_holi"
            rx="21.5"
            ry="21.5"
            cx="21.5"
            cy="21.5"
          ></ellipse>
        </svg>
        <svg class="bdr_holi_ca">
          <ellipse
            id="bdr_holi_ca"
            rx="17.5"
            ry="17.5"
            cx="17.5"
            cy="17.5"
          ></ellipse>
        </svg>
        <img
          id="link1_mid_top_holi"
          src="link1_mid_top_holi.png"
          srcset="link1_mid_top_holi.png 1x, link1_mid_top_holi@2x.png 2x"
          alt=""
        />

        <img
          id="link1_mid_down_holi"
          src="link1_mid_down_holi.png"
          srcset="link1_mid_down_holi.png 1x, link1_mid_down_holi@2x.png 2x"
          alt=""
        />

        <svg
          class="left_link_up_holi_cd"
          viewBox="2247.021 277.652 8.117 2.127"
        >
          <path
            id="left_link_up_holi_cd"
            d="M 2247.021240234375 277.6515502929688 C 2247.52294921875 277.7061767578125 2248.099853515625 277.7145080566406 2248.880126953125 277.7712097167969 C 2249.152587890625 277.7895202636719 2249.626708984375 277.859130859375 2250.271240234375 277.9796752929688 C 2254.037841796875 278.6775817871094 2255.13818359375 279.778076171875 2255.13818359375 279.778076171875"
          ></path>
        </svg>
        <svg
          class="left_link_down_holi_ce"
          viewBox="2242.392 307.828 4.219 0.672"
        >
          <path
            id="left_link_down_holi_ce"
            d="M 2246.6103515625 308.5001220703125 C 2246.6103515625 308.5001220703125 2244.0478515625 308.3907470703125 2242.3916015625 307.8282470703125"
          ></path>
        </svg>
      </div>
      <textarea
        placeholder="Occation"
        class="occasion_input"
        value={occation}
        onChange={(e) => setOccation(e.target.value)}
      ></textarea>
      <input
        type="date"
        class="date_btn"
        value={new Date(holiday).toISOString().split("T")[0]}
        onChange={(e) => setHoliday(e.target.value)}
      />
    </div>
  );
}

export default HolidaysPanel;
