import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {geoFindMe} from "../../Utils"

function AttendancePanelMob(props) {
  const [showStop, setShowStop] = useState(false);
  const [startTime, setStartTime] = useState();
  const [endTime, setEndtime] = useState();
  const [comments, setComments] = useState("");
  const [startlocation, setStartLocation] = useState({});
  const [endLocation, setEndLocation] = useState({});
  const [myUserid, setMyUserId] = useState();
  const [name, setName] = useState();
 
  const startClick = async (e) => {
    setShowStop(false);
    geoFindMe().then(e => {
      setStartLocation({ startlatitude: e.latitude, startlongitude: e.longitude });
    }).catch(err=>alert(err.message))
  };
  const endclick = async () => {
    setShowStop(true);
    geoFindMe().then(e => {
      setEndLocation({ stoplatitude: e.latitude, stoplongitude: e.longitude });
    }).catch(err=>alert(err.message))
  };
  useEffect(() => {
    getLoginStatus();
  }, []);

  function getLoginStatus() {
    let myTokenData = localStorage.getItem("loggedintoken").split(".")[1];
    let userId = JSON.parse(atob(myTokenData))._id;
    const apiUrl =
      process.env.REACT_APP_API_URL + "/api/attendance/loginstatus/" + userId;
    let apiParams = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };
    fetch(apiUrl, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data[0] && repos.data[0].Starttime) {
          setStartTime(repos.data[0].Starttime);
          setMyUserId(repos.data[0]._id);
          if (
            repos.data[0].Starttime !== null &&
            repos.data[0].Starttime.length > 8
          ) {
            setEndtime(repos.data[0].Stoptime);
          }
          if (
            repos.data[0].Stoptime === null ||
            repos.data[0].Stoptime.length < 8
          ) {
            setShowStop(true);
          }
        }
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    if (startlocation.startlatitude === undefined) {
      return;
    }
    let startTimeLocal = parseInt(
      JSON.parse(atob(localStorage.getItem("loggedintoken").split(".")[1]))
        .shiftStart
    );
    let getname = JSON.parse(
      atob(localStorage.getItem("loggedintoken").split(".")[1])
    ).name;

    let t = new Date().toLocaleTimeString();
    let h = new Date();
    let hrs = h.getHours();
    let mins = hrs * 60 + h.getMinutes();
    if (mins < startTimeLocal) {
      setStartTime(t);
      const apiUrl =
        process.env.REACT_APP_API_URL + "/api/attendance/starttimer";
      let apiParam = {
        method: "POST",
        headers: {
          empid: JSON.parse(localStorage.getItem("loggedInUser"))._id,
          fullname: getname,
          starttime: t,
          latitude: startlocation.startlatitude,
          longitude: startlocation.startlongitude,
          stoplatitude: "",
          stoplongitude: "",
          latereason: encodeURIComponent(comments),
          reasons: "",
          endtime: "",
          authtoken: localStorage.getItem("loggedintoken"),
        },
      };

      fetch(apiUrl, apiParam)
        .then((res) => (res.status === 200 ? res.json() : res.text()))
        .then((repos) => {
          if (repos.data) {
            // setMyUserId(repos.data._id);
            // setShowStart(true);
            props.refreshGrid();
            alert("Your session start has been recorded");
            getLoginStatus();
            setComments("");
          } else alert(repos.message || repos);
        })
        .catch((err) => alert(err));
    } else {
      if (comments === null || comments.length === 0) {
        alert("Please enter comments!");
        return;
      } else {
        setStartTime(t);

        const apiUrlLog =
          process.env.REACT_APP_API_URL + "/api/attendance/starttimer";
        var apiParamLog = {
          method: "POST",
          headers: {
            authtoken: localStorage.getItem("loggedintoken"),
            empid: JSON.parse(localStorage.getItem("loggedInUser"))._id,
            fullname: getname,
            starttime: t,
            endtime: "",
            latitude: startlocation.startlatitude,
            longitude: startlocation.startlongitude,
            stoplatitude: "",
            stoplongitude: "",
            latereason: encodeURIComponent(comments),
            reasons: "",
            shiftstarttime: JSON.parse(localStorage.getItem("loggedInUser"))
              .shiftStart,
            shiftendtime: JSON.parse(localStorage.getItem("loggedInUser"))
              .shiftEnd,
          },
        };

        fetch(apiUrlLog, apiParamLog)
          .then((res) => (res.status === 200 ? res.json() : res.text()))
          .then((repos) => {
            if (repos.data) {
              setComments("");
              props.refreshGrid();
              alert("Session started successfully!");
              getLoginStatus();
            } else alert(repos.message || repos);
          })
          .catch((err) => alert(err));
        // getAttendanceStatus();
      }
    }
  }, [startlocation]);

  useEffect(() => {
    if (endLocation.stoplatitude === undefined) {
      return;
    }
    var stopTime = parseInt(
      JSON.parse(atob(localStorage.getItem("loggedintoken").split(".")[1]))
        .shiftEnd
    );

    var t = new Date().toLocaleTimeString();
    var h = new Date();
    var hrs = h.getHours();
    var mins = hrs * 60 + h.getMinutes();

    if (mins > stopTime) {
      setEndtime(t);
      const apiUrl =
        process.env.REACT_APP_API_URL + "/api/attendance/stoptimer";
      var apiParam = {
        method: "POST",
        headers: {
          authtoken: localStorage.getItem("loggedintoken"),
          id: myUserid,
          latitude: endLocation.stoplatitude,
          longitude: endLocation.stoplongitude,
          endtime: t,
          reasons: encodeURIComponent(comments),
        },
      };

      fetch(apiUrl, apiParam)
        .then((res) => (res.status === 200 ? res.json() : res.text()))
        .then((repos) => {
          if (repos.data) {
            // setMyUserId(repos.data._id);

            props.refreshGrid();
            alert("Session stopped successfully!");
            setShowStop(false);
            // setShowStart(true);
            getLoginStatus();
            setComments("");
          } else alert(JSON.stringify(repos));
        })
        .catch((err) => alert(err));
    } else {
      if (comments === null || comments.length === 0) {
        alert("Please enter comments!");
        return;
      } else {
        setEndtime(t);
        const apiUrlz =
          process.env.REACT_APP_API_URL + "/api/attendance/stoptimer";
        var apiParamz = {
          method: "POST",
          headers: {
            id: myUserid,
            latitude: endLocation.stoplatitude,
            longitude: endLocation.stoplongitude,
            endtime: t,
            reasons: encodeURIComponent(comments),
            authtoken: localStorage.getItem("loggedintoken"),
          },
        };

        fetch(apiUrlz, apiParamz)
          .then((res) => (res.status === 200 ? res.json() : res.text()))
          .then((repos) => {
            if (repos.data) {
              // setMyUserId(repos.data._id);
              setShowStop(false);
              // setShowStart(true);
              setComments("");
              props.refreshGrid();
              alert("Session stopped successfully!");
              getLoginStatus();
            } else alert(repos.message || repos);
          })
          .catch((err) => alert(err));
        // getAttendanceStatus();
      }
    }
  }, [endLocation]);
  return (
    <div>
      <div id="input_comment_mob">
        <textarea
          class="mob_employee_bdr_input"
          placeholder="Comments..."
          value={comments}
          onChange={(e) => setComments(e.target.value)}
        ></textarea>
      </div>
      <div id="btn_full_grp">
        <div id="Group_8">
          <svg class="Ellipse_2">
            <ellipse
              id="Ellipse_2"
              rx="17.5"
              ry="17.5"
              cx="17.5"
              cy="17.5"
            ></ellipse>
          </svg>
          <button
            onClick={(e) => {
              startClick(e);
            }}
            disabled={!showStop ? false : true}
          >
            <img
              id="play-buttton_img_task_mob"
              src="play-buttton_img_mob.png"
              alt=""
            />
          </button>
        </div>
        <div id="reset_btn_leave">
          <svg class="bdr_reset_leave">
            <ellipse
              id="bdr_reset_leave"
              rx="17.5"
              ry="17.5"
              cx="17.5"
              cy="17.5"
            ></ellipse>
          </svg>
          <button
            onClick={(e) => {
              endclick(e);
            }}
            disabled={!showStop ? true : false}
          >
            <img id="stop-button_img_leave" src="stop_time.png" alt="" />
          </button>
        </div>
        <div id="Create_lbl_">
          <span>Start</span>
        </div>
        <div id="Reset_lbl">
          <span>Stop</span>
        </div>
      </div>
    </div>
  );
}

export default AttendancePanelMob;
