import React, { useState, useContext, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { UserContext } from "../UserContext";
import NotificationBadge, { Effect } from "react-notification-badge";
// import { makeStyles } from "@mui/styles";
import { makeStyles } from "@material-ui/core";
import io from "socket.io-client";
import "../Styles/tabs.css";

const ENDPOINT = process.env.REACT_APP_API_URL;
var socket, selectedChatCompare;

const useStyle = makeStyles({
  indicator: {
    left: "0px",
  },
  backgroundColor: "secondary",
});

function TabsComponent(props) {
  //variable
  const { user, setUser, notification, setNotification } =
    useContext(UserContext);

  const classes = useStyle();
  var accesstypes = parseInt(user.accesstype);
  //UseState
  const [value, setValue] = useState(0);
  //useEffect

  useEffect(() => {
    localStorage.removeItem("tab");
    localStorage.setItem("tab", 0);
  }, []);

  //functions
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        showStart={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleTabChange = async (tab, newValue) => {
    props.changeTab(newValue);
    setValue(newValue);
    localStorage.setItem("tab", newValue);
    let localid;
    let access = user.accesstype;
    if (access === "0") {
      localid = user._id;
      // setSelectedEmployee(localid);
    } else {
      // localid = selectedEmployee;
    }

    LoadTab();
  };

  function LoadTab(index) {
    let selectedTab = parseInt(localStorage.getItem("tab"))
      ? parseInt(localStorage.getItem("tab"))
      : 0;
  }
  useEffect(() => {
    socket = io(ENDPOINT);
    socket.emit("setup", user);
    socket.on("connected", () => {});
  }, []);
  useEffect(() => {
    socket.on("message received", (newMessageReceived) => {
      if (
        !selectedChatCompare ||
        selectedChatCompare._id !== newMessageReceived.chat._id
      ) {
        //notification
        if (!notification.includes(newMessageReceived)) {
          setNotification([newMessageReceived, ...notification]);
        }
      }
    });
  });
  //renderMethod
  return (
    // <div id="menu_grp">
    //   <Tabs
    //     value={value}
    //     onChange={handleTabChange}
    //     orientation="vertical"
    //     variant="scrollable"
    //     sx={{
    //       borderRight: 1,
    //       borderColor: "divider",
    //       backgroundColor: " rgba(255, 255, 255, 1)",
    //       borderTopRightRadius: "15px",
    //       borderBottomRightRadius: "15px",
    //     }}
    //     classes={{
    //       indicator: classes.indicator,
    //       backgroundColor: classes.backgroundColor,
    //     }}
    //   >
    //     <Tab label="Attendance" {...a11yProps(0)} />
    //     <Tab label="Leaves" {...a11yProps(1)} />
    //     <Tab label="Tasks" {...a11yProps(2)} />
    //     <Tab
    //       label={
    //         <React.Fragment>
    //           <p>
    //             Messages
    //             <NotificationBadge
    //               count={notification.length}
    //               effect={Effect.SCALE}
    //             />
    //           </p>
    //         </React.Fragment>
    //       }
    //       {...a11yProps(3)}
    //     />
    //     {accesstypes === 0 ? (
    //       <Tab label="Holidays" {...a11yProps(4)} />
    //     ) : (
    //       <Tab label="Approval" {...a11yProps(4)} />
    //     )}
    //     {accesstypes === 0 ? (
    //       ""
    //     ) : (
    //       <Tab label="Stories" {...a11yProps(5)} style={{ display: "none" }} />
    //     )}
    //     {accesstypes === 0 ? (
    //       ""
    //     ) : (
    //       <Tab
    //         label="Story Tasks"
    //         {...a11yProps(6)}
    //         style={{ display: "none" }}
    //       />
    //     )}
    //     {/* <Tab label="Documents" {...a11yProps(6)} />
    //       <Tab label="Messages" {...a11yProps(7)} /> */}

    //     {accesstypes === 0 ? null : <Tab label="Holidays" {...a11yProps(7)} />}
    //     {/* {accesstypes === 0 ? null : (
    //       <Tab label="Salary components" {...a11yProps(8)} />
    //     )} */}
    //   </Tabs>
    // </div>
    <div>
      <div id="menu_grp">
        <svg class="full_bg_menu">
          <rect
            id="full_bg_menu"
            rx="15"
            ry="15"
            x="0"
            y="0"
            width="138"
            height="100%"
          ></rect>
        </svg>
        <div
          id="Attendance_lbl"
          className="tab"
          style={{
            borderBottom:
              parseInt(localStorage.getItem("tab")) == 0
                ? "2px solid #14c871"
                : "",
            color: parseInt(localStorage.getItem("tab")) == 0 ? "green" : "",
            cursor: "pointer",
          }}
          onClick={() => handleTabChange(this, 0)}
        >
          <span>Attendance</span>
        </div>
        <img
          id="immigration_img"
          src="immigration_img.png"
          srcset="immigration_img.png 1x, immigration_img@2x.png 2x"
          alt=""
        />

        <div
          id={user.accesstype < 1 ? "Messages_lbl" : "Approval_lbl"}
          className="tab"
          style={{
            borderBottom:
              parseInt(localStorage.getItem("tab")) == 7
                ? "2px solid #14c871"
                : "",
            color: parseInt(localStorage.getItem("tab")) == 7 ? "green" : "",
            cursor: "pointer",
          }}
          onClick={() => handleTabChange(this, 7)}
        >
          <span>Holidays</span>
        </div>
        <img
          id={user.accesstype < 1 ? "msg_img" : "sick_img_"}
          src="holiday_img.png"
          srcset="holiday_img.png 1x, holiday_img@2x.png 2x"
          alt=""
        />

        <div
          id="Leaves_lbl"
          className="tab"
          style={{
            borderBottom:
              parseInt(localStorage.getItem("tab")) == 1
                ? "2px solid #14c871"
                : "",
            color: parseInt(localStorage.getItem("tab")) == 1 ? "green" : "",
            cursor: "pointer",
          }}
          onClick={() => handleTabChange(this, 1)}
        >
          <span>Leaves</span>
        </div>
        <img
          id="sick_img"
          src="sick_img.png"
          srcset="sick_img.png 1x, sick_img@2x.png 2x"
          alt=""
        />

        {/* <div
          id="Messages_lbl"
          className="tab"
          style={{
            borderBottom:
              parseInt(localStorage.getItem("tab")) == 3
                ? "2px solid #14c871"
                : "",
            color: parseInt(localStorage.getItem("tab")) == 3 ? "green" : "",
            cursor: "pointer",
          }}
          onClick={() => handleTabChange(this, 3)}
        >
          <span>Messages</span>
        </div>
        <img
          id="msg_img"
          src="msg_img.png"
          srcset="msg_img.png 1x, msg_img@2x.png 2x"
          alt=""
        /> */}

        <div
          id="Messages_lbl"
          className="tab"
          style={{
            display: user.accesstype < 1 ? "none" : "",
            borderBottom:
              parseInt(localStorage.getItem("tab")) == 4
                ? "2px solid #14c871"
                : "",
            color: parseInt(localStorage.getItem("tab")) == 4 ? "green" : "",
            cursor: "pointer",
          }}
          onClick={() => handleTabChange(this, 4)}
        >
          <span>Approval</span>
        </div>
        <img
          style={{ display: user.accesstype < 1 ? "none" : "" }}
          id="msg_img"
          src="sick_img_.png"
          srcset="sick_img_.png 1x, sick_img_@2x.png 2x"
          alt=""
        />

        <div
          id="Tasks_lbl"
          className="tab"
          style={{
            borderBottom:
              parseInt(localStorage.getItem("tab")) == 2
                ? "2px solid #14c871"
                : "",
            color: parseInt(localStorage.getItem("tab")) == 2 ? "green" : "",
            cursor: "pointer",
          }}
          onClick={() => handleTabChange(this, 2)}
        >
          <span>Tasks</span>
        </div>
        <img
          id="task_img"
          src="task_img.png"
          srcset="task_img.png 1x, task_img@2x.png 2x"
          alt=""
        />
        {/*<img*/}
        {/*  id="salary_img"*/}
        {/*  src="salary.png"*/}
        {/*  alt=""*/}
        {/*  style={{ display: user.accesstype < 1 ? "none" : "" }}*/}
        {/*/>*/}
        {/*<div*/}
        {/*  id="salary_lbl"*/}
        {/*  className="tab"*/}
        {/*  style={{*/}
        {/*    borderBottom:*/}
        {/*      parseInt(localStorage.getItem("tab")) == 8*/}
        {/*        ? "2px solid #14c871"*/}
        {/*        : "",*/}
        {/*    color: parseInt(localStorage.getItem("tab")) == 8 ? "green" : "",*/}
        {/*    cursor: "pointer",*/}
        {/*    display: user.accesstype < 1 ? "none" : "",*/}
        {/*  }}*/}
        {/*  onClick={() => handleTabChange(this, 8)}*/}
        {/*>*/}
        {/*  <span>Salary</span>*/}
        {/*</div>*/}
      </div>
    </div>
  );
}

export default TabsComponent;
