import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import "../../Styles/salary.css";
import { useHistory } from "react-router-dom";

function SalaryComponentPanel(props) {
  const [componentName, setComponentName] = useState();
  const [isPercentage, setIsPercentage] = useState(false);
  const [data, setData] = useState();
  const [isCompulsory, setIsCompulsory] = useState(false);
  const [subComponent, setSubComponent] = useState("");
  const [isDeduction, setIsDeduction] = useState(false);
  const history = useHistory();

  const handleSaveComponent = async () => {
    const apiURL =
      process.env.REACT_APP_API_URL + "/api/payroll/addsalarycomponents";
    const apiParams = {
      method: "POST",
      headers: {
        componentName: componentName,
        isPercentage: isPercentage,
        isCompulsory: isCompulsory,
        subComponent: subComponent && subComponent,
        isDeduction: isDeduction,
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };
    await fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        setData(repos.data);
      });
  };
  useEffect(() => {
    const apiURL =
      process.env.REACT_APP_API_URL + "/api/payroll/getsalarycomponents";
    const apiParams = {
      method: "POST",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        setData(repos.data);
      });
  }, []);

  return (
    // <div>
    //   <Grid container spacing={2}>
    //     <Grid item xs={3}>
    //       <TextField
    //         type="text"
    //         defaultValue={componentName}
    //         label="Component name"
    //         onChange={(e) => {
    //           setComponentName(e.target.value);
    //         }}
    //         sx={{ width: "100%" }}
    //       />
    //     </Grid>
    //     <Grid item xs={2}>
    //       <label>Is Percentage</label>
    //       <input
    //         type="checkbox"
    //         value={isPercentage}
    //         onChange={(e) => setIsPercentage(e.target.checked)}
    //       />
    //     </Grid>
    //     {isPercentage ? (
    //       <Grid item xs={3}>
    //         <FormControl>
    //           <InputLabel id="demo-simple-select-label">
    //             Sub-component
    //           </InputLabel>
    //           <Select
    //             labelId="demo-simple-select-label"
    //             id="demo-simple-select"
    //             label="Sub-component"
    //             sx={{ width: 120 }}
    //             value={subComponent}
    //             onChange={(e) => {
    //               setSubComponent(e.target.value);
    //             }}
    //           >
    //             {data &&
    //               data.map((m) => {
    //                 return (
    //                   <MenuItem value={m.componentName}>
    //                     {m.componentName}
    //                   </MenuItem>
    //                 );
    //               })}
    //           </Select>
    //         </FormControl>
    //       </Grid>
    //     ) : null}
    //     <Grid item xs={2}>
    //       <label>Is Compulsory</label>
    //       <input
    //         type="checkbox"
    //         value={isCompulsory}
    //         onChange={(e) => setIsCompulsory(e.target.checked)}
    //       />
    //     </Grid>
    //     <Grid item xs={2}>
    //       <label>Is Deduction</label>
    //       <input
    //         type="checkbox"
    //         value={isDeduction}
    //         onChange={(e) => setIsDeduction(e.target.checked)}
    //       />
    //     </Grid>
    //     <Grid item>
    //       <Button
    //         variant="contained"
    //         color="success"
    //         onClick={() => {
    //           handleSaveComponent();
    //         }}
    //       >
    //         Save
    //       </Button>
    //     </Grid>
    //   </Grid>
    // </div>
    <div id="mid_changes_salary">
      <svg class="bdr_2">
        <ellipse id="bdr_2" rx="21.5" ry="21.5" cx="21.5" cy="21.5"></ellipse>
      </svg>
      <svg class="bdr_1">
        <ellipse id="bdr_1" rx="17.5" ry="17.5" cx="17.5" cy="17.5"></ellipse>
      </svg>
      <button
        onClick={() => {
          handleSaveComponent();
        }}
      >
        <img id="play-buttton_img" src="save_salary.png" alt="" />
      </button>

      <img id="link_up" src="link_up.png" srcset="link_up.png 1x" alt="" />

      <img
        id="link_down"
        src="link_down.png"
        srcset="link_down.png 1x"
        alt=""
      />

      <div id="mid_grp_">
        <svg class="bdr_back">
          <ellipse
            id="bdr_back"
            rx="21.5"
            ry="21.5"
            cx="21.5"
            cy="21.5"
          ></ellipse>
        </svg>
        <svg class="bdr">
          <ellipse id="bdr" rx="17.5" ry="17.5" cx="17.5" cy="17.5"></ellipse>
        </svg>
      </div>
      <img
        id="link1_mid_top"
        src="link1_mid_top.png"
        srcset="link1_mid_top.png 1x"
        alt=""
      />

      <img
        id="link1_mid_down"
        src="link1_mid_down.png"
        srcset="link1_mid_down.png 1x"
        alt=""
      />

      <div id="Inputs_and_check_box_grp_op">
        <input
          placeholder="Component"
          class="component_input_OP"
          value={componentName}
          onChange={(e) => {
            setComponentName(e.target.value);
          }}
        />
        {isPercentage ? (
          <select
            placeholder="Parent Component"
            class="subcomponent_input_OP"
            value={subComponent}
            onChange={(e) => {
              setSubComponent(e.target.value);
            }}
          >
            {data &&
              data.map((m) => {
                return (
                  <option value={m.componentName}>{m.componentName}</option>
                );
              })}
          </select>
        ) : (
          ""
        )}
        <div id="percentage_lbl_grp">
          <input
            type="checkbox"
            value={isPercentage}
            onChange={(e) => setIsPercentage(e.target.checked)}
            class="checkbox_percentage"
          />

          <div id="Is_Percentage_lbl">
            <span>Is Percentage</span>
          </div>
        </div>
        <div id="compulsory_lbl_grp">
          <input
            type="checkbox"
            class="compulsory_chxkbx"
            value={isCompulsory}
            onChange={(e) => setIsCompulsory(e.target.checked)}
          />

          <div id="Is_Compulsory_lbl">
            <span>Is Compulsory</span>
          </div>
        </div>
        <div id="Deduction_lbl_grp">
          <input
            type="checkbox"
            class="checkbox_ded"
            value={isDeduction}
            onChange={(e) => setIsDeduction(e.target.checked)}
          />

          <div id="Is_Deduction_lbl">
            <span>Is Deduction</span>
          </div>
        </div>
        <div id="component_lbl_">
          <span>Component</span>
        </div>
        {isPercentage ? (
          <div id="subcomponent_lbl_">
            <span>Parent component</span>
          </div>
        ) : (
          ""
        )}
      </div>
      <div id="payroll_btn">
        <Button
          variant="contained"
          color="success"
          onClick={() => history.push("/payroll")}
        >
          Payroll
        </Button>
      </div>
    </div>
  );
}

export default SalaryComponentPanel;
