import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    paddingTop: 20,
  },
  header: {
    margin: 10,
    padding: 5,
    textAlign: "center",
    fontSize: 24,
  },
  address: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
  },
  tableRow: { flexDirection: "row" },
  tableCol: {
    width: "50%",
    borderStyle: "solid",
    // borderWidth: 1,
    // borderLeftWidth: 1,
    // borderTopWidth: 1,
  },
  tableCell: { margin: "auto", marginTop: 5, fontSize: 10 },
});

// Create Document Component
const PDFGenerator = ({ data, month }) => {
  const generatePDF = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* <View style={styles.header}>
          <Text>Operate Live(OPC) Private Limited</Text>
        </View> */}
        <Text style={styles.header}>Operate Live(OPC) Private Limited</Text>
        <Text style={styles.address}>
          38, Nagawara Main Road, Church Cross, Arabic College Post, K. G.
          Halli, Bangalore 560045
        </Text>
        <Text>Salary Slip for {month}</Text>

        <View style={styles.table}>
          {" "}
          {/* TableHeader */}{" "}
          {/* <View style={styles.tableRow}>
            {" "}
            <View style={styles.tableCol}>
              {" "}
              <Text style={styles.tableCell}>Product</Text>{" "}
            </View>{" "}
            <View style={styles.tableCol}>
              {" "}
              <Text style={styles.tableCell}>Type</Text>{" "}
            </View>{" "}
            <View style={styles.tableCol}>
              {" "}
              <Text style={styles.tableCell}>Period</Text>{" "}
            </View>{" "}
            <View style={styles.tableCol}>
              {" "}
              <Text style={styles.tableCell}>Price</Text>{" "}
            </View>{" "}
          </View>{" "} */}
          {/* TableContent */}
          {data.map((sal) => (
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                {sal.isDeduction == true && (
                  <Text style={styles.tableCell}>
                    {sal.component}:{sal.finalAmount}
                  </Text>
                )}
              </View>
              <View style={styles.tableCol}>
                {sal.isDeduction == false && (
                  <Text style={styles.tableCell}>
                    {sal.component}:{sal.finalAmount}
                  </Text>
                )}
              </View>
              {/* )) */}
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
  return (
    <div>
      {/* PDFDownloadLink component triggers the PDF generation and provides a download link */}
      <PDFDownloadLink document={generatePDF()} fileName="SalarySlip.pdf">
        {({ blob, url, loading, error }) =>
          loading ? "Generating PDF..." : "Generate Payslip"
        }
      </PDFDownloadLink>
    </div>
  );
};

export default PDFGenerator;
