import { Modal } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../UserContext";

function MyTasksGridMob(props) {
  const { user, setUser } = useContext(UserContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const [taskId, setTaskId] = useState("");
  const [task, setTask] = useState("");
  const [description, setDescription] = useState("");
  const [deadline, setDeadline] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [assignToId, setAssignToId] = useState("");
  const [assignToName, setAssignToName] = useState("");
  const [important, setImportant] = useState(false);
  const [urgent, setUrgent] = useState(false);
  const [status, setStatus] = useState("Working");
  const [statusFilter, setStatusFilter] = useState("All");
  const [creatorId, setCreatorId] = useState("");
  const [creatorName, setCreatorName] = useState("");
  const [createdDate, setCreatedDate] = useState(new Date());
  const [data, setData] = useState([]);
  const [comments, setComments] = useState("");
  const [newComments, setNewComments] = useState("");

  useEffect(() => {
    let empid = user._id;
    setAssignToId(empid);

    const apiurl =
      process.env.REACT_APP_API_URL +
      "/api/user/employeesdropdown?empid=" +
      user._id;
    const apiparam = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };
    fetch(apiurl, apiparam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setData(repos.data);
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  }, []);

  function handleCellClick(task) {
    setTaskId(task._id);
    setTask(task.task);
    setDescription(task.description);
    setAssignToId(task.assignToId);
    setAssignToName(task.assignToName);
    setImportant(task.important);
    setUrgent(task.urgent);
    setDeadline(task.deadline.slice(0, 10));
    setStatus(task.status);
    setCreatorId(task.creatorId);
    setCreatorName(task.creatorName);
    setCreatedDate(task.createdAt);
    setComments(task.comments);
    setOpen(true);
    setStatus(task.status === "Created" ? "Working" : task.status);
  }
  const handleEdit = () => {
    if (task.length < 1) {
      alert("Please enter task!");
      return;
    }
    if (description.length < 1) {
      alert("Please enter task details!");
      return;
    }
    const apiurl = process.env.REACT_APP_API_URL + "/api/mytasks/edit";
    const apiparam = {
      method: "POST",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
        taskId,
        task,
        description: encodeURIComponent(description),
        assigntoid: assignToId,
        assigntoname: data.filter((emp) => emp._id === assignToId)[0].name,
        important,
        urgent,
        deadline: new Date(deadline).toISOString().split("T")[0],
        creatorid: user._id,
        creatorname: user.name,
        status,
        comments: encodeURIComponent(
          user.name +
            " (" +
            new Date().toISOString().split("T")[0] +
            " " +
            new Date().toLocaleTimeString() +
            "):\n" +
            newComments +
            "\n\n" +
            comments
        ),
      },
    };
    fetch(apiurl, apiparam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          alert(repos.message);
          props.refreshGrid();
          setOpen(false);
          setNewComments("");
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      {props.data.map((task) => {
        return (
          <div id="card_1_task_bar" onClick={() => handleCellClick(task)}>
            <svg class="card_bg_task_mob">
              <rect
                id="card_bg_task_mob"
                rx="10"
                ry="10"
                x="0"
                y="0"
                width="282"
                height="97"
              ></rect>
            </svg>
            <div id="name_lbl_task_mob">
              <span>{task.assignToName}</span>
            </div>
            <div id="date_task_mob">
              <marquee>
                <span>{task.task}</span>
              </marquee>
            </div>
            <div id="time_task_mob">
              <span>{task.status}</span>
            </div>
            <img
              id="to-do-list_task_mob"
              src="to-do-list_task_mob.png"
              srcset="to-do-list_task_mob.png 1x"
              alt=""
            />

            <img
              id="priority_task_mob"
              src="priority_task_mob.png"
              srcset="priority_task_mob.png 1x"
              alt=""
            />

            <img
              id="time_task_mob_cj"
              src="time_task_mob_cj.png"
              srcset="time_task_mob_cj.png 1x"
              alt=""
            />

            <div id="lbl_time_task_mob">
              <span>{new Date(task.deadline).toISOString().split("T")[0]}</span>
            </div>
            <div id="lbl_task_mob">
              <marquee>
                <span>
                  {task.important ? "Important " : ""}
                  {task.important && task.urgent ? "+" : ""}
                  {task.urgent ? " Urgent" : ""}
                </span>
              </marquee>
            </div>
            <svg class="Line_1_task_mob" viewBox="0 0 266 1">
              <path id="Line_1_task_mob" d="M 0 0 L 266 0"></path>
            </svg>
            <div id="lbl_task_details">
              {!isExpanded ? (
                task.description.length > 50 ? (
                  <>
                    {" "}
                    {task.description.slice(0, 50)}
                    <a href="#" onClick={() => handleCellClick(task)}>
                      ...read more
                    </a>
                  </>
                ) : (
                  task.description
                )
              ) : (
                task.description.slice(0, 80)
              )}
            </div>
          </div>
        );
      })}
      {/* tasks popup */}
      <Modal open={open} onClose={handleClose}>
        <div id="task_pop_mob">
          <div id="over_all_task_pop_grp">
            <img
              id="bg_design_mob_tasks"
              src="bg_design_mob.png"
              srcset="bg_design_mob.png 1x"
              alt=""
            />

            <div id="Top_bdr_grp_mob">
              <svg class="update_task_top_mob_l">
                <linearGradient
                  id="update_task_top_mob_l"
                  spreadMethod="pad"
                  x1="0.5"
                  x2="0.5"
                  y1="0"
                  y2="1.252"
                >
                  <stop offset="0" stop-color="#b5ffb7" stop-opacity="1"></stop>
                  <stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
                </linearGradient>
                <rect
                  id="update_task_top_mob_l"
                  rx="12"
                  ry="12"
                  x="0"
                  y="0"
                  width="288"
                  height="50"
                ></rect>
              </svg>
              <div id="Update_Task_top_lbl_mob">
                <span>Update Task</span>
                <img
                  id="close_task_mob_popup"
                  src="close.png"
                  srcset="close.png 1x, close@2x.png 2x"
                  alt=""
                  onClick={handleClose}
                />
              </div>
            </div>
            <div id="input_btn_grp">
              <div id="scroll_uoddate_task_grp">
                <div id="assigned_grp_by_mob">
                  <input
                    placeholder="Assigned By"
                    class="assigned_by_input_mob"
                    value={creatorName}
                    readOnly
                  />

                  <div id="Assigned_by_taskpop_mob">
                    <span>Assigned By</span>
                  </div>
                </div>
                <div id="assigned_on_mob_grp">
                  <input
                    placeholder="Approver Name"
                    type="date"
                    class="assigned_on_mob"
                    disabled
                    value={new Date(createdDate).toISOString().split("T")[0]}
                  />

                  <div id="Assigned_on_taskpop_mob">
                    <span>Assigned on</span>
                  </div>
                </div>
                <div id="assigned_to_mob_grp">
                  <select
                    placeholder="Approver Name"
                    class="assigned_to_mob"
                    value={assignToId}
                    label="Assign To"
                    disabled={user._id != creatorId && user.accesstype != 5}
                    onChange={(e) => {
                      setAssignToId(e.target.value);
                    }}
                    readOnly
                  >
                    {" "}
                    {data.map((employee) => {
                      return (
                        <option value={employee._id}>{employee.name}</option>
                      );
                    })}
                  </select>
                  <div id="Assigned_To_taskpop_mob">
                    <span>Assigned To</span>
                  </div>
                </div>
                <div id="task_grp_mob">
                  <textarea
                    placeholder="Task"
                    class="Task_input_mob"
                    onChange={(e) => setTask(e.target.value)}
                    required
                    disabled={user._id != creatorId && user.accesstype != 5}
                    value={task}
                  ></textarea>
                  <div id="Task_taskpop_mob">
                    <span>Task</span>
                  </div>
                </div>
                <div id="details_grp_mob">
                  <textarea
                    placeholder="Details"
                    class="details_input_mob"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                    disabled={user._id != creatorId && user.accesstype != 5}
                  ></textarea>
                  <div id="Details_taskpop_mob">
                    <span>Details</span>
                  </div>
                </div>
                <div id="deadline_grp_mob">
                  <input
                    placeholder="Deadline"
                    type="date"
                    class="deadline_date_mob"
                    value={deadline}
                    disabled={user._id != creatorId && user.accesstype != 5}
                    onChange={(e) => {
                      const fromDateTimestamp = Date.parse(e.target.value);

                      if (isNaN(fromDateTimestamp)) {
                        // handle invalid date input
                        return;
                      }
                      setDeadline(e.target.value);
                    }}
                  />

                  <div id="Deadline_taskpop_mob">
                    <span>Deadline</span>
                  </div>
                </div>
                <div id="status_grp_mob">
                  <select
                    placeholder="Status"
                    class="status_select_mob"
                    value={status}
                    label="Status"
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                  >
                    <option selected value="Working">
                      Working
                    </option>
                    <option value="On-Hold">On-Hold</option>
                    <option value="Completed">Completed</option>
                    <option value="Cancelled">Cancelled</option>
                    <option value="Closed">Closed</option>
                  </select>
                  <div id="Status_taskpop_mob">
                    <span>Status</span>
                  </div>
                </div>
                <div id="Important_grp_mob">
                  <input
                    type="checkbox"
                    class="imp_checkbox_4_mob"
                    checked={important}
                    disabled={user._id != creatorId && user.accesstype != 5}
                    onChange={(e) => setImportant(e.target.checked)}
                  />

                  <div id="Imp_4_lbl_mob">
                    <span>Important</span>
                  </div>
                </div>
                <div id="urgent_grp__mob">
                  <input
                    type="checkbox"
                    class="urgent_input_mob"
                    checked={urgent}
                    disabled={user._id != creatorId && user.accesstype != 5}
                    onChange={(e) => setUrgent(e.target.checked)}
                  />

                  <div id="urgent_3lbl_mob">
                    <span>Urgent</span>
                  </div>
                </div>
                <div id="comment_mob">
                  <textarea
                    placeholder="Comment"
                    class="add_com_bdr_input_mob"
                    value={comments}
                  ></textarea>
                  <div id="Comments_taskpop_mob">
                    <span>Comments</span>
                  </div>
                </div>
                <div id="com_add_mob">
                  <textarea
                    placeholder="Add Comment"
                    class="com_bdr_input_mob"
                    value={newComments}
                    multiline
                    onChange={(e) => setNewComments(e.target.value)}
                  ></textarea>
                  <div id="Add_Comments_mob">
                    <span>Add Comments</span>
                  </div>
                </div>
              </div>
              <button id="btn_grp_mob">
                <svg class="update_task_leave_mob">
                  <rect
                    id="update_task_leave_mob"
                    rx="9"
                    ry="9"
                    x="0"
                    y="0"
                    width="133"
                    height="50"
                  ></rect>
                </svg>
                <div
                  id="update_task_lbl_mob"
                  onClick={(e) => {
                    handleEdit();
                  }}
                >
                  <span>Update Task</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default MyTasksGridMob;
