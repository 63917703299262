import React, { useState, useEffect, useContext } from "react";
import TextField from "@mui/material/TextField";
import { UserContext } from "../UserContext";
import { nanoid } from "nanoid";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import "../Styles/salary.css";
function OldPayroll() {
  const { user, setUser } = useContext(UserContext);
  const [component, setComponent] = useState("");
  const [amount, setAmount] = useState();
  const [isPercentage, setIsPercentage] = useState(false);
  const [frequency, setFrequency] = useState("");
  const [finalAmt, setFinalAmt] = useState(0);
  const [inputData, setInputData] = useState([]);
  const [isEditing, setIsEditing] = useState(null);
  const [salaryComponents, setSalaryComponents] = useState([]);
  const [subComponent, setSubComponent] = useState();
  const [grossSalary, setGrossSalary] = useState(0);
  const [isDeduction, setIsDeduction] = useState(false);
  const [deductionSalary, setDeductionSalary] = useState(0);
  const [netSalary, setNetSalary] = useState(0);

  const handleEdit = (index) => {
    setIsEditing(index);
  };
  const handleAddRow = async (e) => {
    let newAmount = 0;
    const componentExists = inputData.some(
      (obj) => obj.component === component
    );
    if (componentExists) {
      alert("This Salary component already exists");
      return;
    }
    if (
      isPercentage == true &&
      subComponent != "grossSalary" &&
      subComponent != "netSalary"
    ) {
      let newSubComponent = inputData.find(
        (obj) => obj.component == subComponent
      ).finalAmount;
      newAmount = (amount / 100) * newSubComponent;
    } else if (subComponent == "grossSalary") {
      newAmount = (amount / 100) * grossSalary;
    } else if (subComponent == "netSalary") {
      newAmount = (amount / 100) * netSalary;
    } else {
      newAmount = amount;
    }
    let newField = {
      id: nanoid(),
      date: new Date().toLocaleDateString(),
      component: component,
      amount: amount,
      ispercentage: isPercentage,
      frequency: frequency,
      finalAmount: newAmount,
      updatedBy: user._id,
      subComponent: subComponent,
      isDeduction: isDeduction,
    };
    setInputData([...inputData, newField]);
    setComponent("");
    setAmount("");
    setIsPercentage(false);
    setFrequency("");
    setFinalAmt("");
    setIsDeduction(false);
  };
  useEffect(() => {
    let gross = 0;
    let deductions = 0;
    let net = 0;
    inputData.map((data) => {
      !data.isDeduction
        ? (gross += Number(data.finalAmount))
        : (deductions += Number(data.finalAmount));
    });
    net += gross - deductions;
    setGrossSalary(gross);
    setDeductionSalary(deductions);
    setNetSalary(net);
  }, [inputData]);

  const handleRemoveRow = async (index) => {
    let data = [...inputData];
    const r = data.indexOf(index);
    if (r > -1) {
      data.splice(r, 1);
      setInputData(data);
    }
  };

  const handleEditSave = (index, newData) => {
    let newAmount;
    if (newData.ispercentage == true) {
      let newSubComponent = inputData.find(
        (obj) => obj.component == newData.subComponent
      ).finalAmount;
      newAmount = Number(newData.amount / 100) * newSubComponent;
    } else {
      newAmount = newData.amount;
    }
    let newField = { ...newData, finalAmount: newAmount };
    const newEditedData = [...inputData];
    const indexOfData = newEditedData.findIndex((item) => item.id === index);
    newEditedData[indexOfData] = newField;
    setInputData(newEditedData);
  };

  const handleRowEditSave = (index, newData) => {
    let newAmount = 0;
    console.log(index);
    const newEditedData = [...inputData];
    const indexOfData = newEditedData.findIndex((item) => item.id === index);
    newEditedData[indexOfData] = newData;
    setInputData(newEditedData);
    setIsEditing(null);
  };

  const handleSavePayroll = async () => {
    if (inputData.length == 0) {
      alert("Basic Pay is not Added");
      return;
    }
    const apiURL = process.env.REACT_APP_API_URL + "/api/payroll/addpayroll";
    const apiParam = {
      method: "POST",
      headers: {
        components: JSON.stringify(inputData),
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };
    fetch(apiURL, apiParam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          alert("Payroll Updated");
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };

  const getSalaryComponents = () => {
    const apiURL =
      process.env.REACT_APP_API_URL + "/api/payroll/getsalarycomponents";
    const apiParams = {
      method: "POST",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        setSalaryComponents(repos.data);
      });
  };

  const inputComponent = async (e) => {
    setComponent(e.target.value);
    let newcomponent = salaryComponents.find(
      (obj) => obj.componentName == e.target.value
    );
    console.log(newcomponent);
    setIsPercentage(newcomponent.isPercentage);
    setSubComponent(newcomponent.subComponent);
    setIsDeduction(newcomponent.isDeduction);
  };
  const addCompulsoryComponents = () => {
    salaryComponents.map((component) => {});
  };
  useEffect(() => {
    getSalaryComponents();
    // addCompulsoryComponents();
  }, []);
  useEffect(() => {}, [inputData]);
  return (
    <div>
      <div className="payroll card-payroll">
        <div>
          <table
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            <thead>
              <tr>
                {/* <th>Last Updated</th> */}
                <th>Component</th>
                <th>Is Percentage</th>
                <th>Amount</th>
                <th>Sub Component</th>
                <th>frequency</th>
                <th>Total Amount</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {inputData.map((data) => {
                return (
                  <tr
                    key={data.id}
                    style={{ color: data.isDeduction ? "red" : "green" }}
                  >
                    <td>
                      {isEditing === data.id ? (
                        <FormControl>
                          <InputLabel id="demo-simple-select-label">
                            Salary Component
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Sub-component"
                            sx={{ minWidth: 180 }}
                            value={data.component}
                            onChange={(e) => {
                              const newData = {
                                ...data,
                                component: e.target.value,
                              };
                              handleEditSave(data.id, newData);
                            }}
                          >
                            {salaryComponents &&
                              salaryComponents.map((m) => {
                                return (
                                  <MenuItem value={m.componentName}>
                                    {m.componentName}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      ) : (
                        <Typography>{data.component}</Typography>
                      )}
                    </td>
                    <td>
                      {isEditing == data.id ? (
                        <input
                          type="checkbox"
                          checked={data.ispercentage}
                          onChange={(e) => {
                            const newData = {
                              ...data,
                              ispercentage: e.target.checked,
                              subComponent: "Basic Salary",
                            };
                            handleEditSave(data.id, newData);
                          }}
                        />
                      ) : (
                        <Typography>{data.ispercentage.toString()}</Typography>
                      )}
                    </td>
                    <td>
                      {isEditing == data.id ? (
                        <TextField
                          type={"number"}
                          onChange={(e) => {
                            const newData = {
                              ...data,
                              amount: Number(e.target.value),
                            };
                            handleEditSave(data.id, newData);
                          }}
                          value={data.amount}
                          label="Amount"
                          id="outlined-start-adornment"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="end">
                                {data.ispercentage ? "%" : "₹"}
                              </InputAdornment>
                            ),
                          }}
                        />
                      ) : (
                        <Typography>{data.amount}</Typography>
                      )}
                    </td>

                    <td>
                      {isEditing == data.id && data.ispercentage == true ? (
                        <FormControl>
                          <InputLabel id="demo-simple-select-label">
                            Sub Component
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Sub-component"
                            sx={{ minWidth: 180 }}
                            value={data.subComponent}
                            onChange={(e) => {
                              const newData = {
                                ...data,
                                subComponent: e.target.value,
                              };
                              handleEditSave(data.id, newData);
                            }}
                          >
                            {salaryComponents &&
                              salaryComponents.map((m) => {
                                return (
                                  <MenuItem value={m.componentName}>
                                    {m.componentName}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      ) : (
                        <Typography>{data.subComponent}</Typography>
                      )}
                    </td>

                    <td>
                      {isEditing == data.id ? (
                        <TextField
                          select
                          InputLabelProps={{ shrink: true }}
                          sx={{ width: "15ch" }}
                          label="frequency"
                          value={data.frequency}
                          onChange={(e) => {
                            const newData = {
                              ...data,
                              frequency: e.target.value,
                            };
                            handleEditSave(data.id, newData);
                          }}
                        >
                          <MenuItem value="">select</MenuItem>
                          <MenuItem value="monthly">Per Month</MenuItem>
                          <MenuItem value="anually">Per Annum</MenuItem>
                          <MenuItem value="semi-anually">Semi-Annual</MenuItem>
                        </TextField>
                      ) : (
                        <Typography>{data.frequency}</Typography>
                      )}
                    </td>
                    <td>
                      <Typography>{data.finalAmount}</Typography>
                    </td>
                    <td>
                      {isEditing === data.id ? (
                        <button onClick={() => handleRowEditSave(data)}>
                          Save
                        </button>
                      ) : (
                        <>
                          <IconButton onClick={() => handleEdit(data.id)}>
                            <EditIcon />
                          </IconButton>
                          <IconButton onClick={() => handleRemoveRow(data)}>
                            <DeleteIcon />
                          </IconButton>
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Grid container>
            <Grid item style={{ margin: "40px", fontWeight: "bolder" }}>
              <Typography variant="div">Gross Salary:{grossSalary}</Typography>
            </Grid>
            <Grid item style={{ margin: "40px", fontWeight: "bolder" }}>
              <Typography variant="div">
                Total Deductions:{deductionSalary}
              </Typography>
            </Grid>
            <Grid item style={{ margin: "40px", fontWeight: "bolder" }}>
              <Typography variant="div">Net Salary:{netSalary}</Typography>
            </Grid>
          </Grid>
        </div>
        <div style={{ paddingTop: "50px", paddingBottom: "30px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3>Add new Salary Component</h3>
            </Grid>
            <Grid item xs={2.5}>
              <FormControl>
                <InputLabel id="demo-simple-select-label">
                  Salary Component
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Sub-component"
                  sx={{ minWidth: 180 }}
                  value={component}
                  onChange={(e) => {
                    inputComponent(e);
                    setComponent(e.target.value);
                  }}
                >
                  {salaryComponents &&
                    salaryComponents.map((m) => {
                      return (
                        <MenuItem value={m.componentName}>
                          {m.componentName}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1}>
              <label>Is Percentage</label>
              <input
                type="checkbox"
                checked={isPercentage}
                onChange={(e) => setIsPercentage(e.target.checked)}
              />
            </Grid>
            {isPercentage ? (
              <Grid item xs={2.5}>
                <FormControl>
                  <InputLabel id="demo-simple-select-label">
                    Sub-component
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Sub-component"
                    sx={{ minWidth: 150 }}
                    value={subComponent}
                    onChange={(e) => {
                      setSubComponent(e.target.value);
                    }}
                  >
                    {salaryComponents &&
                      salaryComponents.map((m) => {
                        return (
                          <MenuItem value={m.componentName}>
                            {m.componentName}
                          </MenuItem>
                        );
                      })}

                    <MenuItem value="grossSalary">Gross Salary</MenuItem>
                    <MenuItem value="netSalary">Net Salary</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            ) : null}
            <Grid item xs={2.5}>
              <TextField
                type={"number"}
                onChange={(e) => setAmount(e.target.value)}
                value={amount}
                label="Amount"
                id="outlined-start-adornment"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end">
                      {isPercentage ? "%" : "₹"}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={2}>
              {" "}
              <TextField
                select
                InputLabelProps={{ shrink: true }}
                sx={{ width: "15ch" }}
                label="frequency"
                value={frequency}
                onChange={(e) => setFrequency(e.target.value)}
              >
                <MenuItem value="">select</MenuItem>
                <MenuItem value="monthly">Per Month</MenuItem>
                {/* <MenuItem value="anually">Per Annum</MenuItem>
                <MenuItem value="semi-anually">Semi-Annual</MenuItem> */}
              </TextField>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  handleAddRow();
                }}
              >
                Add Row
              </Button>
            </Grid>
          </Grid>
        </div>

        <div>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              handleSavePayroll();
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}
export default OldPayroll;
