import { Button } from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Image from "./logo.jpg";

function Forget() {
  var history = useHistory();
  const [userName, setUserName] = useState("");

  const forgotpassword = async () => {
    const apiUrl = process.env.REACT_APP_API_URL + "/api/user/forgotpassword";
    const apiParam = {
      method: "POST",
      headers: { email: userName },
    };

    await fetch(apiUrl, apiParam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          localStorage.setItem("email", repos.data);
          history.push("/resetpassword");
        } else {
          alert(repos.message || repos);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <div className="cards ">
        <div className="form-group">
          <div class="img mt-3">
            <img
              src={Image}
              alt="logo"
              width="100"
              height="100"
              class="img"
              style={{ marginBottom: "15px" }}
            />
          </div>
          <h2 style={{ color: "#3e4450" }}>Forgot Password </h2>
          <div class="form-group">
            <input
              type="email"
              class="form-control"
              placeholder="Email"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          </div>

          <div style={{ textAlign: "center", marginBottom: "10px" }}>
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                forgotpassword();
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Forget;
