import React from "react";
import "../../Styles/homeMob.css";
import { useState, useEffect } from "react";
function AttendanceGridMob(props) {
  const [gridData,setGridData]=useState([]);
  function calculateDistance(lat1, lon1) {
    const R = 6371; // radius of the earth in km
    const lat2 = 13.0277861;
    const lon2 = 77.6182165;
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // distance in km
    return Math.round(distance);
  }

  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }
  useEffect(() => {
    localStorage.removeItem("tab");
    localStorage.setItem("tab", 0);
  }, []);
  useEffect(() => {
    setGridData(props.data);
  }, [props.data]);
  return (
    <div>
      {gridData.map((attendance) => {
        return (
          <div id="card_1_grp">
            <svg class="card_bg_mob_1">
              <rect
                id="card_bg_mob_1"
                rx="10"
                ry="10"
                x="0"
                y="0"
                width="282"
                height="97"
              ></rect>
            </svg>
            <svg class="line_bar_card_1_mob" viewBox="0 0 266 1">
              <path id="line_bar_card_1_mob" d="M 0 0 L 266 0"></path>
            </svg>
            <div id="affan_mob_1">
              <span>{attendance.Fullname}</span>
            </div>
            <div id="date_mob_1">
              <span> {attendance.date.slice(0, 10)}</span>
            </div>
            <div
              id="time_mob_1"
              style={{
                color:
                  attendance.Starttime &&
                  new Date(attendance.Starttime).getHours() * 60 +
                    new Date(attendance.Starttime).getMinutes() >
                    JSON.parse(localStorage.getItem("loggedInUser")).shiftStart
                    ? "red"
                    : "green",
              }}
            >
              <span>
                {" "}
                {new Date(attendance.Starttime).toLocaleTimeString()}
              </span>
            </div>
            <img
              id="clock_mob_1"
              src="clock_mob_1.png"
              srcset="clock_mob_1.png 1x"
              alt=""
            />

            <img
              id="des_img_mob_1"
              src="des_img_mob_1.png"
              srcset="des_img_mob_1.png 1x"
              alt=""
            />

            <img
              id="comments_img_mob_1"
              src="comments_img_mob_1.png"
              srcset="comments_img_mob_1.png 1x"
              alt=""
            />

            <div id="IN_mob_card_1">
              <span>IN</span>
            </div>
            <div id="Out_mob_card_1">
              <span>Out</span>
            </div>
            <div
              id="At_Work_lbl_img_mob_1"
              style={{
                color:
                  (attendance &&
                    attendance.Reason === "Auto logout by System") ||
                  new Date(attendance.Stoptime).getHours() * 60 +
                    new Date(attendance.Stoptime).getMinutes() <=
                    JSON.parse(localStorage.getItem("loggedInUser")).shiftEnd
                    ? "red"
                    : "green",
              }}
            >
              <span>
                {attendance.Stoptime
                  ? new Date(attendance.Stoptime).toLocaleTimeString()
                  : "At office"}
              </span>
            </div>
            <img
              id="clock_img_mob_1"
              src="clock_img_mob_1.png"
              srcset="clock_img_mob_1.png 1x"
              alt=""
            />

            <img
              id="n_des_img_mob_1"
              src="n_des_img_mob_1.png"
              srcset="n_des_img_mob_1.png 1x"
              alt=""
            />

            <div id="Late_due_to_traffic_jam_lnl">
              <span>
                <marquee> {attendance.Latereason}</marquee>
              </span>
            </div>

            <img
              id="comments_img_mob_2"
              src="comments_img_mob_1.png"
              srcset="comments_img_mob_1.png 1x"
              alt=""
            />
            <div id="out_comments">
              <span>
                <marquee>{attendance.Reason}</marquee>
              </span>
            </div>
            <div id="Office_out_card_1">
              <span>
                {attendance.Stoptime
                  ? calculateDistance(
                      attendance.Stoplatitude,
                      attendance.Stoplongitude
                    ) + " Km from office"
                  : "At office"}{" "}
              </span>
            </div>
            <div id="n_km_from_Office_in_card1">
              <span>
                {attendance.Starttime
                  ? calculateDistance(
                      attendance.Startlatitude,
                      attendance.Startlongitude
                    ) + " Km from office"
                  : ""}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default AttendanceGridMob;
