import React, { useState, useEffect, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { TextField, MenuItem, Button, Tooltip } from "@mui/material";
import { UserContext } from "../../UserContext";
import "../../Styles/leavePanel.css";
function LeavesPanel(props) {
  //variable
  const { user, setUser } = useContext(UserContext);
  //useState
  const [open, setOpen] = useState(false);
  const [fromdate, setFromDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [todate, setToDate] = useState(new Date().toISOString().slice(0, 10));
  const [disableChecks, setDisableChecks] = useState(true);
  const [leaveDays, setLeaveDays] = useState(1);
  const [typeleave, setTypeLeave] = useState("sick");
  const [leavereason, setLeaveReason] = useState("");
  const [approver, setApprover] = useState("");
  const [Checked, setChecked] = useState(false);
  const [startFirstHalf, setStartFirstHalf] = useState(true);
  const [startSecondHalf, setStartSecondHalf] = useState(true);
  const [endFirstHalf, setEndFirstHalf] = useState(true);
  const [endSecondHalf, setEndSecondHalf] = useState(true);
  const [leavesData, setLeavesData] = useState([]);
  let totalLeaves = 24;
  let totalHolidays = 10;
  //useEffect
  useEffect(() => {
    const fromDateTimestamp = Date.parse(fromdate);
    const toDateTimestamp = Date.parse(todate);

    if (isNaN(fromDateTimestamp) || isNaN(toDateTimestamp)) {
      return;
    }

    const date1 = new Date(fromdate);
    const date2 = new Date(todate);
    const datediff = date2 - date1;

    if (
      date1.toISOString().split("T")[0] === date2.toISOString().split("T")[0]
    ) {
      setLeaveDays(
        date1.getDay() === 0
          ? 1 - (!startFirstHalf ? 0.5 : 0) - (!startSecondHalf ? 0.5 : 0)
          : 1 - (!startFirstHalf ? 0.5 : 0) - (!startSecondHalf ? 0.5 : 0)
      );
      setDisableChecks(true);
      setEndFirstHalf(true);
      setEndSecondHalf(true);
    } else {
      setDisableChecks(false);
      const datediff = date2 - date1;

      const diffdays = Math.ceil(datediff / (1000 * 60 * 60 * 24)) + 1;

      let sundays = 0;
      for (let i = 0; i < diffdays; i++) {
        let currentday = addDays(fromdate, i);
        if (currentday.getDay() === 0) {
          sundays++;
        }
      }
      setLeaveDays(
        diffdays -
          sundays -
          (!startFirstHalf ? 0.5 : 0) -
          (!startSecondHalf ? 0.5 : 0) -
          (!endFirstHalf ? 0.5 : 0) -
          (!endSecondHalf ? 0.5 : 0)
      );
    }
  }, [
    fromdate,
    todate,
    startFirstHalf,
    startSecondHalf,
    endFirstHalf,
    endSecondHalf,
  ]);
  useEffect(() => {
    handleClickOpen();
  }, []);
  //functions
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    let empid = user._id;

    let apiUrl =
      process.env.REACT_APP_API_URL + "/api/user/register?empid=" + empid;
    const apiParam = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };

    fetch(apiUrl, apiParam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setApprover(repos.data);
          props.refreshGrid();
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
    setOpen(true);
  };
  const redirect = () => {
    const date1 = new Date(fromdate);
    const date2 = new Date(todate);
    const datediff = date2 - date1;
    if (datediff < 0) {
      alert("leave days cannot be negative value");
      return;
    }
    if (leaveDays < 0) {
      alert("leave days cannot be negative");
      return;
    }
    if (typeleave === null || typeleave.length === 0) {
      alert("Please enter select leave type!");
      return;
    }
    if (leavereason === null || leavereason.length === 0) {
      alert("Please enter leave reason!");
      return;
    }
    if (leaveDays === 0) {
      alert("Please choose first half or second half");
      return;
    }
    var approve = approver.reportingToId;
    let empName = user.name;
    const apiurl = process.env.REACT_APP_API_URL + "/api/leaves";
    const apiparam = {
      method: "POST",
      headers: {
        empid: user._id,
        fromdate: fromdate,
        fromfirsthalfleave: startFirstHalf,
        fromsecondhalfleave: startSecondHalf,
        todate: todate,
        tofirsthalfleave: endFirstHalf,
        tosecondhalfleave: endSecondHalf,
        typeleave: typeleave,
        leavedays: leaveDays,
        leavereason: leavereason,
        approver: approve,
        name: empName,
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };
    fetch(apiurl, apiparam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          alert(repos.message);
          handlereset();
          props.refreshGrid();
        } else alert(JSON.parse(repos).message);
      });
    handleClose();
  };

  const handlereset = () => {
    setFromDate(new Date());
    setToDate(new Date());
    setTypeLeave("");
    setLeaveDays(
      1 - (!startFirstHalf ? 0.5 : 0) - (!startSecondHalf ? 0.5 : 0)
    );
    setLeaveReason("");
  };
  function addDays(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  const getLeavesData = () => {
    let apiURL = process.env.REACT_APP_API_URL + "/api/leaves/leavesdata";
    let apiParam = {
      method: "GET",
      headers: {
        userid: localStorage.getItem("filterEmployee"),
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };
    fetch(apiURL, apiParam)
      .then((res) => (res.status == 200 ? res.json() : res.text()))
      .then((repos) => {
        setLeavesData(repos.data);
      });
  };
  useEffect(() => {
    getLeavesData();
  }, [localStorage.getItem("filterEmployee")]);

  //renderMethod
  return (
    // <div style={{ padding: "0px 10px 0px 10px" }}>
    //   <table>
    //     <tr>
    //       <td style={{ width: "75%" }}>
    //         {" "}
    //         <form class="row">
    //           <div class="col-md-3">
    //             <LocalizationProvider dateAdapter={AdapterDateFns}>
    //               <DatePicker
    //                 inputFormat="dd/MM/yyyy"
    //                 label="From"
    //                 value={new Date(fromdate).toISOString().split("T")[0]}
    //                 onChange={(newValue) => {
    //                   setFromDate(newValue);
    //                 }}
    //                 required
    //                 renderInput={(params) => <TextField {...params} />}
    //                 style={{ width: "100%" }}
    //               />
    //             </LocalizationProvider>
    //           </div>
    //           <div class="col-md-2">
    //             <span>
    //               <input
    //                 type="checkbox"
    //                 onChange={(e) => setStartFirstHalf(e.target.checked)}
    //                 checked={startFirstHalf}
    //               />
    //               <label>First Half</label>
    //             </span>
    //             <br />
    //             <span>
    //               <input
    //                 type="checkbox"
    //                 onChange={(e) => setStartSecondHalf(e.target.checked)}
    //                 checked={startSecondHalf}
    //               />
    //               <label>Second Half</label>
    //             </span>
    //           </div>
    //           <div class="col-md-3">
    //             <LocalizationProvider dateAdapter={AdapterDateFns}>
    //               <DatePicker
    //                 inputFormat="dd/MM/yyyy"
    //                 label="To"
    //                 value={new Date(todate).toISOString().split("T")[0]}
    //                 minDate={new Date(fromdate).toISOString().split("T")[0]}
    //                 onChange={(newValue) => {
    //                   setToDate(newValue);
    //                 }}
    //                 required
    //                 renderInput={(params) => <TextField {...params} />}
    //                 style={{ width: "100%" }}
    //               />
    //             </LocalizationProvider>
    //           </div>
    //           <div class="col-md-2">
    //             {disableChecks ? (
    //               ""
    //             ) : (
    //               <>
    //                 <span>
    //                   <input
    //                     type="checkbox"
    //                     onChange={(e) => setEndFirstHalf(e.target.checked)}
    //                     checked={endFirstHalf}
    //                   />
    //                   <label>First Half</label>
    //                 </span>
    //                 <br />
    //                 <span>
    //                   <input
    //                     type="checkbox"
    //                     onChange={(e) => setEndSecondHalf(e.target.checked)}
    //                     checked={endSecondHalf}
    //                   />
    //                   <label>Second Half</label>
    //                 </span>
    //               </>
    //             )}
    //           </div>
    //           <div class="col-md-2">
    //             <TextField
    //               id="leaveDays"
    //               label="Leaves Count"
    //               disabled
    //               value={leaveDays}
    //               readOnly={true}
    //               style={{ width: "100%" }}
    //               sx={{
    //                 backgroundColor: "lightgray",
    //               }}
    //             />
    //           </div>

    //           <div class="col-md-12">
    //             <TextField
    //               id="leaveReason"
    //               style={{ width: "100%" }}
    //               label="Leave Reason"
    //               multiline
    //               rows={2}
    //               value={leavereason}
    //               required
    //               onChange={(e) => {
    //                 setLeaveReason(e.target.value);
    //               }}
    //               sx={{
    //                 backgroundColor: "white",
    //                 marginBottom: "10px",
    //               }}
    //             />
    //           </div>
    //           <div class="col-md-4">
    //             <TextField
    //               id="leaveType"
    //               style={{ width: "100%" }}
    //               select
    //               label="Leave Type"
    //               value={typeleave}
    //               onChange={(e) => setTypeLeave(e.target.value)}
    //               required
    //             >
    //               <MenuItem key={"sick"} value={"sick"}>
    //                 Sick
    //               </MenuItem>
    //               <MenuItem key={"personal"} value={"personal"}>
    //                 Personal
    //               </MenuItem>
    //               <MenuItem key={"emergency"} value={"emergency"}>
    //                 Emergency
    //               </MenuItem>
    //             </TextField>
    //           </div>
    //           <div class="col-md-4">
    //             <TextField
    //               id="approver"
    //               style={{ width: "100%" }}
    //               label="Approver"
    //               InputLabelProps={{ shrink: true }}
    //               sx={{
    //                 backgroundColor: "lightgray",
    //               }}
    //               disabled
    //               value={approver.reportingToName}
    //             />
    //           </div>
    //           <div class="col-md-2">
    //             <Button
    //               variant="contained"
    //               color="success"
    //               onClick={() => {
    //                 redirect();
    //               }}
    //               style={{ margin: "5px" }}
    //               disabled={!(fromdate && todate)}
    //             >
    //               Apply
    //             </Button>
    //           </div>
    //           <div class="col-md-2">
    //             <Button
    //               variant="contained"
    //               color="error"
    //               onClick={() => {
    //                 handlereset();
    //               }}
    //               style={{ margin: "5px" }}
    //             >
    //               Reset
    //             </Button>
    //           </div>
    //         </form>
    //       </td>
    //       <td style={{ width: "20%", textAlign: "center" }}>
    //         <table>
    //           <tr>
    //             <td>
    //               <b>Eligible</b>
    //             </td>
    //             <td></td>
    //           </tr>
    //           <tr>
    //             <td style={{ textDecoration: "underline" }}>
    //               <i>Leaves</i>
    //             </td>
    //             <td style={{ textDecoration: "underline" }}>
    //               <i>Holidays</i>
    //             </td>
    //           </tr>
    //           <tr>
    //             <td>{totalLeaves}</td>
    //             <td>{totalHolidays}</td>
    //           </tr>
    //           <tr>
    //             <td>
    //               <b>Availed</b>
    //             </td>
    //             <td></td>
    //           </tr>
    //           <tr>
    //             <td style={{ textDecoration: "underline" }}>
    //               <i>Leaves</i>
    //             </td>
    //             <td style={{ textDecoration: "underline" }}>
    //               <i>Holidays</i>
    //             </td>
    //           </tr>
    //           <tr>
    //             <td>
    //               {leavesData && leavesData.appliedleaves}/
    //               {leavesData && leavesData.leavesthisyear}
    //             </td>

    //             <td>
    //               {leavesData && leavesData.selectedHolidaysbyuser}/
    //               {leavesData && leavesData.totalHolidaysSelected}
    //             </td>
    //           </tr>
    //           <tr>
    //             <td>
    //               <b>Approved</b>
    //             </td>
    //             <td></td>
    //           </tr>
    //           <tr>
    //             <td style={{ textDecoration: "underline" }}>
    //               <i>Leaves</i>
    //             </td>
    //             <td style={{ textDecoration: "underline" }}>
    //               <i>Holidays</i>
    //             </td>
    //           </tr>
    //           <tr>
    //             <td>{leavesData && leavesData.leavestilltoday}</td>
    //             <td> {leavesData && leavesData.totalHolidaysSelected}</td>
    //           </tr>
    //         </table>
    //       </td>
    //     </tr>
    //   </table>
    // </div>
    <div id="mid_changes_grp_full">
      <textarea
        placeholder="Leave Reason*"
        class="comment_box_1"
        value={leavereason}
        required
        onChange={(e) => {
          setLeaveReason(e.target.value);
        }}
      ></textarea>
      <div id="app_res_grp">
        <div id="Start_Session_lbl_2">
          <span>Apply</span>
        </div>
        <div id="Session_End_lbl_2">
          <span>Reset</span>
        </div>
        <svg class="bdr_22">
          <ellipse
            id="bdr_22"
            rx="21.5"
            ry="21.5"
            cx="21.5"
            cy="21.5"
          ></ellipse>
        </svg>
        <svg class="bdr_11">
          <ellipse
            id="bdr_11"
            rx="17.5"
            ry="17.5"
            cx="17.5"
            cy="17.5"
          ></ellipse>
        </svg>
        <button
          onClick={() => {
            redirect();
          }}
          style={{ margin: "5px" }}
          disabled={!(fromdate && todate)}
        >
          <img
            id="play-buttton_img_2"
            src="play-buttton_img_2.png"
            srcset="play-buttton_img_2.png 1x, play-buttton_img_2@2x.png 2x"
            alt=""
          />
        </button>

        <img
          id="link_up_2"
          src="link_up_2.png"
          srcset="link_up_2.png 1x, link_up_2@2x.png 2x"
          alt=""
        />

        <img
          id="link_down_2"
          src="link_down_2.png"
          srcset="link_down_2.png 1x, link_down_2@2x.png 2x"
          alt=""
        />

        <svg class="left_link_up_2_cb" viewBox="2247.021 277.652 8.117 2.127">
          <path
            id="left_link_up_2_cb"
            d="M 2247.021240234375 277.6515502929688 C 2247.52294921875 277.7061767578125 2248.099853515625 277.7145080566406 2248.880126953125 277.7712097167969 C 2249.152587890625 277.7895202636719 2249.626708984375 277.859130859375 2250.271240234375 277.9796752929688 C 2254.037841796875 278.6775817871094 2255.13818359375 279.778076171875 2255.13818359375 279.778076171875"
          ></path>
        </svg>
        <svg class="left_link_down_2_cc" viewBox="2242.392 307.828 4.219 0.672">
          <path
            id="left_link_down_2_cc"
            d="M 2246.6103515625 308.5001220703125 C 2246.6103515625 308.5001220703125 2244.0478515625 308.3907470703125 2242.3916015625 307.8282470703125"
          ></path>
        </svg>
        <svg class="bdr_back_2">
          <ellipse
            id="bdr_back_2"
            rx="21.5"
            ry="21.5"
            cx="21.5"
            cy="21.5"
          ></ellipse>
        </svg>
        <svg class="bdr_2_ce">
          <ellipse
            id="bdr_2_ce"
            rx="17.5"
            ry="17.5"
            cx="17.5"
            cy="17.5"
          ></ellipse>
        </svg>
        <button
          onClick={() => {
            handlereset();
          }}
        >
          <img
            id="stop-button_img_2"
            src="undo.png"
            // srcset="stop-button_img_2.png 1x, stop-button_img_2@2x.png 2x"
            alt=""
          />
        </button>

        <img
          id="link1_mid_top_2"
          src="link1_mid_top_2.png"
          srcset="link1_mid_top_2.png 1x, link1_mid_top_2@2x.png 2x"
          alt=""
        />

        <img
          id="link1_mid_down_2"
          src="link1_mid_down_2.png"
          srcset="link1_mid_down_2.png 1x, link1_mid_down_2@2x.png 2x"
          alt=""
        />

        <svg class="left_link_up_2_ci" viewBox="2247.021 277.652 8.117 2.127">
          <path
            id="left_link_up_2_ci"
            d="M 2247.021240234375 277.6515502929688 C 2247.52294921875 277.7061767578125 2248.099853515625 277.7145080566406 2248.880126953125 277.7712097167969 C 2249.152587890625 277.7895202636719 2249.626708984375 277.859130859375 2250.271240234375 277.9796752929688 C 2254.037841796875 278.6775817871094 2255.13818359375 279.778076171875 2255.13818359375 279.778076171875"
          ></path>
        </svg>
        <svg class="left_link_down_2_cj" viewBox="2242.392 307.828 4.219 0.672">
          <path
            id="left_link_down_2_cj"
            d="M 2246.6103515625 308.5001220703125 C 2246.6103515625 308.5001220703125 2244.0478515625 308.3907470703125 2242.3916015625 307.8282470703125"
          ></path>
        </svg>
      </div>
      <div id="holidays_grp">
        <div id="Eligible_lbl">
          <span>Eligible</span>
        </div>
        <img
          id="leave_img_eli"
          src="leave_img_eli.png"
          srcset="leave_img_eli.png 1x, leave_img_eli@2x.png 2x"
          alt=""
        />

        <div id="leave_no_lbl">
          <span>{totalLeaves}</span>
        </div>
        <img
          id="holiday_img_3"
          src="holiday_img_3.png"
          srcset="holiday_img_3.png 1x, holiday_img_3@2x.png 2x"
          alt=""
        />

        <div id="eligible_lbl_leave">
          <span>{totalHolidays}</span>
        </div>
        <div id="Availed_lbl">
          <span>Availed</span>
        </div>
        <img
          id="avail_img_2"
          src="avail_img_2.png"
          srcset="avail_img_2.png 1x, avail_img_2@2x.png 2x"
          alt=""
        />

        <div id="avail_lbl_2">
          <Tooltip title="Leaves Approval awaiting/Availed">
            <span>
              {leavesData && leavesData.appliedleaves}/
              {leavesData && leavesData.leavesthisyear}
            </span>
          </Tooltip>
        </div>
        <img
          id="avail_img"
          src="avail_img.png"
          srcset="avail_img.png 1x, avail_img@2x.png 2x"
          alt=""
        />

        <div id="availed_lbl_holi">
          <Tooltip title="Holidays availed/ selected">
            <span>
              {leavesData && leavesData.totalHolidaysAvailed}/
              {leavesData && leavesData.totalHolidaysSelected}
            </span>
          </Tooltip>
        </div>
        <div id="Approved_lbl">
          <span>Approved</span>
        </div>
        <div id="arpoved_grp">
          <div id="leave_grp">
            <img
              id="leave_rec"
              src="leave_rec.png"
              srcset="leave_rec.png 1x, leave_rec@2x.png 2x"
              alt=""
            />

            <div id="lbl_leav">
              <Tooltip title="Total Leaves approved and availed">
                <span>{leavesData && leavesData.leavesthisyear}</span>
              </Tooltip>
            </div>
          </div>
          <div id="holiday_grp">
            <img
              id="holiday_rec"
              src="holiday_rec.png"
              srcset="holiday_rec.png 1x, holiday_rec@2x.png 2x"
              alt=""
            />

            <div id="holi_lbl">
              <Tooltip title="Total holidays approved">
                <span>{leavesData && leavesData.totalHolidaysSelected}</span>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
      <div id="leave_days_grp">
        <div id="Leave_Days_lbl">
          <span>Leave Days</span>
        </div>
        <div id="no_grp">
          <svg class="rec_bg">
            <ellipse
              id="rec_bg"
              rx="17.5"
              ry="17.5"
              cx="17.5"
              cy="17.5"
            ></ellipse>
          </svg>
          <div id="no_of_days">
            <span> {leaveDays}</span>
          </div>
        </div>
      </div>
      <div id="from_date_grp">
        <input
          type="date"
          class="from_bdr_input_1"
          value={fromdate}
          onChange={(e) => {
            e.preventDefault();
            setFromDate(e.target.value);
          }}
        />

        <div id="From_lbl_1">
          <span>From</span>
        </div>
        <div id="time_grp">
          <div id="First_Half">
            <span>First Half</span>
          </div>
          <div id="Second_Half">
            <span>Second Half</span>
          </div>
          <input
            type="checkbox"
            class="chkbx_11"
            onChange={(e) => setStartFirstHalf(e.target.checked)}
            checked={startFirstHalf}
          />

          <input
            type="checkbox"
            class="chkbx_12"
            onChange={(e) => setStartSecondHalf(e.target.checked)}
            checked={startSecondHalf}
          />
        </div>
      </div>
      <div id="to_date_grp">
        <input
          type="date"
          class="to_bdr_input_2_dh"
          value={todate}
          onChange={(e) => {
            setToDate(e.target.value);
          }}
        />

        <div id="To_lbl_2_di">
          <span>To</span>
        </div>
        {disableChecks ? (
          ""
        ) : (
          <>
            <div id="First_Half_2">
              <span>First Half</span>
            </div>
            <div id="Second_Half_2">
              <span>Second Half</span>
            </div>
            <input
              type="checkbox"
              class="chkbx_21"
              onChange={(e) => setEndFirstHalf(e.target.checked)}
              checked={endFirstHalf}
            />

            <input
              type="checkbox"
              class="chkbx_22"
              onChange={(e) => setEndSecondHalf(e.target.checked)}
              checked={endSecondHalf}
            />
          </>
        )}
      </div>
      <div id="leave_type_grp">
        <select
          class="leave_reason"
          value={typeleave}
          onChange={(e) => setTypeLeave(e.target.value)}
        >
          <option key={"sick"} value={"sick"}>
            Sick
          </option>
          <option key={"personal"} value={"personal"}>
            Personal
          </option>

          <option key={"emergency"} value={"emergency"}>
            Emergency
          </option>
        </select>
        <div id="leave_type_lbl">
          <span>Leave Type</span>
        </div>
        <input
          placeholder="Approver"
          class="approver_input"
          value={approver.reportingToName}
        />
      </div>
    </div>
  );
}

export default LeavesPanel;
