const geoFindMe = () => {
    return new Promise(function (resolve, reject) {
    function success(position) {
        var coordinates = { latitude: JSON.stringify(position.coords.latitude), longitude: JSON.stringify(position.coords.longitude) }
      return resolve(coordinates);
    }
  
    function error() {
      throw reject(Error("Unable to retrieve your location!"))
    }
  
    if (!navigator.geolocation) {
      throw Error("Geolocation is not supported!");
    } else {
        return navigator.geolocation.getCurrentPosition(success, error, {enableHighAccuracy: true})
    }}
)
  }
export {geoFindMe} ;