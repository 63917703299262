import React from "react";
import "../../Styles/homeMob.css";
import { useState } from "react";
import { useContext } from "react";
import { UserContext } from "../../UserContext";
import { useEffect } from "react";

function FilterMob(props) {
  const { user, setUser } = useContext(UserContext);
  const [data, setData] = useState([]);
  const [disabled, setDisabled] = useState(true);

  function GetEmployees() {
    let empid = user._id;
    const apiurl =
      process.env.REACT_APP_API_URL +
      "/api/user/employeesdropdown?empid=" +
      empid;
    const apiparam = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };

    fetch(apiurl, apiparam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          localStorage.getItem("tab") != 7
            ? setData([{ _id: "all", name: "All Team Members" }, ...repos.data])
            : setData([...repos.data]);
          setDisabled(false);
        } else console.log(repos.message || repos);
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    GetEmployees();
  }, []);
  return (
    <div>
      <svg class="full_bg_design_mob_bo">
        <linearGradient
          id="full_bg_design_mob_bo"
          spreadMethod="pad"
          x1="0.5"
          x2="0.5"
          y1="0"
          y2="1"
        >
          <stop offset="0" stop-color="#b5ffb7" stop-opacity="1"></stop>
          <stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
        </linearGradient>
        <rect
          id="full_bg_design_mob_bo"
          rx="15"
          ry="15"
          x="0"
          y="0"
          width="299"
          height="541"
        ></rect>
      </svg>
      <div id="filter_input_grp_mob">
        <select
          class="employee_bdr_input_mob"
          value={props.employee || user._id}
          onChange={(e) => {
            props.changeEmployee(e.target.value);
          }}
          disabled={disabled}
        >
          {data.map((employee) => {
            return <option value={employee._id}>{employee.name}</option>;
          })}
        </select>
        <div id="Employee_lbl_mob">
          <span>Employee</span>
        </div>
        <input
          type="date"
          value={props.fromDate}
          onChange={(e) => props.changeFromDate(e.target.value)}
          class="from_bdr_input_mob"
        />

        <div id="From_lbl_mob">
          <span>From</span>
        </div>
        <input
          type="date"
          value={props.toDate}
          onChange={(e) => props.changeToDate(e.target.value)}
          class="to_bdr_input_mob"
        />

        <div id="To_lbl_mob">
          <span>To</span>
        </div>
      </div>
      <div id="filter_grp_search">
        <svg class="search_btn_mob">
          <ellipse
            id="search_btn_mob"
            rx="22.5"
            ry="22.5"
            cx="22.5"
            cy="22.5"
          ></ellipse>
        </svg>
        <button
          onClick={() => {
            props.onFilterClick();
            props.closeModal();
          }}
        >
          <img
            id="search1_btn_img_mob"
            src="search1_btn_img_mob.png"
            srcset="search1_btn_img_mob.png 1x"
            alt=""
          />
        </button>
      </div>
    </div>
  );
}

export default FilterMob;
