import { Button } from "@mui/material";
import React, { useState } from "react";

import { useHistory } from "react-router-dom";

function Reset({ location }) {
  var history = useHistory();
  const [userPassword, setUserPassword] = useState("");
  const [OTP, setOTP] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const query = new URLSearchParams(location.search);
  const token = query.get("token");
  const [OTPStatus, setOTPStatus] = useState(false);

  // if (!token) history.push("/");

  const handleResetPassword = () => {
    let email = localStorage.getItem("email");
    const apiUrl = process.env.REACT_APP_API_URL + "/api/user/verifyotp";
    var apiParam = {
      method: "POST",
      headers: {
        otp: OTP,
        email: email,
        new_password: userPassword,
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };

    fetch(apiUrl, apiParam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        alert(repos.message);

        history.push("/");
      })
      .catch((err) => alert(err));
  };
  return (
    <div>
      <div className="card ">
        <div clsassName="form-group ">
          <h2
            style={{
              textAlign: "center",
              color: process.env.REACT_APP_PRIMARY_COLOR,
            }}
          >
            Reset password{" "}
          </h2>

          <div class="form-group">
            <input
              class="form-control"
              name="OTP"
              placeholder="OTP"
              value={OTP}
              onChange={(e) => setOTP(e.target.value)}
              autocomplete="off"
            />
            <input
              type="password"
              class="form-control"
              name="password"
              placeholder="New Password"
              value={userPassword}
              onChange={(e) => setUserPassword(e.target.value)}
              autocomplete="off"
            />
            <input
              type="password"
              class="form-control"
              name="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              autocomplete="off"
            />

            <div style={{ textAlign: "center", marginBottom: "10px" }}>
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  handleResetPassword();
                }}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reset;
