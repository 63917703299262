import {
  Box,
  CircularProgress,
  IconButton,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import { ArrowBack } from "@mui/icons-material";
import { Alert } from "@mui/material";
import React, { useContext, useEffect, useState, useRef } from "react";
import { UserContext } from "../../UserContext";
import { getSender, getSenderFull } from "./ChatLogic";
import ProfileModal from "./ProfileModal";
import UpdateGroupChatModal from "./UpdateGroupChatModal";
import "../../../src/App.css";
import ScrollableChat from "./ScrollableChat";
import io from "socket.io-client";

const ENDPOINT = process.env.REACT_APP_API_URL;
var socket, selectedChatCompare;

const SingleChat = ({ fetchAgain, setFetchAgain }) => {
  const { user, selectedChat, setSelectedChat, notification, setNotification } =
    useContext(UserContext);
  const bottomRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newMessage, setNewMessage] = useState();
  const [snackbarMessage, setSnackBarMessage] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const [socketConnected, setSocketConnected] = useState(false);
  const [typing, setTyping] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [read, setRead] = useState(false);

  useEffect(() => {
    socket = io(ENDPOINT);
    socket.emit("setup", user);
    socket.on("connected", () => setSocketConnected(true));
    socket.on("typing", () => setIsTyping(true));
    socket.on("stop typing", () => setIsTyping(false));
  }, []);

  const fetchMessages = async (e) => {
    if (!selectedChat) {
      return;
    }
    const apiURL =
      process.env.REACT_APP_API_URL + "/api/message/" + selectedChat._id;
    const apiParams = {
      method: "GET",
      headers: {
        userid: user._id,
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };
    setLoading(true);
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          const { data } = repos;
          setMessages(data);
          setLoading(false);
          socket.emit("", selectedChat._id);
          scrollToBottom();
        }
      })
      .catch((err) => {
        setSnackBarMessage(err);
        setOpenToast(true);
      });
  };

  const sendMessage = (e) => {
    if (e.key === "Enter" && newMessage) {
      socket.emit("stop typing", selectedChat._id);
      setNewMessage("");
      const apiURL = process.env.REACT_APP_API_URL + "/api/message";
      const apiParams = {
        method: "POST",
        headers: {
          authtoken: localStorage.getItem("loggedintoken"),
          content: newMessage,
          chatid: selectedChat._id,
          currentuser: user._id,
        },
      };

      fetch(apiURL, apiParams)
        .then((res) => (res.status === 200 ? res.json() : res.text()))
        .then((repos) => {
          if (repos.data) {
            const { data } = repos;
            socket.emit("new message", data);
            setMessages([...messages, data]);
          }
        })
        .catch((err) => {
          setSnackBarMessage(err);
          setOpenToast(true);
        });
    }
  };
  const typingHandler = (e) => {
    setNewMessage(e.target.value);
    // typing indicator
    if (!socketConnected) return;

    if (!typing) {
      setTyping(true);
      socket.emit("typing", selectedChat._id);
    }
    let lastTypingTime = new Date().getTime();
    var timerLength = 3000;
    setTimeout(() => {
      var timeNow = new Date().getTime();
      var timeDiff = timeNow - lastTypingTime;

      if (timeDiff >= timerLength && typing) {
        socket.emit("stop typing", selectedChat._id);
        setTyping(false);
      }
    }, timerLength);
  };

  const scrollToBottom = () => {
    bottomRef.current &&
      bottomRef.current.scrollIntoView({ behaviour: "smooth" });
  };

  useEffect(() => {
    fetchMessages();
    selectedChatCompare = selectedChat;
  }, [selectedChat]);

  useEffect(scrollToBottom, [messages]);

  useEffect(() => {
    socket.on("message received", (newMessageReceived) => {
      if (
        !selectedChatCompare ||
        selectedChatCompare._id !== newMessageReceived.chat._id
      ) {
        //notification
        if (!notification.includes(newMessageReceived)) {
          setNotification([newMessageReceived, ...notification]);

          setFetchAgain(!fetchAgain);
        }
      } else {
        updateMsgStatus(newMessageReceived);
        setMessages([...messages, newMessageReceived]);
      }
    });
    scrollToBottom();
  }, []);

  useEffect(() => {
    socket.on("message received", (newMessageReceived) => {
      if (
        !selectedChatCompare ||
        selectedChatCompare._id !== newMessageReceived.chat._id
      ) {
      } else {
        setMessages([...messages, newMessageReceived]);
        scrollToBottom();
      }
    });
  });

  useEffect(() => {
    socket.on("seen updated", (newMessageReceived) => {
      if (messages.filter((msg) => msg._id === newMessageReceived[0]._id)[0]) {
        messages.filter(
          (msg) => msg._id === newMessageReceived[0]._id
        )[0].status = "seen";
      }
    });
  });

  const updateMsgStatus = (newMessageReceived) => {
    const apiURL = process.env.REACT_APP_API_URL + "/api/message/updatestatus";
    const apiParams = {
      method: "POST",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
        msgid: newMessageReceived._id,
        chatid: newMessageReceived.chat._id,
        status: "seen",
      },
    };

    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setMessages(repos.data);
          scrollToBottom();
          var msgupdate = repos.data.filter(
            (msg) => msg._id === newMessageReceived._id
          );
          socket.emit("seen", msgupdate);
        }
      })
      .catch((err) => {
        setSnackBarMessage(err);
        setOpenToast(true);
      });
  };

  return (
    <>
      {selectedChat ? (
        <>
          <Typography
            sx={{ fontsize: { base: "28px", md: "30px" } }}
            style={{
              paddingBottom: "3px",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* <IconButton
              style={{ display: { base: "flex", md: "none" } }}
              onClick={() => setSelectedChat("")}
            >
              <ArrowBack />
            </IconButton> */}
            {/* 
            {!selectedChat.isGroupChat ? (
              <>
                {getSender(user, selectedChat.users)}
                <ProfileModal user={getSenderFull(user, selectedChat.users)} />
              </>
            ) : (
              <>
                {selectedChat.chatName.toUpperCase()}
                <UpdateGroupChatModal
                  fetchAgain={fetchAgain}
                  setFetchAgain={setFetchAgain}
                  fetchMessages={fetchMessages}
                />
              </>
            )} */}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              padding: "3",
              bgcolor: "#E8E8E8",
              width: "100%",
              height: "100%",
              borderRadius: "25px",
              overflowY: "hidden",
            }}
          >
            {loading ? (
              <CircularProgress
                size={30}
                style={{ alignSelf: "center", margin: "auto" }}
              />
            ) : (
              <div style={{ overflow: "scroll" }}>
                <ScrollableChat messages={messages} read={read} />
                <div ref={bottomRef}></div>
              </div>
            )}
            {isTyping ? <div>typing....</div> : <></>}
            <TextField
              value={newMessage}
              onKeyDown={sendMessage}
              variant="filled"
              onChange={typingHandler}
              placeholder="Enter a Message"
            ></TextField>
          </Box>
        </>
      ) : (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Typography>Click on a User to start chatting</Typography>
        </Box>
      )}
      <Snackbar
        open={openToast}
        autoHideDuration={3000}
        onClose={() => setOpenToast(false)}
      >
        <Alert severity="error">{snackbarMessage}</Alert>
      </Snackbar>
    </>
  );
};

export default SingleChat;
