import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete from "@mui/material/Autocomplete";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { TextField, MenuItem, Button } from "@mui/material";
import { useState, useEffect } from "react";
function StoriesPanel(props) {
  //variable
  //useState
  const [open, setOpen] = useState(false);
  const [fromdate, setFromDate] = useState(new Date());
  const [todate, setToDate] = useState(new Date());
  const [leaveDays, setLeaveDays] = useState(1);
  const [typeleave, setTypeLeave] = useState("");
  const [leavereason, setLeaveReason] = useState("");
  const [approver, setApprover] = useState("");
  const [Checked, setChecked] = useState(false);
  const [startFirstHalf, setStartFirstHalf] = useState(true);
  const [startSecondHalf, setStartSecondHalf] = useState(true);
  const [endFirstHalf, setEndFirstHalf] = useState(true);
  const [endSecondHalf, setEndSecondHalf] = useState(true);
  const [doWhat, setDoWhat] = useState("");
  const [doWhy, setDoWhy] = useState("");
  //useEffect
  useEffect(() => {}, []);
  //functions
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    let empid = JSON.parse(
      atob(localStorage.getItem("loggedintoken").split(".")[1])
    ).reportingto;

    let apiUrl =
      process.env.REACT_APP_API_URL + "/api/user/register?empid=" + empid;
    const apiParam = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };

    fetch(apiUrl, apiParam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          console.log(repos.data);
          setApprover(repos.data);
          props.refreshGrid();
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
    setOpen(true);
  };
  const redirect = () => {
    if (typeleave === null || typeleave.length === 0) {
      alert("Please enter select leave type!");
      return;
    }
    if (leavereason === null || leavereason.length === 0) {
      alert("Please enter leave reason!");
      return;
    }
    var approve = approver._id;

    const apiurl = process.env.REACT_APP_API_URL + "/api/leaves";
    const apiparam = {
      method: "POST",
      headers: {
        empid: JSON.parse(localStorage.getItem("loggedInUser"))._id,
        fromdate: fromdate,
        fromfirsthalfleave: startFirstHalf,
        fromsecondhalfleave: startSecondHalf,
        todate: todate,
        tofirsthalfleave: endFirstHalf,
        tosecondhalfleave: endSecondHalf,
        typeleave: typeleave,
        leavedays: leaveDays,
        leavereason: leavereason,
        approver: approve,
        authtoken: localStorage.getItem("loggedintoken"),
      },
    };
    fetch(apiurl, apiparam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          alert("Leave request submitted successfully");
          props.refreshGrid();
        } else alert(repos.message || repos);
      });
    handleClose();
  };

  const handlereset = () => {
    setFromDate(new Date());
    setToDate(new Date());
    setTypeLeave("");
    setLeaveDays(
      1 - (!startFirstHalf ? 0.5 : 0) - (!startSecondHalf ? 0.5 : 0)
    );
    setLeaveReason("");
  };
  function addDays(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  //renderMethod
  return (
    <div style={{ padding: "0px 10px 0px 10px" }}>
      <form class="row g-3">
        <div class="col-md-4">
          <TextField
            id="initiative"
            style={{ width: "100%" }}
            select
            label="Initiative"
            value={typeleave}
            onChange={(e) => setTypeLeave(e.target.value)}
            required
          >
            <MenuItem key={"sick"} value={"sick"}>
              Sick{" "}
            </MenuItem>
            <MenuItem key={"personal"} value={"personal"}>
              Personal{" "}
            </MenuItem>
            <MenuItem key={"emergency"} value={"emergency"}>
              Emergency{" "}
            </MenuItem>
          </TextField>
        </div>
        <div class="col-md-4">
          <TextField
            id="epic"
            style={{ width: "100%" }}
            select
            label="Epic"
            value={typeleave}
            onChange={(e) => setTypeLeave(e.target.value)}
            required
          >
            <MenuItem key={"sick"} value={"sick"}>
              Sick{" "}
            </MenuItem>
            <MenuItem key={"personal"} value={"personal"}>
              Personal{" "}
            </MenuItem>
            <MenuItem key={"emergency"} value={"emergency"}>
              Emergency{" "}
            </MenuItem>
          </TextField>
        </div>
        <div class="col-md-4">
          <TextField
            id="user"
            style={{ width: "100%" }}
            select
            label="As a"
            value={typeleave}
            onChange={(e) => setTypeLeave(e.target.value)}
            required
          >
            <MenuItem key={"sick"} value={"sick"}>
              Sick{" "}
            </MenuItem>
            <MenuItem key={"personal"} value={"personal"}>
              Personal{" "}
            </MenuItem>
            <MenuItem key={"emergency"} value={"emergency"}>
              Emergency{" "}
            </MenuItem>
          </TextField>
        </div>
        <div class="col-md-6">
          <TextField
            id="doWhat"
            label="I want to"
            value={doWhat}
            onChange={(e) => setDoWhat(e.target.value)}
            required
            style={{ width: "100%" }}
            sx={{
              backgroundColor: "white",
            }}
          />
        </div>
        <div class="col-md-6">
          <TextField
            id="doWhy"
            style={{ width: "100%" }}
            label="so that"
            sx={{
              backgroundColor: "white",
            }}
            required
            value={doWhy}
            onChange={(e) => setDoWhy(e.target.value)}
          />
        </div>
        <div class="col-md-6">
          <div style={{ textAlign: "right", marginTop: "5px" }}>
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                redirect();
              }}
              style={{ margin: "5px" }}
              disabled={!(fromdate && todate)}
            >
              Create
            </Button>
          </div>
        </div>{" "}
        <div class="col-md-6">
          <div style={{ textAlign: "left", marginTop: "5px" }}>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                handlereset();
              }}
              style={{ margin: "5px" }}
            >
              Reset
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default StoriesPanel;
