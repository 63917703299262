import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Image from "./logo.jpg";
import { TextField, MenuItem, Button } from "@mui/material";
import "../Styles/login.css";
import { UserContext } from "../UserContext";

function Login() {
  var history = useHistory();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const { user, setUser } = useContext(UserContext);

  const redirect = () => {
    const apiUrl = process.env.REACT_APP_API_URL + "/api/user/login";
    var apiParam = {
      method: "POST",
      headers: { email: userName, password: password },
      credentials: "same-origin",
    };
    fetch(apiUrl, apiParam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          localStorage.setItem(
            "filterEmployee",
            JSON.parse(atob(repos.data.split(".")[1]))._id
          );
          localStorage.setItem("loggedintoken", repos.data);
          localStorage.setItem("loggedInUser", atob(repos.data.split(".")[1]));
          setUser(JSON.parse(atob(repos.data.split(".")[1])));
          history.push("/");
        } else alert(JSON.stringify(repos));
      })
      .catch((err) => alert(JSON.stringify(err)));
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      redirect();
    }
  };

  return (
    <div id="Employee_portal_OP">
      <svg class="Path_152_h" viewBox="697.506 -13 664.1 700.584">
        <linearGradient
          id="Path_152_h"
          spreadMethod="pad"
          x1="0.123"
          x2="1"
          y1="0"
          y2="1"
        >
          <stop offset="0" stop-color="#6fff6a" stop-opacity="1"></stop>
          <stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
        </linearGradient>
        <path
          id="Path_152_h"
          d="M 778.4480590820312 -13 C 778.4480590820312 -13 691.6524047851562 101.7135925292969 698.433349609375 157.3170318603516 C 705.2142944335938 212.9204711914062 760.8176879882812 294.2913513183594 758.1053466796875 333.62060546875 C 755.3930053710938 372.9498596191406 688.9400634765625 452.9645385742188 698.433349609375 508.5679931640625 C 707.9266357421875 564.1714477539062 758.1053466796875 687.5839233398438 758.1053466796875 687.5839233398438 L 1361.606079101562 687.5839233398438 L 1361.606079101562 -13 L 778.4480590820312 -13 Z"
        ></path>
      </svg>
      <svg class="Path_153" viewBox="1171.74 -13 97.645 78.097">
        <path
          id="Path_153"
          d="M 1235.48046875 -13 L 1269.385009765625 -13 L 1211.069213867188 65.09666442871094 L 1171.739990234375 65.09666442871094 L 1235.48046875 -13 Z"
        ></path>
      </svg>

      <img
        id="My_password-bro"
        src="My_password-bro.png"
        srcset="My_password-bro.png 1x, My_password-bro@2x.png 2x"
        alt="Password Bro"
      />
      <div class="login-box">
        <img
          id="logo_login"
          src="logo.png"
          srcset="logo.png 1x, logo@2x.png 2x"
          alt="operate live logo"
        />
        <form>
          <div class="user-box">
            <input
              type="text"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <label>Username</label>
          </div>
          <div class="user-box">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <label>Password</label>
          </div>
          <a>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <input
              id="logbtn"
              type="button"
              value="Login"
              onClick={(e) => redirect()}
            />
          </a>
        </form>
        <div class="Forgot_Password">
          <Link to="/forgotPassword">ForgotPassword?</Link>
        </div>
      </div>
    </div>
  );
}

export default Login;
